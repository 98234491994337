import React from 'react'
import { Load } from '../shared'

export const Placeholder = (props) => {
  return (
    <div className="card" style={{ height: 'calc(100vh - 186px)' }}>
      <Load width={208} height={30} className="m-t--2" />
      <div className="card-body card-body-fill" style={{ paddingTop: 32 }}>
        <Load height={50} />
      </div>
    </div>
  )
}
