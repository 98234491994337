import React, { useMemo } from 'react'
import moment from 'moment'
import {
  CartesianGrid,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { formatCommission, varClass, isBlank, map, step } from '../shared'

const formatDate = (value: string) => {
  const result = moment(value).format('MMM. D')
  if (!value || result === 'Invalid date') {
    return ''
  } else {
    return result
  }
}

export const HistoryAreaChart = (props) => {
  const { data, campaigns, colors } = props

  if (isBlank(data)) {
    return null
  }

  return (
    <>
      <div className="text-dark text-bold m-b-5">Commission history</div>
      <div className="card-border-top card-border-bottom background-body-light m-x--6">
        <ResponsiveContainer
          width="99.9%"
          height={240}
          className="history-area-chart"
        >
          <LineChart
            data={data}
            margin={{ top: 16, left: 24, right: 24, bottom: 16 }}
          >
            <CartesianGrid
              horizontal={false}
              strokeDasharray="6"
              stroke="#EDEFF2"
            />

            <Tooltip
              content={<GraphTooltip colors={colors} campaigns={campaigns} />}
            />

            {map(campaigns, (campaign) => (
              <React.Fragment key={campaign.campaignUuid}>
                {GraphLine(
                  `commissionFlat${campaign.campaignIndex}`,
                  'flat',
                  colors
                )}
                {GraphLine(
                  `commissionPercentage${campaign.campaignIndex}`,
                  'percentage',
                  colors
                )}
              </React.Fragment>
            ))}

            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              interval="preserveStartEnd"
              tick={<GraphXTick />}
            />
            <YAxis yAxisId="flat" type="number" domain={[0, 'dataMax']} hide />
            <YAxis
              yAxisId="percentage"
              type="number"
              domain={[0, 'dataMax']}
              hide
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

const GraphXTick = (props) => (
  <text x={props.x} y={props.y + 20} textAnchor="middle" fill="#828B9E">
    {formatDate(props.payload.value)}
  </text>
)

const GraphLine = (key, axis, colors) => {
  const colorIndex = parseInt(key[key.length - 1]) % colors.length

  return (
    <Line
      type={step} // "stepAfter"
      dataKey={key}
      yAxisId={axis}
      stroke={colors[colorIndex]}
      strokeWidth={2}
      dot={false}
      activeDot={{
        stroke: '#fff',
        strokeWidth: 8,
        r: 10,
        className: 'chart-dot',
      }}
      connectNulls
    />
  )
}

const GraphTooltip = (props) => {
  const { payload, campaigns, colors } = props

  if (isBlank(payload) || isBlank(payload[0])) {
    return null
  }

  const shownCampaigns = useMemo(
    () =>
      campaigns.reduce((result, campaign) => {
        if (campaign.campaignShowFlat || campaign.campaignShowPercentage) {
          if (
            payload[0].payload[`commissionFlat${campaign.campaignIndex}`] ||
            payload[0].payload[`commissionPercentage${campaign.campaignIndex}`]
          ) {
            result.push(campaign)
          }
        }
        return result
      }, []),
    [payload[0].payload.date]
  )

  if (isBlank(shownCampaigns)) {
    return null
  }

  return (
    <div className="chart-tooltip chart-tooltip-light">
      <div className="chart-tooltip-header">
        {payload[0].payload.date.split(' ')[0]}
      </div>
      <div className="chart-tooltip-body">
        <ul>
          {map(shownCampaigns, (campaign) =>
            campaign.campaignShowFlat || campaign.campaignShowPercentage ? (
              <li
                key={campaign.campaignUuid}
                className={varClass({
                  'chart-tooltip': true,
                })}
              >
                <span>
                  <span
                    className="chart-tooltip-dot"
                    style={{
                      background:
                        colors[campaign.campaignIndex % colors.length],
                    }}
                  />
                  {campaign.campaignName}
                </span>{' '}
                <span>
                  {formatCommission(
                    '',
                    payload[0].payload[
                      `commissionFlat${campaign.campaignIndex}`
                    ],
                    payload[0].payload[
                      `commissionPercentage${campaign.campaignIndex}`
                    ],
                    payload[0].payload[`currencyCode${campaign.campaignIndex}`],
                    true
                  )}
                </span>
              </li>
            ) : null
          )}
        </ul>
      </div>
    </div>
  )
}
