import React from 'react'
import { Load } from '../../shared'

export const Placeholder = (props) => {
  return (
    <>
      <div className="row m-b-20">
        <div className="card flex-1" style={{ height: 380 }}>
          <Load width={120} height={22} className="m-b-2" />
        </div>
        <div className="card flex-1" style={{ height: 380 }}>
          <Load width={120} height={22} className="m-b-2" />
        </div>
      </div>
      <div>
        <div className="card" style={{ height: 380 }}>
          <Load width={160} height={22} className="m-b-2" />
        </div>
      </div>
    </>
  )
}
