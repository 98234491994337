import React from 'react'

export const SmartLinks = (props) => {
  return <div />
}

// import React, { useEffect, useMemo, useRef, useState } from 'react'
// import SVG from 'react-inlinesvg'
// import { Placeholder } from './Placeholder'
// import {
//   Header,
//   host,
//   isBlank,
//   Loadable,
//   map,
//   NavFilter,
//   parseQuery,
//   Search,
//   splitUrl,
//   toArray,
//   truncate,
//   useApiGet,
//   useModal,
//   useOutsideClick,
//   varClass,
// } from '../shared'
// import moment from 'moment/moment'
// import { useForm } from 'react-hook-form'
//
// const isProduction = process.env.GATSBY_ENV === 'production'
//
// const perPage = 50
//
// const filterLabels = {
//   'sites': 'Site',
//   'merchant': 'Merchant',
//   'customTag': 'Custom Tag',
// }
//
// const filterOptions = {
//   'sites': {
//     'siteA': 'Site A',
//   },
//   'merchant': {
//     'merchantA': 'Merchant A',
//   },
//   'customTag': {
//     'tagA': 'Tag A',
//   },
// }
//
//
// const normalizeFilters = (filters) => {
//   const result: { [key: string]: any } = {}
//   return result
// }
//
// export const SmartLinks = (props) => {
//   const initialQuery = useMemo(() => parseQuery(location.search), [])
//
//   const data = useApiGet('/tools/v1/urlshorts')
//   const channels = useApiGet('/channels/v1/list')
//   const connections = useApiGet('/networks/v1/connections')
//   const advertisers = useApiGet('/advs/v1/list', { hasActiveCampaigns: true })
//
//   const channelOptions = useMemo(() => {
//     if (!channels || !Array.isArray(channels)) return []
//     return map(channels, (channel) => ({
//       value: channel.channelUuid,
//       label: host(channel.channelDomain),
//     }))
//   }, [channels])
//
//   const campaignOptions = useMemo(() => {
//     if (!advertisers || !Array.isArray(advertisers)) return []
//     return map(advertisers, (adv) => ({
//       value: adv.advertiserUuid,
//       label: host(adv.advertiserDomain),
//     }))
//   }, [advertisers])
//
//
//   const [filters, setFilters] = useState(() => {
//     return [
//       { key: 'sites', value: '' },
//       { key: 'merchant', value: '' },
//       { key: 'customTag', value: '' },
//     ]
//   })
//   const filterReset = filters
//     .filter((filter) => filter.value)
//     .map((filter) => filter.value)
//     .join(',')
//
//   const [search, setSearch] = useState('')
//
//
//   return (
//     <>
//       <Header label='Smart links' />
//       <Loadable data={data} placeholder={<Placeholder />}>
//         <Content
//           {...{
//             initialQuery,
//             data,
//             channelOptions,
//             campaignOptions,
//             search,
//             setSearch,
//             filters,
//             setFilters,
//           }}
//         />
//       </Loadable>
//     </>
//   )
// }
//
// const Content = (props) => {
//   const {
//     data,
//     channelOptions,
//     campaignOptions,
//     search,
//     setSearch,
//     filters,
//     setFilters,
//   } = props
//
//   return (
//     <div className='card smart-link-card'>
//       <Nav
//         {...{
//           search,
//           setSearch,
//           filters,
//           setFilters,
//           channelOptions,
//           campaignOptions,
//         }}
//       />
//       <SmartLinkTable
//         data={data}
//         filters={filters}
//         search={search}
//         channelOptions={channelOptions}
//         campaignOptions={campaignOptions}
//       />
//
//     </div>
//   )
// }
//
// const Nav = (props) => {
//   const { search, setSearch, filters, setFilters, channelOptions, campaignOptions } = props
//   const [shownSearch, setShownSearch] = useState(search)
//   const { showModal, hideModal } = useModal()
//
//   return (
//     <div className='page-nav m-b-4'>
//       <div className='row row-end row-center'>
//         <button
//           className='btn btn-primary m-b-30'
//           onClick={() => {
//             showModal(
//               <CreateOrUpdateSmartLinkModal
//                 hideModal={hideModal}
//                 showModal={showModal}
//                 channelOptions={channelOptions}
//                 campaignOptions={campaignOptions}
//                 smartLink={null}
//               />,
//               {
//                 cardClassName: 'smartlinks-modal',
//               },
//             )
//           }}
//         >
//           Create smartlink
//         </button>
//       </div>
//       <div className='m-b-18 text-primary text-regular text-normal'>Search smart links</div>
//       <div className='row m-b-4'>
//         <div className='flex-1 vertical-middle'>
//           <Search
//             value={shownSearch}
//             setValue={setShownSearch}
//             onSubmit={() => {
//               setSearch(shownSearch)
//             }}
//             placeholder='Quick search for smart links'
//             className='search w-60'
//             inputClassName='w-100 p-r-8'
//             highlight={!!shownSearch}
//           />
//           <button
//             className={varClass({
//               'link link-new-lighter': true,
//               'o-0 unclickable': !shownSearch,
//               'o-100': shownSearch,
//             })}
//             style={{
//               position: 'relative',
//               top: 2,
//               left: -32,
//               paddingTop: 4,
//               marginTop: -4,
//             }}
//             onClick={() => {
//               setShownSearch('')
//               setSearch('')
//             }}
//           >
//             <SVG src='/images/icon-times.svg' />
//           </button>
//           <button
//             className='btn btn-white btn-merchant-search m-l--6'
//             onClick={() => {
//               setSearch(shownSearch)
//             }}
//           >
//             Search
//           </button>
//         </div>
//         <button
//           className='btn btn-light background-white border-rounder new-card-border-button'
//           onClick={() => {
//           }}
//         >
//           <SVG src='/images/smartlinks/export-csv-icon.svg' className='m-r-10' />
//           <span className='text-small text-bold'>Export CSV</span>
//         </button>
//       </div>
//       <NavFilter
//         {...{
//           filters,
//           setFilters,
//           filterLabels,
//           filterOptions,
//         }}
//         onRemove={(index, key) => {
//           const newFilters = [...filters]
//           if (['trackerCountryCodes'].includes(key)) {
//             const filter = newFilters.find((filter) => filter.key === key)
//             filter.value = ''
//             filter.label = ''
//           } else {
//             newFilters.splice(index, 1)
//           }
//           setFilters(newFilters)
//         }}
//         hideNoValue
//         radio
//         hideAdd
//         isMultipleChoice
//       />
//     </div>
//   )
// }
//
// const SmartLinkTable = ({ data, search, filters, campaignOptions, channelOptions }) => {
//   const [showSmartLinks, setShowSmartLinks] = useState([])
//   const [handledData, setHandledData] = useState(() => toArray(data).map(i => ({ ...i, selected: false })))
//   const [checked, setChecked] = useState(false)
//   useEffect(() => {
//     if (search?.trim()) {
//       const normalizeTextSearch = search.trim().toLowerCase()
//       const searched = handledData.filter(item => item.urlShortName.toLowerCase().includes(normalizeTextSearch))
//       setShowSmartLinks([...searched])
//     } else {
//       setShowSmartLinks(handledData)
//     }
//   }, [search, handledData])
//
//   useEffect(() => {
//     setChecked(showSmartLinks.every(item => item.selected))
//   }, [showSmartLinks])
//
//   const onChangeChecked = () => {
//     setChecked(!checked)
//     setShowSmartLinks(showSmartLinks.map(item => ({ ...item, selected: !checked })))
//   }
//
//   return (
//     <>
//       {isBlank(showSmartLinks) ?
//         <div className='m-y-100 column column-fill column-center column-middle'>
//           <SVG src='images/smartlinks/no-smart-links-found.svg' />
//           <div className='m-t-30 text-dark text-bolder text-large'>No smart links found</div>
//           <div className='m-t-3 text-primary text-big text-normal'>We couldn't find any results. Change selected filters
//             or search.
//           </div>
//         </div>
//         : <div className='smart-link-table'>
//           <div className='p-x-10 row row-space-between'>
//             <div className='row row-fill row-center'>
//               <input type='checkbox' className='custom-control-input' checked={checked}
//                      onChange={onChangeChecked} />
//               <SVG src='images/channels/arrow-down-icon.svg' />
//             </div>
//             <div className='row row-fill row-center'>
//               <span className='text-light text-regular text-bold'>Sort by: </span>&nbsp;
//               <span className='text-blue text-regular text-bold cursor-pointer'>date</span>
//             </div>
//           </div>
//           <table className='table channels-index-table smart-link-table'>
//             <thead className='text-nowrap'>
//             <tr>
//               <SmartLinkTableHead
//                 label='Smartlink'
//                 className='w-45'
//               />
//               <SmartLinkTableHead
//                 label='Tracking'
//                 className='w-15'
//               />
//               <SmartLinkTableHead
//                 label='Created'
//                 className='w-14'
//               />
//               <SmartLinkTableHead
//                 label='Clicks'
//                 className='w-8 label-right'
//                 desc
//               />
//               <SmartLinkTableHead
//                 label='Action'
//                 className='w-18 label-center'
//                 withoutInfo={true}
//               />
//             </tr>
//             </thead>
//             <tbody>
//             {map(showSmartLinks, (smartLink, index) => (
//               <SmartLinkTableItem
//                 key={smartLink.urlShortCode}
//                 smartLink={smartLink}
//                 showSmartLinks={showSmartLinks}
//                 setShowSmartLinks={setShowSmartLinks}
//                 index={index}
//                 campaignOptions={campaignOptions}
//                 channelOptions={channelOptions}
//               />
//             ))}
//             </tbody>
//           </table>
//         </div>}
//     </>
//   )
// }
//
// const SmartLinkTableHead = (props) => {
//   const { label, className, withoutInfo } = props
//
//   return (
//     <th
//       className={varClass({
//         'smart-link-table-head': true,
//         [className]: !!className,
//       })}
//     >
//       {label &&
//         <span className='text-primary text-small text-normal o-60 row row-fill row-center'>
//           {label}
//           {!withoutInfo && <SVG src='/images/recommendations/icon-info.svg' className='m-l-1' />}
//         </span>}
//     </th>
//   )
// }
//
// const SmartLinkTableItem = (
//   {
//     smartLink,
//     index,
//     channelOptions,
//     campaignOptions,
//     showSmartLinks,
//     setShowSmartLinks,
//   },
// ) => {
//   const ref = useRef()
//   const [open, setOpen] = useState(false)
//   const [deactivated, setDeactivated] = useState(false)
//   const { showModal, hideModal } = useModal()
//   const [_, urlShortTrackingPath] = splitUrl(smartLink.urlShortTrackingUrl)
//
//   useEffect(() => {
//     if (deactivated) {
//       showModal(
//         <SuccessModal
//           message='Tracking deactivated'
//           type=''
//         />,
//         {
//           cardClassName: 'smartlinks-modal success-modal',
//         },
//       )
//     }
//     setDeactivated(false)
//   }, [deactivated])
//
//   useOutsideClick(ref, () => {
//     setOpen(false)
//   })
//
//   const handleCheckbox = () => {
//     const updated = showSmartLinks.map(item => {
//       if (item.urlShortCode === smartLink.urlShortCode) {
//         return { ...item, selected: !item.selected }
//       } else {
//         return { ...item }
//       }
//     })
//     setShowSmartLinks([...updated])
//   }
//
//   const onEdit = () => {
//     showModal(
//       <CreateOrUpdateSmartLinkModal
//         hideModal={hideModal}
//         showModal={showModal}
//         channelOptions={channelOptions}
//         campaignOptions={campaignOptions}
//         smartLink={smartLink}
//       />,
//       {
//         cardClassName: 'smartlinks-modal',
//       },
//     )
//   }
//
//   const renderTrackingStatus = () => {
//     const statuses = ['Campaign', 'Auto', 'Deactivated']
//     const status = statuses[index % statuses.length]
//     return (
//       <div className='row row-fill row-nowrap row-center'>
//         <span className={`tracking-status ${status.toLowerCase()}`}></span>
//         <span className='text-primary text-small text-bold text-center m-l-1'>{status}</span>
//         <SVG src='/images/recommendations/icon-info.svg' className='m-l-1' />
//       </div>
//     )
//   }
//
//   const renderMoreAction = () => {
//     return (
//       <div
//         className={varClass({
//           'smart-link-dropdown-button': true,
//           'open': open,
//         })}
//         ref={ref}
//       >
//         <SVG src='/images/smartlinks/more-icon.svg' className='m-l-4 m-t-1 cursor-pointer' onClick={() => {
//           setOpen(true)
//         }} />
//         <ul className='text-regular'>
//           <li>
//             <button
//               className='link'
//               onClick={(event) => {
//                 showModal(
//                   <DeactivateTrackingModal
//                     hideModal={hideModal}
//                     setDeactivated={setDeactivated}
//                   />,
//                   {
//                     cardClassName: 'smartlinks-modal deactivate-tracking-modal',
//                   },
//                 )
//               }}
//             >
//               Deactivate tracking
//             </button>
//           </li>
//           <li>
//             <button
//               className='link'
//               onClick={(event) => {
//               }}
//             >
//               Go to reports
//             </button>
//           </li>
//           <li>
//             <button
//               className='link'
//               onClick={onEdit}
//             >
//               Edit
//             </button>
//           </li>
//         </ul>
//       </div>
//     )
//   }
//
//   return (
//     <tr className='smart-link-table-item'>
//       <td className='smart-link-table-td'>
//         <div className='row row-center row-fill action-info'>
//           <input type='checkbox' className='custom-control-input' checked={smartLink.selected}
//                  onChange={handleCheckbox} />
//           <div className='column column-middle column-fill m-l-3'>
//             <div className='text-bolder text-regular text-primary'>{smartLink.urlShortName}</div>
//             <a className='text-new-blue text-regular text-undecorated text-normal' href={smartLink.urlShortTrackingUrl}
//                target='_blank'>{truncate(urlShortTrackingPath, 25)}</a>
//           </div>
//           <div className='row row-center row-fill m-l-3 actions'>
//             <SVG src='/images/icon-copy-2.svg' className='m-l-3 cursor-pointer' />
//             <SVG src='/images/icon-external-link.svg' className='m-l-3 cursor-pointer' />
//             <SVG src='/images/icon-edit.svg' className='m-l-3 cursor-pointer' onClick={onEdit} />
//             <SVG src='/images/icon-chart.svg' className='m-l-3 cursor-pointer' />
//           </div>
//         </div>
//       </td>
//       <td className='smart-link-table-td'>
//         {renderTrackingStatus()}
//       </td>
//       <td className='smart-link-table-td'>
//         <div className='row row-fill row-center text-primary text-small text-bold'>
//           <SVG src='/images/smartlinks/calendar-icon.svg' className='text-light' />
//           <span className='m-l-1'>{moment(smartLink.urlShortCreatedDatetime).format('DD MMM,  YYYY')}</span>
//         </div>
//       </td>
//       <td className='smart-link-table-td'>
//         <div className='text-primary text-small text-bolder text-right'>{smartLink.clicksCount}</div>
//       </td>
//       <td className='smart-link-table-td'>
//         <div className='row row-fill row-center row-middle'>
//           <div className='text-regular text-blue text-bold cursor-pointer m-l-4'
//                onClick={(event) => {
//                }}>
//             <span>Copy smart link</span>
//           </div>
//           {renderMoreAction()}
//         </div>
//       </td>
//     </tr>
//   )
// }
//
// const DeactivateTrackingModal = ({ hideModal, setDeactivated }) => {
//
//   return <div className='column gap-4 p-x-6 p-y-5'>
//     <SVG src='/images/smartlinks/alert-info-icon.svg' />
//     <div className='text-dark text-bigger text-bolder'>Deactivate tracking</div>
//     <div className='deactivated-desc text-big text-normal'>Deactivation supportive text.</div>
//     <div className='row row-center'>
//       <button
//         className='btn border-none background-body text-primary text-regular text-bolder' onClick={() => {
//         hideModal()
//         setDeactivated(false)
//       }}>
//         No, cancel
//       </button>
//       <button
//         className='btn btn-primary background-new-blue border-new-blue text-regular text-bolder' onClick={() => {
//         hideModal()
//         setDeactivated(true)
//       }}>
//         Deactivate tracking
//       </button>
//     </div>
//   </div>
//
// }
//
// const SuccessModal = ({ message, type = 'generate-link' }) => {
//   return <div className={varClass({
//     'column column-fill column-center column-middle p-x-6 p-y-5': true,
//     'gap-4': !type,
//   })}>
//     <SVG src='/images/smartlinks/success-info-icon.svg' />
//     <div className={varClass({
//       'text-blue text-regular text-bolder': !type,
//       'text-bolder text-bigger text-dark': !!type,
//     })}>{message}</div>
//     {!!type && <>
//       <div className='text-blue text-regular text-normal m-t-8'>
//         <span>https://heylink.com?id=1233232323$</span>
//       </div>
//       <div className='row row-center gap-4 m-t-7'>
//         <div className='column column-center gap-1'>
//           <SVG src='/images/icon-copy-2.svg' className='cursor-pointer text-primary' />
//           <span className='text-regular text-primary text-normal'>Copy</span>
//         </div>
//         <div className='column column-center gap-1'>
//           <SVG src='/images/icon-external-link.svg' className='cursor-pointer text-primary' />
//           <span className='text-regular text-primary text-normal'>Test</span>
//         </div>
//         <div className='column column-center gap-1'>
//           <SVG src='/images/icon-edit.svg' className='cursor-pointer text-primary' />
//           <span className='text-regular text-primary text-normal'>Edit</span>
//         </div>
//       </div>
//     </>}
//   </div>
// }
//
// const CreateOrUpdateSmartLinkModal = ({ showModal, hideModal, smartLink, channelOptions, campaignOptions }) => {
//   const [showAdvancedFilters, setShowAdvancedFilters] = useState(false)
//   const form = useForm({
//     defaultValues: {
//       site: smartLink ? smartLink.channel.channelUuid : '',
//       urlShortName: smartLink ? smartLink.urlShortName : null,
//     },
//   })
//   const { register, handleSubmit, control, setValue, formState } = form
//
//   const onGenerateLink = () => {
//     showModal(
//       <SuccessModal
//         message='Generated smart link'
//       />,
//       {
//         cardClassName: 'smartlinks-modal success-modal',
//       },
//     )
//   }
//   const renderSelectOptions = (label, field, placeHolder, options) => {
//     return <div
//       className={varClass({
//         'control control-select control-smartlinks flex-1': true,
//       })}
//     >
//       <label className='text-regular text-dark text-bold'>{label}</label>
//       <select
//         className='w-100 background-body border-none text-primary text-regular text-normal'
//         {...register(field, {
//           required: true,
//           validate: (value) => value !== placeHolder,
//         })}
//         onChange={(event) => {
//           form.setValue(field, event.target.value)
//         }}
//       >
//         <option value=''>{placeHolder}</option>
//         {options.map(item => <option key={item.value} value={item.value}>{item.label}</option>)}
//       </select>
//     </div>
//   }
//   return <div className='column column-fill p-x-6 p-y-5'>
//     <div
//       className='text-dark text-bolder text-bigger m-b-50'>
//       {smartLink ? 'Edit smart link' : 'Create smart link'}
//     </div>
//     {renderSelectOptions('Where to use smart link', 'site', 'Choose site', channelOptions)}
//     <div className='control w-100 m-t-20'>
//       <label className='text-regular text-dark text-bold'>Paste a URL to turn into an affiliate link</label>
//       <input
//         placeholder='www.adidas.com'
//         className='background-body border-none text-primary text-regular text-normal'
//         type='text'
//         {...register('urlShortName', {
//           required: true,
//         })}
//       />
//     </div>
//     <div className='row row-center row-end m-t-15 m-b-20'>
//       <span
//         className='text-new-blue text-smaller text-normal cursor-pointer'
//         onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}>Advanced filters
//       </span>
//     </div>
//     {showAdvancedFilters && <>
//       {renderSelectOptions('Connect to specific campaign', 'campaign', 'Select campaign', channelOptions)}
//       <div className='control w-100 m-t-20'>
//         <label className='text-regular text-dark text-bold'>Link name (Optional)
//           <SVG src='/images/recommendations/icon-info.svg' /></label>
//         <input
//           placeholder='Add custom tracking'
//           className='background-body border-none text-primary text-regular text-normal'
//           type='text'
//           {...register('linkName', {})}
//         />
//         <span className='text-light text-smaller text-normal'>Total character limit 10/50 left</span>
//       </div>
//
//       <div className='control w-100 m-t-20'>
//         <label className='text-regular text-dark text-bold'>Referring page URL (Optional)
//           <SVG src='/images/recommendations/icon-info.svg' /></label>
//         <input
//           placeholder="The page URL where you'll place the link"
//           className='background-body border-none text-primary text-regular text-normal'
//           type='text'
//           {...register('referringPageUrl', {})}
//         />
//       </div>
//
//
//       <div className='control w-100 m-y-20'>
//         <label className='text-regular text-dark text-bold'>Custom ID (Optional)
//           <SVG src='/images/recommendations/icon-info.svg' /></label>
//         <input
//           placeholder='Add custom tracking'
//           className='background-body border-none text-primary text-regular text-normal'
//           type='text'
//           {...register('customId', {})}
//         />
//         <span className='text-light text-smaller text-normal'>Total character limit 39/100 left</span>
//       </div>
//     </>}
//
//     <div className='row row-center row-end'>
//       <button
//         className='btn btn-primary text-regular text-bolder' onClick={() => {
//         onGenerateLink()
//       }}>
//         {!!smartLink ? 'Update' : 'Get smart link'}
//       </button>
//     </div>
//   </div>
// }
