import React from 'react'
import { Load } from '../shared'

export const Placeholder = (props) => {
  return (
    <>
      <div className="card">
        <div className="row">
          <Load height={128} className="flex-1-3" />
          <Load height={128} className="flex-1-3" />
          <Load height={128} className="flex-1-3" />
          <Load height={128} className="flex-1-3" />
          <Load height={128} className="flex-1-3" />
          <Load height={128} className="flex-1-3" />
          <Load height={128} className="flex-1-3" />
          <Load height={128} className="flex-1-3" />
        </div>
      </div>
    </>
  )
}
