import React, { useState, useEffect, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { isEqual, pick, pickBy } from 'lodash'
import SVG from 'react-inlinesvg'
import useIsMounted from 'ismounted'
import { Placeholder } from './Placeholder'
import {
  Header,
  Loadable,
  TableHead,
  Tooltip,
  WithTooltip,
  Select,
  CountryPhoneInput,
  useModal,
  useSnackbar,
  useApiGet,
  useFormSubmit,
  useSortableTable,
  useCounter,
  formatShortDatetime,
  newTableSort,
  map,
  varClass,
  inTimezone,
  responseError,
  timezones,
  timezoneOffsets,
} from '../../shared'
import { postRequest, putRequest, deleteRequest } from '../../../services/api'
import {
  updateToken,
  getCurrentCurrency,
  setCurrentCurrency,
  getCurrentTimezone,
  setCurrentTimezone,
} from '../../../services/auth'

const defaultUser = {
  userCurrencyCode: 'DKK',
  userIsActive: true,
}

const userSort = newTableSort((a: any, b: any, key: string) => {
  if (key === 'userFullname') {
    return [
      `${a.userFirstname} ${a.userLastname}`,
      `${b.userFirstname} ${b.userLastname}`,
    ]
  }
})

const timezoneOptions = Object.keys(timezones).reduce(
  (result, key) => [
    ...result,
    {
      value: key,
      label: timezones[key],
    },
  ],
  []
)

export const Profile = (props) => {
  const [reloadUserRef, reloadUser] = useCounter()
  const user = useApiGet('/profile/v1/me', undefined, [reloadUserRef])

  const [reloadUsersRef, reloadUsers] = useCounter()
  const users = useApiGet('/profile/v1/users', undefined, [reloadUsersRef])

  const [refreshTooltipRef, refreshTooltip] = useCounter()

  const currencies = useApiGet('/currencies/v1/list')
  const sortedCurrencies = useMemo(
    () => map(currencies, (currency) => currency.currencyCode)?.sort(),
    [currencies]
  )

  return (
    <>
      <Header label="Account" />
      <Loadable
        data={user && users && currencies}
        placeholder={<Placeholder />}
      >
        <div className="profile-settings">
          <div className="row m-b-20">
            <ProfileEdit user={user} reloadUser={reloadUser} />
            <AccountSettings
              user={user}
              reloadUser={reloadUser}
              currencies={sortedCurrencies}
            />
          </div>
          <UserManagement
            users={users}
            currentUser={user}
            reloadUsers={reloadUsers}
            currencies={sortedCurrencies}
            refreshTooltip={refreshTooltip}
          />
        </div>
        <Tooltip
          id="profile-tooltip"
          type="dark"
          place="top"
          rebuild={[refreshTooltipRef]}
        />
      </Loadable>
    </>
  )
}

const ProfileEdit = (props) => {
  const { user, reloadUser } = props

  const form = useForm<any>({
    defaultValues: user,
  })
  const { register, handleSubmit, setValue, control } = form

  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  const anyChanges = form.formState.isDirty

  const [submitUser, submitting] = useFormSubmit(async (values) => {
    let response = undefined

    delete values.phone

    const initialUser = pickBy(user, (_, key) =>
      Object.keys(values).includes(key)
    )
    const newUser = pickBy(values, (_, key) =>
      Object.keys(initialUser).includes(key)
    )

    if (!isEqual(newUser, initialUser)) {
      const userCurrencyCode = getCurrentCurrency() || user.userCurrencyCode

      response = await putRequest(
        `/profile/v1/users/${user.userUuid}`,
        pick(
          {
            ...defaultUser,
            ...user,
            ...values,
            userCurrencyCode,
          },
          [
            'userIsActive',
            'userFirstname',
            'userLastname',
            'userPhoneCountryCode',
            'userPhone',
            'userEmail',
            'userTitle',
            'userCurrencyCode',
          ]
        )
      )

      // setCurrentCurrency(values.userCurrencyCode)
    }

    if (response.code === 200) {
      showSnackbar('Profile updated successfully')
      reloadUser()
    } else {
      showSnackbar(
        response.data?.replace(/^"user/, '"User ') || 'Something went wrong',
        {
          type: 'alert',
        }
      )
    }
  })

  return (
    <div className="flex-1 card p-6 p-t-8">
      <div className="text-dark text-bolder m-b-20">User details</div>

      <form onSubmit={handleSubmit(submitUser)}>
        <div className="row m-b-5">
          <div className="control block flex-1-2">
            <label>First name</label>
            <input
              type="text"
              className="w-100"
              {...register('userFirstname')}
            />
          </div>
          <div className="control block flex-1-2">
            <label>Last name</label>
            <input
              type="text"
              className="w-100"
              {...register('userLastname')}
            />
          </div>
        </div>
        <div className="row m-b-5">
          <div className="control control-phone flex-1 flex-1-2">
            <label>Phone number</label>
            <Controller
              control={control}
              name="userPhone"
              render={(props) => (
                <CountryPhoneInput
                  name={props.field.name}
                  initialCode={user.userPhoneCountryCode || ''}
                  initialPhone={user.userPhone || ''}
                  onChange={(values) => {
                    setValue('userPhoneCountryCode', values.code)
                    props.field.onChange(values.phone)
                  }}
                />
              )}
            />
          </div>
          <div className="control flex-1 flex-1-2">
            <label>E-Mail</label>
            <input type="email" className="w-100" {...register('userEmail')} />
          </div>
        </div>
        <div className="row m-b-8">
          <div className="control flex-1 flex-1-2">
            <label>Title</label>
            <input
              type="text"
              className="w-100"
              placeholder="Your title"
              {...register('userTitle')}
            />
          </div>
          {/*
            <div className="control control-select flex-1 flex-1-2">
              <label>Currency</label>
              <select {...register('userCurrencyCode')}>
                {map(currencies, (currencyCode) => (
                  <option key={currencyCode} value={currencyCode}>
                    {currencyCode}
                  </option>
                ))}
              </select>
            </div>
            */}
        </div>
        <div className="row row-center row-space-between">
          <button
            type="button"
            className="btn btn-md text-bolder"
            onClick={() =>
              showModal(
                <PasswordResetModal user={user} showSnackbar={showSnackbar} />
              )
            }
          >
            Change Password
          </button>
          <button
            className={varClass({
              'btn btn-primary btn-md': true,
              'o-0': !anyChanges,
            })}
            disabled={!anyChanges || submitting}
          >
            Save changes
          </button>
        </div>
      </form>
    </div>
  )
}

const UserManagement = (props) => {
  const { users, currentUser, reloadUsers, currencies, refreshTooltip } = props

  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'userFullname',
    dir: 'asc',
  })

  useEffect(() => {
    refreshTooltip()
  }, [users, sort, dir])

  const headProps = { sort, dir, toggleSort }

  const shownUsers = userSort(users, sort, dir)

  return (
    <div className="flex-1 card p-6 p-b-5">
      <div className="row row-space-between">
        <div className="text-dark text-bolder m-t-2">Users</div>
        <button
          className="btn btn-white-bordered text-bolder"
          onClick={() =>
            showModal(
              <NewUserModal
                currentUser={currentUser}
                reloadUsers={reloadUsers}
                currencies={currencies}
              />
            )
          }
        >
          <SVG
            src="/images/icon-plus-color.svg"
            width={18}
            height={18}
            className="o-50 m-l--1 m-r-15"
          />
          Add member
        </button>
      </div>

      <div className="card-body card-body-fill m-t--1">
        <div className="table-container-overflow">
          <table className="table table-line-rows table-sortable2 table-hoverable text-nowrap">
            <thead className="table-sticky">
              <tr>
                <TableHead value="userFullname" label="Name" {...headProps} />
                <TableHead
                  value="userLoginDatetime"
                  label="Last log in"
                  desc
                  align="right"
                  {...headProps}
                />
                <TableHead
                  value="userUpdatedDatetime"
                  label="Last updated"
                  desc
                  align="right"
                  {...headProps}
                />
                <TableHead
                  value="userTitle"
                  label="Role"
                  align="right"
                  {...headProps}
                />
                <th className="text-center">Actions</th>
              </tr>
            </thead>

            <tbody>
              {map(shownUsers, (user) => (
                <UserItem
                  key={user.userUuid}
                  user={user}
                  currencies={currencies}
                  sort={sort}
                  showModal={showModal}
                  showSnackbar={showSnackbar}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const AccountSettings = (props) => {
  const { user, reloadUser, currencies } = props

  const { showSnackbar } = useSnackbar()

  const currencyOptions = useMemo(
    () =>
      currencies.reduce(
        (result, currency) => [
          ...result,
          {
            value: currency,
            label: currency,
          },
        ],
        []
      ),
    [currencies]
  )

  const [currencyCode, setCurrencyCode] = useState(getCurrentCurrency)
  const [timezone, setTimezone] = useState(getCurrentTimezone)

  const [submittingCurrency, setSubmittingCurrency] = useState(false)
  const submitCurrency = async (newCurrencyCode) => {
    if (submittingCurrency) return

    setSubmittingCurrency(true)

    setCurrencyCode(newCurrencyCode)

    const response = await putRequest(
      `/profile/v1/users/${user.userUuid}/currencycode`,
      { userCurrencyCode: newCurrencyCode }
    )

    setCurrentCurrency(newCurrencyCode)

    setSubmittingCurrency(false)

    if (response.code === 200) {
      showSnackbar('Profile updated successfully')
      updateToken(response.data)
      reloadUser()
    } else {
      showSnackbar(
        response.data?.replace(/^"user/, '"User ') || 'Something went wrong',
        {
          type: 'alert',
        }
      )
    }
  }

  const [submittingTimezone, setSubmittingTimezone] = useState(false)
  const submitTimezone = async (newTimezone) => {
    if (submittingTimezone) return

    setSubmittingTimezone(true)

    setTimezone(newTimezone)

    const response = await putRequest(
      `/profile/v1/users/${user.userUuid}/timezone`,
      { userTimezone: newTimezone }
    )

    setCurrentTimezone(newTimezone)

    setSubmittingTimezone(false)

    if (response.code === 200) {
      showSnackbar('Profile updated successfully')
      updateToken(response.data)
      reloadUser()
    } else {
      showSnackbar(
        response.data?.replace(/^"user/, '"User ') || 'Something went wrong',
        {
          type: 'alert',
        }
      )
    }
  }

  return (
    <div className="flex-1 card p-6 p-t-8 p-b-4">
      <div className="text-dark text-bolder m-b-20">Currency & Timezone</div>

      <div className="m-b-5">
        <div className="control control-select flex-1 w-100 w-lg-up-50">
          <label>Currency</label>
          <Select
            value={currencyOptions.find(
              (option) => option.value === currencyCode
            )}
            onChange={(element) => {
              if (!submittingCurrency) {
                submitCurrency(element.value)
              }
            }}
            options={currencyOptions}
            isSearchable
          />
        </div>
      </div>

      <div className="m-b-5">
        <div className="control control-select2 flex-1 w-100 w-lg-up-50">
          <label>Timezone</label>
          <Select
            value={timezoneOptions.find((option) => option.value === timezone)}
            onChange={(element) => {
              if (!submittingTimezone) {
                submitTimezone(element.value)
              }
            }}
            options={timezoneOptions}
            getOptionLabel={(props) => {
              const labelLeft = props.label
              const labelRight = timezoneOffsets[props.value]
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>{labelLeft}</span>
                  <span
                    style={{
                      fontSize: 13,
                      opacity: 0.75,
                    }}
                  >
                    {labelRight
                      ? labelRight === '+00:00'
                        ? '(UTC)'
                        : `(${labelRight})`
                      : null}
                  </span>
                </div>
              )
            }}
            isSearchable
          />
        </div>
      </div>
    </div>
  )
}

const PasswordResetModal = (props) => {
  const { user, showSnackbar } = props

  const { hideModal } = useModal()

  const repeatMinDelay = 30000

  const [lastRequest, setLastRequest] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  const [page, setPage] = useState(0)

  const isMounted = useIsMounted()
  const [error, setError] = useState('')
  const showError = (message: string, delay: number) => {
    setError(message)
    setTimeout(() => {
      if (isMounted.current) {
        setError('')
      }
    }, delay)
  }

  const [resetToken, setResetToken] = useState([])
  const updateResetToken = (index: number) => (event: any) => {
    let element = event.target.closest('.control')
    const newValue = event.target.value.split('')
    const newResetToken = [...resetToken]

    if (newValue.length <= 0) {
      newResetToken[index] = ''
      if (element.previousSibling && element.previousSibling.children[0]) {
        element.previousSibling.children[0].focus()
      }
    }

    let n = 0
    for (const i in newValue) {
      n = parseInt(i)
      if (index + n < 4) {
        newResetToken[index + n] = newValue[n] || ''
        if (element.nextSibling) {
          element = element.nextSibling
          element.children[0]?.focus()
        } else {
          break
        }
      }
    }

    setResetToken(newResetToken)
  }
  const isResetTokenFilled =
    resetToken[0] && resetToken[1] && resetToken[2] && resetToken[3]

  const [newPassword, setNewPassword] = useState('')
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
  const isNewPasswordFilled = newPassword && newPasswordRepeat

  const gotoPage1 = async () => {
    setSubmitting(true)

    await postRequest('/auth/v1/password/reset/request', {
      userEmail: user.userEmail,
    })
    setLastRequest(Date.now())

    setSubmitting(false)
    setPage(1)
  }

  const redoPage1 = async () => {
    if (Date.now() - lastRequest > repeatMinDelay) {
      await postRequest('/auth/v1/password/reset/request', {
        userEmail: user.userEmail,
      })
    } else {
      if (error === '') {
        showError(
          'Wait a few seconds before repeating your request',
          lastRequest + repeatMinDelay - Date.now()
        )
      }
    }
  }

  const gotoPage2 = async () => {
    setSubmitting(true)
    setError('')

    const response = await postRequest('/auth/v1/password/reset/verify', {
      userEmail: user.userEmail,
      resetToken: resetToken.join(''),
    })

    setSubmitting(false)

    if (response.code === 200) {
      setPage(2)
    } else {
      showError(responseError(response, 'Incorrect verify code'), 10000)
    }
  }

  const gotoPage3 = async () => {
    if (!newPassword) {
      showError("Password can't be blank", 10000)
      return
    }

    if (newPassword.length < 8) {
      showError('Password must be at least 8 characters long', 10000)
      return
    }

    if (newPassword !== newPasswordRepeat) {
      showError("Repeated password don't match", 10000)
      return
    }

    setSubmitting(true)
    setError('')

    const response = await postRequest('/auth/v1/password/reset', {
      userEmail: user.userEmail,
      userPassword: newPassword,
      resetToken: resetToken.join(''),
    })

    setSubmitting(false)

    if (response.code === 200) {
      hideModal()
      showSnackbar('Password updated successfully')
    } else {
      showError(responseError(response), 10000)
    }
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Change password</div>
      </div>
      <div className="card-body">
        {page === 0 && (
          <>
            <div>
              Before we can reset your password you need to verify you email by
              typing a 4 digit code sent to your email.
            </div>
            <div className="text-right m-t-40">
              <button
                className="btn btn-dark"
                onClick={gotoPage1}
                disabled={submitting}
              >
                Next
              </button>
            </div>
          </>
        )}

        {page === 1 && (
          <>
            <div className="m-b-40">
              Before we can reset your password you need to verify you email by
              typing a 4 digit code sent to your email.
            </div>
            <div className="text-center m-b-40">
              <div className="profile-verify-controls">
                <div className="control">
                  <input
                    type="text"
                    value={resetToken[0] || ''}
                    onChange={updateResetToken(0)}
                  />
                </div>
                <div className="control">
                  <input
                    type="text"
                    value={resetToken[1] || ''}
                    onChange={updateResetToken(1)}
                  />
                </div>
                <div className="control">
                  <input
                    type="text"
                    value={resetToken[2] || ''}
                    onChange={updateResetToken(2)}
                  />
                </div>
                <div className="control">
                  <input
                    type="text"
                    value={resetToken[3] || ''}
                    onChange={updateResetToken(3)}
                  />
                </div>
              </div>
            </div>
            {error && <span className="text-red m-b-2">{error}</span>}
            <div className="row row-center row-space-between">
              <button
                className="text-light link"
                onClick={redoPage1}
                disabled={submitting}
              >
                Resend code
              </button>
              <button
                className="btn btn-dark"
                onClick={gotoPage2}
                disabled={!isResetTokenFilled || submitting}
              >
                Verify
              </button>
            </div>
          </>
        )}

        {page === 2 && (
          <>
            <div className="profile-new-password-controls">
              <div className="control block m-b-5">
                <label>New password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                />
                <button
                  className={varClass({
                    'link': true,
                    'active': showPassword,
                  })}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <SVG src="/images/settings/icon-eye.svg" />
                </button>
              </div>
              <PasswordStrength password={newPassword} />
              <div className="control block m-b-5">
                <label>Verify new password</label>
                <input
                  type={showPasswordRepeat ? 'text' : 'password'}
                  value={newPasswordRepeat}
                  onChange={(event) => setNewPasswordRepeat(event.target.value)}
                />
                <button
                  className={varClass({
                    'link': true,
                    'active': showPasswordRepeat,
                  })}
                  onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                >
                  <SVG src="/images/settings/icon-eye.svg" />
                </button>
              </div>
            </div>
            <div className="row row-bottom row-space-between m-t-60">
              <div>
                {error ? (
                  <span className="text-red m-r-2">{error}</span>
                ) : (
                  <span className="text-small text-bold">
                    <SVG
                      src="/images/settings/icon-check.svg"
                      className={varClass({
                        'm-r-15': true,
                        'o-0': newPassword.length < 8,
                      })}
                    />
                    Minimum 8 characters
                  </span>
                )}
              </div>
              <button
                className="btn btn-primary"
                onClick={gotoPage3}
                disabled={!isNewPasswordFilled || submitting}
              >
                Change password
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

const PasswordStrength = (props) => {
  const { password } = props

  const strength = useMemo(() => {
    let result = 0
    if (!password) return result
    if (password !== password.toUpperCase()) result += 1
    if (password !== password.toLowerCase()) result += 1
    if (password.match(/[0-9]/)) result += 1
    if (password.match(/[^A-Za-z0-9]/)) result += 1
    if (password.length > 16) result += 1
    if (password.length < 8) return Math.min(result, 2)
    return Math.min(result, 4)
  }, [password])

  return (
    <div className="password-strength">
      <div className="strength-pins">
        {map(4, (_, i) => (
          <div
            key={i}
            className={varClass({
              [`pin pin-${i}`]: true,
              'pin-active': strength > i,
            })}
          />
        ))}
      </div>
      <div className="strength-label">
        {strength === 1 && 'Try again, that’s too easy.'}
        {strength === 2 && 'It’s ok, but you can do better…'}
        {strength === 3 && 'Good one!'}
        {strength === 4 && 'That’s perfect!'}
      </div>
    </div>
  )
}

const UserItem = (props) => {
  const { user, reloadUsers, currencies, sort, showModal, showSnackbar } = props

  return (
    <tr>
      <td
        className={varClass({
          'text-nowrap p-y-3 p-r-2': true,
          'sort-highlight': sort === 'userFullname',
        })}
      >
        <div className="row row-center row-space-between row-nowrap">
          <div className="row row-center row-narrow row-nowrap">
            <SVG src="/images/aside-user.svg" width={36} height={36} />
            <div>
              <div className="text-dark text-bold m-b-1">
                {user.userFirstname} {user.userLastname}
              </div>
              <div className="text-small">{user.userEmail}</div>
            </div>
          </div>
        </div>
      </td>
      <td
        className={varClass({
          'text-right': true,
          'sort-highlight': sort === 'userLoginDatetime',
        })}
      >
        {formatShortDatetime(inTimezone(user.userLoginDatetime))}
      </td>
      <td
        className={varClass({
          'text-right': true,
          'sort-highlight': sort === 'userUpdatedDatetime',
        })}
      >
        {formatShortDatetime(inTimezone(user.userUpdatedDatetime))}
      </td>
      <td
        className={varClass({
          'text-right': true,
          'sort-highlight': sort === 'userTitle',
        })}
      >
        {user.userTitle}
      </td>
      <td>
        <div className="row row-middle row-fill row-nowrap">
          <WithTooltip
            title="Edit"
            text={
              <button
                className="link link-light-to-blue"
                onClick={() => {
                  showModal(
                    <EditUserModal
                      user={user}
                      reloadUsers={reloadUsers}
                      currencies={currencies}
                    />
                  )
                }}
              >
                <SVG src="/images/icon-edit.svg" />
              </button>
            }
            placement="top"
            forceShow
            tooltipId="profile-tooltip"
          />
          <WithTooltip
            title="Delete"
            text={
              <button
                className="link link-danger"
                onClick={() => {
                  showModal(
                    <DeleteUserModal
                      user={user}
                      reloadUsers={reloadUsers}
                      showSnackbar={showSnackbar}
                    />
                  )
                }}
              >
                <SVG src="/images/icon-trash.svg" />
              </button>
            }
            placement="top"
            forceShow
            tooltipId="profile-tooltip"
          />
        </div>
      </td>
    </tr>
  )
}

const NewUserModal = (props) => {
  const { currentUser, currencies, reloadUsers } = props

  const { hideModal } = useModal()

  const form = useForm<any>()
  const { register, handleSubmit, setValue, control } = form

  const [error, setError] = useState('')
  const isMounted = useIsMounted()
  const showError = (message: string, delay: number) => {
    setError(message)
    setTimeout(() => {
      if (isMounted.current) {
        setError('')
      }
    }, delay)
  }

  const values = form.watch()
  const isFilled =
    values.userFirstname &&
    values.userLastname &&
    values.userPhoneCountryCode &&
    values.userPhone &&
    values.userTitle &&
    values.userEmail &&
    values.userCurrencyCode &&
    values.userPassword &&
    values.userPasswordRepeat

  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    if (!values.userPassword) {
      showError("Password can't be blank", 10000)
      return
    }

    if (values.userPassword.length < 8) {
      showError('Password must be at least 8 characters long', 10000)
      return
    }

    if (values.userPassword !== values.userPasswordRepeat) {
      showError("Repeated password don't match", 10000)
      return
    }

    setError('')

    const response = await postRequest(
      '/profile/v1/users',
      pick(
        {
          ...defaultUser,
          ...values,
        },
        [
          'userFirstname',
          'userLastname',
          'userPhoneCountryCode',
          'userPhone',
          'userTitle',
          'userEmail',
          'userPassword',
        ]
      )
    )

    if (response.code === 200) {
      hideModal()
      reloadUsers()
    } else {
      showError(
        response.data?.replace(/^"user/, '"User ') || 'Something went wrong',
        10000
      )
    }
  })

  return (
    <>
      <div className="card-header">
        <div className="card-title">Create new user</div>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row row-space-between m-b-5">
            <div className="control flex-1-2">
              <label>First name</label>
              <input
                type="text"
                className="w-100"
                {...register('userFirstname')}
              />
            </div>
            <div className="control flex-1-2">
              <label>Last name</label>
              <input
                type="text"
                className="w-100"
                {...register('userLastname')}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control control-phone flex-1-2">
              <label>Phone number</label>
              <Controller
                control={control}
                name="userPhone"
                render={(props) => (
                  <CountryPhoneInput
                    name={props.field.name}
                    initialCode={currentUser.userPhoneCountryCode || ''}
                    initialPhone={''}
                    onChange={(values) => {
                      setValue('userPhoneCountryCode', values.code)
                      props.field.onChange(values.phone)
                    }}
                  />
                )}
              />
            </div>
            <div className="control flex-1-2">
              <label>E-Mail</label>
              <input
                type="email"
                className="w-100"
                autoComplete="off"
                {...register('userEmail')}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control flex-1-2">
              <label>Title</label>
              <input type="text" className="w-100" {...register('userTitle')} />
            </div>
            <div className="control control-select flex-1-2">
              <label>Currency</label>
              <select {...register('userCurrencyCode')}>
                {map(currencies, (currencyCode) => (
                  <option key={currencyCode} value={currencyCode}>
                    {currencyCode}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row m-b-8">
            <div className="control flex-1-2">
              <label>Password</label>
              <input
                type="password"
                className="w-100"
                autoComplete="new-password"
                {...register('userPassword')}
              />
            </div>
            <div className="control flex-1-2">
              <label>Repeat password</label>
              <input
                type="password"
                className="w-100"
                autoComplete="new-password"
                {...register('userPasswordRepeat')}
              />
            </div>
          </div>
          <div className="text-right">
            {error && <span className="text-red m-r-2">{error}</span>}
            <button
              className="btn btn-primary"
              disabled={!isFilled || submitting}
            >
              Create user
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

const EditUserModal = (props) => {
  const { user, reloadUsers, currencies } = props

  const { hideModal } = useModal()

  const form = useForm<any>({
    defaultValues: {
      userFirstname: user.userFirstname,
      userLastname: user.userLastname,
      userPhoneCountryCode: user.userPhoneCountryCode,
      userPhone: user.userPhone,
      userTitle: user.userTitle,
      userEmail: user.userEmail,
      userCurrencyCode: user.userCurrencyCode,
    },
  })

  const { register, handleSubmit, setValue, control } = form

  const [error, setError] = useState('')
  const isMounted = useIsMounted()
  const showError = (message: string, delay: number) => {
    setError(message)
    setTimeout(() => {
      if (isMounted.current) {
        setError('')
      }
    }, delay)
  }

  const anyChanges = form.formState.isDirty

  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    setError('')

    const response = await putRequest(`/profile/v1/users/${user.userUuid}`, {
      userIsActive: true,
      ...pick(
        {
          ...defaultUser,
          ...values,
        },
        [
          'userFirstname',
          'userLastname',
          'userPhoneCountryCode',
          'userPhone',
          'userTitle',
          'userEmail',
          'userCurrencyCode',
        ]
      ),
    })

    if (response.code === 200) {
      hideModal()
      reloadUsers()
    } else {
      showError(
        response.data?.replace(/^"user/, '"User ') || 'Something went wrong',
        10000
      )
    }
  })

  return (
    <>
      <div className="card-header">
        <div className="card-title">Edit user</div>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row row-space-between m-b-5">
            <div className="control flex-1-2">
              <label>First name</label>
              <input
                type="text"
                className="w-100"
                {...register('userFirstname')}
              />
            </div>
            <div className="control flex-1-2">
              <label>Last name</label>
              <input
                type="text"
                className="w-100"
                {...register('userLastname')}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control control-phone flex-1-2">
              <label>Phone number</label>
              <Controller
                control={control}
                name="userPhone"
                render={(props) => (
                  <CountryPhoneInput
                    name={props.field.name}
                    initialCode={user.userPhoneCountryCode || ''}
                    initialPhone={user.userPhone || ''}
                    onChange={(values) => {
                      setValue('userPhoneCountryCode', values.code)
                      props.field.onChange(values.phone)
                    }}
                  />
                )}
              />
            </div>
            <div className="control flex-1-2">
              <label>E-Mail</label>
              <input
                type="email"
                className="w-100"
                autoComplete="off"
                {...register('userEmail')}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control flex-1-2">
              <label>Title</label>
              <input type="text" className="w-100" {...register('userTitle')} />
            </div>
            <div className="control control-select flex-1-2">
              <label>Currency</label>
              <select {...register('userCurrencyCode')}>
                {map(currencies, (currencyCode) => (
                  <option key={currencyCode} value={currencyCode}>
                    {currencyCode}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="text-right">
            {error && <span className="text-red m-r-2">{error}</span>}
            <button
              className="btn btn-primary"
              disabled={!anyChanges || submitting}
            >
              Save changes
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

const DeleteUserModal = (props) => {
  const { user, reloadUsers, showSnackbar } = props

  const { hideModal } = useModal()

  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    const response = await deleteRequest(`/profile/v1/users/${user.userUuid}`)

    if (response.code === 200) {
      hideModal()
      reloadUsers()
    } else {
      showSnackbar(response.message || 'Something went wrong', {
        type: 'alert',
        time: 10000,
      })
    }
  })

  return (
    <>
      <div className="card-header">
        <div className="card-title">Delete user</div>
      </div>
      <div className="card-body">
        <p className="m-b-6">
          Are you sure you want to delete user <b>{user.userEmail}</b>?
        </p>
        <div className="text-right">
          <button
            onClick={onSubmit}
            className="btn btn-danger"
            disabled={submitting}
          >
            Yes
          </button>
          <button onClick={hideModal} className="btn m-l-2">
            No
          </button>
        </div>
      </div>
    </>
  )
}
