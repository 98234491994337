import React, { useState, useEffect, useMemo, useRef } from 'react'
import SVG from 'react-inlinesvg'
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd'
import { HTML5Backend, getEmptyImage } from 'react-dnd-html5-backend'
import ReactTooltip from 'react-tooltip'
import { Placeholder } from './Placeholder'
import {
  TTimeout,
  ICountriesV1List,
  ICurrenciesV1List,
  Header,
  Search,
  TableInfo,
  TableColumns,
  TableEmpty,
  Tooltip,
  WithTooltip,
  NavFilter,
  Loadable,
  SmallLoadable,
  Commission,
  useApiGet,
  useApiMore,
  useSortableTable,
  useTableEmpty,
  formatCommission,
  formatFilterRecords,
  formatShortDatetimeStr,
  varClass,
  navigateTo,
  plural,
  inTimezone,
  map,
  isBlank,
  filterTableColumns,
  parseQuery,
  countrySort,
} from '../shared'
import { getRequest } from '../../services'

const perPage = 50

const filterLabels = {
  'statuses': 'Status',
  'hasTraffic': 'Traffic',
  'commissions': 'Commission type',
  'restrictions': 'Restrictions',
  'trackerNetworkUuids': 'Network',
  'trackerCurrencyCodes': 'Currency',
  'trackerCountryCodes': 'Country',
  'hasOpportunities': 'Recommended',
}

const filterOptions = {
  // 'statuses': {
  //   'trackerIsActive': 'Active',
  //   'trackerIsApplied': 'Applied',
  //   'trackerIsPending': 'Pending',
  //   'trackerIsRejected': 'Rejected',
  // },
  'hasTraffic': {
    '': 'All',
    true: 'Active',
  },
  'commissions': {
    'trackerIsCommissionFlat': 'Flat',
    'trackerIsCommissionPercentage': 'Percentage',
  },
  'restrictions': {
    'trackerAllowPpc': 'PPC allowed',
    'trackerAllowCashback': 'Cashback',
    'trackerAllowCoupon': 'Coupon',
  },
}

const filterOptionsBottom = {
  'hasOpportunities': {
    'all': 'All',
    'opportunities': 'New',
    'alternatives': 'Alternatives',
  },
}

const tableColumnsKey = 'advertisers-columns'
const defaultTableColumns = [
  'advertiserDomain',
  'campaignUpdatedDatetime',
  // 'campaignRejectionRate',
  'campaignNetworksCount',
  // 'campaignCategory',
  'commission',
]

const tableLabels = {
  'advertiserDomain': 'Merchant',
  'campaignUpdatedDatetime': 'Date Modified',
  'campaignRejectionRate': 'Rejection Rate',
  'campaignNetworksCount': 'Networks',
  'campaignCategory': 'Category',
  'commission': 'Commission',
}

const normalizeSort = (sort) => {
  switch (sort) {
    case 'advertiserDomain':
      return 'advertiser_domain'
    case 'campaignUpdatedDatetime':
      return 'tracker_updated_datetime'
    case 'campaignNetworksCount':
      return 'LENGTH(networks)'
    case 'commission':
      return 'GREATEST(tracker_commission_percentage, tracker_commission_flat)'
    default:
      return 'GREATEST(tracker_commission_percentage, tracker_commission_flat)'
  }
}

const normalizeFilters = (filters) => {
  const result: any = {}

  for (const filter of filters) {
    if (filter.value) {
      if (filter.key === 'statuses' && filter.value === 'trackerIsPending') {
        result.trackerIsApplied = false
        result.trackerIsActive = false
        result.trackerIsRejected = false
      } else if (
        filter.key === 'statuses' ||
        filter.key === 'commissions' ||
        filter.key === 'restrictions'
      ) {
        result[filter.value] = true
      } else if (filter.key === 'hasTraffic') {
        result[filter.key] = true
      } else if (filter.key === 'hasOpportunities') {
        result[filter.key] = filter.value
      } else {
        result[filter.key] = [filter.value]
      }
    }
  }

  return result
}

export const Advertisers = (props) => {
  const initialQuery = useMemo(() => parseQuery(location.search), [])

  const [filters, setFilters] = useState(() => {
    const initialFilters = [
      { key: 'trackerCountryCodes', label: '', value: '' },
    ]
    if (initialQuery.recommendations === 'new') {
      initialFilters.push({
        key: 'hasOpportunities',
        label: 'New',
        value: 'new',
      })
    }
    if (initialQuery.recommendations === 'alternatives') {
      initialFilters.push({
        key: 'hasOpportunities',
        label: 'Alternatives',
        value: 'alternatives',
      })
    }
    return initialFilters
  })
  const filterReset = filters
    .filter((filter) => filter.value)
    .map((filter) => filter.value)
    .join(',')

  const [columns, setColumns] = useState(() =>
    filterTableColumns(
      localStorage.getItem(tableColumnsKey),
      defaultTableColumns
    )
  )

  const [draggedColumn, setDraggedColumn] = useState('')

  const swapColumns = (value1: string, value2: string) => {
    const index1 = columns.indexOf(value1)
    const index2 = columns.indexOf(value2) || 1
    if (index1 === index2) return
    if (index1 === 0 || index2 === 0) return
    const newColumns = [...columns]
    const item = newColumns[index1]
    newColumns.splice(index1, 1)
    newColumns.splice(index2, 0, item)
    setColumns(newColumns)
    localStorage.setItem(tableColumnsKey, newColumns.join(','))
  }

  const defaultSort = columns.includes('commission')
    ? 'commission'
    : 'advertiserDomain'
  const { sort, dir, toggleSort } = useSortableTable({
    sort: defaultSort,
    dir: 'desc',
  })

  const [search, setSearch] = useState('')

  const { data, total, loaded, loading, hasMore, loadMore } = useApiMore(
    '/advs/v2/camps',
    (offset: number) => ({
      page: Math.floor(offset / perPage),
      perPage: perPage,
      search: search
        .toLowerCase()
        .replace(/https?:\/\//, '')
        .replace(/^www\./, '')
        .replace(/[/?#].*$/, ''),
      orderBy: normalizeSort(sort),
      orderDirection: dir,
      trackerIsActive: true,
      ...normalizeFilters(filters),
    }),
    {
      perPage,
      resetDeps: [search, sort, dir, filterReset],
      dataKey: 'campaigns',
      totalKey: 'total',
    }
  )

  const networks = useApiGet('/networks/v1/list')

  const countries = useApiGet<ICountriesV1List[]>('/countries/v1/list')
  const sortedCountries = useMemo(
    () => countries && countries.sort(countrySort),
    [countries]
  )

  const currencies = useApiGet<ICurrenciesV1List[]>('/currencies/v1/list')
  const sortedCurrencies = useMemo(
    () => currencies && currencies.map((currency) => currency.currencyCode),
    [currencies]
  )

  return (
    <>
      <Header label="Merchants" />
      <Nav
        {...{ filters, setFilters }}
        networks={networks}
        currencies={sortedCurrencies}
        countries={sortedCountries}
      />
      <Loadable data={loaded} placeholder={<Placeholder />}>
        <DndProvider backend={HTML5Backend}>
          <Content
            {...{
              initialQuery,
              campaigns: data,
              campaignsTotal: total,
              loading,
              hasMore,
              loadMore,
              columns,
              setColumns,
              swapColumns,
              draggedColumn,
              setDraggedColumn,
              search,
              setSearch,
              sort,
              dir,
              toggleSort,
            }}
          />
        </DndProvider>
      </Loadable>
    </>
  )
}

const Nav = (props) => {
  const { filters, setFilters, networks, currencies, countries } = props

  return (
    <div className="page-nav m-b-4">
      <NavFilter
        {...{
          filters,
          setFilters,
          filterLabels,
          filterOptions: {
            ...filterOptions,
            trackerNetworkUuids: formatFilterRecords(
              networks,
              'networkUuid',
              'networkName'
            ),
            trackerCurrencyCodes: formatFilterRecords(
              currencies,
              'currencyCode',
              'currencyCode'
            ),
            trackerCountryCodes: formatFilterRecords(
              countries,
              'countryCode',
              'countryName'
            ),
            ...filterOptionsBottom,
          },
        }}
        onRemove={(index, key) => {
          const newFilters = [...filters]
          if (['trackerCountryCodes'].includes(key)) {
            const filter = newFilters.find((filter) => filter.key === key)
            filter.value = ''
            filter.label = ''
          } else {
            newFilters.splice(index, 1)
          }
          setFilters(newFilters)
        }}
      />
    </div>
  )
}

const Content = (props) => {
  const {
    initialQuery,
    campaigns,
    campaignsTotal,
    loading,
    hasMore,
    loadMore,
    columns,
    setColumns,
    swapColumns,
    draggedColumn,
    setDraggedColumn,
    search,
    setSearch,
    sort,
    dir,
    toggleSort,
  } = props

  // Affiliate Networks url query params
  const [initialPropsUsed, setInitialPropsUsed] = useState(false)

  useEffect(() => {
    const callback = async () => {
      if (!initialPropsUsed) {
        setInitialPropsUsed(true)

        parseInitialParams: {
          if (!initialQuery.advertiser || !initialQuery.network)
            break parseInitialParams

          const advertiserCampaign = campaigns.find(
            (campaign) =>
              campaign.advertiser?.advertiserUuid === initialQuery.advertiser
          )
          if (!advertiserCampaign) break parseInitialParams

          const networkCampaigns = await getRequest(
            `/advs/v1/camps/${initialQuery.advertiser}`
          )

          const networkCampaign = networkCampaigns?.data?.campaigns?.find(
            (campaign) => campaign.network.networkUuid === initialQuery.network
          )
          if (!networkCampaign) break parseInitialParams

          // TODO: redirect to advertiser page instead
          // showModal(
          //   <MyCampaignModal
          //     campaign={{ ...advertiserCampaign, ...networkCampaign }}
          //     showModal={showModal}
          //   />
          // )
        }
      }
    }
    callback()
  }, [initialQuery])

  return (
    <div className="card advertisers-card">
      <Table
        {...{
          campaigns,
          campaignsTotal,
          loading,
          hasMore,
          loadMore,
          columns,
          setColumns,
          swapColumns,
          draggedColumn,
          setDraggedColumn,
          search,
          setSearch,
          sort,
          dir,
          toggleSort,
          initialQuery,
        }}
      />
    </div>
  )
}

const Table = (props) => {
  const {
    campaigns,
    campaignsTotal,
    loading,
    hasMore,
    loadMore,
    columns,
    setColumns,
    swapColumns,
    draggedColumn,
    setDraggedColumn,
    search,
    setSearch,
    sort,
    dir,
    toggleSort,
  } = props

  const [shownSearch, setShownSearch] = useState(search)
  const searchRef = useRef<TTimeout>()
  useEffect(() => {
    if (searchRef.current) {
      clearTimeout(searchRef.current)
    }
    searchRef.current = setTimeout(() => {
      setSearch(shownSearch)
      searchRef.current = undefined
    }, 650)
  }, [shownSearch])

  const [isEmpty, setIsEmpty] = useTableEmpty(campaigns)

  const handleScroll = (event) => {
    if (hasMore && !loading) {
      if (
        event.target.scrollHeight - event.target.scrollTop <=
        event.target.clientHeight + 30
      ) {
        loadMore()
      }
    }
  }

  const itemProps = {
    sort,
    columns,
    draggedColumn,
  }

  return (
    <div className="card-body card-body-fill">
      <TableInfo>
        <Search
          value={shownSearch}
          setValue={setShownSearch}
          placeholder="Find merchant"
          className="search flex-1"
        />
        <div className="vertical-middle">
          <TableColumns
            storageKey={tableColumnsKey}
            columns={columns}
            setColumns={setColumns}
            availableColumns={defaultTableColumns}
            columnLabels={tableLabels}
          />
          <div className="total">
            {campaignsTotal || 0} {plural(campaignsTotal, 'result')}
          </div>
        </div>
      </TableInfo>

      <div className="table-container-overflow" onScroll={handleScroll}>
        <table className="table table-bordered table-sortable table-hoverable advertisers-table">
          <thead>
            <tr>
              {columns.map((column, index) => (
                <TableHead
                  key={column}
                  index={index}
                  value={column}
                  label={tableLabels[column]}
                  desc={
                    column === 'campaignUpdatedDatetime' ||
                    column === 'campaignNetworksCount' ||
                    column === 'commission'
                  }
                  {...{
                    toggleSort,
                    sort,
                    dir,
                    columns,
                    draggedColumn,
                    setDraggedColumn,
                    swapColumns,
                  }}
                />
              ))}
            </tr>
          </thead>
          <tbody className="text-nowrap">
            {map(campaigns, (campaign, index) => (
              <TableItem key={index} campaign={campaign} {...itemProps} />
            ))}
            {!isEmpty && !loading && isBlank(campaigns) && (
              <tr>
                <td colSpan={10} className="text-center text-light text-bolder">
                  Nothing found
                </td>
              </tr>
            )}
            {loading && (
              <tr>
                <td colSpan={10} className="text-center text-light text-bolder">
                  <div className="m-t-15 m-b-2">
                    <SmallLoadable loaded={false} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <DragTable
          {...{
            campaigns,
          }}
        />

        {isEmpty && (
          <TableEmpty
            icon="advertisers"
            title="No Merchant data available yet"
            subtitle="To see your merchants simply connect a network"
            buttons={{
              '/networks': 'Connect network',
            }}
            setIsEmpty={setIsEmpty}
          />
        )}

        <Tooltip
          id="advertiser-alternative-tooltip"
          type="dark"
          effect="solid"
          place="bottom"
          rebuild={[campaigns]}
        />

        <ReactTooltip
          type="dark"
          effect="solid"
          place="bottom"
          className="text-left"
          html={true}
        />
      </div>
    </div>
  )
}

const TableHead = (props) => {
  const {
    value,
    label,
    desc,
    sort,
    dir,
    toggleSort,
    index,
    columns,
    draggedColumn,
    setDraggedColumn,
    swapColumns,
  } = props

  const ref = useRef(null)

  const [{ isDragging }, dragHandle, drag] = useDrag(() => ({
    type: 'column',
    item: () => {
      setDraggedColumn(value)
      return { value }
    },
    end: (item) => {
      setDraggedColumn('')
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const [_opts, drop] = useDrop({
    accept: 'column',
    hover: (item, monitor) => {
      if (!ref.current) {
        return
      }
      const value1 = item.value
      const value2 = value
      if (value1 === value2) {
        return
      }
      const index1 = columns.indexOf(value1)
      const index2 = columns.indexOf(value2)

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const hoverQuarterX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 4

      const clientOffset = monitor.getClientOffset()
      const hoverClientX = clientOffset.x - hoverBoundingRect.left

      if (index1 < index2 && hoverClientX < hoverMiddleX - hoverQuarterX) {
        return
      }
      if (index1 > index2 && hoverClientX > hoverMiddleX + hoverQuarterX) {
        return
      }

      swapColumns(value1, value2)
    },
    drop: (item: any) => {
      swapColumns(item.value, value)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  })

  drag(getEmptyImage())
  drop(ref)

  if (index === 0) {
    return (
      <th
        onClick={toggleSort(value, desc ? 'desc' : 'asc')}
        ref={ref}
        className={varClass({
          'vertical-middle p-r-4 w-30': true,
          'sort-highlight': sort === value,
        })}
      >
        {draggedColumn && (
          <div
            className={varClass({
              'drop-space': true,
              'opaque': draggedColumn === columns[index],
            })}
          />
        )}
        <div className="row row-fill row-nowrap">
          <span className="column-label">
            {label}
            {sort === value ? (
              <>
                {dir === 'desc' ? (
                  <SVG
                    src="/images/insights/caret-down.svg"
                    className="m-l-1"
                  />
                ) : (
                  <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
                )}
              </>
            ) : (
              <SVG src="/images/insights/caret.svg" className="m-l-1" />
            )}
          </span>
        </div>
      </th>
    )
  } else {
    return (
      <th
        onClick={toggleSort(value, desc ? 'desc' : 'asc')}
        ref={ref}
        className={varClass({
          'relative vertical-middle p-l-2 p-r-4 w-20': true,
          'sort-highlight': sort === value,
          'drag-highlight': draggedColumn === value,
        })}
        style={{
          color: isDragging ? 'transparent' : '',
        }}
      >
        {draggedColumn && <div className="drop-space" />}
        <div className="row row-fill row-nowrap">
          <span ref={dragHandle} className="cursor-grab m-r-10">
            <SVG src="/images/insights/icon-drag.svg" className="handle" />
          </span>
          <span className="column-label">
            {label}
            {sort === value ? (
              <>
                {dir === 'desc' ? (
                  <SVG
                    src="/images/insights/caret-down.svg"
                    className="m-l-1"
                  />
                ) : (
                  <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
                )}
              </>
            ) : (
              <SVG src="/images/insights/caret.svg" className="m-l-1" />
            )}
          </span>
        </div>
      </th>
    )
  }
}

const TableItem = (props) => {
  const { campaign, sort, columns, draggedColumn } = props

  const values = {
    'advertiserDomain': campaign.advertiser.advertiserDomain,
    'campaignUpdatedDatetime': formatShortDatetimeStr(
      inTimezone(campaign.campaignUpdatedDatetime),
      ','
    ),
    'campaignRejectionRate': '0%',
    'campaignNetworksCount': campaign.campaignNetworksCount,
    'campaignCategory': campaign.categoryName,
    'commission': formatCommission(
      campaign.campaignComission || campaign.campaignCommission,
      campaign.campaignCommissionFlat,
      campaign.campaignCommissionPercentage,
      campaign.campaignCurrencyCode || 'DKK',
      true
    ),
  }

  return (
    <>
      <tr>
        {columns.map((column) => (
          <td
            key={column}
            className={varClass({
              'text-nowrap': true,
              'text-right':
                column === 'campaignUpdatedDatetime' ||
                column === 'campaignRejectionRate',
              'p-x-4 p-y-3': column === 'campaignNetworksCount',
              'sort-highlight': sort === column,
              'drag-highlight': draggedColumn === column,
            })}
          >
            {(() => {
              switch (column) {
                case 'advertiserDomain':
                  return (
                    <button
                      className="name"
                      onClick={(event) => {
                        navigateTo(
                          event,
                          `/advs/${campaign.advertiser.advertiserUuid}`
                        )
                      }}
                    >
                      {values[column]}
                      <SVG src="/images/icon-details.svg" className="arrow" />
                    </button>
                  )
                case 'campaignNetworksCount':
                  return (
                    <div className="text-wrap advertiser-networks">
                      {campaign.networks?.map((network, index) => (
                        <React.Fragment key={index}>
                          {index < 4 ? (
                            <span
                              key={network.networkUuid}
                              className="badge badge-hoverable text-smaller text-nowrap m-05"
                              onClick={(event) => {
                                navigateTo(
                                  event,
                                  `/networks/${network.networkUuid}`
                                )
                              }}
                            >
                              {network.networkName}
                            </span>
                          ) : index === 4 ? (
                            <span
                              data-tip={`<div>${campaign.networks
                                ?.slice(4)
                                .map((network) => network.networkName)
                                .join('</div><div>')}</div>`}
                              data-html={true}
                            >
                              <SVG
                                src="/images/icon-more-tags.svg"
                                className="m-x-2"
                              />
                            </span>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </div>
                  )
                case 'commission':
                  return (
                    <>
                      <Commission
                        className="m--2"
                        type={
                          campaign.campaignComission ||
                          campaign.campaignCommission
                        }
                        value={values[column]}
                      />
                      {campaign.campaignOpportunity && (
                        <WithTooltip
                          className="advertiser-alternative inline-block m-l-4"
                          title="A new campaign can be found for this merchant."
                          text={
                            <SVG
                              src="/images/recommendations/icon-campaigns.svg"
                              width={18}
                              height={14}
                            />
                          }
                          tooltipId="advertiser-alternative-tooltip"
                          forceShow
                        />
                      )}
                      {campaign.campaignAlternative && (
                        <WithTooltip
                          className="advertiser-alternative inline-block m-l-4"
                          title="A campaign with higher commission can be found for this merchant."
                          text={
                            <SVG
                              src="/images/recommendations/icon-alt-campaigns.svg"
                              width={20}
                              height={14}
                            />
                          }
                          tooltipId="advertiser-alternative-tooltip"
                          forceShow
                        />
                      )}
                    </>
                  )
                default:
                  return values[column]
              }
            })()}
          </td>
        ))}
      </tr>
    </>
  )
}

const DragTable = (props) => {
  const { campaigns } = props

  const { item, currentOffset, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging || !currentOffset) {
    return null
  }

  const columns = [item?.value]

  return (
    <div
      className="advertisers-card drag-card"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        transform: `translate(${currentOffset?.x}px, ${currentOffset?.y}px)`,
        pointerEvents: 'none',
        zIndex: 100,
      }}
    >
      <div className="table-container-overflow">
        <table
          className="table table-bordered table-sortable advertisers-table drag-table relative"
          style={{
            position: 'relative',
            width: 100,
            height: 100,
          }}
        >
          <thead className="text-nowrap">
            <tr>
              <th className="relative vertical-middle p-l-2 p-r-4 drag-highlight">
                <div className="row row-space-between row-nowrap">
                  <span className="cursor-grab text-blue m-r-10">
                    <SVG
                      src="/images/insights/icon-drag.svg"
                      className="handle"
                    />
                  </span>
                  <span className="column-label">
                    {tableLabels[item?.value]}
                    <SVG src="/images/insights/caret.svg" className="m-l-1" />
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-nowrap">
            {map(campaigns, (campaign) => (
              <DragTableItem
                key={campaign.advertiser.advertiserUuid}
                {...{
                  campaign,
                  columns,
                }}
              />
            ))}
            {isBlank(campaigns) && (
              <tr>
                <td colSpan={10} className="text-center text-light text-bolder">
                  Nothing found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const DragTableItem = (props) => {
  const { campaign, columns } = props

  const values = {
    'advertiserDomain': campaign.advertiser.advertiserDomain,
    'campaignUpdatedDatetime': formatShortDatetimeStr(
      inTimezone(campaign.campaignUpdatedDatetime),
      ','
    ),
    'campaignRejectionRate': 0,
    'campaignNetworksCount': campaign.campaignNetworksCount,
    'campaignCategory': campaign.categoryName,
    'commission': formatCommission(
      campaign.campaignComission || campaign.campaignCommission,
      campaign.campaignCommissionFlat,
      campaign.campaignCommissionPercentage,
      campaign.campaignCurrencyCode || 'DKK',
      true
    ),
  }

  const column = columns[0]

  return (
    <>
      <tr>
        <td
          className={varClass({
            'text-nowrap w-20': true,
            'text-right':
              column === 'campaignUpdatedDatetime' ||
              column === 'campaignRejectionRate',
            'p-x-4 p-y-3': column === 'campaignNetworksCount',
          })}
        >
          {(() => {
            switch (column) {
              case 'advertiserDomain':
                return (
                  <button
                    className="link text-blue text-bold"
                    onClick={(event) => {
                      navigateTo(
                        event,
                        `/advs/${campaign.advertiser.advertiserUuid}`
                      )
                    }}
                  >
                    {values[column]}
                  </button>
                )
              case 'campaignNetworksCount':
                return (
                  <div className="text-wrap advertiser-networks m--1">
                    {campaign.networks?.map((network, index) => (
                      <React.Fragment key={index}>
                        {index < 4 ? (
                          <span
                            key={network.networkUuid}
                            className="tag tag-network text-nowrap"
                          >
                            {network.networkName}
                          </span>
                        ) : index === 4 ? (
                          <span>
                            <SVG
                              src="/images/icon-more-tags.svg"
                              className="m-x-2"
                            />
                          </span>
                        ) : null}
                      </React.Fragment>
                    ))}
                  </div>
                )
              case 'commission':
                return (
                  <Commission
                    className="m--2"
                    type={
                      campaign.campaignComission || campaign.campaignCommission
                    }
                    value={values[column]}
                  />
                )
              default:
                return values[column]
            }
          })()}
        </td>
      </tr>
    </>
  )
}
