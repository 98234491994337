import moment from 'moment'
import React, { useMemo, useState, useEffect } from 'react'
import { Tooltip } from '../../shared'
import { deleteRequest, postRequest } from '../../../services'
import {
  useApiGet,
  INetworksV1Connections,
  useArray,
  SmallLoadable,
  varClass,
  DateInput,
  IChannelsV1List,
} from '../../shared'
import { Collapse } from '../../shared/elements/Collapse'
import { MultiSelectDropdown } from './shared'
import { uniq, uniqBy } from 'lodash'

export const SplitTestModal = (props) => {
  const {
    campaign,
    allCampaigns,
    advertiserUuid,
    haveSplitTest,
    hideModal,
    reloadCampaign,
    showSnackbar,
  } = props

  const connections = useApiGet<INetworksV1Connections[]>(
    '/networks/v1/connections'
  )
  const availableChannels = uniqBy(
    allCampaigns.map((campaign) => campaign.channels || []).flat(),
    (channel: { channelUuid: string }) => channel.channelUuid
  )

  const availableConnections = useMemo(() => {
    if (!connections) return []
    const connectionUuids = uniq(
      allCampaigns.map((campaign) => campaign.connection.connectionUuid)
    )
    return connections
      .filter((connection) =>
        connectionUuids.includes(connection.connectionUuid)
      )
      .sort()
  }, [connections, allCampaigns])

  const [connectionA, setConnectionA] = useState(
    campaign.connection.connectionUuid
  )
  const [connectionB, setConnectionB] = useState('')

  const [campaignMethod, setCampaignMethod] = useState('all')
  const {
    values: domains,
    addAll: setDomains,
    toggle: toggleDomain,
    clear: clearDomains,
  } = useArray()
  const availableDomains = useMemo(() => {
    clearDomains()
    const domainsA = uniq(
      allCampaigns
        .filter(
          (campaign) => campaign.connection.connectionUuid === connectionA
        )
        .map((campaign) => campaign.campaign.campaignUrls)
        .flat()
    )
    const domainsB = uniq(
      allCampaigns
        .filter(
          (campaign) => campaign.connection.connectionUuid === connectionB
        )
        .map((campaign) => campaign.campaign.campaignUrls)
        .flat()
    )
    return domainsA.filter((domain) => domainsB.includes(domain))
  }, [connectionA, connectionB])

  const [splitPercentage, setSplitPercentage] = useState(50)
  const [scheduleEnabled, setScheduleEnabled] = useState(false)
  const [endDate, setEndDate] = useState(moment().add(7, 'days').toDate())
  const [endSplitPercentage, setEndSplitPercentage] = useState(50)

  const [channelMethod, setChannelMethod] = useState('')
  const {
    values: channels,
    set: setChannels,
    toggle: toggleChannel,
    clear: clearChannels,
  } = useArray(campaign.channels.map((channel) => channel.channelUuid))

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const [isUpdate, setIsUpdate] = useState(false)

  useEffect(() => {
    // Restore current split test values
    if (haveSplitTest) {
      const currentSplitTrackers = allCampaigns
        .map((campaign) => campaign.trackers)
        .flat()
        .filter((tracker) => tracker.trackerIsSplitTraffic)
      if (!currentSplitTrackers.length) return

      const currentSplitCampaigns = allCampaigns.filter(
        (campaign) => campaign.campaign.campaignIsSplitTraffic
      )
      if (!currentSplitCampaigns.length) return

      // ConnectionB
      const initialSplit: Record<string, number> = {}
      for (const campaign of currentSplitCampaigns) {
        initialSplit[campaign.connection.connectionUuid] =
          campaign.campaign.campaignTrafficChance
      }
      const initialConnectionUuids = Object.keys(initialSplit)
      const initialConnectionB = initialConnectionUuids.filter(
        (connectionUuid) => connectionUuid !== connectionA
      )[0]

      // Domains
      const initialDomains = uniq(
        currentSplitTrackers.map((tracker) => tracker.trackerUrlMatch)
      )
      const allDomains = uniq(
        currentSplitCampaigns.map((campaign) => campaign.campaign.campaignUrl)
      )
      const isAllDomains = initialDomains.length >= allDomains.length

      // Channels
      const initialChannels = uniq(
        currentSplitTrackers.map((tracker) => tracker.trackerChannelUuid)
      )
      const allChannels = uniq(
        allCampaigns
          .map((campaign) =>
            campaign.channels.map((channel) => channel.channelUuid)
          )
          .flat()
      )
      const isAllChannels = initialChannels.length >= allChannels.length

      if (
        initialConnectionUuids.length !== 2 ||
        !initialConnectionUuids.includes(connectionA) ||
        !initialConnectionB ||
        !initialChannels?.length
      ) {
        return
      }

      setConnectionB(initialConnectionB)
      setSplitPercentage(initialSplit[connectionA])

      if (isAllDomains) {
        setCampaignMethod('all')
      } else {
        setCampaignMethod('specific')
      }
      setTimeout(() => setDomains(initialDomains), 300)

      if (isAllChannels) {
        setChannelMethod('all')
      } else {
        setChannelMethod('specific')
      }
      setTimeout(() => setChannels(initialChannels), 300)

      setIsUpdate(true)
    }
  }, [haveSplitTest])

  const onSubmit = async () => {
    setError('')

    const channelUuids = []
    if (channelMethod === 'specific') {
      if (channels.length) {
        channelUuids.push(...channels)
      } else {
        channelUuids.push(
          ...availableChannels.map((channel) => channel.channelUuid)
        )
      }
    } else if (channelMethod === 'all') {
      channelUuids.push(
        ...availableChannels.map((channel) => channel.channelUuid)
      )
    }

    setSubmitting(true)

    if (haveSplitTest) {
      await deleteRequest(`/advs/v1/camps/split`, {
        advertiserUuid,
      })
    }

    const params: any = {
      advertiserUuid,
      channelUuids,
      connectionUuidA: connectionA,
      connectionUuidB: connectionB,
      trafficSplit: splitPercentage,
    }
    if (campaignMethod === 'specific') {
      params.domains = domains
    }
    if (scheduleEnabled) {
      params.endTrafficSplit = endSplitPercentage
      params.endDate = moment(endDate).format('YYYY-MM-DD')
    }
    const response = await postRequest(`/advs/v1/camps/split`, params)

    setSubmitting(false)

    if (response.code === 200) {
      showSnackbar('Traffic split created')
      reloadCampaign()
      hideModal()
    } else {
      setError(response.message)
    }
  }

  const onDisable = async () => {
    setSubmitting(true)

    await deleteRequest(`/advs/v1/camps/split`, {
      advertiserUuid,
    })

    setSubmitting(false)

    showSnackbar('Traffic split removed')
    reloadCampaign()
    hideModal()
  }

  if (!connections || !availableChannels) {
    return (
      <div className="card-body">
        <div className="row row-center m-b-4">
          <SmallLoadable />
        </div>
      </div>
    )
  }

  if (availableConnections?.length <= 1) {
    hideModal()
  }

  const isNew = !haveSplitTest

  return (
    <>
      <div className="card-header">
        <div className="card-title row row-space-between">
          {isNew ? 'Create' : 'Start'} a new split test
        </div>
      </div>

      <div className="card-body">
        <div className="row row-space-between row-nowrap m-b-4">
          <div className="flex-1">
            <div className="control control-select w-100">
              <label>Network</label>
              <select
                value={connectionA}
                onChange={(e) => {
                  setConnectionA(e.target.value)
                  if (e.target.value === connectionB) {
                    setConnectionB(
                      availableConnections.filter(
                        (connection) =>
                          connection.connectionUuid !== e.target.value
                      )[0]?.connectionUuid || ''
                    )
                  }
                }}
              >
                <option value="">Select network</option>
                {availableConnections.map((connection) => (
                  <option
                    key={connection.connectionUuid}
                    value={connection.connectionUuid}
                  >
                    {connection.network.networkName} -{' '}
                    {connection.connectionName ||
                      connection.connectionPartnerId ||
                      ''}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex-1">
            <div className="control control-select w-100">
              <label>Network</label>
              <select
                value={connectionB}
                onChange={(e) => {
                  setConnectionB(e.target.value)
                  if (e.target.value === connectionA) {
                    setConnectionA(
                      availableConnections.filter(
                        (connection) =>
                          connection.connectionUuid !== e.target.value
                      )[0]?.connectionUuid || ''
                    )
                  }
                }}
              >
                <option value="">Select network</option>
                {availableConnections.map((connection) => (
                  <option
                    key={connection.connectionUuid}
                    value={connection.connectionUuid}
                  >
                    {connection.network.networkName} -{' '}
                    {connection.connectionName ||
                      connection.connectionPartnerId ||
                      ''}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="control control-checkbox m-b-2">
          <input
            type="checkbox"
            checked={campaignMethod === 'specific'}
            onChange={(event) =>
              setCampaignMethod(event.target.checked ? 'specific' : 'all')
            }
          />
          <label>Choose specific domains</label>
        </div>
        <Collapse open={campaignMethod === 'specific'}>
          <div
            className={varClass({
              'subnav-filters w-50 m-b-6': true,
              'disabled': campaignMethod !== 'specific',
            })}
          >
            <MultiSelectDropdown
              label="Domains"
              values={availableDomains}
              sortKey="domain"
              uuidGetter={(domain: string) => domain}
              labelGetter={(domain: string) => domain}
              selectedValues={domains}
              toggleSelectedValue={toggleDomain}
              clearSelectedValues={clearDomains}
            />
          </div>
        </Collapse>

        <div className="control control-range w-100 m-b-4">
          <div className="row row-space-between">
            <label className="text-light text-bold">{splitPercentage}%</label>
            <label className="text-light text-bold">
              {100 - splitPercentage}%
            </label>
          </div>
          <input
            type="range"
            min="1"
            max="99"
            value={splitPercentage}
            onChange={(e) => {
              setSplitPercentage(parseInt(e.target.value))
            }}
          />
        </div>

        <div className="control control-checkbox m-b-2">
          <input
            type="checkbox"
            checked={scheduleEnabled}
            onChange={(event) => setScheduleEnabled(event.target.checked)}
          />
          <label>Schedule percentage change</label>
        </div>

        <Collapse open={scheduleEnabled}>
          <div className="m-b-4">
            <div className="row row-center m-b-4">
              <label className="text-small text-bold text-light">
                Gradually change percentage until
              </label>
              <div className="control control-date">
                <DateInput
                  selected={endDate}
                  onChange={(newDate) => setEndDate(newDate)}
                  highlightDates={[new Date()]}
                  minDate={new Date()}
                />
              </div>
            </div>
            <div className="control control-range w-100">
              <div className="row row-space-between">
                <label className="text-light text-bold">
                  {endSplitPercentage}%
                </label>
                <label className="text-light text-bold">
                  {100 - endSplitPercentage}%
                </label>
              </div>
              <input
                type="range"
                min="0"
                max="100"
                value={endSplitPercentage}
                onChange={(e) => {
                  setEndSplitPercentage(parseInt(e.target.value))
                }}
              />
            </div>
          </div>
        </Collapse>

        <div className="column column-narrow m-b-2">
          <div className="control control-radio">
            <input
              type="radio"
              name="campaign-priority"
              value="all"
              checked={channelMethod === 'all'}
              onChange={(e: any) => setChannelMethod(e.target.value)}
            />
            <label>Compare on all sites</label>
          </div>
          <div className="control control-radio">
            <input
              type="radio"
              name="campaign-priority"
              value="specific"
              checked={channelMethod === 'specific'}
              onChange={(e: any) => setChannelMethod(e.target.value)}
            />
            <label>Compare on specific sites</label>
          </div>
        </div>

        <div
          className={varClass({
            'subnav-filters w-50 m-b-4': true,
            'disabled': channelMethod !== 'specific',
          })}
        >
          <MultiSelectDropdown
            label="Sites"
            values={availableChannels}
            sortKey="channelUuid"
            uuidGetter={(channel: IChannelsV1List) => channel.channelUuid}
            labelGetter={(channel: IChannelsV1List) =>
              channel.channelName || channel.channelDomain
            }
            selectedValues={channels}
            toggleSelectedValue={toggleChannel}
            clearSelectedValues={clearChannels}
          />
        </div>

        <div className="row row-space-between row-center row-nowrap m-t-4">
          <div className="text-red max-w-50">{error}</div>
          <div className="row">
            {haveSplitTest && (
              <button
                className="btn btn-bordered"
                disabled={submitting}
                onClick={onDisable}
              >
                Disable split test
              </button>
            )}

            <button
              className="btn btn-dark"
              disabled={
                !connectionA || !connectionB || !channelMethod || submitting
              }
              onClick={onSubmit}
              data-tip={
                haveSplitTest && !isUpdate
                  ? 'Previous split test will be disabled'
                  : null
              }
              data-for="split-test-tooltip"
            >
              {isUpdate ? 'Update' : 'Create'}
            </button>

            <Tooltip
              id="split-test-tooltip"
              title="Previous split test will be disabled"
              type="light"
              place="bottom"
            />
          </div>
        </div>
      </div>
    </>
  )
}
