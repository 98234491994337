import React from 'react'
import { Load } from '../../shared'

export const Placeholder = (props) => {
  return (
    <>
      <div className="row m-b-20">
        <div className="card flex-1-3 p-x-5" style={{ height: 138 }}>
          <Load width={40} height={40} />
          <Load width={50} height={16} className="m-t-4" />
          <Load width={150} height={20} className="m-t-2" />
        </div>
        <div className="card flex-1-3 p-x-5" style={{ height: 138 }}>
          <Load width={40} height={40} />
          <Load width={50} height={16} className="m-t-4" />
          <Load width={150} height={20} className="m-t-2" />
        </div>
        <div className="card flex-1-3 p-x-5" style={{ height: 138 }}>
          <Load width={40} height={40} />
          <Load width={50} height={16} className="m-t-4" />
          <Load width={150} height={20} className="m-t-2" />
        </div>
      </div>
      <div className="card m-t-80" style={{ height: 'calc(100vh - 353.5px)' }}>
        <Load width={208} height={30} className="m-t--2" />
        <div className="card-body card-body-fill" style={{ paddingTop: 32 }}>
          <Load height={50} />
        </div>
      </div>
    </>
  )
}
