import React, { useState, useEffect, useRef } from 'react'
import SVG from 'react-inlinesvg'
import { Placeholder } from './Placeholder'
import {
  TTimeout,
  Header,
  DatePicker,
  NavFilter,
  Loadable,
  SmallLoadable,
  Search,
  Tooltip,
  TableInfo,
  TableEmpty,
  useDatePickerState,
  useApiGet,
  useApiMore,
  useSnackbar,
  useDrawer,
  useSortableTable,
  useTableEmpty,
  formatFilterRecords,
  navigateTo,
  formatStat,
  map,
  newTableSort,
  varClass,
  plural,
  isBlank,
  copyToClipboard,
} from '../../shared'

const perPage = 20

const filterLabels = {
  'channelUuid': 'Channel',
}

const networkSort = newTableSort((a: any, b: any, key: string) => {
  if (key === 'channel') {
    if (a.channels) {
      return [
        a.channels[0]?.channelDomain || '',
        b.channels[0]?.channelDomain || '',
      ]
    }
    if (a.channel) {
      return [a.channel?.channelDomain || '', b.channel?.channelDomain || '']
    }
  }
})

export const NetworkClicks = (props) => {
  const datePickerState = useDatePickerState()

  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState([
    {
      key: 'channelUuid',
      value: '',
    },
  ])

  const channels = useApiGet('/channels/v1/list')

  const { data, loaded, hasMore, loadMore } = useApiMore(
    '/discovers/v1/networkclicks/overview',
    (offset: number) => {
      const result = {
        fromDate: datePickerState.range.startDate,
        toDate: datePickerState.range.endDate,
        search: search
          .toLowerCase()
          .replace(/https?:\/\//, '')
          .replace(/^www\./, '')
          .replace(/[/?#].*$/, ''),
        offset: offset,
        limit: perPage,
      }

      for (const filter of filters) {
        if (filter.value) {
          result[filter.key] = filter.value
        }
      }

      return result
    },
    {
      perPage,
      resetDeps: [
        search,
        JSON.stringify(filters),
        datePickerState.range.startDate,
        datePickerState.range.endDate,
      ],
    }
  )

  return (
    <>
      <Header label="Network clicks" />
      <Nav {...{ filters, setFilters, channels }} />
      <Loadable data={loaded} placeholder={<Placeholder />}>
        <Table
          data={data}
          hasMore={hasMore}
          loadMore={loadMore}
          datePickerState={datePickerState}
          search={search}
          setSearch={setSearch}
        />
      </Loadable>
    </>
  )
}

const Nav = (props) => {
  const { filters, setFilters, channels } = props

  return (
    <>
      <div className="page-nav m-b-4">
        <NavFilter
          {...{
            filters,
            setFilters,
            filterLabels,
            filterOptions: {
              channelUuid: {
                '': 'All channels',
                ...formatFilterRecords(
                  channels,
                  'channelUuid',
                  'channelName',
                  'channelDomain'
                ),
              },
            },
          }}
          hideAdd
          hideRemove
          hideChecks
        />
      </div>
      <DatePicker className="in-header" />
    </>
  )
}

const Table = (props) => {
  const { data, hasMore, loadMore, datePickerState, search, setSearch } = props

  const { showSnackbar } = useSnackbar()
  const { showDrawer } = useDrawer()

  const [shownSearch, setShownSearch] = useState(search)
  const searchRef = useRef<TTimeout>()
  useEffect(() => {
    if (searchRef.current) {
      clearTimeout(searchRef.current)
    }
    searchRef.current = setTimeout(() => {
      setSearch(shownSearch)
      searchRef.current = undefined
    }, 650)
  }, [shownSearch])

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'networkDiscoverCount',
    dir: 'desc',
  })

  const headProps = { sort, dir, toggleSort }
  const itemProps = {
    sort,
    datePickerState,
    showSnackbar,
    showDrawer,
  }

  const shownNetworks = networkSort(data, sort, dir)
  const [isEmpty, setIsEmpty] = useTableEmpty(data)

  return (
    <>
      <div className="card discover-network-card">
        <div className="card-body card-body-fill">
          <TableInfo>
            <Search
              value={shownSearch}
              setValue={setShownSearch}
              placeholder="Find domain"
              className="search flex-1"
            />
            <div className="total">
              {data?.length || 0} {plural(data?.length, 'result')}
            </div>
          </TableInfo>

          <div className="table-container-overflow">
            <table className="table table-bordered table-sortable table-hoverable">
              <thead>
                <tr>
                  <TableHead
                    value="networkDiscoverDestinationDomain"
                    label="Domain"
                    className="w-40"
                    {...headProps}
                  />
                  <TableHead
                    value="networkDiscoverCount"
                    label="Clicks"
                    desc
                    className="w-20"
                    {...headProps}
                  />
                  <TableHead
                    value="channel"
                    label="Channels"
                    className="w-30"
                    {...headProps}
                  />
                </tr>
              </thead>
              <tbody className="text-nowrap">
                {map(shownNetworks, (item, index) => (
                  <TableItem
                    key={item.network.networkUuid + ';' + index}
                    index={index}
                    item={item}
                    {...itemProps}
                  />
                ))}
                {!isEmpty && isBlank(shownNetworks) && (
                  <tr>
                    <td
                      colSpan={10}
                      className="text-center text-light text-bolder"
                    >
                      Nothing found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {isEmpty && (
            <TableEmpty
              icon="clicks"
              title="No data available yet"
              subtitle="To see your Clicks simply create a Channel and add the Tracking script"
              buttons={{
                '/channels': 'Create site',
              }}
              setIsEmpty={setIsEmpty}
            />
          )}
        </div>
      </div>

      {hasMore && (
        <button
          className="btn btn-lg btn-link m-t-5"
          onClick={() => loadMore()}
        >
          Load more
        </button>
      )}
    </>
  )
}

const TableHead = (props) => {
  const { value, label, desc, sort, dir, toggleSort, className } = props

  return (
    <th
      className={varClass({
        'sort-highlight': sort === value,
        [className]: !!className,
      })}
      onClick={toggleSort(value, desc ? 'desc' : 'asc')}
    >
      <span className="column-label text-nowrap">
        {label}
        {sort === value ? (
          <>
            {dir === 'desc' ? (
              <SVG src="/images/insights/caret-down.svg" className="m-l-1" />
            ) : (
              <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
            )}
          </>
        ) : (
          <SVG src="/images/insights/caret.svg" className="m-l-1" />
        )}
      </span>
    </th>
  )
}

const TableItem = (props) => {
  const { item, sort, datePickerState, showSnackbar, showDrawer } = props

  const { channels } = item

  return (
    <>
      <tr>
        <td
          className={varClass({
            'text-dark text-bold': true,
            'sort-highlight': sort === 'networkDiscoverDestinationDomain',
          })}
        >
          <button
            className="name"
            onClick={() => {
              showDrawer(
                <NetworkDrawer
                  item={item}
                  datePickerState={datePickerState}
                  showSnackbar={showSnackbar}
                />,
                {
                  cardClassName: 'discover-network-drawer',
                }
              )
            }}
          >
            {item.networkDiscoverDestinationDomain}
          </button>
        </td>
        <td
          className={varClass({
            'text-right': true,
            'sort-highlight': sort === 'networkDiscoverCount',
          })}
        >
          <div className="value-badge value-badge-clickCount">
            {formatStat(item.networkDiscoverCount)}
          </div>
        </td>
        <td
          className={varClass({
            'p-x-4 m--05': true,
            'sort-highlight': sort === 'channel',
          })}
        >
          {channels.map((channel) => (
            <span
              key={channel.channelDomain}
              className="tag text-smaller inline-block m-05"
              onClick={(event) => {
                navigateTo(event, `/sites/${channel.channelUuid}`)
              }}
            >
              {channel.channelName || channel.channelDomain}
            </span>
          ))}
        </td>
      </tr>
    </>
  )
}

const NetworkDrawer = (props) => {
  const { item, datePickerState, showSnackbar } = props

  const { data, hasMore, loadMore } = useApiMore(
    '/discovers/v1/networkclicks/overview/paths',
    (offset: number) => ({
      networkDiscoverNetworkUuid: item.network.networkUuid,
      networkDiscoverDestinationDomain: item.networkDiscoverDestinationDomain,
      // channelUuid: channelPickerState.selectedChannel?.channelUuid,
      fromDate: datePickerState.range.startDate,
      toDate: datePickerState.range.endDate,
      offset: offset,
      limit: perPage,
    }),
    {
      perPage,
      resetDeps: [
        // channelPickerState.selectedChannel,
        item.network.networkUuid,
        item.networkDiscoverDestinationDomain,
        datePickerState.range.startDate,
        datePickerState.range.endDate,
      ],
    }
  )

  return (
    <div className="card-body">
      <div className="vertical-middle">
        {/*
        <Logo
          src={item.network.networkLogoUrl}
          text={item.network.networkName}
          width={32}
          height={32}
          className='m-r-2'
        />
        */}
        <span className="text-dark text-big text-bolder">
          {item.networkDiscoverDestinationDomain}
        </span>
      </div>

      {/*
      <div className="row row-center row-space-between m-t-4">
        <div />
        <div>
          <button
            className="btn btn-xs text-smaller text-nowrap vertical-middle"
            onClick={() => {
              window.open(`/networks/${item.network.networkUuid}`, '_blank')
            }}
          >
            <span>Create in</span>{' '}
            <span className="text-blue text-bolder">Partner Network</span>{' '}
            <SVG src="/images/chevron-right.svg" className="m-l-1" />
          </button>
        </div>
      </div>
      */}

      <SmallLoadable data={data}>
        <SubTable data={data} showSnackbar={showSnackbar} />
        {hasMore && (
          <button className="btn m-t-4" onClick={() => loadMore()}>
            Load more
          </button>
        )}
        <Tooltip type="dark" place="bottom" />
      </SmallLoadable>
    </div>
  )
}

const SubTable = (props) => {
  const { data, showSnackbar } = props

  const { hideDrawer } = useDrawer()

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'networkDiscoverCount',
    dir: 'desc',
  })

  const headProps = { sort, dir, toggleSort }

  const shownData = networkSort(data, sort, dir)

  return (
    <table className="table table-bordered table-sortable">
      <thead>
        <tr>
          <TableHead
            value="networkDiscoverDestinationUrlPath"
            label="URL"
            {...headProps}
          />
          <TableHead
            value="networkDiscoverCount"
            label="Clicks"
            desc
            {...headProps}
          />
          <TableHead value="channel" label="Channel" desc {...headProps} />
        </tr>
      </thead>
      <tbody>
        {map(shownData, (item, index) => (
          <tr key={index}>
            <td
              className={varClass({
                'relative p-r-40': true,
                'text-dark sort-highlight':
                  sort === 'networkDiscoverDestinationUrlPath',
              })}
            >
              <span className="inline-block overflow-hide">
                {item.networkDiscoverDestinationUrlPath}
              </span>
              <button
                className="link ctc"
                onClick={() => {
                  copyToClipboard(item.networkDiscoverDestinationUrlPath)
                  showSnackbar('Copied to clipboard')
                }}
                data-tip="Copy link"
              >
                <SVG src="/images/icon-copy.svg" className="m-r-2" />
              </button>
            </td>
            <td
              className={varClass({
                'text-dark sort-highlight': sort === 'networkDiscoverCount',
              })}
            >
              {formatStat(item.networkDiscoverCount)}
            </td>
            <td
              className={varClass({
                'overflow-hide': true,
                'text-dark sort-highlight': sort === 'channel',
              })}
            >
              <span
                className="badge badge-hoverable text-smaller p-y-05"
                onClick={(event) => {
                  hideDrawer()
                  navigateTo(event, `/sites/${item.channel.channelUuid}`)
                }}
              >
                {item.channel.channelName || item.channel.channelDomain}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
