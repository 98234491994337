import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import { Placeholder } from './Placeholder'
import {
  Header,
  DatePicker,
  NavFilter,
  TableInfo,
  TableHead,
  TableEmpty,
  WithTooltip,
  Tooltip,
  useDatePickerState,
  useDrawer,
  useSnackbar,
  Search,
  Loadable,
  SmallLoadable,
  ClickDrawer,
  useApiGet,
  useApiMore,
  formatFilterRecords,
  formatShortDatetime,
  useSearch,
  newTableSort,
  filterBySearch,
  varClass,
  isBlank,
  inTimezone,
  map,
  plural,
  copyToClipboard,
} from '../../shared'

const filterLabels = {
  'clickAdvertiserUuids': 'Merchant',
  'clickChannelUuids': 'Site',
  'clickNetworkUuids': 'Network',

  'pageUrlPaths': 'Page',
  'deviceTypes': 'Device',
  'targetUrlPaths': 'Target',
  'referers': 'Referrer',
  'connectionUuids': 'Connection',
  'statusKeys': 'Status',
  'trackerUuids': 'Campaign',
  'urlShortCodes': 'Smart link',

  'subIds': 'Sub ID',
  'subId1s': 'Sub ID 1',
  'subId2s': 'Sub ID 2',
  'subId3s': 'Sub ID 3',
  'subId4s': 'Sub ID 4',
  'subId5s': 'Sub ID 5',
}

const filterOptions = {
  // 'deviceType': {
  //   'desktop': 'Desktop',
  //   'mobile': 'Mobile',
  // },
}

const perPage = 20

const normalizeFilters = (filters) => {
  const result = {}

  for (const filter of filters) {
    if (filter.value) {
      result[filter.key] = filter.value.split(', ')
    }
  }

  return result
}

const clickSort = newTableSort()

const splitUrl = (url: string): [string, string] => {
  if (!url) return ['Undefined', '']

  const canonical = url.replace(/https?:\/\//, '')
  const firstSlash =
    canonical.indexOf('/') !== -1 ? canonical.indexOf('/') : canonical.length

  return [
    canonical.substr(0, firstSlash) || 'Undefined',
    canonical.substr(firstSlash) || '',
  ]
}

export const InsightClicks = (props) => {
  const datePickerState = useDatePickerState()

  const { showDrawer } = useDrawer()
  const { showSnackbar } = useSnackbar()

  const [filters, setFilters] = useState([
    { key: 'clickChannelUuids', value: '', label: '' },
  ])
  const filterReset = filters
    .filter((filter) => filter.value)
    .map((filter) => filter.value)
    .join(',')

  const [search, setSearch] = useSearch()

  const advertisers = useApiGet('/advs/v1/minlist')
  const channels = useApiGet('/channels/v1/list')
  const networks = useApiGet('/networks/v1/list')

  const { data, loading, loaded, hasMore, loadMore } = useApiMore(
    '/insights/v1/clicks',
    (offset: number) => ({
      fromDate: datePickerState.range.startDate,
      toDate: datePickerState.range.endDate,
      offset: offset,
      limit: perPage,
      filters: normalizeFilters(filters),
    }),
    {
      perPage,
      resetDeps: [
        datePickerState.range.startDate,
        datePickerState.range.endDate,
        filterReset,
      ],
    }
  )

  return (
    <>
      <Header label="Clicks" />
      <Nav
        {...{
          data,
          filters,
          setFilters,
          advertisers,
          channels,
          networks,
        }}
      />
      <Loadable loaded={loaded} placeholder={<Placeholder />}>
        <Stats
          {...{
            loading,
            search,
            setSearch,
            data,
            hasMore,
            loadMore,
            filters,
            setFilters,
            networks,
            showDrawer,
            showSnackbar,
          }}
        />
      </Loadable>
    </>
  )
}

const Nav = (props) => {
  const { filters, setFilters, advertisers, channels, networks } = props

  return (
    <>
      <div className="page-nav m-b-4">
        <NavFilter
          {...{
            filters,
            setFilters,
            filterLabels,
            filterOptions: {
              ...filterOptions,
              clickAdvertiserUuids: formatFilterRecords(
                advertisers,
                'advertiserUuid',
                'advertiserDomain'
              ),
              clickChannelUuids: formatFilterRecords(
                channels,
                'channelUuid',
                'channelName',
                'channelDomain'
              ),
              clickNetworkUuids: formatFilterRecords(
                networks,
                'networkUuid',
                'networkName'
              ),
              // 'subId': 'input',
              // 'subId1': 'input',
              // 'subId2': 'input',
              // 'subId3': 'input',
              // 'subId4': 'input',
              // 'subId5': 'input',
            },
          }}
          filtersToKeep={['clickChannelUuid']}
          isMultipleChoice
        />
      </div>
      <DatePicker className="in-header" />
    </>
  )
}

const Stats = (props) => {
  const {
    loading,
    search,
    setSearch,
    data,
    hasMore,
    loadMore,
    networks,
    showDrawer,
    showSnackbar,
  } = props

  const [sort, setSort] = useState('clickDatetime')
  const [dir, setDir] = useState('desc')
  const toggleSort = (newSort: string, defaultDir: 'asc' | 'desc') => () => {
    if (sort === newSort) {
      if (dir === 'asc') {
        setDir('desc')
      } else {
        setDir('asc')
      }
    } else {
      setSort(newSort)
      setDir(defaultDir)
    }
  }

  return (
    <div className="card insights-clicks-card">
      <div className="card-body card-body-fill">
        <StatNav
          {...{
            data,
            search,
            setSearch,
          }}
        />

        <Table
          {...{
            data,
            hasMore,
            loadMore,
            search,
            sort,
            dir,
            toggleSort,
            networks,
            showDrawer,
            showSnackbar,
          }}
        />

        {loading && (
          <div className="m-t-15 m-b-2">
            <SmallLoadable loaded={false} />
          </div>
        )}
      </div>
    </div>
  )
}

const StatNav = (props) => {
  const { data, search, setSearch } = props

  return (
    <TableInfo>
      <Search
        value={search}
        setValue={setSearch}
        placeholder="Find site or URL"
        className="search flex-1"
      />
      <div className="vertical-middle">
        {/*
        <TableColumns
          storageKey={tableColumnsKey}
          columns={columns}
          setColumns={setColumns}
          availableColumns={defaultTableColumns}
          columnLabels={tableLabels}
        />
        */}
        <div className="total">
          {data?.length || 0} {plural(data?.length, 'result')}
        </div>
      </div>
    </TableInfo>
  )
}

const Table = (props) => {
  const {
    data,
    hasMore,
    loadMore,
    search,
    sort,
    dir,
    toggleSort,
    networks,
    showDrawer,
    showSnackbar,
  } = props

  const shownRows = clickSort(
    filterBySearch(data, search, (row: any) => [
      row.clickUrlPath,
      row.clickTargetUrlPath,
    ]),
    sort,
    dir
  )
  const headProps = { sort, dir, toggleSort }

  const isEmpty = !!data && isBlank(data)

  const loadMoreScroll = (event) => {
    if (hasMore) {
      const element = event.target
      const bottom = element.scrollHeight - element.offsetHeight - 100
      const isScrolledToBottom = element.scrollTop >= bottom
      if (isScrolledToBottom) {
        loadMore()
      }
    }
  }

  return (
    <div className="table-container-overflow" onScroll={loadMoreScroll}>
      <table className="table table-bordered table-sortable table-hoverable insights-statistics-table">
        <thead className="text-nowrap">
          <tr>
            <TableHead
              value="clickUrlPath"
              label="URL from"
              className="p-l-6"
              {...headProps}
            />
            <TableHead
              value="clickTargetUrlPath"
              label="URL to"
              {...headProps}
            />
            <TableHead value="clickDatetime" label="Time" {...headProps} desc />
          </tr>
        </thead>
        <tbody>
          {map(shownRows, (row, index) => (
            <TableItem
              key={row.clickUuid || index}
              {...{
                row,
                sort,
                networks,
                showDrawer,
                showSnackbar,
              }}
            />
          ))}
          {!isEmpty && isBlank(shownRows) && (
            <tr>
              <td colSpan={10} className="text-center text-light text-bolder">
                Nothing found
              </td>
            </tr>
          )}
          <tr className="blank-space">
            <td colSpan={10} />
          </tr>
        </tbody>
      </table>

      {isEmpty && (
        <TableEmpty
          icon="clicks"
          title="No data available yet"
          subtitle="To see your Clicks simply create a Site and add the Tracking script"
          buttons={{
            '/channels': 'Create site',
          }}
          dataTitle="No data available yet"
          dataSubtitle="No clicks has gone through yet.<br />Come back later and see your clicks here."
        />
      )}

      <Tooltip
        id="insights-clicks-tooltip"
        type="dark"
        effect="solid"
        place="bottom"
        rebuild={[shownRows]}
      />
    </div>
  )
}

const TableItem = (props) => {
  const { row, sort, networks, showDrawer, showSnackbar } = props

  return (
    <tr>
      <td
        className={varClass({
          'name p-x-5 cursor-pointer': true,
          'sort-highlight': sort === 'clickUrlPath',
        })}
        onClick={() => {
          if (row.clickUuid) {
            showDrawer(
              <ClickDrawer
                clickUuid={row.clickUuid}
                networks={networks}
                showSnackbar={showSnackbar}
              />,
              {
                cardClassName: 'insight-click-drawer',
              }
            )
          }
        }}
      >
        <div className="row row-center row-space-between row-narrow row-nowrap">
          <ClickURL url={row.clickUrlPath} />
          <div className="click-actions">
            <button
              className="link ctc"
              onClick={() => {
                copyToClipboard(row.clickUrlPath)
                showSnackbar('Copied to clipboard')
              }}
            >
              <SVG src="/images/icon-copy.svg" className="m-l-3" />
            </button>
            {row.clickUuid && (
              <div className="arrow-container">
                <SVG src="/images/icon-details.svg" className="arrow" />
              </div>
            )}
          </div>
        </div>
      </td>
      <td
        className={varClass({
          'p-x-5': true,
          'sort-highlight': sort === 'clickTargetUrlPath',
        })}
      >
        <div className="row row-center row-space-between row-narrow row-nowrap">
          <ClickURL url={row.clickTargetUrlPath} target />
          <button
            className="link ctc"
            onClick={() => {
              copyToClipboard(row.clickTargetUrlPath)
              showSnackbar('Copied to clipboard')
            }}
          >
            <SVG src="/images/icon-copy.svg" className="m-l-3" />
          </button>
        </div>
      </td>
      <td
        className={varClass({
          'text-nowrap text-right': true,
          'sort-highlight': sort === 'clickDatetime',
        })}
      >
        {formatShortDatetime(inTimezone(row.clickDatetime))}
      </td>
    </tr>
  )
}

const ClickURL = (props) => {
  const { url, target } = props

  const [host, path] = splitUrl(url)
  return (
    <WithTooltip
      className="click-url-tooltip"
      title={url}
      text={
        <div
          className={varClass({
            'click-url': true,
            'click-url-from': !target,
            'click-url-to': target,
          })}
        >
          <span className="click-url-host">{host}</span>
          {path && <span className="click-url-path">{path}</span>}
        </div>
      }
      tooltipId="insights-clicks-tooltip"
    />
  )
}
