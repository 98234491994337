import React, { useState, useMemo } from 'react'
import SVG from 'react-inlinesvg'
import { sortBy, uniqBy } from 'lodash'
import moment from 'moment'
import {
  CartesianGrid,
  ComposedChart,
  Area,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { curveBumpX } from 'd3-shape'
import {
  varClass,
  isBlank,
  map,
  formatStat,
  formatShortStat,
  groupSort,
} from '../shared'

type RowKey = {
  type: 'area' | 'bar'
  key: string
  index?: number
  yAxisId?: string
  label: string
  color: string
}

const formatDate = (value: string, isSingleDate: boolean) => {
  const result = isSingleDate
    ? moment(value).format('HH:00')
    : moment(value).format('MMM. D')
  if (!value || result === 'Invalid date') {
    return ''
  } else {
    return result
  }
}

export const TopChart = (props) => {
  const { title, grid, rows, isSingleDate, isEmpty } = props
  const rowKeys: RowKey[] = props.rowKeys

  const [hiddenCharts, setHiddenCharts] = useState([])
  const data = useMemo(() => {
    if (!rows || !Array.isArray(rows) || isBlank(rows)) {
      return []
    } else {
      return rows.sort(groupSort).map((row, index) => ({
        date: row.grp,
        pageviewCount: row.pageviewCount,
        clickCount: row.clickCount,
        discoverCount: row.discoverCount,
        conversionCount: row.conversionCount,
        conversionTotalCommission: row.conversionTotalCommission,
      }))
    }
  }, [rows])

  const noData =
    isEmpty &&
    !data.some((row) =>
      Object.values(row).some((value) => typeof value === 'number' && value > 0)
    )

  const rowKeyButtons = sortBy(
    rowKeys.map((rowKey, index) => ({ ...rowKey, originalIndex: index })),
    (rowKey, index) => rowKey.index || index
  )

  return (
    <div className="card flex-1 flex-1-2">
      <div className="card-body p-t-1">
        <div className="row row-space-between">
          <div className="text-dark text-bolder">{title}</div>
          <div>
            {map(rowKeyButtons, (rowKey, index) => (
              <button
                key={rowKey.key}
                className={varClass({
                  'top-chart-filter btn btn-xs text-smaller text-bold m-l-15':
                    true,
                  'dimmed': hiddenCharts.includes(rowKey.originalIndex),
                })}
                style={{
                  color: rowKey.color,
                  background: `${rowKey.color}1A`,
                }}
                onClick={() => {
                  const newHiddenCharts = [...hiddenCharts]
                  if (hiddenCharts.includes(rowKey.originalIndex)) {
                    newHiddenCharts.splice(
                      newHiddenCharts.indexOf(rowKey.originalIndex),
                      1
                    )
                  } else {
                    newHiddenCharts.push(rowKey.originalIndex)
                  }
                  setHiddenCharts(newHiddenCharts)
                }}
              >
                {rowKey.label}
              </button>
            ))}
          </div>
        </div>

        <ResponsiveContainer
          width="99.9%"
          height={250}
          className="overview2-top-chart"
        >
          <ComposedChart
            data={data}
            barCategoryGap={10}
            barGap={10}
            margin={{ top: 32, left: 0, right: 0, bottom: 0 }}
          >
            <defs>
              {map(rowKeys, (rowKey) => (
                <React.Fragment key={rowKey.key}>
                  <linearGradient
                    id={`stroke-${rowKey.key}`}
                    x1="0"
                    y1="0"
                    x2="1"
                    y2="0"
                  >
                    <stop
                      offset="0%"
                      stopColor={rowKey.color}
                      stopOpacity={0.5}
                    />
                    <stop
                      offset="100%"
                      stopColor={rowKey.color}
                      stopOpacity={1}
                    />
                  </linearGradient>
                  <linearGradient
                    id={`fill-${rowKey.key}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="0%"
                      stopColor={rowKey.color}
                      stopOpacity={0.1}
                    />
                    <stop offset="100%" stopColor="#ffffff" stopOpacity={0.2} />
                  </linearGradient>
                </React.Fragment>
              ))}
            </defs>

            <CartesianGrid
              horizontal={grid === 'horizontal'}
              vertical={grid === 'vertical'}
              strokeDasharray="6"
              stroke="#EDEFF2"
            />

            {
              <Tooltip
                content={
                  <GraphTooltip
                    rowKeys={rowKeys}
                    hiddenCharts={hiddenCharts}
                    isSingleDate={isSingleDate}
                  />
                }
              />
            }

            {map(rowKeys, (rowKey: RowKey, index) => (
              <React.Fragment key={rowKey.key}>
                {rowKey.type === 'area' && (
                  <Area
                    dataKey={rowKey.key}
                    yAxisId={`${rowKey.key.toLowerCase()}-y`}
                    type={curveBumpX}
                    stroke={`url(#stroke-${rowKey.key})`}
                    strokeWidth={2}
                    fill={`url(#fill-${rowKey.key})`}
                    hide={hiddenCharts.includes(index)}
                  />
                )}
                {rowKey.type === 'bar' && (
                  <Bar
                    dataKey={rowKey.key}
                    yAxisId={`${
                      rowKey.yAxisId?.toLowerCase() || rowKey.key?.toLowerCase()
                    }-y`}
                    barSize={32}
                    radius={3}
                    stackId="a"
                    fill={rowKey.color + '66'}
                    stroke={'#fff'}
                    strokeWidth={1}
                    hide={hiddenCharts.includes(index)}
                  />
                )}
              </React.Fragment>
            ))}

            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              interval="preserveStartEnd"
              tick={<GraphXTick isSingleDate={isSingleDate} />}
            />
            {map(
              uniqBy(rowKeys, (rowKey: RowKey) => rowKey.yAxisId || rowKey.key),
              (rowKey: RowKey, index) => (
                <YAxis
                  key={rowKey.key}
                  yAxisId={`${rowKey.key.toLowerCase()}-y`}
                  axisLine={false}
                  tickLine={false}
                  allowDecimals={false}
                  interval="preserveStartEnd"
                  orientation={index === 0 ? 'left' : 'right'}
                  tick={
                    <GraphYTick
                      rowKey={rowKey.key}
                      orientation={index === 0 ? 'left' : 'right'}
                    />
                  }
                />
              )
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      {noData && (
        <div className="awaiting">
          <SVG src="/images/overview2/awaiting.svg" />
        </div>
      )}
    </div>
  )
}

const GraphXTick = (props) => (
  <text x={props.x} y={props.y + 20} textAnchor="middle" fill="#828B9E">
    {formatDate(props.payload.value, props.isSingleDate)}
  </text>
)

const GraphYTick = (props) => {
  return (
    <g
      transform={
        props.orientation === 'left'
          ? `translate(${16},${props.y + 2})`
          : `translate(${-32},${props.y + 2})`
      }
    >
      <text
        x={props.orientation === 'left' ? 0 : '99%'}
        y={0}
        textAnchor="start"
        fill="#828B9E"
      >
        {formatShortStat(props.payload.value)}
      </text>
    </g>
  )
}

const GraphTooltip = (props) => {
  const { payload, rowKeys, hiddenCharts, isSingleDate } = props

  if (isBlank(payload) || isBlank(payload[0])) {
    return null
  }

  return (
    <div className="chart-tooltip chart-tooltip-light">
      <div className="chart-tooltip-header">
        {isSingleDate
          ? payload[0].payload.date + ':00'
          : payload[0].payload.date.split(' ')[0]}
      </div>
      <div className="chart-tooltip-body">
        <ul>
          {map(rowKeys, (rowKey, index) =>
            hiddenCharts.includes(index) ? null : (
              <li
                key={rowKey.key}
                className={varClass({
                  'chart-tooltip': true,
                })}
              >
                <span>
                  <span
                    className="chart-tooltip-dot"
                    style={{
                      background: rowKey.color,
                    }}
                  />
                  {rowKey.label}
                </span>{' '}
                <span>{formatStat(payload[0].payload[rowKey.key])}</span>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  )
}
