import React, { useState } from "react"
import SVG from 'react-inlinesvg'
import { deleteRequest } from "../../../services"
import { useModal, varClass } from "../../shared"

export const DeleteUploadModal = (props) => {
  const { selectedConnection, upload, reload } = props

  const { hideModal } = useModal()

  const [error, setError] = useState('')
  const [confirm, setConfirm] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const submit = async () => {
    setSubmitting(true)
    const response = await deleteRequest(
      `/conversion_uploads/v1/${selectedConnection.connectionUuid}/${upload.conversionUploadUuid}`
    )
    setSubmitting(false)

    if (response.code === 200) {
      hideModal()
      reload()
    } else {
      setError(response.message)
    }
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Delete conversions</div>
      </div>
      <div className="card-body">
        <div className="notice-info m-b-4">
          <SVG src="/images/notice-info.svg" className="m-r-2" />
          You are about to delete a conversion file. This will cause all
          imported conversions from this file to be deleted.
        </div>

        <div className="text-smaller text-center m-b-6">
          {upload.conversionUploadFilename}
        </div>

        <div className="control control-checkbox m-b-8">
          <input
            type="checkbox"
            checked={confirm}
            onChange={(event) => setConfirm(event.target.checked)}
          />
          <label>Yes — I'm sure I want to delete these conversions.</label>
        </div>

        <div className="row row-space-between row-nowrap">
          <div className="text-red text-big text-bold m-t-2">
            {error || null}
          </div>

          <div className="row row-nowrap">
            <div
              className={varClass({
                'loader-sm': true,
                'o-0': !submitting,
              })}
              style={{ width: 16, height: 16, margin: 0, marginTop: 10 }}
            />

            <button
              className="btn btn-primary"
              disabled={!confirm || submitting}
              onClick={submit}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
