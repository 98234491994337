import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import { uniqBy } from 'lodash'
import { Placeholder } from './Placeholder'
import {
  IChannelsV1Channels,
  Header,
  NewChannelModal,
  Loadable,
  WithTooltip,
  Tooltip,
  TableInfo,
  TableEmpty,
  NavFilter,
  Search,
  Logo,
  useApiGet,
  useModal,
  useSnackbar,
  useSortableTable,
  useTableEmpty,
  navigateTo,
  formatStat,
  map,
  isBlank,
  newTableSort,
  filterBySearch,
  varClass,
  plural,
} from '../shared'

const filterLabels = {
  'channelType': 'Type',
}

const filterOptions = {
  'channelType': {
    '': 'All channels',
    'App': 'App',
    'Newsletter': 'Newsletter',
    'Website': 'Website',
    'Social media': 'Social media',
  },
}

const channelFilter = (collection: any, filters) =>
  collection.filter((item) => {
    for (const filter of filters) {
      if (filter.value) {
        if (item[filter.key] !== filter.value) {
          return false
        }
      }
    }
    return true
  })

const channelSort = newTableSort((a: any, b: any, key: string) => {
  if (key === 'networks') {
    return [a.networks.length, b.networks.length]
  }
})

const splitUrl = (url: string): [string, string] => {
  if (!url) return ['Undefined', '']

  const canonical = url.replace(/https?:\/\//, '')
  const firstSlash =
    canonical.indexOf('/') !== -1 ? canonical.indexOf('/') : canonical.length

  const host = canonical.substr(0, firstSlash) || 'Undefined'
  const path = canonical.substr(firstSlash) || ''

  return [host, path === '/' ? '' : path]
}

export const Channels = (props) => {
  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  const [filters, setFilters] = useState([
    {
      key: 'channelType',
      value: '',
    },
  ])

  const [search, setSearch] = useState('')

  const channels = useApiGet<IChannelsV1Channels[]>('/channels/v1/channels')

  return (
    <>
      <Header label="Channels" />
      <div className="page-nav m-b-4">
        <NavFilter
          {...{
            filters,
            setFilters,
            filterLabels,
            filterOptions,
          }}
          hideAdd
          hideRemove
          hideChecks
        />
      </div>
      <Loadable data={channels} placeholder={<Placeholder />}>
        <Content
          {...{ channels, filters, search, setSearch, showModal, showSnackbar }}
        />
      </Loadable>
    </>
  )
}

const Content = (props) => {
  const { channels, filters, search, setSearch, showModal, showSnackbar } =
    props

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'channelName',
    dir: 'asc',
  })

  const [isEmpty, setIsEmpty] = useTableEmpty(channels)

  return (
    <div className="card">
      <div className="card-body card-body-fill">
        <TableNav
          {...{ channels, search, setSearch, isEmpty, showModal, showSnackbar }}
        />
        <Table
          {...{
            channels,
            sort,
            dir,
            toggleSort,
            search,
            filters,
            isEmpty,
            setIsEmpty,
          }}
        />
      </div>
    </div>
  )
}

const TableNav = (props) => {
  const { channels, search, setSearch, isEmpty, showModal, showSnackbar } =
    props

  return (
    <TableInfo>
      <Search
        value={search}
        setValue={setSearch}
        placeholder="Find channel"
        className="search flex-1"
      />
      <div className="vertical-middle">
        <button
          className={varClass({
            'btn btn-dark': true,
            'btn-gradient relative z-index-6': isEmpty,
          })}
          onClick={() =>
            showModal(<NewChannelModal showSnackbar={showSnackbar} />)
          }
        >
          <SVG
            src="/images/icon-plus.svg"
            width={18}
            height={18}
            className="o-50 m-r-15"
          />
          Add Channel
        </button>
        <div className="total">
          {channels?.length || 0} {plural(channels?.length, 'result')}
        </div>
      </div>
    </TableInfo>
  )
}

const Table = (props) => {
  const {
    channels,
    sort,
    dir,
    toggleSort,
    search,
    filters,
    isEmpty,
    setIsEmpty,
  } = props

  const shownChannels = channelSort(
    channelFilter(
      filterBySearch(channels, search, (channel: IChannelsV1Channels) => [
        channel.channelDomain,
        channel.channelName,
      ]),
      filters
    ),
    sort,
    dir
  )
  const headProps = { sort, dir, toggleSort }

  return (
    <div className="table-container-overflow">
      <table className="table table-bordered table-sortable table-hoverable channels-index-table">
        <thead className="text-nowrap">
          <tr>
            <TableHead
              value="channelName"
              label="Channel"
              className="w-30"
              {...headProps}
            />
            <TableHead
              value="channelDomain"
              label="Domain"
              className="w-30"
              {...headProps}
            />
            <TableHead
              value="channelAdvertisersCount"
              label="Merchants"
              className="w-20"
              desc
              {...headProps}
            />
            <TableHead
              value="networks"
              label="Mapped networks"
              className="w-20"
              desc
              {...headProps}
            />
            <TableHead
              value="channelType"
              label="Type"
              className="w-20"
              {...headProps}
            />
          </tr>
        </thead>
        <tbody>
          {map(shownChannels, (channel) => (
            <TableItem
              key={channel.channelUuid}
              channel={channel}
              sort={sort}
            />
          ))}
          {!isEmpty && isBlank(shownChannels) && (
            <tr>
              <td colSpan={10} className="text-center text-light text-bolder">
                Nothing found
              </td>
            </tr>
          )}
          <tr className="blank-space">
            <td colSpan={10} />
          </tr>
        </tbody>
      </table>

      {isEmpty && (
        <TableEmpty
          icon="channels"
          title="No Channels added yet"
          subtitle="To add your first channel simply click the button above"
          showArrow
          setIsEmpty={setIsEmpty}
          style={{ minHeight: 580 }}
        />
      )}

      <Tooltip
        id="channels-tooltip"
        type="dark"
        effect="solid"
        place="bottom"
        rebuild={[shownChannels]}
      />
    </div>
  )
}

const TableHead = (props) => {
  const { value, label, desc, sort, dir, toggleSort, className } = props

  return (
    <th
      className={varClass({
        'sort-highlight': sort === value,
        [className]: !!className,
      })}
      onClick={toggleSort(value, desc ? 'desc' : 'asc')}
    >
      <span className="column-label text-nowrap">
        {label}
        {sort === value ? (
          <>
            {dir === 'desc' ? (
              <SVG src="/images/insights/caret-down.svg" className="m-l-1" />
            ) : (
              <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
            )}
          </>
        ) : (
          <SVG src="/images/insights/caret.svg" className="m-l-1" />
        )}
      </span>
    </th>
  )
}

const TableItem = (props) => {
  const { channel, sort } = props

  return (
    <tr>
      <td
        className={varClass({
          'sort-highlight': sort === 'channelName',
        })}
      >
        <button
          className="name"
          onClick={(event) => {
            navigateTo(event, `/channels/${channel.channelUuid}`)
          }}
        >
          <div className="row row-center row-fill row-nowrap">
            {channel.channelFaviconUrl && (
              <Logo
                src={channel.channelFaviconUrl}
                width={28}
                height={28}
                className="logo-rounded m-r-4"
              />
            )}
            <span>{channel.channelName || 'Unnamed channel'}</span>
          </div>
          <SVG src="/images/icon-details.svg" className="arrow" />
        </button>
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'channelDomain',
        })}
      >
        <ChannelDomain url={channel.channelDomain} />
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'channelAdvertisersCount',
        })}
      >
        {formatStat(channel.channelAdvertisersCount)}
      </td>
      <td
        className={varClass({
          'text-wrap p-x-4 p-y-3': true,
          'sort-highlight': sort === 'networks',
        })}
      >
        <div className="channel-networks m--1">
          {map(
            uniqBy(channel.networks, (network: any) => network.networkUuid),
            (network, index) => (
              <React.Fragment key={network.networkUuid}>
                {index < 6 ? (
                  <span
                    className="tag tag-network"
                    onClick={(event) => {
                      navigateTo(event, `/networks/${network.networkUuid}`)
                    }}
                  >
                    {network.networkName}
                  </span>
                ) : index === 6 ? (
                  <span
                    data-tip={`<div>${channel.networks
                      .slice(6)
                      .map((network) => network.networkName)
                      .join('</div><div>')}</div>`}
                    data-html={true}
                  >
                    <SVG src="/images/icon-more-tags.svg" className="m-x-2" />
                  </span>
                ) : null}
              </React.Fragment>
            )
          )}
        </div>
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'channelType',
        })}
      >
        <span className="tag tag-type">{channel.channelType}</span>
      </td>
    </tr>
  )
}

const ChannelDomain = (props) => {
  const { url } = props

  const [host, path] = splitUrl(url)
  return (
    <WithTooltip
      className="channel-domain-tooltip"
      title={url}
      text={
        <div className="channel-domain">
          <span className="channel-domain-host">{host}</span>
          {path && <span className="channel-domain-path">{path}</span>}
        </div>
      }
      tooltipId="channels-tooltip"
    />
  )
}
