import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  CartesianGrid,
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { cloneDeep } from 'lodash'
import { toUnicode } from 'punycode'
import { colors, graphHeight } from './constants'
import {
  useWindowSize,
  formatStat,
  isBlank,
  formatShortStat,
  truncate,
} from '../../shared'

const verticalPoints = []
for (let i = 0; i <= 100; i++) verticalPoints.push(58 * i)

const sortKeys = {
  clicks: 'clickCount',
  conversions: 'conversionCount',
  commission: 'conversionTotalCommission',
}
const sortRowsBy = (key: string) => (a, b) => {
  if (a[sortKeys[key]] < b[sortKeys[key]]) return 1
  if (a[sortKeys[key]] > b[sortKeys[key]]) return -1
  return 0
}
const combineTail = (windowWidth: number) => {
  const tailIndex = windowWidth >= 1200 ? 8 : 5

  return (result, row, index) => {
    if (index <= tailIndex) {
      result.push(row)
    } else {
      result[tailIndex].name = 'Other'
      result[tailIndex].clickCount += row.clickCount
      result[tailIndex].conversionCount += row.conversionCount
      result[tailIndex].conversionTotalCommission +=
        row.conversionTotalCommission
    }
    return result
  }
}

export const PerformanceBarChart = (props) => {
  const { rows, graphColumns, setGraphHeight } = props

  const isGraphColumnShown = (value) => graphColumns.indexOf(value) !== -1

  const data = useMemo(() => {
    if (!rows || !Array.isArray(rows) || isBlank(rows)) {
      return []
    }

    return cloneDeep(rows)
      .sort(sortRowsBy(graphColumns[graphColumns.length - 1]))
      .reduce(combineTail(window.innerWidth), [])
      .map((row) => ({
        name: truncate(toUnicode(row.name || row.grp || 'Unknown'), 40),
        value: [
          row.clickCount,
          row.conversionCount,
          row.conversionTotalCommission,
        ],
        clicks: row.clickCount,
        conversions: row.conversionCount,
        commission: row.conversionTotalCommission,
      }))
  }, [rows, graphColumns])

  const windowSize = useWindowSize()
  const ref = useRef()
  const [rotateLabels, setRotateLabels] = useState(false)
  useEffect(() => {
    setRotateLabels(false)

    if (!ref.current) return

    setTimeout(() => {
      const axisGroup = document.querySelector(
        '.xAxis .recharts-cartesian-axis-ticks'
      )

      if (!axisGroup || isBlank(axisGroup.children)) return

      let rectA = null
      let rectB = null
      for (let i = 0; i < axisGroup.children.length - 1; i++) {
        rectA = axisGroup.children[i].getBoundingClientRect()
        rectB = axisGroup.children[i + 1].getBoundingClientRect()
        if (rectA.right >= rectB.left) {
          setRotateLabels(true)
          return
        }
      }
    }, 100)
  }, [ref, rows, windowSize])

  const [bottomMargin, setBottomMargin] = useState(32)
  useEffect(() => {
    if (!ref.current) return

    const axisGroup = document.querySelector(
      '.xAxis .recharts-cartesian-axis-ticks'
    )

    if (!axisGroup || isBlank(axisGroup.children)) return

    const axisRect = axisGroup.getBoundingClientRect()
    setBottomMargin(axisRect.height)
  }, [rotateLabels])

  useEffect(() => {
    setGraphHeight(rotateLabels ? graphHeight + bottomMargin : graphHeight)
  }, [bottomMargin])

  return (
    <ResponsiveContainer
      minWidth="99.9%"
      height={rotateLabels ? graphHeight + bottomMargin : graphHeight}
      className="performance-bar-chart"
      ref={ref}
    >
      <BarChart
        data={data}
        margin={{
          top: 16,
          left: 0,
          right: 16,
          bottom: bottomMargin,
        }}
        barGap={12}
      >
        <defs>
          <linearGradient id="stroke-pageviews" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor={colors.pageviews} stopOpacity={1} />
            <stop offset="100%" stopColor={colors.pageviews} stopOpacity={1} />
          </linearGradient>
          <linearGradient id="fill-pageviews" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={colors.pageviews} stopOpacity={0.4} />
            <stop offset="100%" stopColor="#ffffff" stopOpacity={0.4} />
          </linearGradient>

          <linearGradient id="stroke-clicks" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={colors.clicks} stopOpacity={1} />
            <stop offset="100%" stopColor={colors.clicks} stopOpacity={1} />
          </linearGradient>
          <linearGradient id="fill-clicks" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={colors.clicks} stopOpacity={0.4} />
            <stop offset="100%" stopColor={colors.clicks} stopOpacity={0.4} />
          </linearGradient>

          <linearGradient id="stroke-conversions" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={colors.conversions} stopOpacity={1} />
            <stop
              offset="100%"
              stopColor={colors.conversions}
              stopOpacity={1}
            />
          </linearGradient>
          <linearGradient id="fill-conversions" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor={colors.conversions}
              stopOpacity={0.4}
            />
            <stop
              offset="100%"
              stopColor={colors.conversions}
              stopOpacity={0.4}
            />
          </linearGradient>

          <linearGradient id="stroke-commission" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={colors.commission} stopOpacity={1} />
            <stop offset="100%" stopColor={colors.commission} stopOpacity={1} />
          </linearGradient>
          <linearGradient id="fill-commission" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={colors.commission} stopOpacity={0.4} />
            <stop
              offset="100%"
              stopColor={colors.commission}
              stopOpacity={0.4}
            />
          </linearGradient>
        </defs>

        <CartesianGrid
          verticalPoints={verticalPoints}
          strokeDasharray="6"
          stroke="#EDEFF2"
        />

        <Tooltip cursor={{ fill: '#32528F14' }} content={<GraphTooltip />} />

        {GraphBar('clicks', isGraphColumnShown('clicks'))}
        {GraphBar('conversions', isGraphColumnShown('conversions'))}
        {GraphBar('commission', isGraphColumnShown('commission'))}

        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          interval={0}
          tick={<GraphXTick rotated={rotateLabels} />}
        />
        <YAxis
          yAxisId="y"
          axisLine={false}
          tickLine={false}
          allowDecimals={false}
          interval="preserveStartEnd"
          tick={<GraphYTick />}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

const GraphXTick = (props) => {
  const { x, y, rotated } = props

  return (
    <g transform={`translate(${x}, ${y})`}>
      <text
        x={0}
        y={0}
        dy={20}
        transform={rotated ? 'rotate(-60)' : ''}
        textAnchor={rotated ? 'end' : 'middle'}
        fill="#828B9E"
      >
        {props.payload.value}
      </text>
    </g>
  )
}

const GraphYTick = (props) => (
  <g transform={`translate(${16},${props.y + 2})`}>
    <text x={0} y={0} textAnchor="start" fill="#828B9E">
      {formatShortStat(props.payload.value)}
    </text>
  </g>
)

const GraphBar = (key, active) => (
  <Bar
    type="linear"
    dataKey={key}
    yAxisId="y"
    barSize={12}
    radius={[4, 4, 0, 0]}
    stroke={`url(#stroke-${key})`}
    strokeWidth={0}
    fill={`url(#fill-${key})`}
    fillOpacity={1}
    hide={!active}
  />
)

const GraphTooltip = (props) => {
  const { active, payload } = props

  if (!active || !payload) {
    return null
  }

  const name = payload[0].payload.name || 'Performance'
  const clicks = payload[0].payload.clicks || 0
  const conversions = payload[0].payload.conversions || 0
  const commission = payload[0].payload.commission || 0

  return (
    <div className="chart-tooltip">
      <div className="chart-tooltip-header">{truncate(name, 60)}</div>
      <div className="chart-tooltip-body">
        <ul>
          {clicks !== undefined && (
            <li className="chart-tooltip-clicks">
              <span>Clicks</span> <span>{formatStat(clicks)}</span>
            </li>
          )}
          {conversions !== undefined && (
            <li className="chart-tooltip-conversions">
              <span>Conversions</span> <span>{formatStat(conversions)}</span>
            </li>
          )}
          {commission !== undefined && (
            <li className="chart-tooltip-commission">
              <span>Commission</span> <span>{formatStat(commission)}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
