import React, { useMemo, useState } from 'react'
import { postRequest } from '../../../services'
import {
  useModal,
  useApiGet,
  IProfileV1Users,
  map,
  useArray,
  Loadable,
  IChannelsV1List,
  INetworksV1List,
} from '../../shared'
import { MultiSelectDropdown } from './shared'

export const IgnoresModal = (props) => {
  const { advertiser, ignores, networks, channels, onDone } = props

  const { hideModal } = useModal()

  const [initialSelectedChannels, initialSelectedNetworks] = useMemo(
    () => [
      map(ignores, (ignore) => ignore.ignoreChannelUuid).filter(Boolean),
      map(ignores, (ignore) => ignore.ignoreNetworkUuid).filter(Boolean),
    ],
    [ignores]
  )
  const {
    values: selectedChannels,
    toggle: toggleSelectedChannel,
    addAll: addAllChannels,
    clear: clearSelectedChannels,
  } = useArray(initialSelectedChannels)
  const {
    values: selectedNetworks,
    toggle: toggleSelectedNetwork,
    addAll: addAllNetworks,
    clear: clearSelectedNetworks,
  } = useArray(initialSelectedNetworks)

  const [submitting, setSubmitting] = useState(false)
  const submit = async () => {
    setSubmitting(true)
    try {
      await postRequest('/advs/v1/ignores', {
        advertiserUuid: advertiser.advertiserUuid,
        channelUuids: selectedChannels,
        networkUuids: selectedNetworks,
      })
    } catch (err) {
      console.error(err)
    }
    setSubmitting(false)
    hideModal()
    if (onDone) {
      onDone()
    }
  }

  if (!networks?.length || !channels?.length) {
    return (
      <div className="card-body">
        <Loadable loaded={false} />
      </div>
    )
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">
          Force deactivate tracking for <b>{advertiser.advertiserDomain}</b>
        </div>
      </div>
      <div className="card-body">
        <div className="column">
          <div className="text-bold">Deactivate clicks from sites</div>
          <div className="subnav-filters">
            <MultiSelectDropdown
              label="Sites"
              emptyLabel="None"
              values={channels}
              sortKey="channelName"
              uuidGetter={(channel: IChannelsV1List) => channel.channelUuid}
              labelGetter={(channel: IChannelsV1List) => channel.channelName}
              selectedValues={selectedChannels}
              toggleSelectedValue={toggleSelectedChannel}
              addAllValues={addAllChannels}
              clearSelectedValues={clearSelectedChannels}
            />
          </div>
        </div>
        {false && (
          <div className="row row-center row-space-between m-t-4">
            <div className="text-bold">Deactivate specific networks</div>
            <div className="subnav-filters">
              <MultiSelectDropdown
                label="Networks"
                emptyLabel="None"
                values={networks}
                sortKey="networkName"
                uuidGetter={(network: INetworksV1List) => network.networkUuid}
                labelGetter={(network: INetworksV1List) => network.networkName}
                selectedValues={selectedNetworks}
                toggleSelectedValue={toggleSelectedNetwork}
                addAllValues={addAllNetworks}
                clearSelectedValues={clearSelectedNetworks}
              />
            </div>
          </div>
        )}
        <div className="text-right m-t-5">
          <button className="btn btn-bordered m-r-2" onClick={hideModal}>
            Cancel
          </button>
          <button
            className="btn btn-dark"
            onClick={submit}
            disabled={submitting}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  )
}
