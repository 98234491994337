import React, { useState, useEffect, useMemo, useRef } from 'react'
import SVG from 'react-inlinesvg'
import { toUnicode } from 'punycode'
import { uniq, isEqual } from 'lodash'
import moment from 'moment'
import { startOfDay, endOfDay, parse } from 'date-fns'
import { Placeholder, PlaceholderTop } from './Placeholder'
import { TopChart } from './TopChart'
import { ignoreHighLowNetworkUuids, ignoreWorstNetworkUuids } from './constants'
import {
  IInsightsV1Statistics,
  IInsightsV1StatisticsRow,
  Header,
  NavFilter,
  DatePicker,
  useDatePickerState,
  Link,
  Trend,
  Logo,
  Tooltip,
  WithTooltip,
  NoDataDiv,
  Loadable,
  SmallLoadable,
  // ConnectFirstNetworkModal,
  ConnectOpportunityNetworkModal,
  ConversionDrawer,
  ConversionJourneyDrawer,
  TableHead,
  useApiGet,
  useApiPost,
  useDrawer,
  useModal,
  useSnackbar,
  useCounter,
  useInterval,
  useSortableTable,
  formatFilterRecords,
  navigateTo,
  useMemoPresent,
  formatStat,
  formatCurrency,
  isBlank,
  map,
  newTableSort,
  filterBy,
  varClass,
  host,
  plural,
  responseError,
  checkAccountConversions,
} from '../shared'
import { getRequest, postRequest } from '../../services'
import { getCurrentUser, isDemo } from '../../services/auth'

const performanceTabLabels = {
  'name': 'Merchants',
  'tracker': 'Campaigns',
  'network': 'Networks',
  'channel': 'Sites',
  'page': 'Pages',
}

const performanceViewKey = 'overview-performance-view'

const isNetworkConnected = (connections, network): boolean => {
  if (!network || !connections || !Array.isArray(connections)) {
    return false
  }
  try {
    return !!connections?.find(
      (connection) => connection.network?.networkUuid === network.networkUuid
    )
  } catch (error) {
    console.log(error)
  }
  return false
}

const normalizeFilter = (filters) => {
  const result = {}

  for (const filter of filters) {
    if (filter.value) {
      result[filter.key] = filter.value.split(', ')
    }
  }

  return result
}

const sortTop = newTableSort((a: any, b: any, key) => {
  if (key === 'cr') {
    return [
      a.clickCount > 0 ? a.conversionCount / a.clickCount : 0,
      b.clickCount > 0 ? b.conversionCount / b.clickCount : 0,
    ]
  }
  if (key === 'ecr') {
    return [
      a.conversionSale > 0 ? a.conversionTotalCommission / a.conversionSale : 0,
      b.conversionSale > 0 ? b.conversionTotalCommission / b.conversionSale : 0,
    ]
  }
  if (key === 'epc') {
    return [
      a.clickCount > 0 ? a.conversionTotalCommission / a.clickCount : 0,
      b.clickCount > 0 ? b.conversionTotalCommission / b.clickCount : 0,
    ]
  }
})

const formatDateFloor = (
  diff: number,
  factor: number,
  letter: string
): string => `${Math.floor(diff / factor)}${letter}`

const formatDateCeil = (diff: number, factor: number, letter: string): string =>
  `${Math.ceil(diff / factor)}${letter}`

const formatDate = (dateStr: string): string => {
  const now: any = new Date()
  const date: any = parse(
    dateStr + ' +01',
    'yyyy-MM-dd, HH:mm:ss X',
    new Date()
  )
  if (date > now - 10 * 1000) return 'Just now'
  if (date > now - 60 * 1000) return formatDateCeil(now - date, 1000, 's')
  if (date > now - 60 * 60 * 1000)
    return formatDateFloor(now - date, 60 * 1000, 'm')
  if (date > now - 24 * 60 * 60 * 1000)
    return formatDateFloor(now - date, 60 * 60 * 1000, 'h')
  if (date > now - 7 * 24 * 60 * 60 * 1000)
    return formatDateFloor(now - date, 24 * 60 * 60 * 1000, 'd')
  return formatDateFloor(now - date, 7 * 24 * 60 * 60 * 1000, 'w')
}

const parseDevices = (data) => {
  if (!data || !Array.isArray(data)) {
    return {
      desktop: 0,
      mobile: 0,
      tablet: 0,
    }
  } else {
    return {
      desktop:
        data.find((datum) => datum.device === 'Desktop')?.pageviewCount || 0,
      mobile:
        data.find((datum) => datum.device === 'Mobile')?.pageviewCount || 0,
      tablet:
        data.find((datum) => datum.device === 'Tablet')?.pageviewCount || 0,
    }
  }
}

const findNetwork = (networks, networkUuid) =>
  networks.find((network) => network.networkUuid === networkUuid)

const detailName = (value: string): string => {
  if (!value) {
    return ''
  } else if (value === 'heylink.com') {
    return 'Test conversion'
  } else {
    return value
  }
}

let accountActivityMemo = null

export const Overview = (props) => {
  const datePickerState = useDatePickerState()

  const [filters, setFilters] = useState([
    {
      key: 'channelUuids',
      label: '',
      value: '',
    },
  ])
  const filterOptions = normalizeFilter(filters)
  const selectedChannelFilter = filters.find(
    (filter) => filter.key === 'channelUuids'
  )
  const selectedChannelUuids = selectedChannelFilter?.value
    ? selectedChannelFilter.value.split(', ')
    : []

  // const [reloadOpportunitiesRef, reloadOpportunities] = useCounter()

  const { showSnackbar } = useSnackbar()

  const data = useApiPost<any>(
    '/overview/v1/dashboard',
    {
      fromDate: startOfDay(datePickerState.range.startDate),
      toDate: endOfDay(datePickerState.range.endDate),
      channelUuids: selectedChannelUuids,
      ...filterOptions,
    },
    [
      datePickerState.range.startDate,
      datePickerState.range.endDate,
      JSON.stringify(selectedChannelUuids),
      JSON.stringify(filterOptions),
    ]
  )
  const channels = useApiGet('/channels/v1/list')
  const networks = useApiGet('/networks/v1/networks')
  const advertisers = useApiGet('/advs/v1/minlist')
  const connections = useApiGet('/networks/v1/connections')
  // const opportunities = useApiGet('/discovers/v3/opportunities/overview', {}, [
  //   reloadOpportunitiesRef,
  // ])

  const [isEmpty, setIsEmpty] = useState(true)
  const isInDemo = useMemo(isDemo, [])

  useEffect(() => {
    const callback = async () => {
      if (!isEmpty) return

      if (accountActivityMemo === null) {
        accountActivityMemo = await checkAccountConversions()
      }

      if (accountActivityMemo) {
        setIsEmpty(false)
        return
      }

      if (data) {
        if (
          !isBlank(data.networks) ||
          !isBlank(data.advertisers) ||
          !isBlank(data.pages) ||
          !isBlank(data.trackers) ||
          !isBlank(data.channels)
        ) {
          setIsEmpty(false)
          return
        }

        if (
          !isBlank(data.totals) &&
          Object.values(data.totals).some(
            (value) => typeof value === 'number' && value > 0
          )
        ) {
          setIsEmpty(false)
          return
        }

        if (
          !isBlank(data.rows) &&
          data.rows?.some((row) =>
            Object.values(row).some(
              (value) => typeof value === 'number' && value > 0
            )
          )
        ) {
          setIsEmpty(false)
          return
        }
      }
    }
    callback()
  }, [data])

  const currentUser = getCurrentUser()

  return (
    <div className="overview2">
      {isInDemo ? (
        <Header label="Welcome to your demo dashboard!" />
      ) : (
        <>
          {currentUser?.user?.userFirstname && (
            <Header label={`Welcome ${currentUser.user.userFirstname}`} />
          )}
        </>
      )}

      <DatePicker className="in-header" />

      {!isBlank(channels) && (
        <div className="m-b-4">
          <NavFilter
            {...{
              filters,
              setFilters,
              filterLabels: {
                channelUuids: 'Site',
                advertiserUuids: 'Merchant',
                networkUuids: 'Network',
                trackerUuids: 'Campaign',
              },
              filterOptions: {
                channelUuids: {
                  '': 'All sites',
                  ...formatFilterRecords(
                    channels,
                    'channelUuid',
                    'channelName',
                    'channelDomain'
                  ),
                },
                advertiserUuids: formatFilterRecords(
                  advertisers,
                  'advertiserUuid',
                  'advertiserName',
                  'advertiserDomain'
                ),
                networkUuids: formatFilterRecords(
                  networks,
                  'networkUuid',
                  'networkName'
                ),
              },
              onRemove: (index, key) => {
                const newFilters = [...filters]
                if (key === 'channelUuids') {
                  newFilters[index].label = ''
                  newFilters[index].value = ''
                } else {
                  newFilters.splice(index, 1)
                }
                setFilters(newFilters)
              },
            }}
            hideChecks
            isMultipleChoice
          />
        </div>
      )}

      <Loadable
        data={data && channels && networks}
        placeholder={<Placeholder />}
      >
        {/*
        {!isInDemo && connections?.length && opportunities?.length && (
          <Opportunities
            data={opportunities}
            networks={networks}
            channels={channels}
            connections={connections}
            reload={reloadOpportunities}
            showSnackbar={showSnackbar}
          />
        )}
        */}
        <Stats
          {...{
            data,
            channels,
            networks,
            advertisers,
            datePickerState,
            selectedChannelUuids,
            filters,
            setFilters,
            isEmpty,
          }}
        />
        <PerformanceStats
          {...{
            data,
            datePickerState,
            selectedChannelUuids,
            isEmpty,
            currentUser,
          }}
        />
      </Loadable>
    </div>
  )
}

const Stats = (props) => {
  const {
    data,
    channels,
    networks,
    advertisers,
    datePickerState,
    selectedChannelUuids,
    filters,
    setFilters,
    isEmpty,
  } = props

  const { showDrawer } = useDrawer()
  const { showSnackbar } = useSnackbar()

  const isSingleDate =
    moment(datePickerState.range.startDate).format('YYYY-MM-DD') ===
    moment(datePickerState.range.endDate).format('YYYY-MM-DD')
  const showTotals = !isBlank(data.totals)

  return (
    <>
      {showTotals && (
        <div className="top-stats m-b-20">
          <TopStat
            title="Pageviews"
            icon="pageviews"
            value={data.totals.pageviews.count}
            valueType="integer"
            valueTrend={data.totals.pageviews.change}
            relevantTitle="EPM"
            relevantValue={data.totals.pageviews.epm}
            relevantValueType={data.totals.commission.currencyCode || 'DKK'}
            isEmpty={isEmpty}
          />
          <TopStat
            title="Clicks"
            icon="clicks"
            value={data.totals.clicks.count}
            valueType="integer"
            valueTrend={data.totals.clicks.change}
            subValue={data.rows.reduce(
              (result, row) => result + row.discoverCount || 0,
              0
            )}
            subValueType="integer"
            relevantTitle="CTR"
            relevantValue={data.totals.clicks.ctr}
            relevantValueType="~trend1"
            isEmpty={isEmpty}
          />
          <TopStat
            title="Conversions"
            icon="conversions"
            value={data.totals.conversions.count}
            valueType="integer"
            valueTrend={data.totals.conversions.change}
            relevantTitle="CR"
            relevantValue={data.totals.conversions.cr}
            relevantValueType="~trend1"
            isEmpty={isEmpty}
          />
          <TopStat
            title="Revenue"
            icon="revenue"
            value={data.totals.commission.value}
            valueType={data.totals.commission.currencyCode || 'DKK'}
            valueTrend={data.totals.commission.change}
            rows={data.rows}
            rowsKey="conversionTotalCommission"
            chartColor="#2BBBC6"
            relevantTitle="EPC"
            relevantValue={data.totals.commission.epc}
            relevantValueType={data.totals.commission.currencyCode || 'DKK'}
            isEmpty={isEmpty}
          />
        </div>
      )}

      <div className="top-charts m-b-20">
        <TopChart
          title="Pageviews and Clicks"
          grid="horizontal"
          rows={data.rows}
          isSingleDate={isSingleDate}
          rowKeys={[
            {
              type: 'bar',
              key: 'clickCount',
              yAxisId: 'clickCount',
              label: 'Clicks',
              color: '#FFB000',
              index: '1',
            },
            {
              type: 'bar',
              key: 'discoverCount',
              yAxisId: 'clickCount',
              label: 'Discover clicks',
              color: '#828B9E',
              index: '2',
            },
            {
              type: 'area',
              key: 'pageviewCount',
              label: 'Pageviews',
              color: '#FF8656',
              index: '0',
            },
          ]}
          isEmpty={isEmpty}
        />
        <TopChart
          title="Conversions and Revenue"
          grid="vertical"
          rows={data.rows}
          isSingleDate={isSingleDate}
          rowKeys={[
            {
              type: 'area',
              key: 'conversionCount',
              label: 'Conversions',
              color: '#6A98FD',
            },
            {
              type: 'area',
              key: 'conversionTotalCommission',
              label: 'Revenue',
              color: '#0CCFAF',
            },
          ]}
          isEmpty={isEmpty}
        />
      </div>

      <LatestStats
        {...{
          networks,
          showDrawer,
          showSnackbar,
          datePickerState,
          selectedChannelUuids,
          isEmpty,
        }}
      />
    </>
  )
}

const TopStat = (props) => {
  const {
    title,
    icon,
    value,
    valueType,
    valueTrend,
    subValue,
    subValueType,
    relevantTitle,
    relevantValue,
    relevantValueType,
    isEmpty,
  } = props

  const noData = isEmpty && !value && !valueTrend && !relevantValue

  return (
    <div className="card card-nooverflow p-x-5 p-b-3">
      <div className="column column-space-between column-fill h-100">
        <div className="row row-fill">
          <SVG src={`/images/overview2/top-${icon}.svg`} className="m-r-3" />
          <div className="flex-1">
            <div className="m-b-2">
              <div className="row row-center row-space-between row-fill overview-top-header">
                <div className="text-light text-bold">
                  {title}{' '}
                  {valueType === 'integer' ? null : (
                    <span className="o-50">{valueType}</span>
                  )}
                </div>
                <Trend valuePercentage={valueTrend} />
              </div>
            </div>
            <div>
              <span className="text-dark text-larger text-boldest text-nowrap m-r-1">
                {valueType === 'integer' ? (
                  <>{formatStat(value, valueType)}</>
                ) : (
                  <>{formatStat(value, valueType, false, true)}</>
                )}
              </span>
              {subValue ? (
                <WithTooltip
                  className="inline-block text-light text-bold m-r-1"
                  label="Discover clicks"
                  text={`(${formatStat(subValue, subValueType)})`}
                  forceShow
                />
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <hr className="m-t-4 m-b-10" />
          <span className="row row-center row-space-between text-light">
            <div>{relevantTitle}</div>
            <div>{formatStat(relevantValue, relevantValueType)}</div>
          </span>
        </div>
      </div>
      {noData && (
        <div className="awaiting">
          <SVG src="/images/overview2/awaiting.svg" />
        </div>
      )}
    </div>
  )
}

const LatestStats = (props) => {
  const { selectedChannelUuids } = props

  const interval = useInterval(30000)
  const loadData = useApiPost<any>(
    '/overview/v1/livebox',
    {
      channelUuids: selectedChannelUuids,
    },
    [interval, JSON.stringify(selectedChannelUuids)]
  )
  const data = useMemoPresent(loadData) || loadData

  const loaded = data && data.pageviews && data.clicks && data.conversions
  const reset =
    data && loaded
      ? [
          data.pageviews.intervals.join(','),
          data.clicks.intervals.join(','),
          data.conversions.intervals.join(','),
        ].join(';')
      : null
  useEffect(() => {
    const cardBody: any = document.querySelector(
      '.overview-livebox > .card-body'
    )
    if (cardBody) {
      cardBody.style.transition = 'none'
      cardBody.style.opacity = 0
      setTimeout(() => {
        cardBody.style.transition = ''
        cardBody.style.opacity = 1
      }, 100)
    }
  }, [reset])

  if (!loaded) {
    return (
      <div className="row m-b-20">
        <div className="card flex-1 flex-1-5">
          <LatestChart rows={[]} color="#FF8656" />
          <div className="text-bolder m-t-3 m-b-1">
            Pageviews <span className="text-light text-bold o-60">30 min</span>
          </div>
          <div className="text-dark text-large text-boldest m-b-3">&nbsp;</div>
          <div className="card-divider m-t-0" />
          <div className="overview-latest-container flex-center">
            <SmallLoadable loaded={false} />
          </div>
        </div>

        <div className="card flex-1 flex-2-5">
          <LatestChart rows={[]} color="#FFB000" />
          <div className="text-bolder m-t-3 m-b-1">
            Clicks <span className="text-light text-bold o-60">30 min</span>
          </div>
          <div className="text-dark text-large text-boldest m-b-3">&nbsp;</div>
          <div className="card-divider m-t-0" />
          <div className="overview-latest-container flex-center">
            <SmallLoadable loaded={false} />
          </div>
        </div>

        <div className="card flex-1 flex-2-5">
          <LatestChart rows={[]} color="#6A98FD" />
          <div className="text-bolder m-t-3 m-b-1">
            Conversions{' '}
            <span className="text-light text-bold o-60">30 min</span>
          </div>
          <div className="text-dark text-large text-boldest m-b-3">&nbsp;</div>
          <div className="card-divider m-t-0" />
          <div className="overview-latest-container flex-center">
            <SmallLoadable loaded={false} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="row m-b-20">
      <div className="card flex-1 flex-1-5">
        <LatestChart rows={data.pageviews.intervals} color="#FF8656" />
        <div className="text-bolder m-t-3 m-b-1">
          Pageviews <span className="text-light text-bold o-60">30 min</span>
        </div>
        <div className="text-dark text-large text-boldest m-b-3">
          {formatStat(data.pageviews.total)}
        </div>
        <div className="card-divider m-t-0" />
        <LatestPageviews {...props} />
      </div>

      <div className="card flex-1 flex-2-5">
        <LatestChart rows={data.clicks.intervals} color="#FFB000" />
        <div className="overview-live-data">Live Data</div>
        <div className="text-bolder m-t-3 m-b-1">
          Clicks <span className="text-light text-bold o-60">30 min</span>
        </div>
        <div className="text-dark text-large text-boldest m-b-3">
          {formatStat(data.clicks.total)}
        </div>
        <div className="card-divider m-t-0" />
        <LatestClicks {...props} />
      </div>

      <div className="card flex-1 flex-2-5">
        <LatestChart rows={data.conversions.intervals} color="#6A98FD" />
        <Link className="overview-show-all" href="/insights/conversions">
          View all
        </Link>
        <div className="text-bolder m-t-3 m-b-1">
          Conversions <span className="text-light text-bold o-60">30 min</span>
        </div>
        <div className="text-dark text-large text-boldest m-b-3">
          {formatStat(data.conversions.total)}
        </div>
        <div className="card-divider m-t-0" />
        <LatestConversions {...props} />
      </div>
    </div>
  )
}

const LatestChart = (props) => {
  const { rows, color } = props

  const max = Math.max(...rows.map((row) => row.value)) || 1
  const shownRows = useMemo(() => {
    const result = [...rows]
    while (result.length < 7) {
      result.unshift({
        timestamp: result[0] ? result[0].timestamp - 1 : new Date().getTime(),
        value: 0,
      })
    }
    return result
  }, [rows])

  return (
    <div className="overview-live-chart">
      {map(shownRows, (row) => (
        <div
          key={row.timestamp}
          className="live-chart-bar"
          style={{
            height: `${(row.value / max) * 14}px`,
            background: color,
          }}
        />
      ))}
    </div>
  )
}

const LatestPageviews = (props) => {
  const { selectedChannelUuids, isEmpty } = props

  const loadData = useApiPost<any>(
    '/overview/v1/pageviews/latest',
    {
      channelUuids: selectedChannelUuids,
    },
    [JSON.stringify(selectedChannelUuids)]
  )

  const data = parseDevices(loadData)
  const total = data.desktop + data.mobile + data.tablet

  const noData = isEmpty && total === 0

  return (
    <div className="overview-latest-container">
      <div className="overview-pageviews">
        {data && (
          <>
            <LatestPageviewsItem
              type="desktop"
              value={data.desktop}
              label="Desktop"
              total={total}
            />
            <LatestPageviewsItem
              type="mobile"
              value={data.mobile}
              label="Mobile"
              total={total}
            />
            <LatestPageviewsItem
              type="tablet"
              value={data.tablet}
              label="Tablet"
              total={total}
            />
          </>
        )}
      </div>
      {noData && (
        <div className="awaiting">
          <SVG src="/images/overview2/awaiting.svg" />
        </div>
      )}
    </div>
  )
}

const LatestPageviewsItem = (props) => {
  const { type, value, label, total } = props

  const factor = total ? value / total : 0
  const minWidth = 80
  const maxWidth = 160
  const circleSize = minWidth + (maxWidth - minWidth) * factor

  return (
    <div
      className={`overview-pageview overview-pageview-${type}`}
      style={{
        width: circleSize,
      }}
    >
      <div>
        <div className="text-small text-bold m-b-2">{label}</div>
        <div className="text-dark text-large text-boldest">
          {formatStat(value)}
        </div>
      </div>
    </div>
  )
}

const LatestClicks = (props) => {
  const { selectedChannelUuids, showDrawer, isEmpty } = props

  const interval = useInterval(15000)
  const loadData = useApiPost<any>(
    '/overview/v1/clicks/latest',
    {
      channelUuids: selectedChannelUuids,
    },
    [interval, JSON.stringify(selectedChannelUuids)]
  )
  const data = useMemoPresent(loadData) || loadData
  const diff = useMemo(() => {
    const result = []
    if (data && loadData && !isEqual(data.clicks, loadData.clicks)) {
      loadData.clicks?.forEach((click) => {
        if (
          click.clickUuid &&
          !data.clicks?.find(
            (oldClick) => click.clickUuid === oldClick.clickUuid
          )
        ) {
          result.push(click.clickUuid)
        }
      })
    }
    return result
  }, [loadData])

  const noData = isEmpty && data && data.clicks && data.clicks.length === 0

  return (
    <div key="overview-clicks" className="overview-latest-container">
      {map(data?.clicks, (click, index) => (
        <LatestClicksItem
          key={click.clickUuid || index}
          click={click}
          time={formatDate(click.clickDatetime)}
          highlight={diff.includes(click.clickUuid)}
          showDrawer={showDrawer}
        />
      ))}
      {isBlank(data?.clicks) && (
        <>
          {data === null ? (
            <div className="overview-latest-container flex-center">
              <SmallLoadable loaded={false} />
            </div>
          ) : (
            <NoDataDiv
              description="No data within the last 7 days."
              className="p-y-50"
            />
          )}
        </>
      )}
      {noData && (
        <div className="awaiting">
          <SVG src="/images/overview2/awaiting.svg" />
        </div>
      )}
      <Tooltip
        id="overview2-clicks-tooltip"
        type="dark"
        effect="solid"
        place="bottom"
        rebuild={[data?.clicks]}
      />
    </div>
  )
}

const LatestClicksItem = (props) => {
  const { click, time, highlight, showDrawer } = props

  return (
    <div
      className={varClass({
        'overview-live-item overview-click-item': true,
        'highlight': highlight,
        'cursor-pointer': !!click.clickUuid,
      })}
      onClick={() => {
        if (click.clickUuid) {
          showDrawer(<ConversionJourneyDrawer clickUuid={click.clickUuid} />)
        }
      }}
    >
      <div className="row row-center row-fill row-nowrap vertical-middle">
        <WithTooltip
          className="from"
          label={click.clickUrlPath || click.clickName || 'Unknown'}
          text={toUnicode(
            host(click.clickUrlPath || click.clickName || 'Unknown')
          )}
          tooltipId="overview2-clicks-tooltip"
        />
        <SVG src="/images/overview2/icon-arrow.svg" className="m-x-15" />
        <div className="text-dark text-small text-bold">
          {toUnicode(
            host(
              detailName(
                click.clickName ||
                  click.advertiser.advertiserName ||
                  click.advertiser.advertiserDomain
              ) || 'Unknown'
            )
          )}
        </div>
      </div>
      <div>{time}</div>
    </div>
  )
}

const LatestConversions = (props) => {
  const { selectedChannelUuids, networks, showDrawer, showSnackbar, isEmpty } =
    props

  const interval = useInterval(300000)
  const loadData = useApiPost<any>(
    '/overview/v1/conversions/latest',
    {
      channelUuids: selectedChannelUuids,
    },
    [interval, JSON.stringify(selectedChannelUuids)]
  )
  const data = useMemoPresent(loadData) || loadData
  const diff = useMemo(() => {
    const result = []
    if (data && loadData && !isEqual(data.conversions, loadData.conversions)) {
      loadData.conversions?.forEach((conversion) => {
        if (
          conversion.conversionUuid &&
          !data.conversions?.find(
            (oldConversion) =>
              conversion.conversionUuid === oldConversion.conversionUuid
          )
        ) {
          result.push(conversion.conversionUuid)
        }
      })
    }
    return result
  }, [loadData])

  const noData =
    isEmpty && data && data.conversions && data.conversions.length === 0

  return (
    <div className="overview-latest-container">
      {map(data?.conversions, (conversion) => (
        <LatestConversionsItem
          key={conversion.conversionUuid}
          conversion={conversion}
          networks={networks}
          time={formatDate(conversion.conversionDatetime)}
          highlight={diff.includes(conversion.conversionUuid)}
          showDrawer={showDrawer}
          showSnackbar={showSnackbar}
        />
      ))}
      {isBlank(data?.conversions) && (
        <>
          {data === null ? (
            <div className="overview-latest-container flex-center">
              <SmallLoadable loaded={false} />
            </div>
          ) : (
            <NoDataDiv
              description="No data within the last 7 days."
              className="p-y-50"
            />
          )}
        </>
      )}
      {noData && (
        <div className="awaiting">
          <SVG src="/images/overview2/awaiting.svg" />
        </div>
      )}
      <Tooltip
        id="overview2-conversions-tooltip"
        type="dark"
        effect="solid"
        place="bottom"
        rebuild={[data?.conversions]}
      />
    </div>
  )
}

const LatestConversionsItem = (props) => {
  const { conversion, networks, time, highlight, showDrawer, showSnackbar } =
    props

  const unmapped =
    !conversion.conversionName &&
    !conversion.conversionUrlPath &&
    !conversion.advertiser.advertiserDomain

  return (
    <div
      className={varClass({
        'overview-live-item': true,
        'cursor-pointer': !!conversion.conversionClickUuid,
      })}
      onClick={() => {
        if (conversion.conversionClickUuid) {
          showDrawer(
            <ConversionDrawer
              conversionUuid={conversion.conversionUuid}
              networks={networks}
              showSnackbar={showSnackbar}
            />,
            {
              cardClassName: 'insight-conversion-drawer',
            }
          )
          // showDrawer(
          //   <ConversionJourney clickUuid={conversion.conversionClickUuid} />
          // )
        }
      }}
    >
      <div
        className={varClass({
          'overview-conversion-item': true,
          'highlight': highlight,
        })}
      >
        {!unmapped ? (
          <div className="row row-center row-fill row-nowrap vertical-middle">
            <WithTooltip
              className="from"
              label={
                toUnicode(conversion.conversionUrlPath) ||
                conversion.network?.networkName ||
                'Unknown'
              }
              text={toUnicode(
                host(
                  conversion.conversionUrlPath ||
                    conversion.network?.networkName ||
                    'Unknown'
                )
              )}
              tooltipId="overview2-conversions-tooltip"
            />
            <SVG src="/images/overview2/icon-arrow.svg" className="m-x-15" />
            <div className="text-dark text-small text-bold">
              {toUnicode(
                host(
                  detailName(
                    conversion.conversionName ||
                      conversion.advertiser.advertiserDomain
                  ) || 'Unknown'
                )
              )}
            </div>
          </div>
        ) : (
          <div className="row row-center row-fill row-nowrap vertical-middle">
            <div className="from">
              {conversion.network?.networkName || 'Unmapped conversion'}
            </div>
          </div>
        )}
        <div>{time}</div>
      </div>
      <div
        className={varClass({
          'overview-conversion-revenue': true,
          'highlight': highlight,
        })}
      >
        <div>{conversion.conversionCurrencyCode || 'DKK'}</div>
        <div>
          {formatStat(
            conversion.conversionCommission,
            conversion.conversionCurrencyCode,
            false,
            true
          )}
        </div>
      </div>
    </div>
  )
}

const PerformanceStats = (props) => {
  const { data: totals, selectedChannelUuids, isEmpty, currentUser } = props

  const [view, setView] = useState(
    () => localStorage.getItem(performanceViewKey) || 'best'
  )
  const [tab, setTab] = useState(() => Object.keys(performanceTabLabels)[0])

  let fromDate = moment().toDate()
  let toDate = moment().toDate()
  let ignoredNetworks: string[] | undefined
  // let prevFromDate = moment().add(1, 'day').toDate()
  // let prevToDate = moment().add(1, 'day').toDate()
  if (view === 'best') {
    fromDate = moment().subtract(30, 'days').toDate()
    toDate = moment().subtract(1, 'days').toDate()
  } else if (view === 'worst') {
    fromDate = moment().subtract(4, 'days').toDate()
    toDate = moment().subtract(1, 'days').toDate()
  } else if (view === 'high-low') {
    fromDate = moment().subtract(7, 'days').toDate()
    toDate = moment().subtract(1, 'days').toDate()
    ignoredNetworks = ignoreHighLowNetworkUuids
  } else if (view === 'low-high') {
    fromDate = moment().subtract(30, 'days').toDate()
    toDate = moment().subtract(1, 'days').toDate()
    ignoredNetworks = ignoreHighLowNetworkUuids
  }
  const data = useApiPost<IInsightsV1Statistics>(
    '/insights/v1/statistics',
    {
      fromDate: startOfDay(fromDate),
      toDate: endOfDay(toDate),
      groupBy: tab,
      filters: {
        channelUuids: selectedChannelUuids,
        networkNotUuids: ignoredNetworks,
      },
    },
    [
      tab,
      moment(fromDate).format('YYYY-MM-DD'),
      moment(toDate).format('YYYY-MM-DD'),
      JSON.stringify(selectedChannelUuids),
      view,
    ]
  )
  // const prevData = useApiPost<IInsightsV1Statistics>(
  //   '/insights/v1/statistics',
  //   {
  //     fromDate: startOfDay(prevFromDate),
  //     toDate: endOfDay(prevToDate),
  //     groupBy: tab,
  //     filters: {
  //       channelUuids: selectedChannelUuids,
  //     },
  //   },
  //   [
  //     tab,
  //     moment(prevFromDate).format('YYYY-MM-DD'),
  //     moment(prevToDate).format('YYYY-MM-DD'),
  //     JSON.stringify(selectedChannelUuids),
  //     view,
  //   ]
  // )

  useEffect(() => {
    localStorage.setItem(performanceViewKey, view)
  }, [view])

  const currencyCode = currentUser.currency?.currencyCode || 'DKK'

  const noData =
    isEmpty &&
    totals &&
    totals.totals &&
    totals.totals.clicks?.count === 0 &&
    totals.totals.commission?.value === 0 &&
    totals.totals.conversions?.count === 0 &&
    totals.totals.pageviews?.count === 0

  return (
    <div className="card overview-performance">
      <div className="page-subnav m-b-4">
        <div className="nav">
          <ul>
            <li className={varClass({ 'active': view === 'best' })}>
              <button className="link" onClick={() => setView('best')}>
                Best performing (30 days)
              </button>
            </li>
            <li className={varClass({ 'active': view === 'worst' })}>
              <button className="link" onClick={() => setView('worst')}>
                Worst performing (4 days)
              </button>
            </li>
            <li className={varClass({ 'active': view === 'high-low' })}>
              <button className="link" onClick={() => setView('high-low')}>
                High-low merchants (7 days)
              </button>
            </li>
            <li className={varClass({ 'active': view === 'low-high' })}>
              <button className="link" onClick={() => setView('low-high')}>
                Low-high merchants (30 days)
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="page-subnav">
        <div className="nav p-b-2 m-t--4 m-b-0">
          <ul>
            {map(performanceTabLabels, (key, label) => (
              <li
                key={key}
                className={varClass({
                  'active': tab === key,
                })}
              >
                <button
                  className="link p-y-4"
                  onClick={() => {
                    setTab(key)
                  }}
                >
                  {label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <button
        className="btn btn-bordered btn-report vertical-middle"
        onClick={(event) => {
          if (view === 'worst') {
            navigateTo(event, `/insights/reports?tab=${tab}&crFilter=1`)
          } else if (view === 'high-low') {
            navigateTo(event, `/insights/reports?tab=${tab}`)
          } else {
            navigateTo(event, `/insights/reports?tab=${tab}`)
          }
        }}
      >
        Show report
        <SVG src="/images/chevron-right.svg" className="m-l-2" />
      </button>
      <Loadable data={data /* && prevData */} placeholder={<PlaceholderTop />}>
        <PerformanceTable
          data={data?.rows || []}
          // prevData={prevData?.rows || []}
          totals={totals?.totals || {}}
          view={view}
          tab={tab}
          currencyCode={currencyCode}
        />
        {noData && (
          <div className="awaiting">
            <SVG src="/images/overview2/awaiting.svg" />
          </div>
        )}
      </Loadable>
    </div>
  )
}

const PerformanceTable = (props) => {
  const { data, prevData, totals, view, tab, currencyCode } = props

  const { sort, dir, toggleSort } = useSortableTable(
    view === 'best'
      ? {
          sort: 'conversionTotalCommission',
          dir: 'desc',
        }
      : view === 'worst'
      ? {
          sort: 'cr',
          dir: 'asc',
        }
      : view === 'high-low'
      ? {
          sort: 'ecr',
          dir: 'desc',
        }
      : view === 'low-high'
      ? {
          sort: 'conversionTotalCommission',
          dir: 'desc',
        }
      : {
          sort: 'conversionTotalCommission',
          dir: 'desc',
        }
  )

  const findPrevItem = (row) => {
    if (!prevData?.length) return undefined
    return prevData.find(
      (prevRow) =>
        (row.name && prevRow.name === row.name) ||
        (row.grp && prevRow.grp === row.grp)
    )
  }

  const headProps = { sort, dir, toggleSort }
  const shownRows = sortTop(data, sort, dir)
    .filter((item: IInsightsV1StatisticsRow) => {
      if (view === 'worst') {
        const networkUuids = [
          ...(item.conversionNetworkUuids || '').split(','),
          ...(item.clickNetworkUuids || '').split(','),
        ].filter((v) => v)
        if (
          networkUuids.length &&
          networkUuids.every((networkUuid) =>
            ignoreWorstNetworkUuids.includes(networkUuid)
          )
        ) {
          return false
        }
        const cr = (item.conversionCount / (item.clickCount || 1)) * 100
        return item.clickCount >= 500 && cr <= 0.8
      } else if (view === 'high-low') {
        if (!item.name && !item.grp) return false
        if (!item.conversionSale || !item.clickCount) {
          return false
        }
        const cr = (item.conversionCount / (item.clickCount || 1)) * 100
        const ecr =
          (item.conversionTotalCommission / (item.conversionSale || 1)) * 100
        return item.clickCount >= 130 && cr >= 0.9 && ecr <= 9.2
      } else if (view === 'low-high') {
        if (!item.name && !item.grp) return false
        if (!item.conversionSale || !item.clickCount) {
          return false
        }
        return item.conversionTotalCommission >= 500 && item.clickCount <= 1000
      } else {
        return true
      }
    })
    .slice(0, view === 'best' ? 25 : 150) as IInsightsV1StatisticsRow[]

  const showPageviews = tab === 'page' || tab === 'channel'
  const showCr = view === 'high-low'
  const showEcr = view === 'high-low'
  const showEpc = false // view === 'low-high'

  useEffect(() => {
    if (!showPageviews && sort === 'pageviewCount') {
      toggleSort('conversionTotalCommission', 'desc')()
    }
  }, [showPageviews])

  return (
    <div className="table-container-overflow">
      <table className="table table-bordered table-sortable table-hoverable">
        <thead>
          <tr>
            <th className="width-xl">{performanceTabLabels[tab]}</th>
            {showPageviews && (
              <TableHead
                value="pageviewCount"
                label="Pageviews"
                className="width-sm"
                {...headProps}
                desc
              />
            )}
            <TableHead
              value="clickCount"
              label="Clicks"
              className="width-sm"
              {...headProps}
              desc
            />
            <TableHead
              value="conversionCount"
              label="Conversions"
              className="width-sm"
              {...headProps}
              desc
            />
            {showCr && (
              <TableHead
                value="cr"
                label="CR"
                className="width-sm"
                {...headProps}
                desc
              />
            )}
            {showEcr && (
              <TableHead
                value="ecr"
                label="ECR"
                className="width-sm"
                {...headProps}
                desc
              />
            )}
            {showEpc && (
              <TableHead
                value="epc"
                label="EPC (4 days / 30 days)"
                className="width-sm"
                {...headProps}
                desc
              />
            )}
            <TableHead
              value="conversionTotalCommission"
              label="Revenue"
              className="width-md"
              {...headProps}
              desc
            />
          </tr>
        </thead>
        <tbody>
          {map(shownRows, (item, index) => (
            <TopItem
              key={item[`${tab}Uuid`] || index}
              item={item}
              prevItem={findPrevItem(item)}
              sort={sort}
              totals={totals}
              view={view}
              tab={tab}
              showPageviews={showPageviews}
              showCr={showCr}
              showEcr={showEcr}
              showEpc={showEpc}
              currencyCode={currencyCode}
            />
          ))}
          {shownRows?.length === 0 && (
            <tr>
              <td colSpan={10} className="text-center text-light text-bolder">
                No {performanceTabLabels[tab].toLowerCase()}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Tooltip
        id="overview2-performance-tooltip"
        type="dark"
        effect="solid"
        place="bottom"
        rebuild={[shownRows]}
      />
    </div>
  )
}

const TopItem = (props) => {
  const {
    item,
    prevItem,
    sort,
    totals,
    view,
    tab,
    showPageviews,
    showCr,
    showEcr,
    showEpc,
    currencyCode,
  } = props

  return (
    <tr>
      <td>
        <div className="row row-center row-space-between">
          <WithTooltip
            className="top-value-name name text-dark text-bold"
            label={toUnicode(item.name || item.grp || 'Unknown')}
            tooltipId="overview2-performance-tooltip"
          />
          <div className="row row-center row-narrow row-nowrap">
            {/*
            view === 'slow' && (
              <button
                className="link link-new-lighter link-filter"
                onClick={async (event) => {
                  navigateTo(
                    event,
                    `/insights/reports?tab=${tab}&ecrFilter=1&search=${encodeURIComponent(
                      item.name || item.grp
                    )}`
                  )
                }}
              >
                <SVG
                  width={16}
                  height={16}
                  src="/images/aside-statistics.svg"
                  className="vertical-middle"
                />
              </button>
            )
            */}
            {tab === 'name' && (
              <button
                className="link link-new-lighter link-filter"
                onClick={async (event) => {
                  const response = await getRequest(
                    `/advs/v1/uuid/${item.name || item.grp}`
                  )
                  if (response.data) {
                    navigateTo(event, `/merchants/${response.data}`)
                  }
                }}
              >
                <SVG
                  src="/images/icon-details.svg"
                  className="vertical-middle"
                />
              </button>
            )}
          </div>
        </div>
      </td>
      {showPageviews && (
        <td
          className={varClass({
            'sort-hightlight': sort === 'pageviewCount',
          })}
        >
          <TopValue value={formatStat(item.pageviewCount)} color="red" />
          <TopBar
            show={sort === 'pageviewCount'}
            value={item.pageviewsCount}
            max={totals.pageviews.count}
            color="red"
          />
        </td>
      )}
      <td
        className={varClass({
          'sort-hightlight': sort === 'clickCount',
        })}
      >
        <TopValue value={formatStat(item.clickCount)} color="orange" />
        <TopBar
          show={sort === 'clickCount'}
          value={item.clickCount}
          max={totals.clicks?.count}
          color="orange"
        />
      </td>
      <td
        className={varClass({
          'sort-hightlight': sort === 'conversionCount',
        })}
      >
        <TopValue value={formatStat(item.conversionCount)} color="blue" />
        <TopBar
          show={sort === 'conversionCount'}
          value={item.conversionCount}
          max={totals.conversions?.count}
          color="blue"
        />
      </td>
      {showCr && (
        <td
          className={varClass({
            'sort-hightlight': sort === 'cr',
          })}
        >
          <div className="row row-narrow row-nowrap">
            <TopValue
              value={formatStat(
                (item.conversionCount / (item.clickCount || 1)) * 100,
                'percent2'
              )}
              color="blue"
            />
            {prevItem && (
              <>
                <span>/</span>
                <TopValue
                  value={formatStat(
                    (item.conversionCount / (item.clickCount || 1)) * 100,
                    'percent2'
                  )}
                  color="light-blue"
                />
              </>
            )}
          </div>
        </td>
      )}
      {showEcr && (
        <td
          className={varClass({
            'sort-hightlight': sort === 'ecr',
          })}
        >
          <div className="row row-narrow row-nowrap">
            <TopValue
              value={formatStat(
                (item.conversionTotalCommission / (item.conversionSale || 1)) *
                  100,
                'percent2'
              )}
              color="blue"
            />
            {prevItem && (
              <>
                <span>/</span>
                <TopValue
                  value={formatStat(
                    (prevItem.conversionTotalCommission /
                      (prevItem.conversionSale || 1)) *
                      100,
                    'percent2'
                  )}
                  color="light-blue"
                />
              </>
            )}
          </div>
        </td>
      )}
      {showEpc && (
        <td
          className={varClass({
            'sort-hightlight': sort === 'epc',
          })}
        >
          <div className="row row-narrow row-nowrap">
            <TopValue
              value={formatStat(
                item.conversionTotalCommission / (item.clickCount || 1),
                currencyCode
              )}
              color="blue"
            />
            {prevItem && (
              <>
                <span>/</span>
                <TopValue
                  value={formatStat(
                    prevItem.conversionTotalCommission /
                      (prevItem.clickCount || 1),
                    currencyCode
                  )}
                  color="light-blue"
                />
              </>
            )}
          </div>
        </td>
      )}
      <td
        className={varClass({
          'sort-hightlight': sort === 'conversionTotalCommission',
        })}
      >
        <div className="text-right text-nowrap">
          <TopValue
            value={formatCurrency(
              item.conversionTotalCommission,
              item.currencyCode || currencyCode
            )}
            color="green"
          />
        </div>
        <TopBar
          show={sort === 'conversionTotalCommission'}
          value={item.conversionTotalCommission}
          max={totals.commission?.value}
          color="green"
        />
      </td>
    </tr>
  )
}

const TopValue = (props) => {
  const { value, color } = props

  return (
    <div className={`top-value top-value-${color} text-nowrap`}>{value}</div>
  )
}

const TopBar = (props) => {
  const { show, value, max, color } = props

  if (!show) {
    return null
  }

  return (
    <div className={`top-bar top-bar-${color}`}>
      <div style={{ width: `${(value / max) * 100}%` }} />
    </div>
  )
}

// const GettingStarted = (props) => {
//   const { networks, channels } = props
//
//   const { showModal } = useModal()
//
//   const [step, setStep] = useState(0)
//   const [chosenNetwork, setChosenNetwork] = useState(null)
//
//   return (
//     <div className="card p-x-5 p-y-5 m-b-20">
//       <div className="text-dark text-bolder m-t-05 m-b-3">Get started</div>
//       <div className="row row-space-between row-nowrap">
//         <div className="text-bold m-b-5">
//           Get the full experience of your Heylink dashboard by completing the
//           steps.
//         </div>
//         <div className="text-light">{Math.min(step + 1, 2)}/2</div>
//       </div>
//       <div className="overview-opportunity-bar m-b-20">
//         {map([0, 1], (i) => (
//           <div
//             key={i}
//             className={varClass({
//               'done': i < step,
//               'active': i === step,
//             })}
//           />
//         ))}
//       </div>
//       <div className="overview-opportunity row row-center row-space-between row-nowrap m-b-10">
//         <div className="row row-center row-narrow row-nowrap">
//           <SVG
//             src="/images/discover/icon-opportunities.svg"
//             className="m-r-3"
//           />
//           <div>
//             <div className="text-dark text-bolder m-b-15">
//               Connect to networks
//             </div>
//             <div className="text-bold">
//               Create a connection to your first affiliate network to get deeper
//               insights in your traffic.
//             </div>
//           </div>
//         </div>
//         {!chosenNetwork ? (
//           <button
//             className="btn btn-dark btn-md m-l-10"
//             onClick={() => {
//               showModal(
//                 <ConnectFirstNetworkModal
//                   networks={networks}
//                   channels={channels}
//                   onConnect={(network) => {
//                     setStep(1)
//                     setChosenNetwork(network)
//                   }}
//                   onDone={(network) => {
//                     setStep(2)
//                   }}
//                 />
//               )
//             }}
//             disabled={chosenNetwork}
//           >
//             <SVG src="/images/icon-plus.svg" className="o-40 m-r-2" />
//             Connect network
//           </button>
//         ) : (
//           <button className="btn btn-dark btn-md m-l-10">
//             <SVG
//               src="/images/icon-check.svg"
//               className="text-new-green o-60 m-r-2"
//             />
//             Connect network
//           </button>
//         )}
//       </div>
//       <div
//         className={varClass({
//           'overview-opportunity row row-center row-space-between row-nowrap':
//             true,
//           'unclickable o-70': step < 1,
//         })}
//       >
//         <SVG
//           src="/images/overview2/opportunity-arrow.svg"
//           className="overview-opportunity-arrow"
//         />
//         <div className="row row-center row-narrow row-nowrap">
//           <SVG
//             src="/images/discover/icon-opportunities.svg"
//             className={varClass({ 'm-r-3': true, 'grayscale': step < 1 })}
//           />
//           <div>
//             <div className="text-dark text-bolder m-b-15">
//               Map your affiliate channels
//             </div>
//             <div className="text-bold">
//               Get in-depth insights on the traffic going out from these
//               channels.
//             </div>
//           </div>
//         </div>
//         {step === 0 && (
//           <button className="btn btn-dark btn-md m-l-10 o-50">
//             <SVG src="/images/icon-plus.svg" className="o-40 m-r-2" />
//             Map affiliate channels
//           </button>
//         )}
//         {step === 1 && (
//           <button
//             className="btn btn-dark btn-md m-l-10"
//             onClick={() => {
//               navigateTo(`/networks/${chosenNetwork.networkUuid}`)
//             }}
//           >
//             <SVG src="/images/icon-plus.svg" className="o-40 m-r-2" />
//             Map affiliate channels
//           </button>
//         )}
//         {step === 2 && (
//           <button className="btn btn-dark btn-md m-l-10">
//             <SVG src="/images/icon-check.svg" className="o-40 m-r-2" />
//             Map affiliate channels
//           </button>
//         )}
//       </div>
//     </div>
//   )
// }

// const Opportunities = (props) => {
//   const { data, networks, channels, connections, showSnackbar, reload } = props
//
//   const { showModal } = useModal()
//
//   const [open, setOpen] = useState(true)
//
//   const shownData = filterBy(
//     data,
//     (opportunity) => !opportunity.ignored.isIgnored
//   )
//
//   const [shownIndex, setShownIndex] = useState(0)
//   const opportunity = shownData[shownIndex]
//   const network =
//     opportunity && findNetwork(networks, opportunity.network.networkUuid)
//   const isConnected = isNetworkConnected(connections, network)
//   const hasPrev = shownIndex > 0
//   const hasNext = shownIndex < shownData.length - 1
//
//   if (isBlank(shownData) || isBlank(network)) {
//     return null
//   }
//
//   const ignoreOpportunity = async (opportunity) => {
//     opportunity.ignored.isIgnored = true
//     data.find(
//       (datum) => datum.network.networkUuid === opportunity.network.networkUuid
//     ).ignored.isIgnored = true
//     const response = await postRequest('/discovers/v3/opportunities/ignores', {
//       opportunityIgnoreNetworkUuid: opportunity.network.networkUuid,
//       opportunityIgnoreType: opportunity.opportunityType,
//     })
//     if (response.code === 200) {
//       reload()
//     } else {
//       showSnackbar(responseError(response))
//     }
//   }
//
//   return (
//     <div
//       className="card p-x-5 p-y-5 m-b-20"
//       style={{
//         maxHeight: open ? 'unset' : 60,
//         overflow: 'hidden',
//       }}
//     >
//       <div className="card-header m-b-5">
//         <div className="row row-space-between">
//           <div className="text-dark text-bolder">Opportunities</div>
//           <div className="vertical-middle m-t--2 m-b--2">
//             <span className="text-light text-bolder m-r-6">
//               {shownIndex + 1}/{shownData.length}
//             </span>
//             <button
//               className="link link-dark m-t-1 m-b--1"
//               onClick={() => {
//                 setOpen(!open)
//               }}
//             >
//               <SVG
//                 src="/images/chevron-down.svg"
//                 width={26}
//                 height={26}
//                 className={varClass({
//                   'smooth-rotate-0': open,
//                   'smooth-rotate-180': !open,
//                 })}
//               />
//             </button>
//           </div>
//         </div>
//       </div>
//       <div className="card-body p-0">
//         <div className="row row-space-between row-nowrap">
//           <div className="text-bold m-b-5">
//             We continually analyze your data to help you optimize and monetise
//             on all your traffic.
//           </div>
//         </div>
//         <div className="scrollable-horizontal">
//           <div className="inline-block-sm">
//             {shownData.length > 1 && (
//               <div className="overview-opportunity-bar m-b-20">
//                 {map(shownData.length, (_, i) => (
//                   <div
//                     key={i}
//                     className={varClass({
//                       'done': i < shownIndex,
//                       'active': i === shownIndex,
//                     })}
//                   />
//                 ))}
//               </div>
//             )}
//             {opportunity && (
//               <>
//                 <div className="overview-opportunity text-dark text-bolder m-b-10">
//                   <div className="vertical-middle">
//                     <SVG
//                       src="/images/discover/icon-opportunities.svg"
//                       className="m-r-20"
//                     />
//                     Connect to networks
//                   </div>
//                   <div className="text-light text-bold text-nowrap">
//                     Monetise on up to{' '}
//                     <span className="value-badge value-badge-orange">
//                       {opportunity.opportunityClicks}{' '}
//                       {plural(opportunity.opportunityClicks, 'click')}
//                     </span>
//                   </div>
//                 </div>
//                 <div className="overview-opportunity text-bolder">
//                   <SVG
//                     src="/images/overview2/opportunity-arrow.svg"
//                     className="overview-opportunity-arrow"
//                   />
//                   <div className="row row-center row-nowrap flex-1">
//                     <div className="card card-logo borderless">
//                       <Logo
//                         src={network.networkLogoUrl}
//                         text={network.networkName}
//                         width={100}
//                         height={24}
//                       />
//                     </div>
//                     <div className="text-bold">
//                       You are sending traffic to one or more campaigns from{' '}
//                       {network.networkName}. Connect to monetise.
//                     </div>
//                   </div>
//                   <div className="row row-center row-end row-narrow row-nowrap text-nowrap">
//                     <button
//                       className="btn btn-bordered btn-md background-white m-l-10"
//                       onClick={() => {
//                         ignoreOpportunity(opportunity)
//                       }}
//                     >
//                       Ignore for now
//                     </button>
//                     {!isConnected && (
//                       <button
//                         className="btn btn-dark btn-md m-l-10"
//                         onClick={() => {
//                           showModal(
//                             <ConnectOpportunityNetworkModal
//                               network={network}
//                               channels={channels}
//                               opportunity={opportunity}
//                               onDone={reload}
//                             />
//                           )
//                         }}
//                       >
//                         <SVG
//                           src="/images/icon-plus.svg"
//                           className="o-40 m-r-2"
//                         />
//                         Connect network
//                       </button>
//                     )}
//                   </div>
//                 </div>
//                 {(hasPrev || hasNext) && (
//                   <div className="row row-center row-space-between row-nowrap text-bold m-t-20">
//                     <div className="flex-1-3 text-left">
//                       {hasPrev && (
//                         <button
//                           className="link link-secondary vertical-middle"
//                           onClick={() => setShownIndex(shownIndex - 1)}
//                         >
//                           <SVG
//                             src="/images/chevron2-right.svg"
//                             className="rotate-180 m-t--05 m-r-3"
//                           />{' '}
//                           Previous
//                         </button>
//                       )}
//                     </div>
//                     <div className="flex-1-3 text-center">
//                       <Link
//                         href="/discover/opportunities"
//                         className="link link-dark"
//                       >
//                         View all
//                       </Link>
//                     </div>
//                     <div className="flex-1-3 text-right">
//                       {hasNext && (
//                         <button
//                           className="link link-secondary vertical-middle"
//                           onClick={() => setShownIndex(shownIndex + 1)}
//                         >
//                           Next{' '}
//                           <SVG
//                             src="/images/chevron2-right.svg"
//                             className="m-t--05 m-l-3"
//                           />
//                         </button>
//                       )}
//                     </div>
//                   </div>
//                 )}
//               </>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
