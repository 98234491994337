import React, { useState } from 'react'
import SVG from 'react-inlinesvg'
import { uniqBy } from 'lodash'
import { Placeholder } from './Placeholder'
import {
  IChannelsV2Channels,
  Header,
  NewChannelModal,
  Loadable,
  WithTooltip,
  Tooltip,
  TableInfo,
  TableEmpty,
  NavFilter,
  SearchSubmit,
  Logo,
  useApiGet,
  useModal,
  useSnackbar,
  useSortableTable,
  useTableEmpty,
  navigateTo,
  formatStat,
  formatShortDateStr,
  map,
  isBlank,
  newTableSort,
  filterBySearch,
  varClass,
  plural,
} from '../shared'

const filterLabels = {
  'channelType': 'Type',
}

const filterOptions = {
  'channelType': {
    '': 'All channels',
    'App': 'App',
    'Newsletter': 'Newsletter',
    'Website': 'Website',
    'Social media': 'Social media',
  },
}

const sortOptions = {
  'channelName': 'Name',
  'networks': 'Networks',
  'channelCampaignCount': 'Campaigns',
  'channelIsActive': 'Status',
}
const dirOptions = {
  'channelName': 'asc',
  'networks': 'desc',
  'channelCampaignCount': 'desc',
  'channelIsActive': 'asc',
}

const channelFilter = (collection: any, filters) =>
  collection.filter((item) => {
    for (const filter of filters) {
      if (filter.value) {
        const values = filter.value.split(',').map((value) => value.trim())
        if (!values.includes(item[filter.key])) {
          return false
        }
      }
    }
    return true
  })

const channelSort = newTableSort((a: any, b: any, key: string) => {
  if (key === 'networks') {
    return [a.networks.length, b.networks.length]
  }
})

const splitUrl = (url: string): [string, string] => {
  if (!url) return ['Undefined', '']

  const canonical = url.replace(/https?:\/\//, '')
  const firstSlash =
    canonical.indexOf('/') !== -1 ? canonical.indexOf('/') : canonical.length

  const host = canonical.substr(0, firstSlash) || 'Undefined'
  const path = canonical.substr(firstSlash) || ''

  return [host, path === '/' ? '' : path]
}

export const Sites = (props) => {
  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  const [filters, setFilters] = useState([
    {
      key: 'channelType',
      value: '',
    },
  ])

  const [search, setSearch] = useState('')

  const channels = useApiGet<IChannelsV2Channels[]>('/channels/v2/channels')

  return (
    <>
      <Header label="My sites" />
      <Loadable data={channels} placeholder={<Placeholder />}>
        <Content
          {...{
            channels,
            filters,
            setFilters,
            search,
            setSearch,
            showModal,
            showSnackbar,
          }}
        />
      </Loadable>
    </>
  )
}

const Content = (props) => {
  const {
    channels,
    filters,
    setFilters,
    search,
    setSearch,
    showModal,
    showSnackbar,
  } = props

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'channelName',
    dir: 'asc',
  })

  const [isEmpty, setIsEmpty] = useTableEmpty(channels)

  return (
    <div
      className="card"
      style={{
        minHeight: isEmpty ? 650 : '',
      }}
    >
      <div className="card-body p-x-15 p-y-0">
        {/* <Summary {...{ channels }} /> */}
        <TableNav
          {...{
            channels,
            search,
            setSearch,
            filters,
            setFilters,
            isEmpty,
            showModal,
            showSnackbar,
          }}
        />
        <Table
          {...{
            channels,
            sort,
            dir,
            toggleSort,
            search,
            filters,
            isEmpty,
            setIsEmpty,
          }}
        />
      </div>
    </div>
  )
}

const Summary = (props) => {
  const { channels } = props

  const discoverCount = channels.filter(
    (channel) => !channel.channelIsActive
  ).length
  const activeCount = channels.length - discoverCount

  return (
    <div className="card-border card-border-rounded p-x-6 p-y-30">
      <div className="row row-nowrap">
        <div>
          <div className="text-dark text-big text-bold m-b-10">
            Number of sites
          </div>
          <div className="text-largiestest text-boldest text-blue m-b-10">
            {channels.length}
          </div>
          <div className="text-smaller">
            Create a Site for each channel you want to monetise.
          </div>
        </div>
        <div className="m-l-6">
          <div className="text-dark text-big text-bold m-b-10">Site mode</div>
          <div className="row row-medium row-nowrap">
            <div
              className="p-x-4 p-y-10 border-round text-nowrap"
              style={{ minWidth: 131, background: '#DEE2E6' }}
            >
              <div className="lh-21 m-b-1">Discover mode</div>
              <div className="text-dark text-larger lh-36">{discoverCount}</div>
            </div>
            <div
              className="flex-1 p-x-4 p-y-10 border-round text-nowrap"
              style={{ minWidth: 131, background: '#E9ECEF' }}
            >
              <div className="lh-21 m-b-1">Auto mode</div>
              <div className="text-dark text-larger lh-36">{activeCount}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const TableNav = (props) => {
  const {
    channels,
    search,
    setSearch,
    filters,
    setFilters,
    isEmpty,
    showModal,
    showSnackbar,
  } = props

  const [shownSearch, setShownSearch] = useState(search)

  return (
    <div className="p-y-20 m-t--3 m-b-10">
      <div className="text-bolder">Search sites</div>
      <div className="row row-space-between p-t-05 m-t-4 m-b-3">
        <SearchSubmit
          value={search}
          setValue={setSearch}
          placeholder="Search for a site name or domain"
          className="search w-60"
          inputClassName="w-100 p-r-8"
          highlight
        />
        <div className="vertical-middle">
          <button
            className={varClass({
              'btn btn-primary': true,
              'btn-gradient relative z-index-6': isEmpty,
            })}
            onClick={() =>
              showModal(<NewChannelModal showSnackbar={showSnackbar} />)
            }
          >
            <SVG
              src="/images/icon-plus2.svg"
              width={18}
              height={18}
              className="m-r-15"
            />
            Add new site
          </button>
        </div>
      </div>
      <NavFilter
        {...{
          filters,
          setFilters,
          filterLabels,
          filterOptions,
        }}
        onRemove={(_, key) => {
          const newFilters = filters.map((item) => {
            if (item.key === key) {
              return { ...item, value: '', label: '' }
            }
            return { ...item }
          })
          setFilters(newFilters)
        }}
        hideRemove={filters.some((item) => !item.value)}
        hideAdd
        hideChecks
        isMultipleChoice
      />
    </div>
  )
}

const Table = (props) => {
  const {
    channels,
    sort,
    dir,
    toggleSort,
    search,
    filters,
    isEmpty,
    setIsEmpty,
  } = props

  const shownChannels = channelSort(
    channelFilter(
      filterBySearch(channels, search, (channel: IChannelsV2Channels) => [
        channel.channelDomain,
        channel.channelName,
      ]),
      filters
    ),
    sort,
    dir
  )

  const headProps = { sort, dir, toggleSort }

  return (
    <div className="table-container-overflow">
      <table className="table table-block-rows channels-index-table">
        <thead className="text-nowrap">
          <tr>
            <TableHead
              value="channelName"
              label="Site name"
              className="w-30"
              {...headProps}
            />
            <TableHead
              value="networks"
              label="Connected networks"
              className="w-20"
              desc
              {...headProps}
            />
            <TableHead
              value="channelCampaignCount"
              label="Imported campaigns"
              className="w-20"
              desc
              {...headProps}
            />
            <TableHead
              value="channelIsActive"
              label="Status"
              className="w-20"
              {...headProps}
            />
            <TableHead
              value="channelCreatedDatetime"
              label="Created date"
              className="w-20"
              desc
              {...headProps}
            />
            <th></th>
          </tr>
        </thead>
        <tbody>
          {map(shownChannels, (channel) => (
            <TableItem
              key={channel.channelUuid}
              channel={channel}
              sort={sort}
            />
          ))}
          {!isEmpty && isBlank(shownChannels) && (
            <tr>
              <td
                colSpan={10}
                className="text-center text-light text-bolder"
                style={{ height: 51 }}
              >
                Nothing found
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {isEmpty && (
        <TableEmpty
          icon="channels"
          title="No Sites added yet"
          subtitle="To add your first site simply click the button above"
          showArrow
          setIsEmpty={setIsEmpty}
        />
      )}

      <Tooltip
        id="channels-tooltip"
        type="dark"
        effect="solid"
        place="bottom"
        rebuild={[shownChannels]}
      />
    </div>
  )
}

const TableHead = (props) => {
  const { value, label, className, desc, sort, dir, toggleSort } = props

  return (
    <th
      className={varClass({
        'cursor-pointer': true,
        [className]: !!className,
      })}
      onClick={toggleSort(value, desc ? 'desc' : 'asc')}
    >
      <span className="column-label text-nowrap">
        {label}
        {sort === value ? (
          <>
            {dir === 'desc' ? (
              <SVG src="/images/insights/caret-down.svg" className="m-l-1" />
            ) : (
              <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
            )}
          </>
        ) : (
          <SVG src="/images/insights/caret.svg" className="m-l-1" />
        )}
      </span>
    </th>
  )
}

const TableItem = (props) => {
  const { channel, sort } = props

  return (
    <tr>
      <td
        className={varClass({
          'sort-highlight': sort === 'channelName',
        })}
      >
        <button
          className="btn btn-link-new borderless row row-center row-fill row-nowrap"
          onClick={(event) => {
            navigateTo(event, `/sites/${channel.channelUuid}`)
          }}
        >
          <Logo
            src={channel.channelFaviconUrl}
            letter={channel.channelDomain || channel.channelName}
            width={32}
            height={32}
            className="logo-rounded m-r-2"
          />

          <div className="text-left text-nowrap">
            <div
              className="text-primary text-bolder lh-20 m-b-1"
              style={{
                maxWidth: 250,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {channel.channelName}
            </div>
            <div
              className="lh-20"
              style={{
                maxWidth: 250,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {channel.channelDomain}
            </div>
          </div>
        </button>
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'channelDomain',
        })}
      >
        <div className="vertical-middle">
          <SVG src="/images/icon-network.svg" className="m-r-1" />
          <span className="text-small text-bold">
            {formatStat(channel.networks.length)}
          </span>
        </div>
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'channelCampaignCount',
        })}
      >
        <span className="text-small text-bold">
          {formatStat(channel.channelCampaignCount)}
        </span>
      </td>
      <td
        className={varClass({
          'text-wrap p-y-3': true,
          'sort-highlight': sort === 'networks',
        })}
      >
        {channel.channelIsActive ? (
          <div className="text-nowrap">
            <div className="pin-green m-r-1" />{' '}
            <span className="text-small text-bold">Active</span>
          </div>
        ) : (
          <div className="text-nowrap">
            <div className="pin-grey m-r-1" />{' '}
            <span className="text-small text-bold">Discover mode</span>
          </div>
        )}
      </td>
      <td className="text-small text-nowrap">
        {formatShortDateStr(channel.channelCreatedDatetime)}
      </td>
      <td>
        <button
          className="link text-small"
          onClick={(event) => {
            navigateTo(event, `/sites/${channel.channelUuid}`)
          }}
        >
          Settings
        </button>
      </td>
    </tr>
  )
}

const ChannelDomain = (props) => {
  const { url } = props

  const [host, path] = splitUrl(url)
  return (
    <WithTooltip
      className="channel-domain-tooltip"
      title={url}
      text={
        <div className="channel-domain">
          <span className="channel-domain-host">{host}</span>
          {path && <span className="channel-domain-path">{path}</span>}
        </div>
      }
      tooltipId="channels-tooltip"
    />
  )
}
