import React, { useState, useEffect, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import SVG from 'react-inlinesvg'
import { format, parse } from 'date-fns'
import { sortBy, isEqual, pick, pickBy } from 'lodash'
import { Placeholder } from './Placeholder'
import {
  Header,
  Loadable,
  TableHead,
  CountrySelectOptions,
  CountryPhoneWithDialInput,
  Tooltip,
  WithTooltip,
  useApiGet,
  useModal,
  useSnackbar,
  useSortableTable,
  useFormSubmit,
  navigateTo,
  varClass,
  formatStat,
  formatCurrencyReversed,
  newTableSort,
  map,
  toArray,
  inTimezone,
  capitalize,
  responseError,
} from '../../shared'
import {
  getCurrentUser,
  postRequest,
  putRequest,
  connectGet,
} from '../../../services'

const currencyIcons = {
  'usd': '$',
  'eur': '€',
  'gbp': '£',
}
const currencyIcon = (currency: string): string => {
  if (currencyIcons[currency.toLowerCase()]) {
    return currencyIcons[currency.toLowerCase()]
  } else {
    return currency.toUpperCase()
  }
}

const invoiceSort = newTableSort()
const payoutSort = newTableSort()

export const Billing = (props) => {
  const data = useApiGet('/billing/v2/customer')
  const portal = useApiGet('/billing/v1/portal')

  const [partners, setPartners] = useState(null)
  const [showPlanOverview, setShowPlanOverview] = useState(false)
  const [currentPlan, setCurrentPlan] = useState({ planLevel: 1 })
  const [label, setLabel] = useState('')

  useEffect(() => {
    setLabel(showPlanOverview ? '' : 'Plans & Billing')
  }, [showPlanOverview])

  useEffect(() => {
    const callback = async () => {
      const currentUser = getCurrentUser()
      try {
        const response = await connectGet(
          `/publishers/${currentUser.account?.accountUuid}`
        )
        if (response.code === 200) {
          setPartners(response?.data?.partners || [])
        } else {
          setPartners([])
        }
      } catch (error) {
        console.log(error)
        setPartners([])
      }
    }
    callback()
  }, [])

  const [reloadAccountRef, setReloadAccountRef] = useState(0)
  const reloadAccount = () => setReloadAccountRef(reloadAccountRef + 1)
  const account = useApiGet('/profile/v1/account', undefined, [
    reloadAccountRef,
  ])

  return (
    <>
      <Header label={label} />
      <Loadable
        data={data && partners && portal && account}
        placeholder={<Placeholder />}
      >
        {showPlanOverview ? (
          <PlanOverView
            setShowPlanOverview={setShowPlanOverview}
            currentPlan={currentPlan}
            setCurrentPlan={setCurrentPlan}
          />
        ) : (
          <BillingOverview
            data={data}
            partners={partners}
            portal={portal}
            account={account}
            reloadAccount={reloadAccount}
            setShowPlanOverview={setShowPlanOverview}
          />
        )}
      </Loadable>
    </>
  )
}

const BillingOverview = (props) => {
  const {
    data,
    partners,
    portal,
    account,
    reloadAccount,
    setShowPlanOverview,
  } = props

  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  // const [menuOpen, setMenuOpen] = useState(false)
  // const menuRef = useRef()
  // useOutsideClick(menuRef, () => {
  //   setMenuOpen(false)
  // })

  // const anyFeatures =
  //   data.usage.features.api ||
  //   data.usage.features.integration ||
  //   data.usage.features.whitelabel ||
  //   data.usage.features.googleFeed

  const customer = data.customer
  const subscription = data.subscriptions.find(
    (subscription) => subscription.subscriptionStatus === 'active'
  )

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'invoiceCreatedDatetime',
    dir: 'desc',
  })
  const headProps = { sort, dir, toggleSort }
  const shownInvoices = invoiceSort(toArray(data.invoices), sort, dir)
  const shownPayouts = payoutSort(toArray(data.payouts), sort, dir)

  const [tab, setTab] = useState('payment')
  const revshareEnabled = useMemo(
    () => getCurrentUser()?.user?.userAccountRevshareEnabled,
    []
  )

  useEffect(() => {
    if (location.hash.includes('#done')) {
      showModal(<ConfirmModal />)
    }
  }, [])

  return (
    <div className="billing-overview">
      <div className="card flex-1 flex-1-2 m-b-5">
        <div className="row row-space-between">
          <div className="flex-1">
            <div className="text-dark text-boldest m-t-10 m-b-1">Your Plan</div>
            {subscription ? (
              <div className="row row-bottom row-wider row-nowrap">
                <BillingPlanName
                  customer={customer}
                  subscription={subscription}
                />
                <BillingPlanPrice subscription={subscription} />
              </div>
            ) : (
              <div className="row row-bottom row-wider row-nowrap">
                <BillingPlanName customer={customer} />
                <BillingPlanPrice />
              </div>
            )}

            {/*
            {anyFeatures && (
              <div className="w-50 m-t-8">
                <div className="text-big text-bolder">Features</div>
                <ul className="p-l-4 m-t-10 text-light">
                  {data.usage.features.integration && (
                    <li className="m-b-1">
                      Unified reporting across multiple affiliate networks
                    </li>
                  )}
                  {data.usage.features.api && (
                    <li className="m-b-1">
                      Full API access Integration with merchants
                    </li>
                  )}
                  {data.usage.features.whitelabel && (
                    <li className="m-b-1">White-label affiliate network</li>
                  )}
                  {data.usage.features.googleFeed && (
                    <li className="m-b-1">
                      Google-shopping feed Sub-affiliates
                    </li>
                  )}
                </ul>
              </div>
            )}
            */}

            {subscription ? (
              <a
                className="billing-upgrade-btn btn btn-primary btn-xl cursor-pointer"
                href={portal.checkoutSessionUrl}
                onClick={() => {
                  if (data.customer.customerIsCustom) {
                    showSnackbar(
                      "You're on a custom plan. Please contact us in order to update your subscription details."
                    )
                  }
                }}
                target="_blank"
              >
                Change plan
              </a>
            ) : (
              <button
                className="billing-upgrade-btn btn btn-primary btn-xl cursor-pointer"
                onClick={() => {
                  if (data.customer?.customerIsCustom) {
                    showSnackbar(
                      "You're on a custom plan. Please contact us in order to update your subscription details."
                    )
                  } else {
                    // setShowPlanOverview(true)
                    showModal(<UpgradeModal showSnackbar={showSnackbar} />, {
                      cardClassName: 'billing-upgrade-modal',
                    })
                  }
                }}
              >
                Change plan
              </button>
            )}

            {/*
            <div className="billing-dropdown d-none" ref={menuRef}>
              <button className="link" onClick={() => setMenuOpen(!menuOpen)}>
                <SVG src="/images/settings/billing-menu.svg" />
              </button>
              <div className={varClass({ 'submenu': true, 'shown': menuOpen })}>
                <button
                  className="cursor-pointer"
                  onClick={() => {
                    setMenuOpen(false)
                    showModal(<UpgradeModal showSnackbar={showSnackbar} />, {
                      cardClassName: 'billing-upgrade-modal',
                    })
                  }}
                >
                  <SVG src="/images/settings/billing-update.svg" />
                  Upgrade your plan
                </button>
                {subscription && (
                  <button
                    className="cursor-pointer"
                    onClick={() => {
                      setMenuOpen(false)
                      showModal(<CancelModal />, {
                        cardClassName: 'billing-cancel-modal',
                      })
                    }}
                  >
                    <SVG src="/images/settings/billing-cancel.svg" />
                    Cancel subscription
                  </button>
                )}
                <button
                  className="cursor-pointer"
                  onClick={(event) => {
                    if (subscription) {
                      navigateTo(event, portal.checkoutSessionUrl)
                    } else {
                      setMenuOpen(false)
                      showModal(<UpgradeModal showSnackbar={showSnackbar} />, {
                        cardClassName: 'billing-upgrade-modal',
                      })
                    }
                  }}
                >
                  <SVG src="/images/settings/billing-update.svg" />
                  Upgrade your plan
                </button>
                {subscription && (
                  <button
                    className="cursor-pointer"
                    onClick={(event) => {
                      navigateTo(event, portal.checkoutSessionUrl)
                    }}
                  >
                    <SVG src="/images/settings/billing-cancel.svg" />
                    Cancel subscription
                  </button>
                )}
              </div>
            </div>
            */}
          </div>

          <div className="flex-1">
            <div className="text-dark text-boldest m-t-10 m-b-4">Includes</div>
            <BillingUsages usage={data.usage} partners={partners} />
          </div>
        </div>
      </div>

      <div className="row row-space-between">
        <div className="card flex-1 flex-1-2">
          <div className="text-dark text-boldest m-t-10 m-b-5">
            Payment method
          </div>
          <div className="row flex-1 m-t-30">
            {subscription ? (
              <div>
                Your plan renews on{' '}
                <b>
                  {format(
                    parse(
                      subscription.subscriptionCurrentPeriodEndDatetime +
                        ' +01',
                      'yyyy-MM-dd HH:mm:ss X',
                      new Date()
                    ),
                    'MMMM dd, yyyy'
                  )}
                </b>
                <i className="text-light text-smaller m-l-10">
                  {capitalize(subscription.subscriptionInterval)}ly billing
                </i>
              </div>
            ) : null}
            {map(
              sortBy(data.sources, (source) => !source.sourceIsPrimary),
              (source) => (
                <BillingCard
                  key={`${source.sourceBrand}_${source.sourceLastFour}`}
                  source={source}
                  portal={portal}
                  customerIsCustom={data.customer.customerIsCustom}
                  showSnackbar={showSnackbar}
                />
              )
            )}
            {!data.sources?.length && (
              <div
                className="text-light text-bolder m-t-2 m-b-4 o-60"
                style={{ flexBasis: '100%' }}
              >
                No payment method found
              </div>
            )}
            <button
              className="link text-light text-bolder vertical-middle"
              onClick={(event) => {
                navigateTo(event, portal.checkoutSessionUrl)
              }}
            >
              <SVG src="/images/icon-plus-color.svg" className="m-r-1" />
              <span>Add new payment method</span>
            </button>
          </div>
        </div>

        <div className="card flex-1 flex-1-2">
          <div className="text-dark text-boldest m-t-10 m-b-5">
            Company details
          </div>
          <CompanyForm
            account={account}
            reloadAccount={reloadAccount}
            showSnackbar={showSnackbar}
          />
        </div>
      </div>

      <div className="card m-t-20">
        {revshareEnabled ? (
          <div className="page-subnav">
            <div className="nav">
              <ul>
                <li className={tab === 'payment' ? 'active' : ''}>
                  <button
                    className="link text-bolder"
                    onClick={() => {
                      toggleSort('invoiceCreatedDatetime', 'desc')
                      setTab('payment')
                    }}
                  >
                    Payment History
                  </button>
                </li>
                <li className={tab === 'payout' ? 'active' : ''}>
                  <button
                    className="link text-bolder"
                    onClick={() => {
                      toggleSort('payoutDate', 'desc')
                      setTab('payout')
                    }}
                  >
                    Heylink Boost Payouts
                  </button>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="card-header border-bottom-none">
            <div className="card-title">Payment History</div>
          </div>
        )}

        {tab === 'payment' && (
          <div className="card-body card-body-fill billing-invoices">
            <div className="table-container-overflow">
              <table className="table table-bordered table-sortable table-hoverable table-zebra">
                <thead className="table-sticky">
                  <tr>
                    <TableHead
                      value="invoiceCreatedDatetime"
                      label="Date"
                      {...headProps}
                      desc
                    />
                    <TableHead
                      value="invoiceTotalAmount"
                      label="Amount"
                      align="right"
                      {...headProps}
                      style={{ width: 156 }}
                      desc
                    />
                    <TableHead
                      value="invoiceNumber"
                      label="Invoice number"
                      align="right"
                      {...headProps}
                    />
                    <td className="text-center" style={{ width: 112 }}>
                      Status
                    </td>
                    <TableHead value="" label="Plan name" {...headProps} />
                  </tr>
                </thead>
                <tbody className="text-nowrap">
                  {map(shownInvoices, (invoice) => (
                    <InvoiceItem
                      key={invoice.invoiceId}
                      invoice={invoice}
                      sort={sort}
                    />
                  ))}
                  {!data.invoices?.length && (
                    <tr>
                      <td
                        colSpan={10}
                        className="text-center text-light text-bolder"
                      >
                        No payments available yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Tooltip id="billing-tooltip" type="dark" place="top" />
            </div>
          </div>
        )}
        {tab === 'payout' && (
          <div className="card-body card-body-fill billing-invoices">
            <div className="table-container-overflow">
              <table className="table table-bordered table-sortable table-hoverable table-zebra">
                <thead className="table-sticky">
                  <tr>
                    <TableHead
                      value="payoutDate"
                      label="Date"
                      {...headProps}
                      desc
                    />
                    <TableHead
                      value="payoutAmount"
                      label="Amount"
                      align="right"
                      {...headProps}
                      style={{ width: 156 }}
                      desc
                    />
                  </tr>
                </thead>
                <tbody className="text-nowrap">
                  {map(shownPayouts, (payout) => (
                    <PayoutItem
                      key={payout.payoutUuid}
                      payout={payout}
                      sort={sort}
                    />
                  ))}
                  {!data.payouts?.length && (
                    <tr>
                      <td
                        colSpan={10}
                        className="text-center text-light text-bolder"
                      >
                        No payouts available yet
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Tooltip id="billing-tooltip" type="dark" place="top" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const BillingUsages = (props) => {
  const { usage, partners } = props

  return (
    <div className="billing-usages">
      <div className="billing-usage spacer" />
      <BillingUsage
        title="Commission"
        icon="dollar"
        value={usage.usage.commission}
        maxValue={usage.limits.commission}
      />
      <BillingUsage
        title="Users"
        icon="user"
        value={usage.usage.users}
        maxValue={usage.limits.users}
        noPercentage
      />
      <BillingUsage
        title="Records"
        icon="click"
        value={usage.usage.records}
        maxValue={usage.limits.records}
      />
      <BillingUsage
        title="Direct Partners"
        icon="users"
        value={partners.length}
        maxValue={usage.limits.partners}
      />
      <BillingUsage
        title="Pageviews"
        icon="pageview"
        value={usage.usage.pageviews}
        maxValue={usage.limits.pageviews}
      />
    </div>
  )
}

const BillingUsage = (props) => {
  const { title, icon, value, maxValue, noPercentage } = props

  const percentage = maxValue ? Math.floor((value / maxValue) * 100) : 0
  const isOver = value > maxValue

  return (
    <div className="billing-usage">
      <div className="text-bold">{title}</div>
      <div className="billing-usage-gauge">
        <div style={{ width: `${Math.min(percentage, 100)}%` }} />
      </div>
      <div className="row row-center row-space-between row-nowrap">
        <div
          className={varClass({
            'text-smaller text-bolder text-nowrap vertical-middle': true,
            'text-light': !isOver,
            'text-new-blood': isOver,
          })}
        >
          <SVG
            src={`/images/settings/icon-billing-${icon}.svg`}
            className="m-r-1"
          />
          <span>
            {formatStat(value)} {noPercentage ? null : `(${percentage}%)`}
          </span>
        </div>
        <div className="text-light text-smaller text-bold">
          {formatStat(maxValue)}
        </div>
      </div>
    </div>
  )
}

const BillingCard = (props) => {
  const { source, portal, customerIsCustom, showSnackbar } = props

  return (
    <div className="billing-source">
      <div className="row row-center row-fill row-nowrap">
        <div
          className={varClass({
            'billing-source-primary': true,
            'active': source.sourceIsPrimary,
          })}
        />
        {source.sourceBrand === 'visa' ? (
          <SVG src="/images/card-visa.svg" className="billing-source-brand" />
        ) : source.sourceBrand === 'mastercard' ? (
          <SVG
            src="/images/card-mastercard.svg"
            className="billing-source-brand"
          />
        ) : (
          <SVG src="/images/card-stripe.svg" className="billing-source-brand" />
        )}
        {source.sourceLastFour === '0000' &&
        !source.sourceExpMonth &&
        !source.sourceExpYear ? (
          <div className="m-l-4">
            <div className="row row-center row-fill row-nowrap">
              <div className="text-dark text-big text-bold">
                Manage on Stripe
              </div>
            </div>
            <div className="text-light text-big text-bold m-t-1"></div>
          </div>
        ) : (
          <div className="m-l-4">
            <div className="row row-center row-fill row-nowrap">
              <SVG
                src="/images/settings/billing-card-numbers.svg"
                className="m-r-3"
              />
              <SVG
                src="/images/settings/billing-card-numbers.svg"
                className="m-r-3"
              />
              <SVG
                src="/images/settings/billing-card-numbers.svg"
                className="m-r-3"
              />
              <div className="text-dark text-big text-bold">
                {source.sourceLastFour}
              </div>
            </div>
            <div className="text-light text-big text-bold m-t-1">
              Expiry {source.sourceExpMonth}/{source.sourceExpYear}
            </div>
          </div>
        )}
      </div>

      <button
        className="btn btn-xs btn-edit text-smaller vertical-middle"
        onClick={(event) => {
          navigateTo(event, portal.checkoutSessionUrl)
        }}
      >
        <SVG
          src="/images/icon-edit.svg"
          width={14}
          height={14}
          className="m-l-1 m-r-1"
        />
        <span>Edit</span>
      </button>
    </div>
  )
}

const CompanyForm = (props) => {
  const { account, reloadAccount, showSnackbar } = props

  const form = useForm({
    defaultValues: account,
  })
  const { register, handleSubmit, control } = form

  const anyChanges = form.formState.isDirty

  const [submitAccount, submitting] = useFormSubmit(async (values) => {
    const initialAccount = pickBy(account, (_, key) =>
      Object.keys(values).includes(key)
    )
    const newAccount = pickBy(values, (_, key) =>
      Object.keys(initialAccount).includes(key)
    )
    if (!isEqual(newAccount, initialAccount)) {
      const response = await putRequest(
        `/profile/v1/account`,
        pick(values, [
          'accountName',
          'accountVatNumber',
          'accountAddress',
          'accountZipcode',
          'accountCity',
          'accountCountryCode',
          'accountPhone',
        ])
      )

      if (response) {
        if (response.code === 200) {
          showSnackbar('Company details updated successfully')
          reloadAccount()
        } else {
          showSnackbar(response.data.replace(/^"account/, '"Company '), {
            type: 'alert',
          })
        }
      }
    }
  })

  return (
    <form onSubmit={handleSubmit(submitAccount)}>
      <div className="row m-b-5">
        <div className="control flex-1 flex-1-2">
          <label>Company name</label>
          <input type="text" className="w-100" {...register('accountName')} />
        </div>
        <div className="control flex-1 flex-1-2">
          <label>VAT number</label>
          <input
            type="text"
            className="w-100"
            {...register('accountVatNumber')}
          />
        </div>
      </div>
      <div className="row m-b-5">
        <div className="control flex-1 flex-1-2">
          <label>Address</label>
          <input
            type="text"
            className="w-100"
            {...register('accountAddress')}
          />
        </div>
        <div className="control flex-1 flex-1-2">
          <label>Postal code</label>
          <input
            type="text"
            className="w-100"
            {...register('accountZipcode')}
          />
        </div>
      </div>
      <div className="row m-b-5">
        <div className="control flex-1 flex-1-2">
          <label>City</label>
          <input type="text" className="w-100" {...register('accountCity')} />
        </div>
        <div className="control control-select flex-1 flex-1-2">
          <label>Country</label>
          <select className="w-100" {...register('accountCountryCode')}>
            <CountrySelectOptions />
          </select>
        </div>
      </div>
      <div className="row m-b-8">
        <div className="control control-phone flex-1 flex-1-2">
          <label>Office phone number</label>
          <Controller
            control={control}
            name="accountPhone"
            render={(props) => (
              <CountryPhoneWithDialInput
                name={props.field.name}
                initialCode={''}
                initialPhone={account.accountPhone || ''}
                onChange={(values) => {
                  props.field.onChange(values.phone)
                }}
              />
            )}
          />
        </div>
        <div
          className="flex-1 flex-1-2"
          style={{ alignSelf: 'flex-end', marginBottom: -22 }}
        >
          <div className="text-right">
            <button
              className={varClass({
                'btn btn-primary btn-md': true,
                'o-0': !anyChanges,
              })}
              disabled={!anyChanges || submitting}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

const InvoiceItem = (props) => {
  const { invoice, sort } = props

  return (
    <tr>
      <td
        className={varClass({
          'name p-0': true,
          'sort-highlight': sort === 'invoiceCreatedDatetime',
        })}
      >
        <a
          href={invoice.invoicePdfUrl}
          target="_blank"
          rel="noreferrer"
          className="block link link-primary billing-invoice-link p-x-6 p-y-2"
        >
          <div className="row row-center row-space-between row-nowrap vertical-middle">
            <div className="text-dark text-bold">
              {format(
                parse(
                  invoice.invoiceCreatedDatetime + ' +01',
                  'yyyy-MM-dd HH:mm:ss X',
                  new Date()
                ),
                'MMMM dd yyyy'
              )}
            </div>
            <WithTooltip
              title="Download"
              className="text-nolineheight"
              text={<SVG src="/images/icon-download.svg" className="m-l-2" />}
              placement="top"
              forceShow
              tooltipId="billing-tooltip"
            />
          </div>
        </a>
      </td>
      <td
        className={varClass({
          'text-right': true,
          'sort-highlight': sort === 'invoiceTotalAmount',
        })}
      >
        {formatCurrencyReversed(
          invoice.invoiceTotalAmount,
          invoice.invoiceCurrencyCode.toUpperCase()
        )}
      </td>
      <td
        className={varClass({
          'text-right': true,
          'sort-highlight': sort === 'invoiceNumber',
        })}
      >
        #{invoice.invoiceNumber}
      </td>
      <td className="text-center">
        <StatusTag status={invoice.invoiceStatus} />
      </td>
      <td
        className={varClass({
          'text-dark text-bold': true,
          'sort-highlight': sort === '',
        })}
      >
        <div className="">{invoice.invoiceType}</div>
      </td>
    </tr>
  )
}

const PayoutItem = (props) => {
  const { payout, sort } = props

  return (
    <tr>
      <td
        className={varClass({
          'name p-0': true,
          'sort-highlight': sort === 'payoutDate',
        })}
      >
        <div className="text-dark text-bold p-x-6 p-y-2">
          {format(
            parse(
              payout.payoutDate + ' 12:00:00 +01',
              'yyyy-MM-dd HH:mm:ss X',
              new Date()
            ),
            'MMMM yyyy'
          )}{' '}
        </div>
      </td>
      <td
        className={varClass({
          'text-right': true,
          'sort-highlight': sort === 'payoutAmount',
        })}
      >
        {formatCurrencyReversed(
          payout.payoutAmount,
          payout.payoutCurrencyCode.toUpperCase()
        )}
      </td>
    </tr>
  )
}

const BillingPlanName = (props) => {
  const { customer, subscription } = props

  const name = useMemo(
    () =>
      subscription?.subscriptionPlanName ||
      getCurrentUser()?.user?.userAccountPlan ||
      '',
    [customer]
  )

  if (customer?.customerIsCustom) {
    return (
      <div className={`billing-plan-name billing-plan-name-custom`}>
        <SVG className="m-r-2" src={`/images/settings/icon-medal-custom.svg`} />
        Custom plan
      </div>
    )
  }

  switch (name?.toLowerCase()) {
    case 'starter':
    case 'growth':
    case 'premium':
      return (
        <div
          className={`billing-plan-name billing-plan-name-${name.toLowerCase()}`}
        >
          <SVG
            className="m-r-2"
            src={`/images/settings/icon-medal-${name.toLowerCase()}.svg`}
          />
          {capitalize(name)} plan
        </div>
      )
    default:
      return (
        <div className="billing-plan-name">
          <SVG
            className="m-r-2"
            src="/images/settings/icon-medal-gradient.svg"
          />
          {capitalize(name || 'Free')} plan
        </div>
      )
  }
}

const BillingPlanPrice = (props) => {
  const { subscription } = props

  switch (subscription?.subscriptionPlanName?.toLowerCase()) {
    case 'starter':
    case 'growth':
    case 'premium':
      return (
        <div className="billing-plan">
          <div className="billing-plan-currency">
            {currencyIcon(subscription.subscriptionCurrency)}
          </div>
          <div className="billing-plan-amount">
            {subscription.subscriptionPrice}
          </div>
          <div className="billing-plan-interval">
            per {subscription.subscriptionInterval}
          </div>
        </div>
      )
    default:
      return <div className="billing-plan blank" />
  }
}

const StatusTag = (props) => {
  const { status } = props

  return (
    <>
      {status === 'paid' ? (
        <div className="billing-invoice-status text-green">
          <SVG src="/images/icon-check.svg" />
          <span>{capitalize(status)}</span>
        </div>
      ) : (
        <div className="billing-invoice-status text-red">
          <SVG src="/images/icon-times.svg" />
          <span>{capitalize(status)}</span>
        </div>
      )}
    </>
  )
}

const UpgradeModal = (props) => {
  const { showSnackbar } = props

  const [submitting, setSubmitting] = useState(false)

  const checkout = (plan) => async () => {
    setSubmitting(true)
    const response = await postRequest('/billing/v1/checkout', {
      plan,
    })

    if (response.code === 200) {
      navigateTo(response.data.checkoutSessionUrl)
    } else {
      showSnackbar(responseError(response))
      setSubmitting(false)
    }
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Upgrade your plan</div>
      </div>
      <div className="card-body card-body-fill">
        <div className="row row-space-between row-narrow row-nowrap upgrade-options p-x-10 p-y-20">
          {/*
          <div className="flex-1 flex-1-4 card p-x-5 p-y-6">
            <div>
              <div className="name text-gradient">Free</div>
              <p>Create your first network and monitor everything with ease</p>
              <div className="label text-gradient">No credit card needed</div>
            </div>
            <div>
              <div
                className={varClass({
                  'action action-gradient': true,
                  'o-100': !submitting,
                  'o-60': submitting,
                })}
              >
                Free forever
              </div>
            </div>
          </div>
          */}
          <div className="flex-1 flex-1-3 card p-x-5 p-y-6">
            <div>
              <div className="name text-new-blue">Starter</div>
              <p>Expand your network with unlimited slots & integration</p>
            </div>
            <div>
              <div className="price text-dark">
                <span>$49</span> Per month
              </div>
              <button
                className={varClass({
                  'action action-blue cursor-pointer': true,
                  'o-60': submitting,
                })}
                onClick={checkout('starter')}
                disabled={submitting}
              >
                Get started
              </button>
            </div>
          </div>
          <div className="flex-1 flex-1-3 card p-x-5 p-y-6">
            <div>
              <div className="name text-new-orange">Growth</div>
              <p>Create lasting customer loyalty with advanced automations</p>
            </div>
            <div>
              <div className="price text-dark">
                <span>$149</span> Per month
              </div>
              <button
                className={varClass({
                  'action action-orange cursor-pointer': true,
                  'o-60': submitting,
                })}
                onClick={checkout('growth')}
                disabled={submitting}
              >
                Get started
              </button>
            </div>
          </div>
          {/*
          <div className="flex-1 flex-1-3 card p-x-5 p-y-6">
            <div>
              <div className="name text-new-orange">Premium</div>
              <p>Create lasting customer loyalty with advanced automations</p>
            </div>
            <div>
              <div className="price text-dark">
                <span>$299</span> Per month
              </div>
              <button
                className={varClass({
                  'action action-orange cursor-pointer': true,
                  'o-60': submitting,
                })}
                onClick={checkout('premium')}
                disabled={submitting}
              >
                Get started
              </button>
            </div>
          </div>
          */}
          <div className="flex-1 flex-1-3 card p-x-5 p-y-6">
            <div>
              <div className="name text-dark">Custom</div>
              <p>
                Made to suit your exact needs tailored interface and features
              </p>
            </div>
            <div>
              <a
                href="https://heylink.com/pricing#hsform"
                className={varClass({
                  'action action-dark': true,
                  'o-60': submitting,
                })}
              >
                Get in touch
              </a>
            </div>
          </div>
        </div>

        <div className="upgrade-options text-center p-y-40">
          <a
            href="http://heylink.com/pricing"
            className="btn btn-dark p-x-30 p-y-4 text-bold inline-block"
            target="_blank"
          >
            See all features
          </a>
        </div>

        {/*
          <div className="feature-table m-b-40">
            <div className="feature-row">
              <div className="feature-cell">
                <div className="text-dark text-large text-boldest">Features</div>
              </div>
              <div className="feature-cell">
                <span className="text-gradient text-bigger text-bolder">
                  Free
                </span>
              </div>
              <div className="feature-cell">
                <span className="text-new-blue text-bigger text-bolder">
                  Growth
                </span>
              </div>
              <div className="feature-cell">
                <span className="text-new-orange text-bigger text-bolder">
                  Premium
                </span>
              </div>
              <div className="feature-cell">
                <span className="text-dark text-bigger text-bolder">
                  Enterprise
                </span>
              </div>
            </div>
            <div className="feature-row">
              <div className="feature-cell">
                <div className="o-90 text-smaller">Scripts installed</div>
              </div>
              <div className="feature-cell text-center">
                <SVG src="/images/settings/billing-check-gradient.svg" />
              </div>
              <div className="feature-cell text-center">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-blue"
                />
              </div>
              <div className="feature-cell text-center">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-orange"
                />
              </div>
              <div className="feature-cell text-center">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-dark"
                />
              </div>
            </div>
            <div className="feature-row">
              <div className="feature-cell">
                <div className="o-90 text-smaller">Connect to network</div>
              </div>
              <div className="feature-cell">
                <SVG src="/images/settings/billing-check-gradient.svg" />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-blue"
                />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-orange"
                />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-dark"
                />
              </div>
            </div>
            <div className="feature-row">
              <div className="feature-cell">
                <div className="o-90 text-smaller">Live monetisation</div>
              </div>
              <div className="feature-cell">
                <SVG src="/images/settings/billing-check-gradient.svg" />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-blue"
                />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-orange"
                />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-dark"
                />
              </div>
            </div>
            <div className="feature-row">
              <div className="feature-cell">
                <div className="o-90 text-smaller">Discover</div>
              </div>
              <div className="feature-cell">
                <SVG src="/images/settings/billing-check-gradient.svg" />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-blue"
                />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-orange"
                />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-dark"
                />
              </div>
            </div>
            <div className="feature-row">
              <div className="feature-cell">
                <div className="o-90 text-smaller">Unlimited users</div>
              </div>
              <div className="feature-cell">
                <SVG src="/images/settings/billing-check-gradient.svg" />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-blue"
                />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-orange"
                />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-dark"
                />
              </div>
            </div>
            <div className="feature-row">
              <div className="feature-cell">
                <div className="o-90 text-smaller">Unlimited clicks</div>
              </div>
              <div className="feature-cell">
                <SVG src="/images/settings/billing-check-gradient.svg" />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-blue"
                />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-orange"
                />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-dark"
                />
              </div>
            </div>
            <div className="feature-row">
              <div className="feature-cell">
                <div className="o-90 text-smaller">
                  Unlimited integration with networks
                </div>
              </div>
              <div className="feature-cell"></div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-blue"
                />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-orange"
                />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-dark"
                />
              </div>
            </div>
            <div className="feature-row">
              <div className="feature-cell">
                <div className="o-90 text-smaller">Secret cheeseburger sause</div>
              </div>
              <div className="feature-cell"></div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-blue"
                />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-new-orange"
                />
              </div>
              <div className="feature-cell">
                <SVG
                  src="/images/settings/billing-check.svg"
                  className="text-dark"
                />
              </div>
            </div>
          </div>
        */}
      </div>
    </>
  )
}

const ConfirmModal = (props) => {
  const { hideModal } = useModal()

  return (
    <>
      <div className="card-header">
        <div className="card-title">Payment setup successful</div>
      </div>
      <div className="card-body">
        <p className="m-t-0 m-b-8">
          Your payment has been setup successfully. An invoice has been
          generated and send to your billing email.
        </p>
        <div className="text-right">
          <button className="btn btn-dark" onClick={hideModal}>
            Done
          </button>
        </div>
      </div>
    </>
  )
}

enum PlanButtonTypes {
  annual,
  monthly,
}

type PlanButtonType = PlanButtonTypes.annual | PlanButtonTypes.monthly

const PlanOverView = (props) => {
  const { setShowPlanOverview, currentPlan, setCurrentPlan } = props
  const [currentBtnType, setCurrentBtnType] = useState(PlanButtonTypes.annual)
  const listPlans: BillingPlanType[] = useMemo(
    () => [
      {
        icon: '/images/settings/freemium-icon.svg',
        name: 'Freemium',
        pricePrefix: '',
        price: 0,
        priceDesc: 'forever free',
        level: 0,
        featureLabel: 'For growing experts',
        featureIcon: '/images/settings/billing-check-gray.svg',
        featureList: [
          'Tracking with Script',
          'Smart links',
          '5 sites',
          '2 networks',
          'Auto network property import',
        ],
        buttonLabel: 'Downgrade to Freemium',
      },
      {
        icon: '/images/settings/starter-icon.svg',
        name: 'Starter',
        pricePrefix: '',
        price: 49,
        priceDesc: 'per month/ billed annualy',
        level: 1,
        featureLabel: 'For growing teams',
        featureIcon: '/images/settings/billing-check-blue.svg',
        featureList: [
          'Everything in Free +',
          'Tracking with WP plugin',
          'Auto Tagging for affiliate links',
          'Recommendations',
          'Broken link scanner (500)',
          'Manual network property import',
          'Unlimited amount of sites and networks',
          'Custom Networks',
          'Real time data for your conversions',
        ],
        buttonLabel: 'Your current plan',
      },
      {
        icon: '/images/settings/growth-icon.svg',
        name: 'Growth',
        pricePrefix: '',
        price: 149,
        priceDesc: 'per month/ billed annualy',
        level: 2,
        featureLabel: 'For scaling businesses ',
        featureIcon: '/images/settings/billing-check-gray.svg',
        featureList: [
          'Everything in Star',
          'Auto-linking',
          'Chrome extension',
          'Broken link scanner (1500)',
        ],
        buttonLabel: 'Upgrade to Growth',
      },
      {
        icon: '/images/settings/customx-icon.svg',
        name: 'Custom X',
        pricePrefix: 'From',
        price: 749,
        priceDesc: 'per month/ billed annualy',
        level: 3,
        featureLabel: 'For large-scale organizations',
        featureIcon: '/images/settings/billing-check-black.svg',
        featureList: [
          'Everything in Growth +',
          'Tracking and reporting via API',
          'Direct Partnerships',
          'White label solution',
          'Sub Affiliates',
          'Affiliate consultancy',
        ],
        buttonLabel: 'Talk to Sales',
      },
    ],
    []
  )
  const planButtons = useMemo(
    () => [
      {
        type: PlanButtonTypes.annual,
        label: 'Annual',
        subLabel: '15%',
      },
      {
        type: PlanButtonTypes.monthly,
        label: 'Monthly',
        subLabel: '',
      },
    ],
    []
  )

  const { showModal, hideModal } = useModal()

  const handleSelectPlanButton = (type: PlanButtonType) => {
    setCurrentBtnType(type)
  }

  const renderPlanButtons = () => {
    return planButtons.map((btn) => {
      return (
        <>
          <div
            key={btn.type}
            className={`btn-plan-toggle row gap-10 p-x-3 p-y-1 ${
              currentBtnType === btn.type ? 'active' : ''
            }`}
            onClick={() => handleSelectPlanButton(btn.type)}
          >
            <span className="label">{btn.label}</span>
            {btn.subLabel && <span className="sub-label">{btn.subLabel}</span>}
          </div>
        </>
      )
    })
  }

  const handleOpenSuccessModal = () => {
    showModal(<SuccessModal />, { cardClassName: 'feedback-modal-container' })
  }

  const handleOpenFeedbackModal = () => {
    showModal(
      <FeedbackModal
        openSuccessModal={handleOpenSuccessModal}
        hideModal={hideModal}
      />,
      { cardClassName: 'feedback-modal-container' }
    )
  }

  const handleOpenBillingFeatureModal = () => {
    showModal(<FreemiumModal openFeedbackModal={handleOpenFeedbackModal} />)
  }

  const handleClickPlanButton = (level: number) => {
    if (level < currentPlan.planLevel) {
      showModal(
        <BillingDetailModal
          openBillingFeatureModal={handleOpenBillingFeatureModal}
        />,
        { cardClassName: 'billing-modal-container' }
      )
    }
  }

  const renderPlans = () => {
    return listPlans.map((plan, index) => (
      <BillingPlan
        key={index}
        {...plan}
        onClickButton={() => handleClickPlanButton(plan.level)}
        planLevel={currentPlan.planLevel}
      />
    ))
  }

  return (
    <>
      <div className="plan-overview p-x-40 p-y-50 column gap-50 column-middle">
        <SVG
          className="close-icon"
          src="/images/settings/icon_close.svg"
          onClick={() => setShowPlanOverview(false)}
        />
        <div className="row row-fill row-middle">
          <div className="row row-fill">{renderPlanButtons()}</div>
        </div>
        <div className="payment-plans">{renderPlans()}</div>
        <div className="plan-overview-footer">
          <div>More details and all features</div>
          <span onClick={() => setShowPlanOverview(false)}>
            View pricing page
          </span>
        </div>
      </div>
    </>
  )
}

type BillingPlanType = {
  icon: string
  name: string
  buttonLabel: string
  pricePrefix: string
  price: number
  priceDesc: string
  level: number
  featureLabel: string
  featureIcon: string
  featureList: string[]
}

type BillingPlanProps = {
  onClickButton: () => void
  planLevel: number
}

const BillingPlan = (props: BillingPlanType & BillingPlanProps) => {
  const {
    icon,
    name,
    buttonLabel,
    pricePrefix,
    price,
    level,
    featureLabel,
    featureIcon,
    featureList,
    priceDesc,
    onClickButton,
    planLevel,
  } = props

  const [btnLabel, setBtnLabel] = useState('')
  useEffect(() => {
    if (planLevel === level) {
      setBtnLabel('Your current plan')
    } else {
      if (level === 3) {
        setBtnLabel('Talk to Sales')
      } else {
        if (level > planLevel) {
          setBtnLabel('Downgrade to Freemium')
        } else {
          setBtnLabel('Upgrade to Growth')
        }
      }
    }
  }, [planLevel])

  return (
    <div className="billing-plan">
      <SVG src={icon} />
      <h2 className="billing-name">{name}</h2>
      <div className="row gap-1 row-bottom text-black text-bolder">
        {pricePrefix && <div className="text-big">{pricePrefix}</div>}
        <div className="text-larger">${price}</div>
      </div>
      <p className="billing-desc m-t-1">{priceDesc}</p>
      <button
        className={varClass({
          'billing-button': true,
          'disabled background-light': level === planLevel,
        })}
        onClick={onClickButton}
      >
        {btnLabel}
      </button>
      <p className="feature-label">{featureLabel}</p>
      <div>
        {featureList.map((label, index) => (
          <div key={index} className="feature-item">
            <SVG src={featureIcon} />
            <p>{label}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

const BillingDetailModal = ({ openBillingFeatureModal }) => {
  return (
    <div className="billing-modal-content">
      <div className="modal-left-content">
        <p className="modal-left-title">
          Here's what you should know before you cancel
        </p>
        <div className="modal-alert">
          <SVG src="/images/settings/icon-alert.svg" />
          <p>
            Switching from Starter to Freemium means losing premium features,
            and some data and settings may need adjustment to match the new plan
          </p>
        </div>
        <p className="before-description">
          Before you downgrade, please follow the steps to make changes:
        </p>

        <div className="action-required">Action required</div>
        <BillingFeatureItem
          icon="/images/settings/icon-minus-circle.svg"
          title="Tracking with WP plugin"
          content="Delete Sites from My sites"
        />
        <BillingFeatureItem
          icon="/images/settings/icon-minus-circle.svg"
          title="Auto Tagging for affiliate links"
          content="Change site mode to “Auto tagging”"
        />
        <BillingFeatureItem
          icon="/images/settings/icon-minus-circle.svg"
          title="Unlimited sits"
          content="Delete any extra websites on My sites page."
        />
        <BillingFeatureItem
          icon="/images/settings/icon-minus-circle.svg"
          title="Unlimited networks"
          content="Delete extra networks on Networks page."
        />
        <p className="before-description">
          These features will no longer be available in your plan:
        </p>

        <div className="premium-feature">Premium feature</div>
        <BillingFeatureItem
          icon="/images/settings/icon-multiply.svg"
          title="Broken link scanner (500 links)"
          content="You're lowering the link limit from 500 to just 3 for the broken link scanner."
        />
        <BillingFeatureItem
          icon="/images/settings/icon-multiply.svg"
          title="Conversions Real time data "
          content="You'll receive updates every 24 hours, and no more frequently than that."
        />
        <BillingFeatureItem
          icon="/images/settings/icon-multiply.svg"
          title="Recommendations"
          content="With the new plan, you'll have fewer recommendations available."
        />
        <BillingFeatureItem
          icon="/images/settings/icon-multiply.svg"
          title="Tracking with WP plugin"
          content="With the new plan, you'll have fewer recommendations available."
        />
      </div>
      <div className="modal-right-content">
        <SVG src="/images/settings/growth-icon.svg" />
        <h2 className="right-content-title">Freemium</h2>
        <span className="downgrade-plan">Downgrade plan</span>
        <p className="description">
          Your new plan has some limits that could impact your growth potential.
        </p>
        <div className="right-content-item">
          <p>Broken link scanner</p>
          <div className="item-description">
            <SVG src="/images/settings/icon-billing-pageview.svg" />
            <span className="cross-text">500 links</span>
            <SVG src="/images/settings/arrow-right.svg" />
            <span className="value">3 links</span>
          </div>
        </div>
        <div className="right-content-item">
          <p>Commission</p>
          <div className="item-description">
            <SVG src="/images/settings/icon-billing-dollar.svg" />
            <span className="cross-text">25.000</span>
            <SVG src="/images/settings/arrow-right.svg" />
            <span className="value">10.000</span>
          </div>
        </div>
        <div className="right-content-item">
          <p>Page views</p>
          <div className="item-description">
            <SVG src="/images/settings/icon-eye.svg" />
            <span>100.000</span>
            <SVG src="/images/settings/arrow-right.svg" />
            <span className="value">100.000</span>
          </div>
        </div>
        <div className="right-content-item">
          <p>Networks</p>
          <div className="item-description">
            <SVG src="/images/settings/icon-eye.svg" />
            <span className="cross-text">Unlimited</span>
            <SVG src="/images/settings/arrow-right.svg" />
            <span>2 networks</span>
          </div>
        </div>
        <div className="right-content-item">
          <p>Websites</p>
          <div className="item-description">
            <SVG src="/images/settings/icon-billing-pageview.svg" />
            <span className="cross-text">Unlimited</span>
            <SVG src="/images/settings/arrow-right.svg" />
            <span>5 websites</span>
          </div>
        </div>
        <div className="right-content-item">
          <p>Records</p>
          <div className="item-description">
            <SVG src="/images/settings/icon-billing-click.svg" />
            <span>25.000</span>
            <SVG src="/images/settings/arrow-right.svg" />
            <span className="value">10.000</span>
          </div>
        </div>
        <div className="right-content-item">
          <p>Users</p>
          <div className="item-description">
            <SVG src="/images/settings/icon-billing-user.svg" />
            <span className="cross-text">3</span>
            <SVG src="/images/settings/arrow-right.svg" />
            <span className="value">1</span>
          </div>
        </div>

        <div className="button-group">
          <button
            className="billing-button full-width"
            onClick={openBillingFeatureModal}
          >
            Downgrade plan to Freemium
          </button>
          <button className="billing-button full-width cancel">Cancel</button>
        </div>
      </div>
    </div>
  )
}

const BillingFeatureItem = (props) => {
  const { icon, title, content } = props

  return (
    <div className="billing-feature-item">
      <SVG src={icon} />
      <div>
        <div className="billing-feature-header">
          {title}
          <SVG src="/images/settings/icon-info.svg" />
        </div>
        <p className="billing-description">{content}</p>
      </div>
    </div>
  )
}

const FreemiumModal = ({ openFeedbackModal }) => {
  return (
    <div className="freemium-modal">
      <SVG src="/images/settings/icon-double-error-info.svg" />
      <p className="freemium-description">
        Before you can downgrade, there are a few steps you need to follow.
      </p>
      <div className="freemium-warning-item">
        <div className="freemium-warning-header">
          <SVG src="/images/settings/icon-minus-circle.svg" />
          <p>Auto Tagging for affiliate links</p>
          <SVG src="/images/settings/icon-info.svg" />
        </div>
        <div className="freemium-warning-body">
          <SVG src="/images/settings/icon-alert.svg" />
          <p>
            Change site mode to "<b>Auto tagging</b>".
          </p>
        </div>
      </div>
      <div className="freemium-warning-item">
        <div className="freemium-warning-header">
          <SVG src="/images/settings/icon-minus-circle.svg" />
          <p>Unlimited sits</p>
          <SVG src="/images/settings/icon-info.svg" />
        </div>
        <div className="freemium-warning-body">
          <SVG src="/images/settings/icon-alert.svg" />
          <p>
            Delete any <b>extra websites</b> on My sites page.
          </p>
        </div>
      </div>
      <div className="freemium-warning-item">
        <div className="freemium-warning-header">
          <SVG src="/images/settings/icon-minus-circle.svg" />
          <p>Unlimited networks</p>
          <SVG src="/images/settings/icon-info.svg" />
        </div>
        <div className="freemium-warning-body">
          <SVG src="/images/settings/icon-alert.svg" />
          <p>
            Delete any <b>extra networks</b> on My sites page.
          </p>
        </div>
      </div>

      <div className="freemium-modal-footer">
        <button className="billing-button" onClick={openFeedbackModal}>
          Got it
        </button>
      </div>
    </div>
  )
}

const FeedbackModal = ({ openSuccessModal, hideModal }) => {
  const [easeUseRate, setEaseUseRate] = useState<number>(0)
  const [loadTimeRate, setLoadTimeRate] = useState<number>(0)
  const [featureRate, setFeatureRate] = useState<number>(0)

  return (
    <div className="feedback-modal">
      <SVG src="/images/settings/icon-double-blue-info.svg" />
      <h5 className="feedback-modal-title">One thing before you go...</h5>
      <p>
        Your feedback is incredibly valuable to us and can help us improve for
        the future.
      </p>
      <div>
        <p className="description">
          What's your overall impression of the product?
        </p>
        <div className="rate-controller-wrapper">
          <p>Ease of use</p>
          <RateController value={easeUseRate} onChange={setEaseUseRate} />
        </div>
        <div className="rate-controller-wrapper">
          <p>Load time</p>
          <RateController value={loadTimeRate} onChange={setLoadTimeRate} />
        </div>
        <div className="rate-controller-wrapper">
          <p>Feature specifications</p>
          <RateController value={featureRate} onChange={setFeatureRate} />
        </div>
      </div>

      <textarea placeholder="Please share why you're canceling your subscription." />

      <div className="feedback-modal-action">
        <button className="billing-button cancel" onClick={hideModal}>
          Cancel
        </button>
        <button className="billing-button" onClick={openSuccessModal}>
          Send
        </button>
      </div>
    </div>
  )
}

type RateControllerProps = {
  value: number
  onChange: (value: number) => void
}

const RateController = (props: RateControllerProps) => {
  const { value, onChange } = props

  return (
    <div className="rate-controller">
      {new Array(5).fill(1).map((_, index) => (
        <span key={index} onClick={() => onChange(index + 1)}>
          <SVG
            src={`/images/settings/${
              index < value ? 'award-active' : 'award-disable'
            }.svg`}
            className="cursor-pointer"
          />
        </span>
      ))}
    </div>
  )
}

const SuccessModal = () => {
  return (
    <div className="feedback-modal">
      <SVG src="/images/settings/icon-double-blue-info.svg" />
      <h5 className="feedback-modal-title">
        You have successfully switched from
        <p>Starter to Free plan!</p>
      </h5>
      <p>
        Discover the features and usage details of your new plan by visiting the
        subscription page.
      </p>
    </div>
  )
}

// const CancelModal = (props) => {
//   return (
//     <>
//       <div className="card-header">
//         <div className="card-title">Cancel subscription</div>
//       </div>
//       <div className="card-body">
//         <p className="m-t-0 m-b-6">
//           Are you sure? By cancelling your subscription all your data will be
//           lost.
//         </p>
//         <div className="notice-info m-b-8">
//           <SVG src="/images/notice-info.svg" />
//           Your subscription will expire after current month plus one month.
//         </div>
//         <div className="text-right">
//           <button className="btn btn-dark">Cancel subscription</button>
//         </div>
//       </div>
//     </>
//   )
// }

// const BillingSettings = (props) => {
//   const { data } = props
//
//   return (
//     <div className="billing-methods">
//       <div className="row m-b-5">
//         {map(data.sources, (source, index) => (
//           <BillingCard
//             key={source.sourceLastFour}
//             item={{
//               isDefault: index === 0,
//               digits: source.sourceLastFour,
//               country: source.sourceCountry,
//               expireMonth: source.sourceExpMonth,
//               expireYear: source.sourceExpYear,
//               issuer: source.sourceBrand,
//             }}
//           />
//         ))}
//         {isBlank(data.sources) && (
//           <div className="text-light text-bold text-center flex-1 p-5">
//             No cards available
//           </div>
//         )}
//       </div>
//     </div>
//   )
// }
//
// const BillingCard = (props) => {
//   const { item } = props
//   const { isDefault, digits, country, expireMonth, expireYear, issuer } = item
//
//   const [menuOpen, setMenuOpen] = useState(false)
//   const menuRef = useRef()
//   useOutsideClick(menuRef, (e) => {
//     setMenuOpen(false)
//   })
//
//   return (
//     <div
//       className={varClass({
//         'card billing-card': true,
//         'billing-card-secondary': !isDefault,
//       })}
//       style={{ width: 400 }}
//     >
//       <div className="row row-top row-fill">
//         <div className="billing-card-check" />
//         <div>
//           {isDefault ? (
//             <>
//               <div className="text-dark m-b-1">Default Card</div>
//               <div className="text-smaller">
//                 Next payment: {format(new Date(), 'MMM dd, yyyy')}
//               </div>
//             </>
//           ) : (
//             <>
//               <div className="m-b-1">Set as default</div>
//               <div className="text-smaller">&nbsp;</div>
//             </>
//           )}
//         </div>
//       </div>
//
//       <div className="billing-card-number m-t-40 m-b-30">
//         <div className="hidden-digits">****</div>
//         <div className="hidden-digits">****</div>
//         <div className="hidden-digits">****</div>
//         <div className="last-digits">{digits}</div>
//       </div>
//
//       <div className="row">
//         <div className="m-r-3">
//           <div className="text-light text-smaller m-b-1">Country</div>
//           <div className="text-dark">{country}</div>
//         </div>
//         <div>
//           <div className="text-light text-smaller m-b-1">Expire</div>
//           <div className="text-dark">
//             {rjust(expireMonth, 2, '0')}/{rjust(expireYear, 4, '0')}
//           </div>
//         </div>
//       </div>
//
//       <div className="billing-card-issuer">
//         {issuer === 'mastercard' && <SVG src="/images/card-mastercard.svg" />}
//         {issuer === 'visa' && <SVG src="/images/card-visa.svg" />}
//       </div>
//
//       <div
//         ref={menuRef}
//         className={varClass({
//           'hidden': true,
//           'menu': true,
//           'open': menuOpen,
//         })}
//       >
//         <button
//           onClick={() => {
//             setMenuOpen(!menuOpen)
//           }}
//         >
//           <SVG src="/images/icon-menu.svg" />
//         </button>
//         <ul>
//           {!isDefault && (
//             <li>
//               <button
//                 onClick={() => {
//                   setMenuOpen(false)
//                   console.log('TODO: set as default')
//                 }}
//               >
//                 <SVG src="/images/settings/icon-default.svg" />{' '}
//                 <span>Set as default</span>
//               </button>
//             </li>
//           )}
//           <li>
//             <button
//               onClick={() => {
//                 setMenuOpen(false)
//                 console.log('TODO: edit')
//               }}
//             >
//               <SVG src="/images/settings/icon-edit.svg" /> <span>Edit</span>
//             </button>
//           </li>
//           <li className="divider" />
//           <li>
//             <button
//               onClick={() => {
//                 if (window.confirm('Are you sure?')) {
//                   setMenuOpen(false)
//                   console.log('TODO: delete')
//                 }
//               }}
//             >
//               <SVG src="/images/settings/icon-remove.svg" /> <span>Delete</span>
//             </button>
//           </li>
//         </ul>
//       </div>
//     </div>
//   )
// }
