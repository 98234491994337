import React from 'react'
import { Load } from '../../shared'

export const Placeholder = (props) => {
  return (
    <div className="card" style={{ height: 500 }}>
      <Load width={208} height={36} />
      <div className="card-body card-body-fill" style={{ paddingTop: 40 }}>
        <Load height={42} />
      </div>
    </div>
  )
}
