import React from 'react'
import { Load } from '../../shared'

export const Placeholder = (props) => {
  return (
    <>
      <div className="card m-b-20" style={{ height: 183 }}>
        <div className="m-t-15">
          <Load width={100} height={24} />
        </div>
      </div>
    </>
  )
}
