import React, { useState, useRef } from 'react'
import SVG from 'react-inlinesvg'
import { Placeholder } from './Placeholder'
import {
  Header,
  Tooltip,
  WithTooltip,
  ValueIndicator,
  Loadable,
  useApiGet,
  useOutsideClick,
  map,
  newTableSort,
  varClass,
  isBlank,
  plural,
  navigateTo,
} from '../../shared'
import { getCurrentCurrency } from '../../../services'

const recommendationsSort = newTableSort((a: any, b: any, key: string) => {
  switch (key) {
    case 'score':
      return [a.data?.score || 0, b.data?.score || 0]
    case 'clicks':
      return [a.data?.clickCount || 0, b.data?.clickCount || 0]
    case 'count':
      return [a.data?.count || 0, b.data?.count || 0]
    default:
      return [a.data?.score || 0, b.data?.score || 0]
  }
})

const sortOptions = {
  'score': 'Pr. value',
  'clicks': 'Clicks',
  'count': 'Amount',
}

export const Recommendations = (props) => {
  const currencyCode = getCurrentCurrency()

  const channels = useApiGet('/channels/v1/list')

  const data = useApiGet('/discovers/v1/recommendations/overview', {
    currencyCode,
  })

  const contentProps = {
    data,
    currencyCode,
  }

  return (
    <>
      <Header label="Recommendations" />
      {channels && isBlank(channels) ? (
        <div className="column column-center column-fill m-t-80">
          <SVG src="/images/recommendations/blank-channels.svg" />
          <div className="text-larger text-bolder text-dark m-t-40">
            Start sending clicks through Heylink!
          </div>
          <div className="text-light text-center m-t-8">
            Before we can suggest you recommendations, we will first need to get
            some data in here!
          </div>
          <button
            className="btn btn-primary m-t-20"
            onClick={(event) => {
              navigateTo(event, '/sites')
            }}
          >
            Manage your sites
          </button>
        </div>
      ) : (
        <Loadable data={data && channels} placeholder={<Placeholder />}>
          <Content {...contentProps} />
        </Loadable>
      )}
    </>
  )
}

const Content = (props) => {
  const { data, currencyCode } = props

  return (
    <div className="recommendations">
      <RecommendationsTotal data={data} />
      <RecommendationsDetails data={data} currencyCode={currencyCode} />
    </div>
  )
}

const RecommendationsTotal = (props) => {
  const { data } = props

  const total =
    (data.newCampaigns?.count || 0) +
    (data.alternativeCampaigns?.count || 0) +
    (data.newNetworks?.count || 0) +
    (data.brokenLinks?.count || 0)

  const totalClicks =
    (data.newCampaigns?.clickCount || 0) +
    (data.alternativeCampaigns?.clickCount || 0) +
    (data.brokenLinks?.clickCount || 0)

  const score = Math.max(
    data.newCampaigns?.score || 0,
    data.alternativeCampaigns?.score || 0,
    data.newNetworks?.score || 0,
    data.brokenLinks?.score || 0
  )

  return (
    <div className="card recommendations-total-card m-b-6">
      <div className="row row-center row-space-between">
        <div className="recommendations-total">
          <div className="text-dark text-bold">Total recommendations</div>
          <div className="text-green value">{total}</div>
          {totalClicks > 0 && (
            <div>
              Based on{' '}
              <span className="text-primary text-bolder">{totalClicks}</span>{' '}
              {plural(totalClicks, 'click')}
            </div>
          )}
        </div>

        {total > 0 && (
          <div className="row row-center row-space-between recommendations-explanation">
            <div className="text-nowrap">
              <span className="text-dark text-bold m-r-30">
                Projected value score
              </span>
              <ValueIndicator
                score={score}
                tooltipId="insights-recommendations-tooltip"
              />
            </div>
            <div className="text-new-light m-l-50">
              Explain how project value score is calculated and how it will help
              user to pick the right suggestion.
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const RecommendationsDetails = (props) => {
  const { data, currencyCode } = props

  const [filter, setFilter] = useState('all')
  const filters = [
    {
      value: 'all',
      label: 'All types',
      count:
        (data.newCampaigns?.count || 0) +
        (data.alternativeCampaigns?.count || 0) +
        (data.newNetworks?.count || 0) +
        (data.brokenLinks?.count || 0),
    },
    {
      value: 'campaign',
      label: 'Campaigns',
      count:
        (data.newCampaigns?.count || 0) +
        (data.alternativeCampaigns?.count || 0),
    },
    {
      value: 'network',
      label: 'Networks',
      count: data.newNetworks?.count || 0,
    },
    {
      value: 'broken',
      label: 'Broken links',
      count: data.brokenLinks?.count || 0,
    },
  ]

  const [sort, setSort] = useState('score')

  const showCampaigns = filter === 'all' || filter === 'campaign'
  const showNetworks = filter === 'all' || filter === 'network'
  const showBroken = filter === 'all' || filter === 'broken'

  const shownData = recommendationsSort(
    [
      { type: 'newCampaigns', data: data.newCampaigns },
      { type: 'alternativeCampaigns', data: data.alternativeCampaigns },
      { type: 'newNetworks', data: data.newNetworks },
      { type: 'broken', data: data.brokenLinks },
    ].filter(
      (row) =>
        (row.type === 'newCampaigns' && showCampaigns) ||
        (row.type === 'alternativeCampaigns' && showCampaigns) ||
        (row.type === 'newNetworks' && showNetworks) ||
        (row.type === 'broken' && showBroken)
    ),
    sort,
    'desc'
  )

  const totalCount =
    (data.newCampaigns?.count || 0) +
    (data.alternativeCampaigns?.count || 0) +
    (data.brokenLinks?.count || 0) +
    (data.newNetworks?.count || 0)
  if (!totalCount) {
    return (
      <div className="column column-center column-fill text-center m-t-80">
        <SVG src="/images/illustration-hooray.svg" />
        <div className="text-larger text-bolder text-dark m-t-40">
          Great work maximising profits with all recommendations!
        </div>
        <div className="text-light m-t-8" style={{ maxWidth: '60%' }}>
          Currently, no new recommendations are available. But don't worry,
          we're searching for new opportunities to boost your traffic. Stay
          tuned for updates in the side menu.
        </div>
      </div>
    )
  }

  return (
    <div className="card p-t-10 p-x-5 p-b-6">
      <div className="row row-bottom row-space-between m-b-3">
        <div className="page-subnav">
          <div className="nav p-y-2 m-x--4 m-t-0 m-b-1">
            <ul>
              {map(filters, (item) =>
                item.value === 'all' || item.count ? (
                  <li
                    key={item.value}
                    className={filter === item.value ? 'active' : ''}
                  >
                    <button
                      className="link"
                      onClick={() => setFilter(item.value)}
                    >
                      {item.label}
                      {item.count && (
                        <div className="tab-count">
                          {Math.min(item.count, 99)}
                        </div>
                      )}
                    </button>
                  </li>
                ) : null
              )}
            </ul>
          </div>
        </div>

        <SortDropdown sort={sort} setSort={setSort} />
      </div>

      {map(shownData, (row) => (
        <Recommendation
          key={row.type}
          type={row.type}
          data={row.data}
          currencyCode={currencyCode}
        />
      ))}

      <Tooltip
        id="insights-recommendations-tooltip"
        type="dark"
        effect="solid"
        place="bottom"
        rebuild={[shownData]}
      />
    </div>
  )
}

const Recommendation = (props) => {
  const { type, data } = props

  if (!data.count) return null

  return (
    <div className="card card-light m-t-10">
      <div className="row row-center row-space-between row-nowrap">
        <div className="row row-center">
          {type === 'newNetworks' && (
            <>
              <SVG src="/images/recommendations/icon-networks.svg" />
              <div>
                <div className="text-dark text-bigger text-boldest">
                  {data.count} Networks
                  <WithTooltip
                    className="recommendation-info"
                    title="You are sending traffic to Merchants that exists on affiliate networks you are not connected to. Connect to these networks to earn more."
                    text={<SVG src="/images/recommendations/icon-info.svg" />}
                    tooltipId="insights-recommendations-tooltip"
                    forceShow
                  />
                </div>
                <div className="text-new-light m-t-2">
                  You have traffic to Merchants found on these networks.
                </div>
              </div>
            </>
          )}
          {type === 'newCampaigns' && (
            <>
              <SVG src="/images/recommendations/icon-campaigns.svg" />
              <div>
                <div className="text-dark text-bigger text-boldest">
                  {data.count} New campaigns
                  <WithTooltip
                    className="recommendation-info"
                    title="We’ve found affiliate campaigns for Merchants that you are sending non-monetised traffic to. Apply for these campaigns to earn more."
                    text={<SVG src="/images/recommendations/icon-info.svg" />}
                    tooltipId="insights-recommendations-tooltip"
                    forceShow
                  />
                </div>
                <div className="text-new-light m-t-2">
                  Apply for these campaigns to start earning revenue.
                </div>
              </div>
            </>
          )}
          {type === 'alternativeCampaigns' && (
            <>
              <SVG src="/images/recommendations/icon-alt-campaigns.svg" />
              <div>
                <div className="text-dark text-bigger text-boldest">
                  {data.count} Alternative campaigns
                  <WithTooltip
                    className="recommendation-info"
                    title="We’ve found campaigns with a higher commission than you have currently. Apply for these campaigns to earn more per sale than today."
                    text={<SVG src="/images/recommendations/icon-info.svg" />}
                    tooltipId="insights-recommendations-tooltip"
                    forceShow
                  />
                </div>
                <div className="text-new-light m-t-2">
                  We found campaigns with a higher commission.
                </div>
              </div>
            </>
          )}
          {type === 'broken' && (
            <>
              <SVG src="/images/recommendations/icon-broken-links.svg" />
              <div>
                <div className="text-dark text-bigger text-boldest">
                  {data.count} Broken links
                  <WithTooltip
                    className="recommendation-info"
                    title="You are sending traffic to links that are broken. Fix these links to earn more and keep your users happy."
                    text={<SVG src="/images/recommendations/icon-info.svg" />}
                    tooltipId="insights-recommendations-tooltip"
                    forceShow
                  />
                </div>
                <div className="text-new-light m-t-2">Fix your broken links to optimize your revenue.</div>
              </div>
            </>
          )}
        </div>

        <div className="row row-center">
          <div
            className="text-bold row row-center row-fill"
            style={{ minWidth: 80 }}
          >
            {type === 'newNetworks' && (
              <>
                <div className="recommendation-type-dot text-new-blue" />
                Network
              </>
            )}
            {type === 'newCampaigns' && (
              <>
                <div className="recommendation-type-dot text-new-grass" />
                Campaign
              </>
            )}
            {type === 'alternativeCampaigns' && (
              <>
                <div className="recommendation-type-dot text-new-grass" />
                Campaign
              </>
            )}
            {type === 'broken' && (
              <>
                <div className="recommendation-type-dot text-new-blood" />
                Broken {plural(data.count, 'link')}
              </>
            )}
          </div>
          <div className="text-smaller text-bold text-center m-l-40">
            <ValueIndicator
              score={data.score}
              tooltipId="insights-recommendations-tooltip"
            />
          </div>
          <div className="text-right m-l-80" style={{ minWidth: 140 }}>
            {type === 'newNetworks' && (
              <button
                className="link text-bold"
                onClick={(event) => {
                  navigateTo(event, '/networks')
                }}
              >
                {plural(data.count, 'Connect network')}
              </button>
            )}
            {type === 'newCampaigns' && (
              <button
                className="link text-bold"
                onClick={(event) => {
                  navigateTo(event, '/advs?recommendations=new')
                }}
              >
                {plural(data.count, 'Join campaign')}
              </button>
            )}
            {type === 'alternativeCampaigns' && (
              <button
                className="link text-bold"
                onClick={(event) => {
                  navigateTo(event, '/advs?recommendations=alternatives')
                }}
              >
                {plural(data.count, 'Join campaign')}
              </button>
            )}
            {type === 'broken' && (
              <button
                className="link text-bold"
                onClick={(event) => {
                  navigateTo(event, '/discover/broken')
                }}
              >
                {plural(data.count, 'Fix broken link')}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const SortDropdown = (props) => {
  const { sort, setSort } = props

  const [open, setOpen] = useState(false)

  const ref = useRef()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  return (
    <div
      className={varClass({
        'discover-broken-dropdown-container m-r-2': true,
        'open': open,
      })}
      ref={ref}
    >
      <button
        className="discover-broken-dropdown-btn hover-sort link p-x-2 p-y-1 m-r--2 m-b--1"
        onClick={() => {
          setOpen(!open)
        }}
      >
        <span className="text-light text-bold">Sort by : </span>
        <span className="text-blue text-bold">{sortOptions[sort]}</span>
      </button>

      <div className="discover-broken-dropdown item-dropdown">
        {map(sortOptions, (value, label) => (
          <div
            key={value}
            className={varClass({
              'item': true,
              'active': value === sort,
            })}
            onClick={() => {
              setSort(value)
              setOpen(false)
            }}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  )
}
