import React from 'react'
import { Load } from '../../shared'

export const Placeholder = (props) => {
  return (
    <>
      <div className="row m-b-5">
        <div className="card flex-1-2" style={{ height: 214 }}>
          <Load width={120} height={22} className="m-b-2" />
        </div>
        <div className="card flex-1-2" style={{ height: 214 }}>
          <Load width={160} height={22} className="m-b-2" />
        </div>
      </div>
      <div className="card" style={{ height: 'calc(100vh - 370px)' }}>
        <Load width={160} height={22} className="m-b-2" />
        <div className="card-body card-body-fill" style={{ paddingTop: 30 }}>
          <Load height={42} />
        </div>
      </div>
    </>
  )
}
