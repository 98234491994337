export const ignoreWorstNetworkUuids = [
  '2819e3d5-84bd-4db0-b55c-28b61af9ae33',
  '4b4b3f71-6cee-40d0-9210-3b43062d5b27',
  'd205cfb3-bd00-42dc-9606-fab308d62c2d',
  '9e83d754-5b6f-4c67-b459-07e792884d82',
  '85b0eff4-38e4-431e-9e1e-062cc32d7c7e',
  'a1fd6c4f-c2e0-46e5-a309-9c28ae513e7b',
]

export const ignoreHighLowNetworkUuids = [
  '2819e3d5-84bd-4db0-b55c-28b61af9ae33'
]
