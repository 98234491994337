import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import SVG from 'react-inlinesvg'
import {
  INetworksV1List,
  Search,
  Logo,
  Header,
  Loadable,
  SmallLoadable,
  ConnectNetworkModal,
  // EditConnectionModal,
  // RemoveConnectionModal,
  RemoveMappingModal,
  WithTooltip,
  useApiGet,
  useApiPost,
  useModal,
  useSnackbar,
  useOutsideClick,
  useCounter,
  map,
  toArray,
  varClass,
  isBlank,
  capitalize,
  copyToClipboard,
  navigateTo,
} from '../shared'
import { postRequest, putRequest, deleteRequest } from '../../services/api'
import { daisyconOauth } from '../../services'
import {
  UploadAmazonModal,
  UploadBolModal,
  UploadGoAffProModal,
  UploadRefersionModal,
  DeleteUploadModal,
} from './modals'

const featureLabels = {
  apiConversions: 'Conversions',
  apiPaidConversions: 'Payment status',
  apiPrograms: 'Programs',
  apiChannels: 'Sites',
  apiRejections: 'Rejections',
  apiCpc: 'CPC',
  callbacks: 'Real-time callbacks',
}

const uploadFormats = {
  'Amazon': 'CSV',
  'Bol.com': 'XLSX',
  'GoAffPro': 'CSV',
  'Refersion': 'CSV',
}

const connectionSort = (a, b) => {
  return (
    a.connectionChannelName.localeCompare(b.connectionChannelName) ||
    a.connectionChannelUuid.localeCompare(b.connectionChannelUuid)
  )
}

const mappingsSort = (a, b) => {
  if (a.channel.channelName < b.channel.channelName) {
    return -1
  } else if (a.channel.channelName > b.channel.channelName) {
    return 1
  } else {
    return 0
  }
}

export const Network = (props) => {
  const { networkUuid } = props

  const [reloadRef, reload] = useCounter()

  const channels = useApiGet('/channels/v1/list')
  const data = useApiGet(`/networks/v1/networks/${networkUuid}`, {}, [
    reloadRef,
  ])
  const networks = useApiGet<INetworksV1List[]>('/networks/v1/networks', {}, [
    reloadRef,
  ])
  const networkDetails = useMemo(() => {
    if (networks) {
      return networks.find((network) => network.networkUuid === networkUuid)
    } else {
      return undefined
    }
  }, [networks, networkUuid])

  if (data && isBlank(data)) {
    setTimeout(reload, 2000)
    return null
  }

  return (
    <>
      <Header backPath="/networks" />
      <Loadable data={data && networkDetails}>
        <Content {...{ data, networkDetails, channels, reload }} />
      </Loadable>
    </>
  )
}

const Content = (props) => {
  const { data, networkDetails, channels, reload } = props

  const { network, features } = data
  const connections = toArray(data.connections)

  const [tab, setTab] = useState('')

  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  const [selectedConnectionUuid, setSelectedConnectionUuid] = useState(() =>
    isBlank(connections) ? undefined : connections[0]?.connectionUuid
  )
  const selectedConnectionIndex = connections.findIndex(
    (connection) => connection.connectionUuid === selectedConnectionUuid
  )
  const selectedConnection = connections[selectedConnectionIndex]

  const enableUpload = Object.keys(uploadFormats).includes(network.networkName)

  return (
    <>
      <div className="card p-3 m-b-20">
        <div className="card-body p-0">
          <div className="row">
            <div className="column column-space-between flex-1 flex-1-3 p-y-3">
              <Logo
                src={network.networkLogoUrl}
                text={network.networkName}
                className="logo-left"
                width={86}
                height={24}
              />
              <div className="m-b-10">
                <div className="text-dark text-big text-bolder m-b-1">
                  {network.networkName}
                </div>
                <a
                  href={`https://${network.networkUrl}`}
                  className="text-light text-small link vertical-middle"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{network.networkUrl}</span>
                  <SVG src="/images/icon-outside.svg" className="m-l-2" />
                </a>
              </div>
            </div>

            <div className="flex-1 flex-1-3">
              <div className="card-border card-border-rounded h-100 p-20 m--20">
                <div className="text-dark text-bold">Supported features</div>
                <div className="network-show-features">
                  {map(featureLabels, (key, label) => (
                    <div key={key}>
                      <SVG
                        src={`/images/networks/feature-${
                          features[key] ? 'on' : 'off'
                        }.svg`}
                        className="m-r-10"
                      />
                      <span>{label}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/*
            <div className="flex-1 flex-1-3">
              <div className="card-border card-border-rounded h-100 p-20 m--20">
                <div className="text-dark text-bold">Related articles</div>
              </div>
            </div>
            */}
          </div>
        </div>
      </div>

      <div className="network-connection-list">
        <div className="network-connections">
          <div className="card h-100 p-b-3">
            <div className="card-body p-t-10">
              <div className="text-dark text-bold m-b-4">
                Connected accounts
              </div>
              <div className="m-x--10">
                <button
                  className="btn w-100 m-b-10 text-light text-small text-bold text-left"
                  onClick={() => {
                    showModal(
                      <ConnectNetworkModal
                        network={networkDetails}
                        channels={channels}
                        onDone={reload}
                      />
                    )
                  }}
                >
                  <SVG
                    src="/images/icon-plus-color.svg"
                    height={18}
                    className="m-r-15"
                  />
                  Connect another account
                </button>
                <ul className="network-show-connections">
                  {map(connections, (connection, index) => (
                    <li key={connection.connectionUuid}>
                      <button
                        onClick={() =>
                          setSelectedConnectionUuid(connection.connectionUuid)
                        }
                        className={varClass({
                          'active':
                            connection.connectionUuid ===
                            selectedConnection?.connectionUuid,
                        })}
                      >
                        <div className="text-bold m-b-1">
                          {connection.connectionName ||
                            `${data.network.networkName} ${index + 1}`}
                        </div>
                        <div className="text-light text-small text-ellipsis w-80">
                          {connection.connectionApiPartnerId}
                        </div>
                        <SVG src="/images/chevron-right.svg" />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="network-connection">
          {selectedConnection && (
            <div className="card p-b-0">
              <div className="card-body p-t-10">
                <div className="network-show-connection">
                  <EditableNetworkName
                    key={selectedConnectionUuid}
                    data={data}
                    index={selectedConnectionIndex}
                  />
                  <div className="delete-btn">
                    {network.networkName === 'Daisycon' && (
                      <WithTooltip
                        className="inline"
                        title="Refresh Daisycon access token, all imported data will remain intact"
                        text={
                          <button
                            type="button"
                            id="network-modal-signin-btn"
                            className="btn btn-xs text-blue text-bold text-small text-nowrap m-r-2"
                            onClick={() => {
                              localStorage.setItem(
                                'daisycon-reconnect',
                                selectedConnectionUuid
                              )
                              daisyconOauth()
                            }}
                          >
                            Reconnect
                          </button>
                        }
                        forceShow
                      />
                    )}
                    <button
                      className="btn btn-xs text-blue text-bold text-small"
                      onClick={() => {
                        showModal(
                          <RemoveModal connection={selectedConnection} />
                        )
                      }}
                    >
                      Delete
                    </button>
                  </div>
                  <div className="text-small">
                    <span className="text-light">Partner ID: </span>
                    <span className="text-dark text-bold">
                      {selectedConnection.connectionApiPartnerId}
                    </span>
                  </div>
                  <div className="text-small m-t-3">
                    <span className="text-light">Connection: </span>
                    <span
                      className={varClass({
                        'tag': true,
                        'text-green': selectedConnection.connectionIsActive,
                        'text-red': !selectedConnection.connectionIsActive,
                      })}
                    >
                      {selectedConnection.connectionIsActive
                        ? 'Active'
                        : 'Inactive'}
                    </span>
                    <span className="text-light m-l-5">
                      Real-time callback:{' '}
                    </span>
                    <span
                      className={varClass({
                        'tag': true,
                        'text-orange':
                          selectedConnection.connectionCallbackStatus ===
                          'PENDING',
                        'text-red':
                          selectedConnection.connectionCallbackStatus ===
                          'INACTIVE',
                        'text-green':
                          selectedConnection.connectionCallbackStatus ===
                          'ACTIVE',
                      })}
                    >
                      {capitalize(
                        selectedConnection.connectionCallbackStatus.toLowerCase()
                      )}
                    </span>
                  </div>
                </div>

                <div className="page-subnav m-t-4">
                  <div className="nav p-x-0 m-x--3 m-y-0">
                    <ul>
                      <li className={tab === '' ? 'active' : ''}>
                        <button className="link" onClick={() => setTab('')}>
                          Map sites
                        </button>
                      </li>
                      <li className={tab === 'callback' ? 'active' : ''}>
                        <button
                          className="link"
                          onClick={() => setTab('callback')}
                        >
                          Setup real-time callback
                        </button>
                      </li>
                      {enableUpload && (
                        <li className={tab === 'conversions' ? 'active' : ''}>
                          <button
                            className="link"
                            onClick={() => setTab('conversions')}
                          >
                            Manual upload of earnings
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="card-divider m-t--2" />
                </div>

                {tab === '' && (
                  <Table
                    {...{
                      network,
                      selectedConnection,
                      channels,
                      showModal,
                      showSnackbar,
                      reload,
                    }}
                  />
                )}
                {tab === 'callback' && (
                  <Callback
                    {...{
                      network,
                      selectedConnection,
                      showSnackbar,
                    }}
                  />
                )}
                {tab === 'conversions' && (
                  <UploadConversions
                    {...{
                      network,
                      selectedConnection,
                      showModal,
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const EditableNetworkName = (props) => {
  const { data, index } = props

  const connection = data.connections[index]
  const name =
    connection.connectionName || `${data.network.networkName} ${index + 1}`

  const [editing, setEditing] = useState(false)
  const [newName, setNewName] = useState(name)

  const submit = async () => {
    connection.connectionName = newName
    setEditing(false)
    await putRequest(`/networks/v1/connections/${connection.connectionUuid}`, {
      connectionName: newName,
    })
  }

  if (editing) {
    return (
      <div className="edit-container vertical-middle m-b-4">
        <input
          value={newName}
          onChange={(event) => setNewName(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              submit()
            }
            if (event.key === 'Escape') {
              setNewName(name)
              setEditing(false)
            }
          }}
        />
        <button className="link m-l-2" onClick={submit}>
          Save
        </button>
      </div>
    )
  } else {
    return (
      <div className="text-dark text-big text-bolder vertical-middle m-b-4">
        {name}
        <button
          className="edit-btn link m-l-2"
          onClick={() => {
            setEditing(true)
            setTimeout(() => {
              const element = document.querySelector(
                '.edit-container input'
              ) as HTMLInputElement
              if (element) {
                element.focus()
              }
            }, 0)
          }}
        >
          <SVG src="/images/networks/icon-edit2.svg" />
        </button>
      </div>
    )
  }
}

const Table = (props) => {
  const {
    network,
    selectedConnection,
    channels,
    showModal,
    showSnackbar,
    reload,
  } = props

  const addMenuOffset = useRef({ left: 0, top: 0, bottom: 0 })
  const [selectedConnectionChannel, setSelectedConnectionChannel] = useState()
  const showAddMenu = (event, connectionChannel) => {
    const button =
      event.target.nodeName === 'BUTTON'
        ? event.target
        : event.target.closest('button')
    if (!button) return
    addMenuOffset.current.left = button.offsetParent.offsetLeft + 20
    addMenuOffset.current.top =
      button.offsetParent.offsetTop + button.offsetParent.offsetHeight - 12
    addMenuOffset.current.bottom =
      button.offsetParent.offsetParent.offsetHeight -
      button.offsetParent.offsetTop -
      button.offsetParent.offsetHeight +
      48
    setSelectedConnectionChannel(connectionChannel)
  }

  return (
    <div className="card-body-fill m-t-5">
      <div className="relative">
        <table className="table table-bordered table-sortable network-show-mappings">
          <thead>
            <tr>
              <th style={{ minWidth: 160 }}>
                Medias found at {network.networkName}
              </th>
              <th className="w-100">Mapped heylink sites</th>
            </tr>
          </thead>
          <tbody>
            {map(
              selectedConnection.connectionChannels?.sort(connectionSort),
              (channel) => (
                <TableItem
                  key={channel.connectionChannelUuid}
                  {...{
                    channel,
                    channels,
                    showAddMenu,
                    showModal,
                    showSnackbar,
                  }}
                />
              )
            )}
          </tbody>
        </table>
        <AddMenu
          show={!!selectedConnectionChannel}
          channel={selectedConnectionChannel}
          hideAddMenu={() => {
            setSelectedConnectionChannel(undefined)
          }}
          {...{
            channels,
            addMenuOffset,
            reload,
          }}
        />
      </div>
    </div>
  )
}

const TableItem = (props) => {
  const { channel, channels, showAddMenu, showModal, showSnackbar } = props

  const hasAvailableChannels = useMemo(() => {
    if (!channel || !channels || !Array.isArray(channels)) return false
    return channels.length > channel.connectionsMappings.length
  }, [channels, channel])

  return (
    <tr>
      <td className="text-bold text-nowrap">{channel.connectionChannelName}</td>
      <td className="vertical-middle relative m--1 p-x-4 p-y-3">
        {map(channel.connectionsMappings?.sort(mappingsSort), (mapping) => (
          <span key={mapping.connectionMappingUuid} className="tag">
            {mapping.channel.channelName}
            <button
              className="link link-remove"
              onClick={() => {
                showModal(
                  <RemoveMappingModal
                    channel={channel}
                    mapping={mapping}
                    showSnackbar={showSnackbar}
                  />
                )
              }}
            >
              <SVG src="/images/networks/icon-x.svg" />
            </button>
          </span>
        ))}
        {hasAvailableChannels && (
          <button
            className="link link-add"
            onClick={(event) => {
              showAddMenu(event, channel)
            }}
          >
            <SVG src="/images/icon-plus-color.svg" />
          </button>
        )}
      </td>
    </tr>
  )
}

const AddMenu = (props) => {
  const { show, channel, channels, addMenuOffset, hideAddMenu, reload } = props

  if (!show) return <div className="network-new-mapping" />

  const parentChannelUuid = channel.connectionChannelUuid

  const [search, setSearch] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const availableChannels = useMemo(() => {
    if (!channel || !channels || !Array.isArray(channels)) return []
    const connectedChannelUuids =
      channel.connectionsMappings?.map(
        (mapping) => mapping.connectionMappingChannelUuid
      ) || []
    return channels.filter(
      (availableChannel) =>
        !connectedChannelUuids.includes(availableChannel.channelUuid)
    )
  }, [channels, channel])

  const shownChannels = useMemo(() => {
    if (search) {
      if (!availableChannels || !Array.isArray(availableChannels)) return []
      return availableChannels.filter(
        (channel) =>
          channel.channelDomain.toLowerCase().indexOf(search.toLowerCase()) !==
          -1
      )
    } else {
      return availableChannels
    }
  }, [availableChannels, search])

  const [positionBottom, setPositionBottom] = useState(null)
  const availableChannelsRef = useRef<HTMLDivElement>()
  useOutsideClick(availableChannelsRef, () => {
    hideAddMenu()
  })

  useEffect(() => {
    if (availableChannelsRef.current && show) {
      const parent = availableChannelsRef.current.offsetParent as HTMLElement
      const bottom =
        addMenuOffset.current.top + availableChannelsRef.current.offsetHeight
      setPositionBottom(bottom > parent.offsetHeight)
    }
  }, [channels, channel, show])

  return (
    <div
      className="network-new-mapping shown"
      style={
        positionBottom
          ? {
              left: addMenuOffset.current.left,
              bottom: addMenuOffset.current.bottom,
            }
          : {
              left: addMenuOffset.current.left,
              top: addMenuOffset.current.top,
            }
      }
      ref={availableChannelsRef}
    >
      <div className="card p-0">
        <Search
          value={search}
          setValue={setSearch}
          placeholder="Type to search"
          className="search"
        />
        {isBlank(shownChannels) ? (
          <div className="text-light text-smallest text-bold text-uppercase p-4">
            Nothing found
          </div>
        ) : (
          <>
            <div className="text-light text-smallest text-bold text-uppercase p-4 p-b-2">
              All sites
            </div>
            <ul>
              {map(shownChannels, (channel) => (
                <li
                  key={channel.channelUuid}
                  onClick={async () => {
                    setSubmitting(true)
                    await postRequest(
                      `/channels/v1/channel/${channel.channelUuid}/connectionchannels`,
                      {
                        connectionChannelUuid: parentChannelUuid,
                      }
                    )
                    reload()
                    hideAddMenu()
                  }}
                >
                  {channel.channelDomain}
                  {channel.channelName !== channel.channelDomain && (
                    <span className="text-smaller text-normal text-new-light o-40 m-l-2">
                      {channel.channelName}
                    </span>
                  )}
                </li>
              ))}
            </ul>
            {submitting && (
              <div className="loader-container">
                <div className="loader" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const Callback = (props) => {
  const { network, selectedConnection, showSnackbar } = props

  const link = useApiPost<string>(
    '/networks/v1/connections/callbackurls',
    {
      connectionUuid: selectedConnection.connectionUuid,
      networkUuid: network.networkUuid,
    },
    [network, selectedConnection]
  )

  return (
    <div className="p-b-60 m-b-60">
      <p>
        Real-time callbacks enables you to get live data from affiliate networks
        to Heylink Outbound instead of hourly or daily imports.
      </p>
      <div
        className="network-show-callback"
        onClick={() => {
          if (!isBlank(link)) {
            copyToClipboard(link)
            showSnackbar('Copied to clipboard')
          }
        }}
      >
        <div className="text-light text-small vertical-middle">
          Callback link:
          <SVG src="/images/icon-copy.svg" className="m-t--1 m-l-2" />
        </div>
        <SmallLoadable loaded={link} height={80}>
          {isBlank(link) ? (
            <pre className="text-primary text-smallest">
              The link will appear here
            </pre>
          ) : (
            <pre className="text-primary text-smallest">{link}</pre>
          )}
        </SmallLoadable>
      </div>
      <div className="text-light text-smallest">
        Copy callback link and paste it to your {network.networkName} account.
      </div>
    </div>
  )
}

const RemoveModal = (props) => {
  const { connection } = props

  const { hideModal } = useModal()

  const [confirm, setConfirm] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const submit = async () => {
    setSubmitting(true)
    await deleteRequest(`/networks/v1/connections/${connection.connectionUuid}`)
    hideModal()
    navigateTo('/networks')
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Delete network</div>
      </div>
      <div className="card-body">
        <div className="notice-info m-b-4">
          <SVG src="/images/notice-info.svg" className="m-r-2" />
          You are about to delete a Network connection. This will cause all
          imported Campaigns from this Network to no longer be imported and used
          by Heylink Intelligent Tracking.
        </div>
        <div className="control control-checkbox m-b-8">
          <input
            type="checkbox"
            checked={confirm}
            onChange={(event) => setConfirm(event.target.checked)}
          />
          <label>
            Yes — I'm sure I want to delete this network connection.
          </label>
        </div>

        <div className="text-right">
          <button
            className="btn btn-primary"
            disabled={!confirm || submitting}
            onClick={submit}
          >
            Delete
          </button>
        </div>
      </div>
    </>
  )
}

const UploadConversions = (props) => {
  const { network, selectedConnection, showModal } = props

  const [reloadRef, reload] = useCounter()

  const uploads = useApiGet(
    `/conversion_uploads/v1/${selectedConnection.connectionUuid}`,
    {},
    [selectedConnection, reloadRef]
  )

  const [error, setError] = useState('')

  const fileFormat = uploadFormats[network.networkName] || 'report'

  return (
    <div className="relative">
      <p>Upload your {network.networkName} earnings manually here.</p>
      <WithTooltip
        className="discover-broken-info"
        style={{ position: 'absolute', top: 2, right: 0 }}
        title={`Here you can manually upload ${fileFormat} files with your ${network.networkName} earnings. It might take a couple minutes for new conversions to appear on your dashboard.`}
        text={<SVG src="/images/recommendations/icon-info.svg" />}
        forceShow
      />

      <div className="row row-end m-b-4">
        {network.networkName === 'Amazon' && (
          <button
            className="btn btn-white-bordered text-bolder"
            onClick={() => {
              showModal(
                <UploadAmazonModal
                  selectedConnection={selectedConnection}
                  reload={reload}
                />
              )
            }}
          >
            Upload file
          </button>
        )}
        {network.networkName === 'Bol.com' && (
          <button
            className="btn btn-white-bordered text-bolder"
            onClick={() => {
              showModal(
                <UploadBolModal
                  selectedConnection={selectedConnection}
                  reload={reload}
                />
              )
            }}
          >
            Upload file
          </button>
        )}
        {network.networkName === 'GoAffPro' && (
          <button
            className="btn btn-white-bordered text-bolder"
            onClick={() => {
              showModal(
                <UploadGoAffProModal
                  selectedConnection={selectedConnection}
                  reload={reload}
                />
              )
            }}
          >
            Upload file
          </button>
        )}
        {network.networkName === 'Refersion' && (
          <button
            className="btn btn-white-bordered text-bolder"
            onClick={() => {
              showModal(
                <UploadRefersionModal
                  selectedConnection={selectedConnection}
                  reload={reload}
                />
              )
            }}
          >
            Upload file
          </button>
        )}
      </div>

      <div className="card-body-fill relative">
        <SmallLoadable loaded={uploads} className="m-b-8">
          <div className="table-container-overflow">
            <table className="table table-borderless table-sortable2">
              <thead>
                <tr>
                  <th>File name</th>
                  <th>Upload date</th>
                  <th>User uploaded</th>
                  <th>Period</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {map(uploads, (upload) => (
                  <tr key={upload.conversionUploadUuid} className="text-nowrap">
                    <td>{upload.conversionUploadFilename}</td>
                    <td>{upload.conversionUploadDatetime}</td>
                    <td>
                      {upload.user.userFirstname} {upload.user.userLastname}
                    </td>
                    <td>
                      {upload.conversionUploadFromDatetime} —{' '}
                      {upload.conversionUploadToDatetime}
                    </td>
                    <td>
                      <button
                        className="link link-danger p-t-1"
                        onClick={() => {
                          showModal(
                            <DeleteUploadModal
                              selectedConnection={selectedConnection}
                              upload={upload}
                              reload={reload}
                            />
                          )
                        }}
                      >
                        <SVG src="/images/icon-trash.svg" />
                      </button>
                    </td>
                  </tr>
                ))}
                {isBlank(uploads) && (
                  <tr>
                    <td
                      colSpan={10}
                      className="text-center text-light text-bolder"
                    >
                      No uploads
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </SmallLoadable>
      </div>
    </div>
  )
}
