import React, { useState, useMemo } from 'react'
import SVG from 'react-inlinesvg'
import { Placeholder } from './Placeholder'
import {
  Header,
  Tooltip,
  WithTooltip,
  Loadable,
  NoDataDiv,
  TableHead,
  useApiGet,
  useSortableTable,
  formatStat,
  map,
  newTableSort,
  isBlank,
  varClass,
  navigateTo,
} from '../../shared'

const opportunitySort = newTableSort((a: any, b: any, key: string) => {
  if (key === 'channel') {
    if (a.channels) {
      return [a.channels.length, b.channels.length]
    }
    if (a.channel) {
      return [a.channel?.channelDomain || '', b.channel?.channelDomain || '']
    }
  } else if (key === 'domain') {
    return [
      a.advertiser?.advertiserDomain || '',
      b.advertiser?.advertiserDomain || '',
    ]
  }
})

export const Opportunities2 = (props) => {
  const channels = useApiGet('/channels/v1/list')

  const data = useApiGet('/discovers/v3/opportunities_alternatives/top')

  return (
    <>
      <Header label="Opportunities" />
      {channels && isBlank(channels) ? (
        <div className="card card-transparent">
          <div className="row row-nowrap row-center row-space-between">
            <div className="row row-nowrap row-center">
              <SVG src="/images/empty-opportunities.svg" />
              <div className="column column-fill">
                <div className="text-bigger text-bolder m-b-10">
                  No data available yet
                </div>
                <div className="text-bold">
                  To see your opportunities simply create a Site and add the
                  Tracking script
                </div>
              </div>
            </div>
            <button
              className="btn btn-dark"
              onClick={(event) => {
                navigateTo(event, '/sites')
              }}
            >
              Create site
            </button>
          </div>
        </div>
      ) : (
        <Loadable data={data} placeholder={<Placeholder />}>
          <Content data={data} />

          <Tooltip type="dark" place="bottom" />
        </Loadable>
      )}
    </>
  )
}

const Content = (props) => {
  const { data } = props

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'opportunityClicks',
    dir: 'desc',
  })

  const headProps = { sort, dir, toggleSort }

  const shownData = opportunitySort(data, sort, dir)

  if (isBlank(data)) {
    return (
      <NoDataDiv
        description="No new opportunities found yet."
        className="p-y-50"
      />
    )
  }

  return (
    <div className="card discover-opportunity-content">
      <div className="card-body">
        <table className="table table-bordered table-sortable">
          <thead>
            <tr>
              <TableHead value="domain" label="URL" {...headProps} />
              <TableHead value="opportunityType" label="Type" {...headProps} />
              <TableHead
                value="opportunityClicks"
                label="Clicks"
                desc
                {...headProps}
              />
              <TableHead value="channel" label="Site" {...headProps} />
            </tr>
          </thead>
          <tbody>
            {map(shownData, (item, index) => (
              <TableItem
                key={`${item.opportunityType}_${item.opportunityUrlDomain}_${item.opportunityClicks}_${item.channel?.channelUuid}`}
                item={item}
                sort={sort}
              />
            ))}
          </tbody>
        </table>

        <Tooltip
          id="opportunities-drawer-tooltip"
          type="dark"
          place="bottom"
          rebuild={[shownData]}
        />

        {isBlank(data) && (
          <NoDataDiv
            description="No new opportunities found yet."
            className="p-y-50"
          />
        )}
      </div>
    </div>
  )
}

const TableItem = (props) => {
  const { item, sort } = props

  const [channelsExpanded, setChannelsExpanded] = useState(
    item.channels?.length <= 4
  )

  const shownChannels = useMemo(
    () => (channelsExpanded ? item.channels : item.channels?.slice(0, 3)),
    [channelsExpanded, item]
  )

  const domain = (item.advertiser.advertiserDomain || '').replace('www.', '')

  if (!domain) {
    return null
  }

  return (
    <tr>
      <td
        className={varClass({
          'relative w-30 vertical-middle': true,
          'text-dark sort-highlight': sort === 'opportunityUrlDomain',
        })}
      >
        <div className="row row-narrow row-nowrap row-space-between">
          {domain}
          <button
            className="link link-filter"
            onClick={(event) => {
              navigateTo(event, `/merchants/${item.advertiser.advertiserUuid}`)
            }}
          >
            <SVG src="/images/icon-details.svg" className="vertical-middle" />
          </button>
        </div>
      </td>
      <td
        className={varClass({
          'w-0 text-right': true,
          'sort-highlight': sort === 'opportunityType',
        })}
      >
        {item.opportunityType === 'connect' && (
          <WithTooltip
            className="opportunity-type"
            title="You are sending clicks through that could be making you more money. ‘New’ opportunities show non-monetised clicks while ‘Alternative’ opportunities show campaigns with higher commission than the one being used today"
            text={
              <>
                <SVG src="/images/recommendations/icon-campaigns.svg" />
                New
              </>
            }
            forceShow
            tooltipId="opportunities-drawer-tooltip"
          />
        )}
        {item.opportunityType === 'alternative' && (
          <WithTooltip
            className="opportunity-type"
            title="You are sending clicks through that could be making you more money. ‘New’ opportunities show non-monetised clicks while ‘Alternative’ opportunities show campaigns with higher commission than the one being used today"
            text={
              <>
                <SVG src="/images/recommendations/icon-alt-campaigns.svg" />
                Alternative
              </>
            }
            forceShow
            tooltipId="opportunities-drawer-tooltip"
          />
        )}
      </td>
      <td
        className={varClass({
          'text-dark sort-highlight': sort === 'opportunityClicks',
        })}
      >
        {formatStat(item.opportunityClicks || 0)}
      </td>
      <td
        className={varClass({
          'overflow-hide': true,
          'text-dark sort-highlight': sort === 'channel',
        })}
      >
        {map(shownChannels, (channel) => (
          <span
            key={channel.channelUuid}
            className="badge badge-hoverable text-smaller p-y-05 m-05"
            onClick={(event) => {
              navigateTo(event, `/sites/${channel.channelUuid}`)
            }}
          >
            {channel.channelName || channel.channelDomain}
          </span>
        ))}
        {!channelsExpanded && (
          <div
            className="badge badge-hoverable text-smaller text-nowrap m-05"
            onClick={(event) => {
              setChannelsExpanded(true)
            }}
          >
            show more
          </div>
        )}
      </td>
    </tr>
  )
}
