import React from 'react'
import { Load } from '../shared'

export const Placeholder = (props) => {
  return (
    <>
      <div className="row p-t-1 m-t-70 m-b-20">
        <div className="card flex-1-4 row p-x-5" style={{ height: 132 }}>
          <Load width={40} height={40} />
          <div
            className="inline-block m-l--1"
            style={{ width: 'calc(100% - 60px)' }}
          >
            <Load height={16} className="m-b-3" />
            <Load height={24} className="m-b-4" />
          </div>
        </div>
        <div className="card flex-1-4 row p-x-5" style={{ height: 132 }}>
          <Load width={40} height={40} />
          <div
            className="inline-block m-l--1"
            style={{ width: 'calc(100% - 60px)' }}
          >
            <Load height={16} className="m-b-3" />
            <Load height={24} className="m-b-4" />
          </div>
        </div>
        <div className="card flex-1-4 row p-x-5" style={{ height: 132 }}>
          <Load width={40} height={40} />
          <div
            className="inline-block m-l--1"
            style={{ width: 'calc(100% - 60px)' }}
          >
            <Load height={16} className="m-b-3" />
            <Load height={24} className="m-b-4" />
          </div>
        </div>
        <div className="card flex-1-4 row p-x-5" style={{ height: 132 }}>
          <Load width={40} height={40} />
          <div
            className="inline-block m-l--1"
            style={{ width: 'calc(100% - 60px)' }}
          >
            <Load height={16} className="m-b-3" />
            <Load height={24} className="m-b-4" />
          </div>
        </div>
      </div>
      <div className="row m-b-20">
        <div className="card flex-1-2" style={{ height: 320 }}>
          <Load width={120} height={20} className="m-b-20" />
          <Load width="calc(100%)" height={216} />
        </div>
        <div className="card flex-1-2" style={{ height: 320 }}>
          <Load width={120} height={20} className="m-b-20" />
          <Load width="calc(100%)" height={216} />
        </div>
      </div>
      <div className="row">
        <div className="card flex-1-5 flex-1" style={{ height: 433 }}>
          <Load width={100} height={20} className="m-t-40 m-b-20" />
        </div>
        <div className="card flex-2-5 flex-2" style={{ height: 433 }}>
          <Load width={100} height={20} className="m-t-40 m-b-20" />
        </div>
        <div className="card flex-2-5 flex-2" style={{ height: 433 }}>
          <Load width={100} height={20} className="m-t-40 m-b-20" />
        </div>
      </div>
    </>
  )
}

export const PlaceholderTop = (props) => {
  return (
    <div className="m-x--6 m-b--5" style={{ height: 465 }}>
      <Load width="100%" height={48} />
    </div>
  )
}
