import React from 'react'
import { Load } from '../shared'

export const Placeholder = (props) => {
  return (
    <>
      <div className="card" style={{ height: 'calc(100vh - 184px)' }}>
        <div className="card-body">
          <Load height={30} className="m-t--2" />
          <div className="card-body-fill m-t-3">
            <Load height={50} />
          </div>
        </div>
      </div>
    </>
  )
}
