import React, { useState, useEffect, useMemo } from 'react'
import SVG from 'react-inlinesvg'
import { uniqBy, sortBy } from 'lodash'
import { ScriptmanagerForm } from './ScriptmanagerForm'
import {
  IChannelsV1Channel,
  IChannelsV1List,
  INetworksV1Networks,
  EditChannelModal,
  RemoveChannelModal,
  ConnectNewNetworkModal,
  EditConnectNetworkModal,
  Logo,
  Loadable,
  Header,
  useApiGet,
  useModal,
  useSnackbar,
  navigateTo,
  navigateReload,
  varClass,
  isBlank,
  toArray,
  map,
} from '../shared'
import { putRequest, scriptmanagerGet } from '../../services'

export const Site = (props) => {
  const { channelUuid } = props

  const channel = useApiGet<IChannelsV1Channel>(
    `/channels/v1/channel/${channelUuid}`
  )
  const channels = useApiGet<IChannelsV1List[]>('/channels/v1/list')
  const networks = useApiGet<INetworksV1Networks[]>('/networks/v1/networks')
  const [scriptmanagerOptions, setScriptmanagerOptions] = useState(null)
  useEffect(() => {
    const callback = async () => {
      const response = await scriptmanagerGet(
        `/internal/publishers/${channelUuid}`
      )
      setScriptmanagerOptions(response)
    }
    callback()
  }, [channelUuid])

  const connections = useApiGet<any>(
    `/channels/v1/channel/${channelUuid}/connectionchannels`
  )

  return (
    <>
      <Header backPath="/sites" />
      <Loadable data={channel && channels && connections}>
        <Content
          channel={channel}
          channels={channels}
          networks={networks}
          connections={connections}
          scriptmanagerOptions={scriptmanagerOptions}
        />
      </Loadable>
    </>
  )
}

const Content = (props) => {
  const { channel, channels, networks, connections, scriptmanagerOptions } =
    props

  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  const shownNetworks = useMemo(
    () =>
      sortBy(
        uniqBy(
          toArray(connections)
            .filter(
              (connection) => connection.connectionChannelIsMappedToThisChannel
            )
            .reduce((result, connection) => {
              result.push(connection.network)
              return result
            }, []),
          (network: INetworksV1Networks) => network.networkUuid
        ),
        (network: INetworksV1Networks) => network.networkName
      ),
    [connections]
  )

  const showDomain =
    channel.channelName && channel.channelName !== channel.channelDomain

  return (
    <div className="channel-show-content">
      <div className="channel-info">
        <div className="card m-b-20">
          <div className="card-body p-t-2">
            <div className="row row-center row-fill row-nowrap">
              <Logo
                src={channel.channelFaviconUrl}
                letter={channel.channelName || channel.channelDomain || ''}
                width={32}
                height={32}
                className="flex-noshrink m-r-3"
                bordered
              />
              <div className="text-breakword">
                <div className="text-dark text-big text-bolder">
                  {channel.channelName || channel.channelDomain}
                  {channel.channelCurrencyCode && (
                    <span className="text-light text-smallest text-bolder o-70 m-l-2">
                      {channel.channelCurrencyCode}
                    </span>
                  )}
                </div>
                {showDomain && (
                  <div className="text-light text-small text-bold m-t-05">
                    {channel.channelDomain}
                  </div>
                )}
              </div>
            </div>

            <div className="row row-center row-space-between m-t-30">
              <div className="text-light text-small text-bold">Merchants:</div>
              <div className="text-bold">{channel.channelAdvertisersCount}</div>
            </div>

            <div className="m-t-4">
              <div className="text-light text-small text-bold">Networks:</div>
              <div className="m-t-15 m-x--05">
                {map(shownNetworks, (network) => (
                  <span
                    key={network.networkUuid}
                    className="badge badge-hoverable text-smaller text-nowrap m-05"
                    onClick={(event) => {
                      navigateTo(event, `/networks/${network.networkUuid}`)
                    }}
                  >
                    {network.networkName}
                  </span>
                ))}
              </div>
            </div>

            <div className="card-divider m-t-5" />

            <div className="text-dark text-boldest m-b-15">
              Heylink Intelligent Tracking™
            </div>
            <div className="m-b-20">
              When you add the Heylink script or Plugin to your website, all
              external clicks are monitored and logged. If you choose to connect
              your affiliate networks, Heylink will be able to add SubId
              tracking to your links and both automize and optimize your
              affiliate and non-affiliate links.
            </div>

            <Autotrack channel={channel} />

            <div className="card-divider m-t-10 m-b-5" />

            <div className="row row-center row-fill">
              <button
                className="btn btn-xs text-dark text-smaller text-bolder p-x-3 m-r-10"
                onClick={() => {
                  showModal(
                    <EditChannelModal
                      channel={channel}
                      showSnackbar={showSnackbar}
                    />
                  )
                }}
              >
                Edit site
              </button>
              <button
                className="btn btn-xs text-dark text-smaller text-bolder p-x-3"
                onClick={() => {
                  showModal(
                    <RemoveChannelModal
                      channel={channel}
                      showSnackbar={showSnackbar}
                    />
                  )
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body p-t-2">
            <div className="text-dark text-boldest m-b-10">
              Map network sites
            </div>
            <div>
              By mapping the affiliate sites that matches your{' '}
              {channel.channelName}, you are able to get in-depth insights on
              the traffic going out from this site.
            </div>

            <div className="card-divider m-t-5 m-b-0" />

            <Mappings
              channels={channels}
              connections={connections}
              showModal={showModal}
              showSnackbar={showSnackbar}
            />

            <div className="m-t-40">
              <button
                className="btn btn-xs text-dark text-smaller text-bolder row row-center row-fill p-x-3"
                onClick={(event) => {
                  showModal(
                    <ConnectNewNetworkModal
                      networks={networks}
                      channels={channels}
                      onSkip={navigateReload}
                      onDone={navigateReload}
                    />
                  )
                }}
              >
                <SVG
                  src="/images/icon-plus-color.svg"
                  className="o-40 m-r-15"
                />
                Add network
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="channel-data">
        {!isBlank(scriptmanagerOptions) && (
          <ScriptmanagerForm
            channel={channel}
            options={scriptmanagerOptions}
            showModal={showModal}
            showSnackbar={showSnackbar}
          />
        )}
      </div>
    </div>
  )
}

export const Autotrack = (props) => {
  const { channel } = props

  const [autotrackEnabled, setAutotrackEnabled] = useState(
    channel.channelIsActive
  )
  const onAutotrackChange = async (event) => {
    setAutotrackEnabled(!event.target.checked)
    await putRequest(`/settings/v1/hit/${channel.channelUuid}`, {
      channelIsActive: !event.target.checked,
    })
  }

  return (
    <div className="channel-autotrack m-x--3">
      <div className="row row-center row-space-between row-nowrap">
        <div className="control control-switch">
          <input
            type="checkbox"
            checked={!autotrackEnabled}
            onChange={onAutotrackChange}
          />
          <label>{autotrackEnabled ? 'Off' : 'On'}: discover mode</label>
        </div>
      </div>
      <div className="channel-autotrack-divider" />
      <div className="row row-fill row-nowrap">
        <SVG src="/images/notice-info.svg" />
        <div>
          When Discover mode is active, Heylink only logs clicks - no links are
          optimized.
        </div>
      </div>
    </div>
  )
}

const Mappings = (props) => {
  const { channels, connections, showModal, showSnackbar } = props

  const shownConnections = sortBy(
    uniqBy(
      toArray(connections).filter(
        (connection) => connection.connectionChannelIsMappedToThisChannel
      ),
      (connection) => connection.network.networkUuid
    ),
    (connection) => connection.network.networkName
  )

  if (isBlank(shownConnections)) {
    return <div className="m-y--5" />
  }

  return (
    <div className="channel-mappings">
      {map(shownConnections, (connection) => (
        <div
          key={connection.network.networkUuid}
          className="row row-center row-space-between"
        >
          <div>
            <span
              className="mapping-name cursor-pointer"
              onClick={(event) => {
                navigateTo(event, `/networks/${connection.network.networkUuid}`)
              }}
            >
              {connection.network.networkName}
            </span>
            <span
              className={varClass({
                'mapping-count': true,
                'o-30': connection.connectionChannelMappingUuids.length === 0,
              })}
            >
              {connection.connectionChannelMappingUuids.length}
            </span>
          </div>
          <button
            className="btn btn-xs text-dark text-smaller text-bolder p-x-3"
            onClick={() => {
              showModal(
                <EditConnectNetworkModal
                  network={connection.network}
                  channels={channels}
                  connection={connection}
                  onDone={() => {
                    showSnackbar(
                      <div>
                        <div>Mappings updated successfully.</div>
                        <div>
                          It might take a few seconds before changes take place.
                        </div>
                      </div>
                    )
                  }}
                />
              )
            }}
          >
            Edit mapping
          </button>
        </div>
      ))}
    </div>
  )
}
