import React, { useState, useMemo } from 'react'
import { putRequest } from '../../../services'
import { useArray, varClass, IChannelsV1List } from '../../shared'
import { MultiSelectDropdown } from './shared'
import { uniq } from 'lodash'

export const PrioritizeModal = (props) => {
  const { campaign, hideModal, reloadCampaign, showSnackbar } = props
  const trackers = campaign.trackers
  const [selectedMethod, setSelectedMethod] = useState('')

  const initialSelectedChannels = useMemo(
    () =>
      uniq(
        trackers
          .filter((tracker) => tracker.trackerIsPriority)
          .map((tracker) => tracker.trackerChannelUuid)
      ),
    [campaign]
  )
  const {
    values: selectedChannels,
    toggle: toggleSelectedChannel,
    clear: clearSelectedChannels,
  } = useArray(initialSelectedChannels)

  const canUnprioritizeAll = useMemo(
    () => !!initialSelectedChannels.length,
    [campaign]
  )

  const submit = async () => {
    let priorityCampaignUuids = []
    let unpriorityCampaignUuids = []

    if (selectedMethod === 'specific') {
      if (selectedChannels.length) {
        priorityCampaignUuids = trackers
          .filter((tracker) =>
            selectedChannels.includes(tracker.trackerChannelUuid)
          )
          .map((t) => t.trackerUuid)
      } else {
        priorityCampaignUuids = []
      }
    } else if (selectedMethod === 'all') {
      priorityCampaignUuids = trackers.map((tracker) => tracker.trackerUuid)
    } else if (selectedMethod === 'none') {
      priorityCampaignUuids = []
    }

    for (const tracker of trackers) {
      if (!priorityCampaignUuids.includes(tracker.trackerUuid)) {
        unpriorityCampaignUuids.push(tracker.trackerUuid)
      }
    }

    priorityCampaignUuids = priorityCampaignUuids.filter((uuid) => uuid)
    unpriorityCampaignUuids = unpriorityCampaignUuids.filter((uuid) => uuid)

    await Promise.all([
      priorityCampaignUuids.length
        ? putRequest(`/advs/v2/camps/tracking`, {
            campaignUuids: priorityCampaignUuids,
            campaignIsPriority: true,
          })
        : null,
      unpriorityCampaignUuids.length
        ? putRequest(`/advs/v2/camps/tracking`, {
            campaignUuids: unpriorityCampaignUuids,
            campaignIsPriority: false,
          })
        : null,
    ])

    showSnackbar('Tracking priority updated')
    reloadCampaign()
    hideModal()
  }

  return (
    <>
      <div className="card-body">
        <div
          onChange={(e: any) => setSelectedMethod(e.target.value)}
          className="column column-narrow"
        >
          <div className="control control-radio">
            <input type="radio" name="campaign-priority" value="all" />
            <label>Prioritize for all sites</label>
          </div>
          {canUnprioritizeAll && (
            <div className="control control-radio">
              <input type="radio" name="campaign-priority" value="none" />
              <label>Unprioritize for all sites</label>
            </div>
          )}
          <div className="control control-radio">
            <input type="radio" name="campaign-priority" value="specific" />
            <label>Prioritize for specific sites</label>
          </div>
        </div>

        <div
          className={varClass({
            'subnav-filters w-100 m-t-2': true,
            'disabled': selectedMethod !== 'specific',
          })}
        >
          <MultiSelectDropdown
            label="Sites"
            values={campaign.channels}
            sortKey="channelUuid"
            uuidGetter={(channel: IChannelsV1List) => channel.channelUuid}
            labelGetter={(channel: IChannelsV1List) =>
              channel.channelName || channel.channelDomain
            }
            selectedValues={selectedChannels}
            toggleSelectedValue={toggleSelectedChannel}
            clearSelectedValues={clearSelectedChannels}
          />
        </div>

        <div className="row row-end m-t-5">
          <button
            className="btn btn-dark"
            disabled={!selectedMethod}
            onClick={submit}
          >
            Update
          </button>
        </div>
      </div>
    </>
  )
}
