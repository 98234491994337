import React, { useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import SVG from 'react-inlinesvg'
import {
  ScriptChannelModal,
  useFormSubmit,
  useOutsideClick,
  varClass,
  map,
  isBlank,
} from '../shared'
import { scriptmanagerPut } from '../../services'

const hashToArray = (obj: any): string[] =>
  Object.keys(obj).reduce((result, key) => {
    result.push(`${key}=${obj[key]}`)
    return result
  }, [])

const formatArray = (arr: string[]): string => {
  if (isBlank(arr)) {
    return ''
  } else {
    return arr.filter((item: string) => !!item).join(',')
  }
}

const formatHash = (arr: string[]): string => {
  if (isBlank(arr)) {
    return ''
  } else {
    return arr
      .filter((item: string) => {
        const [key, value] = item.split(/=(.*)/)
        return !!key && !!value
      })
      .join(',')
  }
}

export const ScriptmanagerForm = (props) => {
  const { channel, options, showModal, showSnackbar } = props

  const channelUuid = channel.channelUuid

  const [_render, setRender] = useState(0)
  const rerender = () => setRender(Date.now())

  const form = useForm({
    defaultValues: {
      append_classes: options.append_classes,
      append_attributes: hashToArray(options.append_attributes),
      ignore_selectors: options.ignore_selectors,
      ignore_domains: options.ignore_domains,
      exclude_pages: options.exclude_pages,
      rel_nofollow: options.rel_nofollow,
      rel_sponsored: options.rel_sponsored,
      disable_cookies: options.disable_cookies,
      disable_localstorage: options.disable_localstorage,
      dom_listener: options.dom_listener,
    },
  })

  const { register, handleSubmit, setValue, getValues } = form

  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    try {
      await scriptmanagerPut(`/internal/publishers/${channelUuid}`, {
        dom_listener: values.dom_listener || false,
        append_classes: formatArray(values.append_classes),
        append_attributes: formatHash(values.append_attributes),
        ignore_selectors: formatArray(values.ignore_selectors),
        ignore_domains: formatArray(values.ignore_domains),
        exclude_pages: formatArray(values.exclude_pages),
        rel_nofollow: values.rel_nofollow || false,
        rel_sponsored: values.rel_sponsored || false,
        // disable_cookies: values.disable_cookies.join(','),
        // disable_localstorage: values.disable_localstorage.join(','),
      })
      showSnackbar('Script settings updated successfully')
    } catch (error) {
      showSnackbar(error)
    }
  })

  const values = getValues()
  const addArrayValue = (key) => () => {
    const newValues = [...(values[key] || [])]
    newValues.push('')
    setValue(key, newValues)
    rerender()
  }
  const setArrayValue = (key, index) => (event) => {
    const newValues = [...(values[key] || [])]
    newValues[index] = event.target.value
    setValue(key, newValues)
    rerender()
  }
  const removeArrayValue = (key, index) => () => {
    const newValues = [...(values[key] || [])]
    newValues.splice(index, 1)
    setValue(key, newValues)

    if (newValues.length === 0 && displayedFields.indexOf(key) !== -1) {
      const newDisplayedFields = [...displayedFields]
      newDisplayedFields.splice(newDisplayedFields.indexOf(key), 1)
      setDisplayedFields(newDisplayedFields)
    }

    rerender()
  }
  const addHashValue = (key) => () => {
    const newValues = [...(values[key] || [])]
    newValues.push('=')
    setValue(key, newValues)
    rerender()
  }
  const setHashKey = (key, index) => (event) => {
    const newValues = [...(values[key] || [])]
    const [_oldKey, oldValue] = newValues[index].split(/=(.*)/)
    newValues[index] = `${event.target.value.replace(/=/g, '')}=${
      oldValue || ''
    }`
    setValue(key, newValues)
    rerender()
  }
  const setHashValue = (key, index) => (event) => {
    const newValues = [...(values[key] || [])]
    const [oldKey, _oldValue] = newValues[index].split(/=(.*)/)
    newValues[index] = `${oldKey || ''}=${event.target.value.replace(/=/g, '')}`
    setValue(key, newValues)
    rerender()
  }
  const removeHashValue = (key, index) => () => {
    const newValues = [...(values[key] || [])]
    newValues.splice(index, 1)
    setValue(key, newValues)

    if (newValues.length === 0 && displayedFields.indexOf(key) !== -1) {
      const newDisplayedFields = [...displayedFields]
      newDisplayedFields.splice(newDisplayedFields.indexOf(key), 1)
      setDisplayedFields(newDisplayedFields)
    }

    rerender()
  }

  const arrayProps = { values, addArrayValue, setArrayValue, removeArrayValue }
  const hashProps = {
    values,
    addHashValue,
    setHashKey,
    setHashValue,
    removeHashValue,
  }

  const [displayedFields, setDisplayedFields] = useState(() => {
    const values = form.getValues()
    const result = []
    if (!isBlank(values.append_classes)) result.push('append_classes')
    if (!isBlank(values.append_attributes)) result.push('append_attributes')
    if (!isBlank(values.ignore_selectors)) result.push('ignore_selectors')
    if (!isBlank(values.ignore_domains)) result.push('ignore_domains')
    if (!isBlank(values.exclude_pages)) result.push('exclude_pages')
    if (values.rel_nofollow || values.rel_sponsored) result.push('rel')
    if (values.dom_listener) result.push('dom_listener')
    return result
  })
  const addDisplayedField = (newField) => {
    const newDisplayedFields = [...displayedFields]
    if (newDisplayedFields.indexOf(newField) === -1) {
      newDisplayedFields.push(newField)
    }
    setDisplayedFields(newDisplayedFields)
  }

  const addMenuRef = useRef()
  const [addMenuOpen, setAddMenuOpen] = useState(false)
  useOutsideClick(addMenuRef, () => {
    setAddMenuOpen(false)
  })

  return (
    <div className="card">
      <div className="card-body p-t-10">
        <div className="text-dark text-big text-bolder m-b-4">
          Script settings
        </div>
        <div className="w-70 m-b-30">
          Add custom rules to your Heylink setup. It is possible to exclude
          links on several levels, add parameters and classes to links and more.
        </div>

        <div className="row row-center row-space-between">
          <div
            className="row row-center row-fill row-nowrap relative"
            ref={addMenuRef}
          >
            <div className="text-light text-small m-r-3">Script settings:</div>
            <button
              className="btn btn-light btn-sm vertical-middle"
              onClick={() => {
                setAddMenuOpen(!addMenuOpen)
              }}
            >
              <SVG
                src="/images/channels/icon-close.svg"
                className="rotate-45 m-r-1 m-b--05"
                width={12}
                height={12}
              />
              <span className="text-small text-bold">Add setting</span>
            </button>
            <ul
              className={varClass({
                'scriptmanager-options-menu': true,
                'open': addMenuOpen,
              })}
            >
              <li>
                <button
                  className="btn"
                  onClick={() => {
                    addDisplayedField('append_classes')
                    addArrayValue('append_classes')()
                    setAddMenuOpen(false)
                  }}
                >
                  Append class to links
                </button>
              </li>
              <li>
                <button
                  className="btn"
                  onClick={() => {
                    addDisplayedField('rel')
                    setAddMenuOpen(false)
                  }}
                >
                  Append "rel" attribute to links
                </button>
              </li>
              <li>
                <button
                  className="btn"
                  onClick={() => {
                    addDisplayedField('append_attributes')
                    addHashValue('append_attributes')()
                    setAddMenuOpen(false)
                  }}
                >
                  Append custom attribute to links
                </button>
              </li>
              <li>
                <button
                  className="btn"
                  onClick={() => {
                    addDisplayedField('ignore_selectors')
                    addArrayValue('ignore_selectors')()
                    setAddMenuOpen(false)
                  }}
                >
                  Ignore links that match CSS selector
                </button>
              </li>
              <li>
                <button
                  className="btn"
                  onClick={() => {
                    addDisplayedField('ignore_domains')
                    addArrayValue('ignore_domains')()
                    setAddMenuOpen(false)
                  }}
                >
                  Ignore links that lead to matching domain
                </button>
              </li>
              <li>
                <button
                  className="btn"
                  onClick={() => {
                    addDisplayedField('exclude_pages')
                    addArrayValue('exclude_pages')()
                    setAddMenuOpen(false)
                  }}
                >
                  Ignore all links on a page
                </button>
              </li>
              <li>
                <button
                  className="btn"
                  onClick={() => {
                    addDisplayedField('dom_listener')
                    setAddMenuOpen(false)
                  }}
                >
                  SPA or dynamic website
                </button>
              </li>
            </ul>
          </div>
          <button
            className="btn btn-bordered btn-md text-smaller"
            onClick={() => {
              showModal(
                <ScriptChannelModal
                  channel={channel}
                  showSnackbar={showSnackbar}
                />,
                {
                  cardClassName: 'script-link-modal',
                }
              )
            }}
          >
            Get script
          </button>
        </div>

        <hr className="m-t-5 m-b-6" />

        <form onSubmit={handleSubmit(onSubmit)} className="scriptmanager-form">
          {map(displayedFields, (field) => {
            switch (field) {
              case 'append_classes':
                return (
                  <ScriptmanagerArray
                    key={field}
                    field="append_classes"
                    label="Append classes to links"
                    hint="Example: <pre>custom-class</pre>"
                    {...arrayProps}
                  />
                )
              case 'append_attributes':
                return (
                  <ScriptmanagerHash
                    key={field}
                    field="append_attributes"
                    label="Append attributes to links"
                    hint="Example: <pre>target</pre> = <pre>_blank</pre>"
                    {...hashProps}
                  />
                )
              case 'ignore_selectors':
                return (
                  <ScriptmanagerArray
                    key={field}
                    field="ignore_selectors"
                    label="Ignore links matching selectors"
                    hint="Example: <pre>.class</pre> , <pre>#id</pre>"
                    {...arrayProps}
                  />
                )
              case 'ignore_domains':
                return (
                  <ScriptmanagerArray
                    key={field}
                    field="ignore_domains"
                    label="Ignore links that lead to matching domains"
                    hint="Example: <pre>heylink.com</pre>"
                    {...arrayProps}
                  />
                )
              case 'exclude_pages':
                return (
                  <ScriptmanagerArray
                    key={field}
                    field="exclude_pages"
                    label="Ignore links on matching URLs"
                    hint="Example: <pre>/path/to/ignore</pre>"
                    {...arrayProps}
                  />
                )
              case 'rel':
                return (
                  <div key={field} className="form-group">
                    <label className="text-bold">
                      Append "rel" attributes to links
                    </label>
                    <div className="block control control-checkbox control-focusless m-t-2 m-b-4">
                      <input type="checkbox" {...register('rel_nofollow')} />
                      <label>Add rel="nofollow" to links</label>
                    </div>
                    <div className="block control control-checkbox control-focusless">
                      <input type="checkbox" {...register('rel_sponsored')} />
                      <label>Add rel="sponsored" to links</label>
                    </div>
                  </div>
                )
              case 'dom_listener':
                return (
                  <div key={field} className="form-group">
                    <label className="text-bold">
                      Single Page Application or dynamic website
                    </label>
                    <div
                      key={field}
                      className="block control control-switch control-focusless m-t-2"
                    >
                      <input type="checkbox" {...register('dom_listener')} />
                      <label>Dynamic website</label>
                    </div>
                  </div>
                )
              default:
                return null
            }
          })}

          <div>
            <button
              type="submit"
              className="btn btn-dark"
              disabled={isBlank(displayedFields) || submitting}
            >
              <SVG
                src="/images/channels/icon-refresh.svg"
                className={varClass({
                  'm-r-15': true,
                  'rotate-loop': submitting,
                })}
              />
              Update script
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

const ScriptmanagerArray = (props) => {
  const {
    field,
    label,
    hint,
    values,
    addArrayValue,
    setArrayValue,
    removeArrayValue,
  } = props

  return (
    <div key={field} className="form-group">
      <label className="text-bold vertical-middle">
        {label}
        <button
          type="button"
          className="link link-add"
          onClick={addArrayValue(field)}
        >
          <SVG src="/images/channels/icon-close.svg" />
        </button>
      </label>
      {map(values[field], (value, index) => (
        <div key={`${field}-${index}`}>
          <div className="control control-focusless m-y-2">
            <input
              type="text"
              value={value.trim()}
              onChange={setArrayValue(field, index)}
            />
            <button
              type="button"
              className="link link-remove"
              onClick={removeArrayValue(field, index)}
            >
              <SVG src="/images/channels/icon-close.svg" />
            </button>
          </div>
        </div>
      ))}
      {hint && (
        <small
          className="text-light block"
          dangerouslySetInnerHTML={{ __html: hint }}
        />
      )}
    </div>
  )
}

const ScriptmanagerHash = (props) => {
  const {
    field,
    label,
    hint,
    values,
    addHashValue,
    setHashKey,
    setHashValue,
    removeHashValue,
  } = props
  return (
    <div key={field} className="form-group">
      <label className="text-bold">
        {label}
        <button
          type="button"
          className="link link-add p-x-0 m-b-2"
          onClick={addHashValue(field)}
        >
          <SVG src="/images/channels/icon-close.svg" />
        </button>
      </label>
      {map(values[field], (value, index) => (
        <div key={`${field}-${index}`}>
          <div className="control control-focusless m-y-2">
            <input
              type="text"
              value={(value.split('=')[0] || '').trim()}
              onChange={setHashKey(field, index)}
            />
            {' = '}
            <input
              type="text"
              value={(value.split('=')[1] || '').trim()}
              onChange={setHashValue(field, index)}
            />
            <button
              type="button"
              className="link link-remove"
              onClick={removeHashValue(field, index)}
            >
              <SVG src="/images/channels/icon-close.svg" />
            </button>
          </div>
        </div>
      ))}
      {hint && (
        <small
          className="text-light block"
          dangerouslySetInnerHTML={{ __html: hint }}
        />
      )}
    </div>
  )
}
