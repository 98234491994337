import React, { useState, useEffect, useMemo, useRef } from 'react'
import SVG from 'react-inlinesvg'
import ReactTooltip from 'react-tooltip'
import {
  ResponsiveContainer,
  CartesianGrid,
  ComposedChart,
  XAxis,
  YAxis,
  Area,
  Bar,
  Rectangle,
  PieChart,
  Pie,
  Cell,
  Tooltip as ChartTooltip,
} from 'recharts'
import { curveBumpX } from 'd3-shape'
import moment from 'moment'
import { sortBy, uniq } from 'lodash'
import { Placeholder } from './Placeholder'
import {
  TTimeout,
  Search,
  Tooltip,
  Header,
  Loadable,
  SmallLoadable,
  NavFilter,
  TableSort,
  WithTooltip,
  ValueIndicator,
  Pagination,
  useApiGet,
  useSearch,
  useSnackbar,
  useCounter,
  useArray,
  useWindowSize,
  useOutsideClick,
  useMemoPresent,
  newTableSort,
  formatShortStat,
  isBlank,
  filterBySearch,
  map,
  varClass,
  plural,
  inTimezone,
  navigateTo,
  copyToClipboard,
  downloadCsv,
  responseError,
} from '../../shared'
import { postRequest, deleteRequest, getCurrentUser } from '../../../services'

const isProduction = process.env.GATSBY_ENV === 'production'

const perPage = 10

const brokenLinkKey = (brokenLink): string =>
  [brokenLink.brokenLinkUrlPath, brokenLink.brokenLinkTargetUrlPath].join('::')

// Unused error codes:
// 'STUCK_IN_REDIRECT',
// 'UNAUTHORIZED',
// 'CLIENT_ERROR',
// 'SERVER_ERROR',
const isBroken = (brokenLink) =>
  [
    'PAGE_NOT_FOUND',
    'INTERNAL_SERVER_ERROR',
    'DESTINATION_MISSMATCH',
    'CAMPAIGN_IS_CLOSED',
  ].includes(brokenLink.brokenLinkErrorCode)
const isStock = (brokenLink) =>
  ['PRODUCT_IS_OUT_OF_STOCK'].includes(brokenLink.brokenLinkErrorCode)

const isFixed = (brokenLink): boolean => brokenLink.brokenLinkIsFixed
const isNotFixed = (brokenLink): boolean => !brokenLink.brokenLinkIsFixed

const isIgnored =
  (ignoredBrokenLinks) =>
  (brokenLink): boolean =>
    !!ignoredBrokenLinks.find(
      (ignore) =>
        ignore.ignoreUrlPath === brokenLink.brokenLinkUrlPath &&
        ignore.ignoreTargetUrl === brokenLink.brokenLinkTargetUrlPath
    )
const isNotIgnored =
  (ignoredBrokenLinks) =>
  (brokenLink): boolean =>
    !ignoredBrokenLinks.find(
      (ignore) =>
        ignore.ignoreUrlPath === brokenLink.brokenLinkUrlPath &&
        ignore.ignoreTargetUrl === brokenLink.brokenLinkTargetUrlPath
    )

const isFiltered =
  (filters) =>
  (brokenLink): boolean => {
    for (const filter of filters) {
      if (filter.value) {
        switch (filter.key) {
          case 'type':
            if (brokenLink.brokenLinkClickType !== filter.value) return false
            break
          case 'code':
            if (brokenLink.brokenLinkErrorCode !== filter.value) return false
            break
          case 'site':
            if (brokenLink.brokenLinkChannelUuid !== filter.value) return false
            break
        }
      }
    }
    return true
  }

const sortOptions = {
  'brokenLinkScore': 'Pr. value',
  'brokenLinkClickCount': 'Clicks',
  'brokenLinkStatusCode': 'Type',
}

const brokenLinkSort = newTableSort((a: any, b: any, key: string) => {
  if (key === 'brokenLinkScore') {
    return [a.brokenLinkClickCount || 0, b.brokenLinkClickCount || 0]
  }
})

const humanizeErrorCode = (
  brokenLink: string | { brokenLinkErrorCode: string }
) => {
  switch (
    typeof brokenLink === 'object' ? brokenLink.brokenLinkErrorCode : brokenLink
  ) {
    case 'PAGE_NOT_FOUND':
      return 'Page not found'
    case 'INTERNAL_SERVER_ERROR':
      return 'Internal server error'
    case 'DESTINATION_MISSMATCH':
      return 'Destination mismatch'
    case 'CAMPAIGN_IS_CLOSED':
      return 'Campaign is closed'
    case 'PRODUCT_IS_OUT_OF_STOCK':
      return 'Product is out of stock'
    default:
      return 'Broken link'
  }
}

const fullUrl = (url: string): string => {
  if (url.startsWith('http')) {
    return url
  } else {
    return 'https://' + url
  }
}

const splitUrl = (url: string): [string, string] => {
  if (!url) return ['', '']
  const normalizedUrl = url.replace(/https?:\/\//, '')
  const splitIndex = normalizedUrl.indexOf('/')
  if (splitIndex === -1) {
    return [normalizedUrl, '/']
  } else {
    return [normalizedUrl.slice(0, splitIndex), normalizedUrl.slice(splitIndex)]
  }
}

const compareUrls = (url1: string, url2: string): boolean =>
  url1
    .replace(/https?:\/\//, '')
    .replace(/\?.*/, '')
    .replace(/#.*/, '')
    .replace(/\/$/, '') ===
  url2
    .replace(/https?:\/\//, '')
    .replace(/\?.*/, '')
    .replace(/#.*/, '')
    .replace(/\/$/, '')

const csvColumns = {
  'brokenLinkUrlPath': 'Page URL',
  'brokenLinkTargetUrl': 'Link URL',
  'brokenLinkDestinationUrl': 'Destination URL',
  'brokenLinkStatusCode': 'Status code',
  'brokenLinkErrorCode': 'Error',
  'brokenLinkClickCount': 'Clicks',
}

const clickChartColors = {
  healthy: '#98EECB',
  broken: '#FF9596',
  stock: '#BBBDBF',

  links: '#FFB000',
  clicks: '#0CCFAF',
}

const channelChartColors = [
  '#456FCC',
  '#6A8CD6',
  '#8FA9E0',
  '#C7D4F0',
  '#ECF1FA',
]

export const BrokenLinks = (props) => {
  const [reloadRef, reload] = useCounter()
  const [reloading, setReloading] = useState(false)

  const { showSnackbar } = useSnackbar()

  const channels = useApiGet('/channels/v1/list')

  const loadData = useApiGet('/discovers/v2/brokenlinks/overview', {}, [
    reloadRef,
  ])
  const data = useMemoPresent(loadData) || loadData

  const {
    values: selectedItems,
    addAll: addSelectedItems,
    removeAll: removeSelectedItems,
    toggle: toggleSelectedItem,
    clear: clearSelectedItems,
  } = useArray<string>()

  const [fixedCounterAnimation, setFixedCounterAnimation] = useState(false)
  const [ignoreCounterAnimation, setIgnoreCounterAnimation] = useState(false)

  const fixLink = async (brokenLinkKeys: string[]) => {
    setReloading(true)

    const brokenLinks = data.brokenLinks
      .filter((item) => brokenLinkKeys.includes(brokenLinkKey(item)))
      .map((item) => ({
        brokenLinkChannelUuid: item.brokenLinkChannelUuid,
        brokenLinkUrlPath: item.brokenLinkUrlPath,
        brokenLinkTargetUrlPath: item.brokenLinkTargetUrlPath,
      }))
    const response = await postRequest('/discovers/v2/brokenlinks/fix', {
      brokenLinks,
    })

    if (response?.code !== 200) {
      showSnackbar(responseError(response))
    } else {
      setFixedCounterAnimation(true)
      setTimeout(() => {
        setFixedCounterAnimation(false)
      }, 3000)
    }

    showSnackbar(
      `${brokenLinkKeys.length} broken link ${plural(
        brokenLinkKeys.length,
        'card'
      )} marked as fixed`,
      {
        type: 'dark',
      }
    )
    removeSelectedItems(brokenLinkKeys)
    reload()
  }
  const ignoreLinks = async (brokenLinkKeys: string[]) => {
    setReloading(true)

    const brokenLinks = data.brokenLinks
      .filter((item) => brokenLinkKeys.includes(brokenLinkKey(item)))
      .map((item) => ({
        brokenLinkChannelUuid: item.brokenLinkChannelUuid,
        brokenLinkUrlPath: item.brokenLinkUrlPath,
        brokenLinkTargetUrl: item.brokenLinkTargetUrlPath,
      }))
    const response = await postRequest('/discovers/v2/brokenlinks/ignore', {
      brokenLinks,
    })

    if (response?.code !== 200) {
      showSnackbar(responseError(response))
    } else {
      setIgnoreCounterAnimation(true)
      setTimeout(() => {
        setIgnoreCounterAnimation(false)
      }, 3000)
    }

    showSnackbar(
      `${brokenLinkKeys.length} broken link ${plural(
        brokenLinkKeys.length,
        'card'
      )} marked as ignored`,
      {
        type: 'dark',
      }
    )
    removeSelectedItems(brokenLinkKeys)
    reload()
  }
  const unignoreLinks = async (brokenLinkKeys: string[]) => {
    setReloading(true)

    const ignoreUuids = data.brokenLinks
      .filter((item) => brokenLinkKeys.includes(brokenLinkKey(item)))
      .map(
        (brokenLink) =>
          data.ignoredBrokenLinks.find(
            (ignore) =>
              ignore.ignoreUrlPath === brokenLink.brokenLinkUrlPath &&
              ignore.ignoreTargetUrl === brokenLink.brokenLinkTargetUrlPath
          )?.ignoreUuid
      )
      .filter((value) => value)
    const response = await deleteRequest('/discovers/v2/brokenlinks/ignore', {
      ignoreUuids,
    })

    if (response?.code !== 200) {
      showSnackbar(responseError(response))
    } else {
      setIgnoreCounterAnimation(true)
      setTimeout(() => {
        setIgnoreCounterAnimation(false)
      }, 3000)
    }

    showSnackbar(
      `${brokenLinkKeys.length} broken link ${plural(
        brokenLinkKeys.length,
        'card'
      )} unmarked as ignored`,
      {
        type: 'dark',
      }
    )
    removeSelectedItems(brokenLinkKeys)
    reload()
  }

  useEffect(() => {
    setReloading(false)
  }, [data])

  const currentUser = getCurrentUser()

  const shouldShow =
    !isProduction ||
    currentUser?.user?.userBrokenLinksAccess ||
    window.location.search.includes('show=true')
  if (!shouldShow) {
    return (
      <>
        <Header label="Broken links" />
        <div className="card p-y-90 column column-center column-fill m-t-20">
          <SVG src="/images/discover/blank-channels.svg" />
          <div className="text-larger text-bolder text-dark m-t-50">
            Broken links, closed campaigns,
            <br />
            out-of-stock links. <span className="text-blue">Catch’em all!</span>
          </div>
          <div
            className="text-light text-big text-center m-t-4"
            style={{ maxWidth: 420 }}
          >
            Discover <b>where</b> you have broken links, and learn how to{' '}
            <b>fix</b> them. Stay tuned for the launch of the <br />
            "Broken Link Scanner".
          </div>
          <div className="btn btn-white-bordered m-t-8">Coming soon!</div>
        </div>
      </>
    )
  }

  return (
    <>
      <Header label="Broken links" />
      {channels && isBlank(channels) ? (
        <div className="card p-y-90 column column-center column-fill m-t-20">
          <SVG src="/images/discover/blank-channels.svg" />
          <div className="text-larger text-bolder text-dark m-t-40">
            Discover the broken links with Heylink!
          </div>
          <div className="text-light text-center m-t-8">
            Before we can identify your broken links, let's establish a
            connection by linking your sites together.
          </div>
          <button
            className="btn btn-primary m-t-20"
            onClick={(event) => {
              navigateTo(event, '/sites')
            }}
          >
            Manage your sites
          </button>
        </div>
      ) : (
        <Loadable data={data && channels} placeholder={<Placeholder />}>
          <Content
            {...{
              currentUser,
              data,
              channels,
              fixLink,
              ignoreLinks,
              unignoreLinks,
              ignoreCounterAnimation,
              reloading,
              selectedItems,
              addSelectedItems,
              removeSelectedItems,
              toggleSelectedItem,
              clearSelectedItems,
              showSnackbar,
            }}
          />
        </Loadable>
      )}
    </>
  )
}

const Content = (props) => {
  const {
    currentUser,
    data,
    channels,
    fixLink,
    ignoreLinks,
    unignoreLinks,
    ignoreCounterAnimation,
    reloading,
    selectedItems,
    addSelectedItems,
    removeSelectedItems,
    toggleSelectedItem,
    clearSelectedItems,
    showSnackbar,
  } = props

  const [mode, setMode] = useState('')
  const showFixed = mode === 'fixed'
  const showIgnored = mode === 'ignored'

  const [search, setSearch] = useSearch()
  const [filters, setFilters] = useState([
    { key: 'site', value: '' },
    { key: 'type', value: '' },
    { key: 'code', value: '' },
  ])
  const [sort, setSort] = useState('brokenLinkClickCount')

  const shownBrokenLinks = useMemo(() => {
    if (!data || !data.brokenLinks) return []

    const filteredBrokenLinks = data.brokenLinks
      .filter(showFixed ? isFixed : isNotFixed)
      .filter(
        showIgnored
          ? isIgnored(data.ignoredBrokenLinks)
          : isNotIgnored(data.ignoredBrokenLinks)
      )
      .filter(isFiltered(filters))
    const sortedBrokenLinks: any = brokenLinkSort(
      brokenLinkSort(filteredBrokenLinks, 'brokenLinkUrlPath', 'asc'),
      sort,
      sort === 'brokenLinkStatusCode' ? 'asc' : 'desc'
    )
    for (const brokenLink of sortedBrokenLinks) {
      brokenLink.brokenLinkDatetime = inTimezone(brokenLink.brokenLinkDatetime)
    }
    return filterBySearch(sortedBrokenLinks, search, (brokenLink: any) => [
      brokenLink.brokenLinkUrlPath,
      brokenLink.brokenLinkTargetUrlPath,
      brokenLink.brokenLinkDestinationUrl,
    ])
  }, [data, showFixed, showIgnored, filters, sort, search])

  return (
    <>
      {!isBlank(data) && (
        <div className="card p-y-6 p-x-30 m-b-20">
          <Overview data={data} channels={channels} />
        </div>
      )}
      <div className="card relative relative p-30">
        <Nav
          {...{
            search,
            setSearch,
            brokenLinks: shownBrokenLinks,
            fixedCount: data.brokenLinks?.filter(isFixed)?.length || 0,
            ignoredCount: data.ignoredBrokenLinks?.length || 0,
            showIgnored,
            showFixed,
            setMode,
            ignoreCounterAnimation,
            channels,
            filters,
            setFilters,
          }}
        />
        <Table
          {...{
            currentUser,
            data,
            brokenLinks: shownBrokenLinks,
            showIgnored,
            showFixed,
            fixLink,
            ignoreLinks,
            unignoreLinks,
            search,
            filters,
            sort,
            setSort,
            selectedItems,
            addSelectedItems,
            removeSelectedItems,
            toggleSelectedItem,
            clearSelectedItems,
            showSnackbar,
          }}
        />
        <div
          className={varClass({
            'discover-broken-reloading': true,
            'active': reloading,
          })}
        >
          <SmallLoadable loaded={!reloading} />
        </div>
      </div>
    </>
  )
}

const Overview = (props) => {
  const { data, channels } = props

  const [
    totalCount,
    scannedCount,
    healthyCount,
    brokenCount,
    stockCount,
    urlScannerLimit,
    urlScannerHours,
  ] = useMemo(() => {
    const totalCount =
      (data.totals.PAGE_NOT_FOUND || 0) +
      (data.totals.INTERNAL_SERVER_ERROR || 0) +
      (data.totals.DESTINATION_MISSMATCH || 0) +
      (data.totals.CAMPAIGN_IS_CLOSED || 0) +
      (data.totals.PRODUCT_IS_OUT_OF_STOCK || 0)

    const healthyCount = data.totals.HEALTHY || 0

    const scannedCount = totalCount + healthyCount

    const brokenCount =
      (data.totals.PAGE_NOT_FOUND || 0) +
      (data.totals.INTERNAL_SERVER_ERROR || 0) +
      (data.totals.DESTINATION_MISSMATCH || 0) +
      (data.totals.CAMPAIGN_IS_CLOSED || 0)

    const stockCount = data.totals.PRODUCT_IS_OUT_OF_STOCK || 0

    const urlScannerLimit = data.urlScanner.limit || 500
    const urlScannerHours = data.urlScanner.hours || 72

    return [
      totalCount,
      scannedCount,
      healthyCount,
      brokenCount,
      stockCount,
      urlScannerLimit,
      urlScannerHours,
    ]
  }, [data])

  return (
    <div className="row row-space-between row-fill row-nowrap">
      <div
        className="column column-space-between flex-nogrow flex-noshrink"
        style={{
          flex: 0.346,
        }}
      >
        <div>
          <div className="text-dark text-big text-bold m-b-20">
            Broken links
          </div>

          <div className="row row-center row-fill row-nowrap">
            <div>
              <div className="discover-broken-total">
                <span>{totalCount}</span>
              </div>
              <div className="text-light text-big text-bold">
                {Math.min(scannedCount, urlScannerLimit)}/{urlScannerLimit}{' '}
                links scanned
              </div>
            </div>
            <WithTooltip
              className="discover-broken-info"
              title={`Your plan includes ${urlScannerLimit} links. Each ${urlScannerHours} hours we scan ${urlScannerLimit} links with the most clicks from your sites.`}
              text={<SVG src="/images/recommendations/icon-info.svg" />}
              forceShow
            />
          </div>
        </div>
        <div className="row row-nowrap text-nowrap">
          <div>
            <span className="discover-broken-error-dot dot-healthy" />
            <span className="text-smaller text-bold">Healthy</span>
            <div className="text-large text-bolder text-dark m-t-15">
              {healthyCount}
            </div>
          </div>
          <div>
            <span className="discover-broken-error-dot dot-broken" />
            <span className="text-smaller text-bold">Broken links</span>
            <div className="text-large text-bolder text-dark m-t-15">
              {brokenCount}
            </div>
          </div>
          <div>
            <span className="discover-broken-error-dot dot-stock" />
            <span className="text-smaller text-bold">Out of stock</span>
            <div className="text-large text-bolder text-dark m-t-15">
              {stockCount}
            </div>
          </div>
        </div>
      </div>

      <div
        className="column column-space-between flex-nogrow flex-noshrink discover-broken-overview-borders"
        style={{
          flex: 0.385,
        }}
      >
        <div className="text-big text-bold m-b-0">
          Clicks on broken links <span className="text-light">7 days</span>
        </div>

        <ClicksChart data={data} />
        {/*
        <div className="discover-broken-group group-broken">
          <div className="title">
            Broken
            <WithTooltip
              className="group-info"
              title="Links that lead to an error page"
              text={<SVG src="/images/recommendations/icon-info.svg" />}
              forceShow
            />
          </div>
          <div className="value">{brokenCount}</div>
        </div>
        <div className="discover-broken-group group-redirect">
          <div className="title">
            Redirect
            <WithTooltip
              className="group-info"
              title="Links with incorrect destination"
              text={<SVG src="/images/recommendations/icon-info.svg" />}
              forceShow
            />
          </div>
          <div className="value">{redirectCount}</div>
        </div>
        <div className="discover-broken-group group-stock" r>
          <div className="title">
            Out of stock
            <WithTooltip
              className="group-info"
              title="Links that lead to out of stock products"
              text={<SVG src="/images/recommendations/icon-info.svg" />}
              forceShow
            />
          </div>
          <div className="value">{stockCount}</div>
        </div>
        */}
      </div>

      <div
        className="column column-space-between flex-nogrow flex-noshrink"
        style={{
          flex: 0.269,
        }}
      >
        <div className="text-big text-bold m-b-0">
          Broken clicks by site <span className="text-light">7 days</span>
        </div>

        <ChannelsChart data={data} channels={channels} />
      </div>
    </div>
  )
}

const ClicksChart = (props) => {
  const { data } = props

  const [showClicks, setShowClicks] = useState(true)
  const [showLinks, setShowLinks] = useState(true)

  const chartData = useMemo(() => {
    const result = []

    const { brokenByDate, stockByDate } = data.brokenLinks.reduce(
      (result, brokenLink) => {
        if (
          [
            'PAGE_NOT_FOUND',
            'INTERNAL_SERVER_ERROR',
            'DESTINATION_MISSMATCH',
            'CAMPAIGN_IS_CLOSED',
          ].includes(brokenLink.brokenLinkErrorCode)
        ) {
          for (const date in brokenLink.brokenLinkDatetimes) {
            result.brokenByDate[date] = result.brokenByDate[date] || 0
            result.brokenByDate[date] += brokenLink.brokenLinkDatetimes[date]
          }
        } else if (
          ['PRODUCT_IS_OUT_OF_STOCK'].includes(brokenLink.brokenLinkErrorCode)
        ) {
          for (const date in brokenLink.brokenLinkDatetimes) {
            result.stockByDate[date] = result.stockByDate[date] || 0
            result.stockByDate[date] += brokenLink.brokenLinkDatetimes[date]
          }
        }
        return result
      },
      { brokenByDate: {}, stockByDate: {} }
    )
    const healthyByDate = data.allClicks.groups.reduce((result, group) => {
      const date = group.grp
      result[date] = Math.max(
        group.clickCount - (brokenByDate[date] || 0) - (stockByDate[date] || 0),
        0
      )
      return result
    }, {})
    const linksByDate = data.brokenLinks.reduce((result, brokenLink) => {
      const dates = uniq(Object.keys(brokenLink.brokenLinkDatetimes))
      dates.forEach((date) => {
        result[date] = result[date] || 0
        result[date] += 1
      })
      return result
    }, {})

    for (let i = 0; i < 7; i++) {
      const filterDate = moment().subtract(i, 'days').format('YYYY-MM-DD')
      const name = moment().subtract(i, 'days').format('ddd')

      result.push({
        name,
        date: filterDate,
        links: linksByDate[filterDate] || 0,
        healthy: healthyByDate[filterDate] || 0,
        broken: brokenByDate[filterDate] || 0,
        stock: stockByDate[filterDate] || 0,
      })
    }

    return result.reverse()
  }, [data])

  return (
    <div className="discover-broken-clicks-chart">
      <div className="discover-broken-clicks-chart-control">
        <button
          className={varClass({
            'btn btn-xs text-smaller text-bold m-l-15': true,
            'disabled': !showLinks,
          })}
          style={{
            color: clickChartColors.links,
            background: clickChartColors.links + '1A',
          }}
          onClick={() => {
            const newShowLinks = !showLinks
            setShowLinks(newShowLinks)
            if (!newShowLinks && !showClicks) setShowClicks(true)
          }}
        >
          Broken links
        </button>
        <button
          className={varClass({
            'btn btn-xs text-smaller text-bold m-l-15': true,
            'disabled': !showClicks,
          })}
          style={{
            color: clickChartColors.clicks,
            background: clickChartColors.clicks + '1A',
          }}
          onClick={() => {
            const newShowClicks = !showClicks
            setShowClicks(newShowClicks)
            if (!newShowClicks && !showLinks) setShowLinks(true)
          }}
        >
          Clicks
        </button>
      </div>

      <ResponsiveContainer width="99.9%" height={150}>
        <ComposedChart
          data={chartData}
          margin={{ top: 0, right: -4, bottom: 0, left: -4 }}
        >
          <defs>
            <linearGradient id="stroke-links" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#FFB000" stopOpacity={0.5} />
              <stop offset="100%" stopColor="#FFB000" stopOpacity={1} />
            </linearGradient>
            <linearGradient id="fill-links" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#FFB000" stopOpacity={0.1} />
              <stop offset="100%" stopColor="#ffffff" stopOpacity={0.2} />
            </linearGradient>
          </defs>

          <CartesianGrid
            vertical={false}
            strokeDasharray="8 8"
            stroke="#EDEFF2"
          />

          <XAxis
            dataKey="name"
            interval={0}
            axisLine={false}
            tickLine={false}
            tick={(props) => (
              <g transform={`translate(${props.x},${props.y + 14})`}>
                <text x={0} y={0} textAnchor="middle" fill="#525E75">
                  {props.payload.value}
                </text>
              </g>
            )}
            {...({ includeHidden: true } as any)}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            interval="preserveStart"
            tick={(props) => (
              <g transform={`translate(${-1},${props.y + 4})`}>
                <text x={0} y={0} textAnchor="start" fill="#525E75">
                  {formatShortStat(props.payload.value)}
                </text>
              </g>
            )}
            hide
            {...({ includeHidden: true } as any)}
          />
          <YAxis dataKey="links" yAxisId="links-y" hide />

          {showClicks && (
            <>
              <Bar
                dataKey="healthy"
                stackId="bar"
                fill={clickChartColors.healthy}
                background={{ fill: '#F7F8FA', radius: [5, 5, 0, 0] }}
                maxBarSize={24}
                shape={(props) => (
                  <Rectangle
                    radius={!props.stock && !props.broken ? [3, 3, 0, 0] : null}
                    {...props}
                  />
                )}
              />
              <Bar
                dataKey="stock"
                stackId="bar"
                fill={clickChartColors.stock}
                maxBarSize={24}
                shape={(props) => (
                  <Rectangle
                    radius={!props.broken ? [3, 3, 0, 0] : null}
                    {...props}
                  />
                )}
                // shape={(props) => (
                //   <>
                //     {props.healthy > 0 ? (
                //       <Rectangle
                //         {...props}
                //         y={props.y - 2}
                //         radius={0}
                //         fill={clickChartColors.healthy}
                //       />
                //     ) : null}
                //     <Rectangle {...props} healthy/>
                //   </>
                // )}
              />
              <Bar
                dataKey="broken"
                stackId="bar"
                fill={clickChartColors.broken}
                maxBarSize={24}
                radius={[3, 3, 0, 0]}
                shape={(props) => <Rectangle {...props} />}
              />
            </>
          )}

          {showLinks && (
            <Area
              dataKey="links"
              yAxisId="links-y"
              type={curveBumpX}
              stroke="url(#stroke-links)"
              strokeWidth={2}
              fill="url(#fill-links)"
            />
          )}

          <ChartTooltip
            content={<ClicksChartTooltip />}
            cursor={{ fill: '#ffffff21' }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

const ClicksChartTooltip = (props) => {
  const { active, payload } = props

  if (!active || !payload || !payload[0]) {
    return null
  }

  const { date, links, healthy, broken, stock } = payload[0].payload

  if (!links && !healthy && !broken && !stock) {
    return null
  }

  return (
    <div className="chart-tooltip chart-tooltip-light">
      <div className="chart-tooltip-header">{date}</div>
      <div className="chart-tooltip-body">
        <ul>
          {!!links && (
            <li className="chart-tooltip-broken">
              <span>
                <span
                  className="chart-tooltip-dot"
                  style={{
                    background: clickChartColors.links,
                  }}
                />
                Broken links
              </span>{' '}
              <span>{links}</span>
            </li>
          )}
          {!!broken && (
            <li className="chart-tooltip-broken">
              <span>
                <span
                  className="chart-tooltip-dot"
                  style={{
                    background: clickChartColors.broken,
                  }}
                />
                Clicks on broken links
              </span>{' '}
              <span>{broken}</span>
            </li>
          )}
          {!!stock && (
            <li className="chart-tooltip-broken">
              <span>
                <span
                  className="chart-tooltip-dot"
                  style={{
                    background: clickChartColors.stock,
                  }}
                />
                Clicks on out of stock links
              </span>{' '}
              <span>{stock}</span>
            </li>
          )}
          {!!healthy && (
            <li className="chart-tooltip-broken">
              <span>
                <span
                  className="chart-tooltip-dot"
                  style={{
                    background: clickChartColors.healthy,
                  }}
                />
                Clicks on healthy links
              </span>{' '}
              <span>{healthy}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

const ChannelsChart = (props) => {
  const { data, channels } = props

  const [chartData, totalCount] = useMemo(() => {
    const channelClicks = {}
    const channelDiscovers = {}

    const fromDate = moment().subtract(7, 'days').format('YYYY-MM-DD 00:00:00')

    data.brokenLinks.forEach((brokenLink) => {
      const channelUuid = brokenLink.brokenLinkChannelUuid

      for (const date in brokenLink.brokenLinkDatetimes) {
        if (moment(date).isBefore(fromDate, 'day')) break

        if (brokenLink.brokenLinkClickType === 'click') {
          channelClicks[channelUuid] = channelClicks[channelUuid] || 0
          channelClicks[channelUuid] += 1
        } else if (brokenLink.brokenLinkClickType === 'discover') {
          channelDiscovers[channelUuid] = channelDiscovers[channelUuid] || 0
          channelDiscovers[channelUuid] += 1
        }
      }
    })

    let total = 0
    const channelUuids = uniq([
      ...Object.keys(channelClicks),
      ...Object.keys(channelDiscovers),
    ])
    const result = channelUuids.reduce((result, channelUuid) => {
      total +=
        (channelClicks[channelUuid] || 0) + (channelDiscovers[channelUuid] || 0)
      result.push({
        name:
          channels.find((channel) => channel.channelUuid === channelUuid)
            ?.channelName || 'Unknown',
        clicks: channelClicks[channelUuid] || 0,
        discovers: channelDiscovers[channelUuid] || 0,
      })
      return result
    }, [])

    return [sortBy(result, (item) => item.clicks + item.discovers), total]
  }, [data])

  return (
    <div className="discover-broken-channels-chart relative">
      <ResponsiveContainer width="99.9%" height={130}>
        <PieChart margin={{ left: -16 }}>
          <Pie
            data={chartData}
            dataKey="clicks"
            nameKey="name"
            fill="#E6E8EB"
            innerRadius={44}
            outerRadius={54}
            startAngle={90}
            endAngle={450}
          >
            {chartData.map((key, index) => (
              <Cell
                key={key}
                stroke={
                  channelChartColors[
                    Math.min(
                      Math.max(chartData.length - 1 - index, 0),
                      channelChartColors.length - 1
                    )
                  ]
                }
                fill={
                  channelChartColors[
                    Math.min(
                      Math.max(chartData.length - 1 - index, 0),
                      channelChartColors.length - 1
                    )
                  ]
                }
              />
            ))}
          </Pie>
          <Pie
            data={chartData}
            dataKey="discovers"
            nameKey="name"
            fill="#E6E8EB"
            innerRadius={44}
            outerRadius={54}
            startAngle={90}
            endAngle={450}
          >
            {chartData.map((key, index) => (
              <Cell
                key={key}
                stroke={
                  channelChartColors[
                    Math.min(
                      Math.max(chartData.length - 1 - index, 0),
                      channelChartColors.length - 1
                    )
                  ]
                }
                fill={
                  channelChartColors[
                    Math.min(
                      Math.max(chartData.length - 1 - index, 0),
                      channelChartColors.length - 1
                    )
                  ]
                }
              />
            ))}
          </Pie>
          <ChartTooltip
            content={<ChannelsChartTooltip />}
            cursor={{ fill: '#ffffff21' }}
          />
        </PieChart>
      </ResponsiveContainer>

      <div className="discover-broken-channels-total">
        <div>Total</div>
        <div>{totalCount}</div>
      </div>
    </div>
  )
}

const ChannelsChartTooltip = (props) => {
  const { active, payload } = props

  if (!active || !payload || !payload[0]) {
    return null
  }

  const { name, clicks, discovers } = payload[0].payload

  if (!name) {
    return null
  }
  if (!clicks && !discovers) {
    return null
  }

  const hasBoth = !!clicks && !!discovers

  return (
    <div className="chart-tooltip chart-tooltip-light">
      <div className="chart-tooltip-body">
        <ul>
          {clicks > 0 && (
            <li className="chart-tooltip">
              {hasBoth ? (
                <span>
                  {name}
                  <br />
                  Monetised
                </span>
              ) : (
                <span>{name}</span>
              )}
              <span>
                {clicks} {plural(clicks, 'click')}
              </span>
            </li>
          )}
          {discovers > 0 && (
            <li className="chart-tooltip">
              {hasBoth ? (
                <span>
                  {name}
                  <br />
                  Discover
                </span>
              ) : (
                <span>{name}</span>
              )}
              <span>
                {discovers} {plural(discovers, 'click')}
              </span>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

const Nav = (props) => {
  const {
    search,
    setSearch,
    brokenLinks,
    fixedCount,
    ignoredCount,
    showIgnored,
    showFixed,
    setMode,
    fixedCounterAnimation,
    ignoreCounterAnimation,
    channels,
    filters,
    setFilters,
  } = props

  const [shownSearch, setShownSearch] = useState(search)
  const searchRef = useRef<TTimeout>()
  useEffect(() => {
    if (searchRef.current) {
      clearTimeout(searchRef.current)
    }
    searchRef.current = setTimeout(() => {
      setSearch(shownSearch)
      searchRef.current = undefined
    }, 650)
  }, [shownSearch])

  return (
    <div>
      <div className="text-bold m-b-4">Discover broken links</div>
      <div className="table-info-container">
        <div className="table-info row row-center row-space-between row-wide m-b-4">
          <div className="flex-1">
            <Search
              value={shownSearch}
              setValue={setShownSearch}
              placeholder="Find broken link"
              className="search w-70"
              inputClassName="p-r-8"
              highlight={!!shownSearch}
            />
            <button
              className={varClass({
                'link link-new-lighter': true,
                'o-0 unclickable': !shownSearch,
                'o-100': shownSearch,
              })}
              style={{
                position: 'relative',
                top: 6,
                left: -32,
                paddingTop: 4,
                marginTop: -4,
              }}
              onClick={() => {
                setShownSearch('')
                setSearch('')
              }}
            >
              <SVG src="/images/icon-times.svg" />
            </button>
            {/*
            <button
              className={varClass({
                'btn text-bold m-l-2': true,
                'border-new-blue text-new-blue': shownSearch && !search,
                'border-blue text-blue': search,
              })}
              onClick={() => {
                setSearch(shownSearch)
              }}
            >
              Search
            </button>
            */}
          </div>
          <div className="vertical-middle">
            <button
              className={varClass({
                'btn action m-l-15': true,
                'p-l-4': !fixedCount,
                'btn-primary text-white border-blue b-1': showFixed,
              })}
              onClick={() => {
                if (showFixed) {
                  setMode('')
                } else {
                  setMode('fixed')
                }
              }}
            >
              {(fixedCount > 0 || fixedCounterAnimation) && (
                <span
                  className={varClass({
                    'discover-broken-ignore-count': true,
                    'active': showFixed,
                    'animated': fixedCounterAnimation,
                  })}
                >
                  {fixedCount}
                </span>
              )}
              Fixed links
            </button>
            <button
              className={varClass({
                'btn action m-l-15': true,
                'p-l-4': !ignoredCount,
                'btn-primary text-white border-blue b-1': showIgnored,
              })}
              onClick={() => {
                if (showIgnored) {
                  setMode('')
                } else {
                  setMode('ignored')
                }
              }}
            >
              {(ignoredCount > 0 || ignoreCounterAnimation) && (
                <span
                  className={varClass({
                    'discover-broken-ignore-count': true,
                    'active': showIgnored,
                    'animated': ignoreCounterAnimation,
                  })}
                >
                  {ignoredCount}
                </span>
              )}
              Ignored links
            </button>
            <button
              className="link action m-l-15"
              onClick={() => {
                downloadCsv<any>({
                  filename: `broken-links_${moment().format('YYYY-MM-DD')}.csv`,
                  columns: Object.keys(csvColumns),
                  rows: brokenLinks,
                  columnFormatter: (column) => csvColumns[column],
                  rowFormatter: (row) => ({
                    ...row,
                    brokenLinkErrorCode: humanizeErrorCode(row),
                  }),
                })
              }}
            >
              <SVG src="/images/insights/icon-csv.svg" className="m-r-10" />
              Export CSV
            </button>
          </div>
        </div>
      </div>
      <div className="discover-broken-filter page-nav m-b-7">
        <NavFilter
          {...{
            filters,
            setFilters,
            filterLabels: {
              'type': 'Link type',
              'code': 'Error status',
              'site': 'Site',
            },
            filterOptions: {
              'type': {
                '': 'All',
                'click': 'Monetised',
                'discover': 'Discover',
              },
              'code': {
                '': 'All',
                'PAGE_NOT_FOUND': humanizeErrorCode('PAGE_NOT_FOUND'),
                'INTERNAL_SERVER_ERROR': humanizeErrorCode(
                  'INTERNAL_SERVER_ERROR'
                ),
                // 'DESTINATION_MISSMATCH': humanizeErrorCode(
                //   'DESTINATION_MISSMATCH'
                // ),
                'CAMPAIGN_IS_CLOSED': humanizeErrorCode('CAMPAIGN_IS_CLOSED'),
                'PRODUCT_IS_OUT_OF_STOCK': humanizeErrorCode(
                  'PRODUCT_IS_OUT_OF_STOCK'
                ),
              },
              'site': channels.reduce(
                (result, channel) => {
                  result[channel.channelUuid] = channel.channelName
                  return result
                },
                { '': 'All' }
              ),
            },
            filtersToKeep: ['type', 'code', 'site'],
          }}
          hideAdd
          hideNoValue
          hideRemove={['type', 'code']}
          hideRemoveOnlyWithValue
          radio
        />
        {filters.filter((filter) => filter.value).length > 0 && (
          <button
            className="btn btn-md m-r-2"
            onClick={() => {
              const newFilters = []
              for (const filter of filters) {
                newFilters.push({
                  key: filter.key,
                  value: '',
                })
              }
              setFilters(newFilters)
            }}
          >
            Clear
          </button>
        )}
      </div>
    </div>
  )
}

const Table = (props) => {
  const {
    currentUser,
    data,
    brokenLinks,
    showIgnored,
    showFixed,
    fixLink,
    ignoreLinks,
    unignoreLinks,
    search,
    filters,
    sort,
    setSort,
    selectedItems,
    addSelectedItems,
    removeSelectedItems,
    toggleSelectedItem,
    clearSelectedItems,
    showSnackbar,
  } = props

  const [tooltipRefresh, refreshTooltip] = useCounter()

  const [page, setPage] = useState(1)
  const maxPage = Math.ceil(brokenLinks.length / perPage)

  useEffect(() => {
    clearSelectedItems()
  }, [showIgnored, showFixed])

  useEffect(() => {
    setPage(1)
  }, [data, sort, search, filters, showIgnored, showFixed])

  const shownBrokenLinks = brokenLinks.slice(
    (page - 1) * perPage,
    (page - 1) * perPage + perPage
  )

  const isSelectedAll =
    brokenLinks.length > 0 &&
    brokenLinks.every((item) => selectedItems.includes(brokenLinkKey(item)))

  const [detailsWidth, setDetailsWidth] = useState(9999)
  const windowSize = useWindowSize()

  const moreCount = data.moreBrokenLinksCount || 0

  const itemProps = {
    selectedItems,
    toggleSelectedItem,
    detailsWidth,
    setDetailsWidth,
    windowSize,
    isIgnored: showIgnored,
    isFixed: showFixed,
    fixLink,
    ignoreLinks,
    unignoreLinks,
    refreshTooltip,
    showSnackbar,
  }

  const anyFilters = filters.filter((filter) => filter.value).length > 0

  if (
    !showIgnored &&
    !showFixed &&
    !search &&
    !anyFilters &&
    !brokenLinks.length &&
    moreCount <= 0
  ) {
    return (
      <div className="column column-center column-fill text-center m-b-30">
        <SVG src="/images/illustration-hooray.svg" />
        <div className="text-larger text-bolder text-dark m-t-40">
          Fantastic work fixing those revenue-damaging broken links!
        </div>
        <div className="text-light m-t-8" style={{ maxWidth: '60%' }}>
          We're actively searching for any remaining broken links, and you'll be
          notified through the side menu as soon as the first one is found. Keep
          up the fantastic work!
        </div>
      </div>
    )
  }

  return (
    <div className="m-t-10 hacc">
      <div className="row row-center row-space-between">
        <div
          className={varClass({
            'p-l-3': true,
            'o-0 unclickable': showFixed,
          })}
        >
          <div
            className="control control-checkbox p-t-1"
            style={{ width: 20, height: 20 }}
          >
            <input
              type="checkbox"
              checked={isSelectedAll}
              onChange={() => {
                if (isSelectedAll) {
                  clearSelectedItems()
                } else {
                  addSelectedItems(brokenLinks.map(brokenLinkKey))
                }
              }}
            />
            <label className="p-0 m-0" />
          </div>
          <SelectAllDropdown
            {...{
              brokenLinks,
              selectedItems,
              addSelectedItems,
              removeSelectedItems,
              clearSelectedItems,
            }}
          />
          <button
            className={varClass({
              'link link-opacity text-bold p-x-0 m-b--1': true,
              'unclickable o-0': !selectedItems.length,
              'o-100': selectedItems.length,
            })}
            onClick={() => {
              if (showIgnored) {
                unignoreLinks(selectedItems)
              } else {
                ignoreLinks(selectedItems)
              }
            }}
            style={{
              position: 'relative',
              top: -4,
            }}
          >
            {showIgnored ? 'Unignore selected' : 'Ignore selected'}{' '}
            <span className="discover-broken-ignore-count m-l-1">
              {selectedItems.length}
            </span>
          </button>
        </div>

        <TableSort {...{ sort, setSort, sortOptions }} />
      </div>

      <div className="table-container-overflow- m-b--50">
        <table className="table table-block-rows discover-broken-table m-b-50">
          <thead className="table-sticky">
            <tr>
              <th className="w-0 p-0" />
              <th className="text-nowrap vertical-middle p-l-3">
                Found on page
                <WithTooltip
                  className="discover-broken-info top-0"
                  title="The page on your Site where the link was found."
                  text={<SVG src="/images/recommendations/icon-info.svg" />}
                  forceShow
                  tooltipId="discover-broken-error-tooltip"
                />
              </th>
              <th className="text-nowrap vertical-middle">
                Link scanned
                <WithTooltip
                  className="discover-broken-info top-0"
                  title="The link that was scanned."
                  text={<SVG src="/images/recommendations/icon-info.svg" />}
                  forceShow
                  tooltipId="discover-broken-error-tooltip"
                />
              </th>
              <th className="text-center text-nowrap vertical-middle">
                Error message
                <WithTooltip
                  className="discover-broken-info top-0"
                  title="The type of error that was found on the scanned link."
                  text={<SVG src="/images/recommendations/icon-info.svg" />}
                  forceShow
                  tooltipId="discover-broken-error-tooltip"
                />
              </th>
              <th className="text-right text-nowrap vertical-middle">
                Clicks
                <WithTooltip
                  className="discover-broken-info top-0"
                  title="The number of clicks you have to the scanned link in last 30 days."
                  text={<SVG src="/images/recommendations/icon-info.svg" />}
                  forceShow
                  tooltipId="discover-broken-error-tooltip"
                />
              </th>
              {!showIgnored ? (
                <th className="text-center text-nowrap vertical-middle">
                  Projected value
                  <WithTooltip
                    className="discover-broken-info top-0"
                    title="The value of fixing the link, based on the performance of similar traffic and conversions."
                    text={<SVG src="/images/recommendations/icon-info.svg" />}
                    forceShow
                    tooltipId="discover-broken-error-tooltip"
                  />
                </th>
              ) : (
                <th className="text-center text-nowrap vertical-middle">
                  <span className="inline-block o-0 p-r-5">
                    Projected value
                  </span>
                </th>
              )}
              <th className="w-0" />
            </tr>
          </thead>

          <tbody className="text-nowrap">
            {map(shownBrokenLinks, (item, index) => (
              <TableItem key={brokenLinkKey(item)} item={item} {...itemProps} />
            ))}

            {(!!search || !!anyFilters) && !shownBrokenLinks.length && (
              <tr>
                <td colSpan={10} className="text-center border-none">
                  <div className="text-wrap w-60 m-x-auto">
                    <div className="text-light text-bolder m-b-4">
                      No Results Found for Category.
                    </div>
                    <div className="text-light">
                      We couldn't find any results for the chosen category. Try
                      selecting a different category or adjusting other filters
                      to discover what you're looking for.
                    </div>
                  </div>
                </td>
              </tr>
            )}

            {!search && !anyFilters && showIgnored && !brokenLinks.length && (
              <tr>
                <td colSpan={10} className="text-center border-none">
                  <div className="text-wrap w-60 m-x-auto">
                    <div className="text-light">
                      Well done, {currentUser?.user?.userFirstname}, you have 0
                      ignored links so far!
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {moreCount > 0 && (
          <>
            <div className="discover-broken-more">
              <div className="row row-center row-middle">
                <div className="text-big text-bolder">
                  You have got {moreCount} more broken links!
                </div>
                <a href="/settings/billing" className="btn btn-primary btn-lg">
                  Upgrade to view them all!
                </a>
              </div>
            </div>

            {shownBrokenLinks.length > 0 ? (
              <div className="discover-broken-table-fake">
                <table className="table table-block-rows discover-broken-table m-b-50">
                  <tbody className="text-nowrap">
                    {map(
                      Math.max(1, perPage - shownBrokenLinks.length),
                      (i) => (
                        <TableItem
                          key={`fake_item_${i}`}
                          item={shownBrokenLinks[shownBrokenLinks.length - 1]}
                          {...itemProps}
                        />
                      )
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="m-y-50" />
            )}
          </>
        )}

        {maxPage > 1 && (
          <div className="row row-middle m-t--40 m-b-40">
            <Pagination {...{ page, setPage, maxPage }} />
          </div>
        )}

        <Tooltip
          id="discover-broken-error-tooltip"
          type="dark"
          place="top"
          rebuild={[brokenLinks, tooltipRefresh]}
        />

        <Tooltip
          id="discover-broken-fix-tooltip"
          place="bottom"
          getContent={() => (
            <>
              When link is fixed, choose "I fixed it."
              <br />
              Link returns if Broken Link Scanner finds new clicks on it.
            </>
          )}
          textColor="#1F2A37"
          backgroundColor="#EBF5FF"
          rebuild={[brokenLinks, tooltipRefresh]}
        />
      </div>
    </div>
  )
}

const TableItem = (props) => {
  const {
    item,
    selectedItems,
    toggleSelectedItem,
    detailsWidth,
    setDetailsWidth,
    windowSize,
    isFixed,
    isIgnored,
    fixLink,
    ignoreLinks,
    unignoreLinks,
    refreshTooltip,
    showSnackbar,
  } = props

  const itemKey = brokenLinkKey(item)
  const isSelected = selectedItems.includes(itemKey) || false

  const rowRef = useRef<HTMLTableRowElement>()

  useEffect(() => {
    if (rowRef.current) {
      const rowRect = rowRef.current?.getBoundingClientRect()
      if (detailsWidth !== rowRect.width) {
        setDetailsWidth(rowRect.width)
      }
    }
  }, [rowRef, windowSize])

  const [open, setOpen] = useState(false)

  useEffect(() => {
    refreshTooltip()
  }, [open])

  const pageUrl = item.brokenLinkUrlPath
  const linkUrl = item.brokenLinkNetworkUrl || item.brokenLinkTargetUrl
  const targetUrl = item.brokenLinkTargetUrl || item.brokenLinkNetworkUrl
  const destinationUrl =
    item.brokenLinkDestinationUrl || item.brokenLinkTargetUrlPath
  const showOpenDestinationUrl = !compareUrls(targetUrl, destinationUrl)

  const [originDomain, originPath] = splitUrl(pageUrl)
  // const [linkDomain, linkPath] = splitUrl(linkUrl)
  const [destinationDomain, destinationPath] = splitUrl(destinationUrl)

  const itemType = isBroken(item)
    ? 'broken'
    : isStock(item)
    ? 'stock'
    : 'broken'

  return (
    <>
      <tr
        className={varClass({
          'discover-broken-table-row cursor-pointer': true,
          'discover-broken-table-with-details': open,
          'background-pale': isSelected,
        })}
        onClick={(event: any) => {
          if (!event.defaultPrevented && !event.myDefaultPrevented) {
            setOpen(!open)
          }
        }}
        ref={rowRef}
      >
        <td className="relative p-l-3 p-r-0 p-y-3">
          <div className="row-hover-shadow" />
          <div
            className={varClass({
              'control control-checkbox p-t-1': true,
              'o-0 unclickable': isFixed,
            })}
            style={{ width: 20, height: 20 }}
          >
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => {
                toggleSelectedItem(itemKey)
              }}
              onClick={(event: any) => {
                event.myDefaultPrevented = true
              }}
            />
            <label className="p-0 m-0" />
          </div>
        </td>
        <td className="relative p-l-3 p-y-3">
          <div className="text-bolder m-b-1">{originDomain}</div>
          <a
            className="link link-new-another-blue discover-broken-table-path"
            onClick={(event: any) => {
              event.myDefaultPrevented = true
            }}
            href={fullUrl(originDomain + originPath)}
            target="_blank"
          >
            <span>{originPath}</span>
            <SVG src="/images/icon-outside-new.svg" height={11} />
          </a>
        </td>
        <td className="relative p-y-3">
          <div className="text-bolder m-b-1">{destinationDomain}</div>
          <a
            className="link link-new-another-blue discover-broken-table-path"
            onClick={(event: any) => {
              event.myDefaultPrevented = true
            }}
            href={fullUrl(destinationDomain + destinationPath)}
            target="_blank"
          >
            <span>{destinationPath}</span>
            <SVG src="/images/icon-outside-new.svg" height={11} />
          </a>
        </td>
        <td className="text-center text-small text-bold text-nowrap vertical-middle p-y-3">
          {humanizeErrorCode(item.brokenLinkErrorCode)}
          <WithTooltip
            className="discover-broken-info"
            title={`Status code: ${item.brokenLinkStatusCode}`}
            text={<SVG src="/images/recommendations/icon-info.svg" />}
            forceShow
            tooltipId="discover-broken-error-tooltip"
          />
        </td>
        <td className="text-right text-small text-bolder p-y-3">
          {item.brokenLinkClickCount}
        </td>
        {!isIgnored ? (
          <td className="text-center p-y-3">
            <ValueIndicator
              score={item.score}
              tooltipId="discover-broken-error-tooltip"
            />
          </td>
        ) : (
          <td className="text-center text-light text-bold o-60 p-y-3">
            Ignored
          </td>
        )}
        <td className="relative p-y-3 p-r-4 text-right">
          <TableItemDropdown
            {...{
              item,
              open,
              setOpen,
              isFixed,
              isIgnored,
              ignoreLinks,
              unignoreLinks,
            }}
          />
        </td>
      </tr>

      {open && (
        <tr
          className={varClass({
            'discover-broken-table-details': true,
            [`discover-broken-table-details-${itemType}`]: itemType,
            'background-pale': isSelected,
          })}
        >
          <td colSpan={7} className="p-t-0">
            <div
              className="discover-broken-table-details-content"
              style={{ width: detailsWidth - 50 }}
            >
              <div
                className={varClass({
                  'p-l-40': true,
                  'discover-broken-detail-arrow': showOpenDestinationUrl,
                })}
              >
                <div className="row row-center row-space-between row-nowrap m-l--40">
                  <div className="vertical-middle">
                    <SVG
                      src="/images/discover/icon-broken-from.svg"
                      className="m-r-4"
                    />
                    <span>We found link with error</span>
                  </div>
                  <div className="text-small text-bold text-light vertical-middle">
                    <span className="m-r-2">Last click</span>
                    {item.brokenLinkDatetime}
                    <WithTooltip
                      className="inline-block m-l-2"
                      title={'Last click date'}
                      text={<SVG src="/images/recommendations/icon-info.svg" />}
                      forceShow
                      tooltipId="discover-broken-error-tooltip"
                    />
                  </div>
                </div>
                <div className="background-body border-rounder row row-space-between p-10 m-t-3">
                  <div className="flex-1 text-new-dark-red text-bold text-wrap">
                    {fullUrl(targetUrl)}
                  </div>
                  <div className="row row-center row-narrow">
                    <WithTooltip
                      className="inline text-nolineheight"
                      title="Copy"
                      text={
                        <button
                          className="link link-light-to-blue text-nolineheight p-0"
                          onClick={(event) => {
                            ReactTooltip.hide()
                            copyToClipboard(fullUrl(targetUrl))
                            showSnackbar('Link copied', {
                              type: 'inplace',
                              time: 2000,
                              element: event.target,
                            })
                          }}
                        >
                          <SVG src="/images/icon-copy-new.svg" />
                        </button>
                      }
                      forceShow
                      tooltipId="discover-broken-error-tooltip"
                    />
                    <WithTooltip
                      className="inline text-nolineheight"
                      title="View link"
                      text={
                        <a
                          href={fullUrl(targetUrl)}
                          target="_blank"
                          className="link link-light-to-blue text-nolineheight p-0"
                        >
                          <SVG src="/images/icon-outside-new.svg" />
                        </a>
                      }
                      forceShow
                      tooltipId="discover-broken-error-tooltip"
                    />
                  </div>
                </div>
                {showOpenDestinationUrl && (
                  <div className="m-t-6">That leads to</div>
                )}
              </div>
              {showOpenDestinationUrl && (
                <div className="p-l-40 m-t-3">
                  <div className="background-body border-rounder inline-row row-space-between p-10">
                    <div className="flex-1 text-new-another-blue text-bold text-wrap">
                      {fullUrl(destinationUrl)}
                    </div>
                    <div className="row row-center row-narrow">
                      <WithTooltip
                        className="inline text-nolineheight"
                        title="Copy"
                        text={
                          <button
                            className="link link-light-to-blue text-nolineheight p-0"
                            onClick={() => {
                              ReactTooltip.hide()
                              copyToClipboard(fullUrl(destinationUrl))
                              showSnackbar('Link copied', {
                                type: 'inplace',
                                time: 2000,
                                element: event.target,
                              })
                            }}
                          >
                            <SVG src="/images/icon-copy-new.svg" />
                          </button>
                        }
                        forceShow
                        tooltipId="discover-broken-error-tooltip"
                      />
                      <WithTooltip
                        className="inline text-nolineheight"
                        title="View link"
                        text={
                          <a
                            href={fullUrl(destinationUrl)}
                            target="_blank"
                            className="link link-light-to-blue text-nolineheight p-0"
                          >
                            <SVG src="/images/icon-outside-new.svg" />
                          </a>
                        }
                        forceShow
                        tooltipId="discover-broken-error-tooltip"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="discover-broken-icon-fix m-r-4">
                <SVG src="/images/discover/icon-broken-fix.svg" />
              </div>
              <div className="p-l-40 m-t-20">
                <div className="flex-1 background-body border-rounder relative p-10">
                  <div className="m-b-2">Error type</div>
                  <div className="m-b-20">
                    <span className="text-big text-bold">
                      {item.brokenLinkStatusCode}
                    </span>
                    {' • '}
                    <span className="text-big">
                      {humanizeErrorCode(item.brokenLinkErrorCode)}
                    </span>
                  </div>
                  <div className="text-bolder m-b-2">
                    How to fix this error?
                  </div>
                  <div className="text-wrap">
                    <TableItemFix item={item} />
                  </div>
                  {!isFixed && (
                    <WithTooltip
                      className="discover-broken-fix-checkbox"
                      title="I fixed it"
                      text={
                        <div className="control control-checkbox control-checkbox-reverse">
                          <input
                            type="checkbox"
                            checked={isFixed}
                            onChange={() => {
                              if (!isFixed) {
                                fixLink([brokenLinkKey(item)])
                              }
                            }}
                          />
                          <label>I fixed it</label>
                        </div>
                      }
                      forceShow
                      tooltipId="discover-broken-fix-tooltip"
                    />
                  )}
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  )
}

const TableItemFix = (props) => {
  const { item } = props

  switch (item.brokenLinkErrorCode) {
    case 'PAGE_NOT_FOUND':
      return (
        <div>
          A 404 error means that the links is pointing at a page that no longer
          exists. To fix this error you need to either remove the link or
          replace it with a new link that points to a working page.
        </div>
      )
    case 'INTERNAL_SERVER_ERROR':
      return (
        <div>
          A 500 error means that the page or website you are linking to is down.
          It might be a temporary error. To fix this error you need to either
          remove the link or replace it with a new link that points to a working
          page or website.
        </div>
      )
    case 'DESTINATION_MISSMATCH':
      return (
        <>
          <div>
            A destination mismatch is an error that happens when the intended
            target of the link is redirected to another destination. This could
            be an expired affiliate link that is redirected by the affiliate
            network, or a link to a website that redirects to a different
            domain.
          </div>
          <div>
            Check the link and the destination to see if something is wrong. You
            might need to replace the link.
          </div>
        </>
      )
    case 'CAMPAIGN_IS_CLOSED':
      return (
        <div>
          Your affiliate link is pointing to an expired campaign from an
          affiliate network and needs to be replaced. Consider using a clean
          link and use Heylinks auto-linking feature to avoid this in the future
          - or replace the affiliate link with a new link to a working campaign.
        </div>
      )
    case 'PRODUCT_IS_OUT_OF_STOCK':
      return (
        <>
          <div>
            The link is pointing to a product page where the product is out of
            stock or not available anymore.
          </div>
          <div>
            It might be a good idea to find the product at another Merchant and
            link to their website instead.
          </div>
        </>
      )
    default:
      return <div>Unrecognized error code</div>
  }
}

const TableItemDropdown = (props) => {
  const {
    item,
    open: detailsShown,
    setOpen: setDetailsShown,
    isFixed,
    isIgnored,
    ignoreLinks,
    unignoreLinks,
  } = props

  const [open, setOpen] = useState(false)

  const ref = useRef()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  return (
    <div
      className={varClass({
        'discover-broken-dropdown-container': true,
        'open': open,
      })}
      ref={ref}
      onClick={(event: any) => {
        event.myDefaultPrevented = true
      }}
    >
      <button
        className="discover-broken-dropdown-btn hover-dots link link-primary"
        onClick={() => {
          setOpen(!open)
        }}
      >
        <SVG src="/images/icon-menu-color.svg" />
      </button>

      <div className="discover-broken-dropdown item-dropdown text-left">
        {!isFixed && (
          <>
            {isIgnored ? (
              <div
                className="item"
                onClick={() => {
                  unignoreLinks([brokenLinkKey(item)])
                  setOpen(false)
                }}
              >
                Unignore broken link
              </div>
            ) : (
              <div
                className="item"
                onClick={() => {
                  ignoreLinks([brokenLinkKey(item)])
                  setOpen(false)
                }}
              >
                Ignore broken link
              </div>
            )}
          </>
        )}
        {detailsShown ? (
          <div
            className="item"
            onClick={() => {
              setDetailsShown(false)
              setOpen(false)
            }}
          >
            Hide details
          </div>
        ) : (
          <div
            className="item"
            onClick={() => {
              setDetailsShown(true)
              setOpen(false)
            }}
          >
            See details
          </div>
        )}
      </div>
    </div>
  )
}

const SelectAllDropdown = (props) => {
  const {
    brokenLinks,
    selectedItems,
    addSelectedItems,
    removeSelectedItems,
    clearSelectedItems,
  } = props

  const [open, setOpen] = useState(false)

  const ref = useRef()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  const anyBroken = brokenLinks.filter(isBroken).length > 0
  const anyStock = brokenLinks.filter(isStock).length > 0

  const isSelectedAll =
    brokenLinks.length > 0 &&
    selectedItems.length > 0 &&
    brokenLinks.every((item) => selectedItems.includes(brokenLinkKey(item)))

  const isSelectedBroken =
    brokenLinks.length > 0 &&
    selectedItems.length > 0 &&
    anyBroken &&
    brokenLinks.every(
      (item) => !isBroken(item) || selectedItems.includes(brokenLinkKey(item))
    )
  const isSelectedStock =
    brokenLinks.length > 0 &&
    selectedItems.length > 0 &&
    anyStock &&
    brokenLinks.every(
      (item) => !isStock(item) || selectedItems.includes(brokenLinkKey(item))
    )

  return (
    <div
      className={varClass({
        'discover-broken-dropdown-container': true,
        'open': open,
      })}
      ref={ref}
    >
      <button
        className="discover-broken-dropdown-btn link link-primary"
        onClick={() => {
          setOpen(!open)
        }}
      >
        <SVG src="/images/chevron-down.svg" />
      </button>

      <div className="discover-broken-dropdown">
        <div
          className={varClass({
            'item': true,
            'active': isSelectedAll,
          })}
          onClick={() => {
            if (isSelectedAll) {
              clearSelectedItems()
            } else {
              addSelectedItems(brokenLinks.map(brokenLinkKey))
            }
          }}
        >
          All
        </div>
        <div
          className={varClass({
            'item': true,
            'active': isSelectedBroken,
          })}
          onClick={() => {
            if (isSelectedBroken) {
              removeSelectedItems(
                brokenLinks.filter(isBroken).map(brokenLinkKey)
              )
            } else {
              addSelectedItems(brokenLinks.filter(isBroken).map(brokenLinkKey))
            }
          }}
        >
          Broken links
        </div>
        <div
          className={varClass({
            'item': true,
            'active': isSelectedStock,
          })}
          onClick={() => {
            if (isSelectedStock) {
              removeSelectedItems(
                brokenLinks.filter(isStock).map(brokenLinkKey)
              )
            } else {
              addSelectedItems(brokenLinks.filter(isStock).map(brokenLinkKey))
            }
          }}
        >
          Out of stock links
        </div>
      </div>
    </div>
  )
}
