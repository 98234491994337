import React, { useState, useEffect, useMemo, useRef } from 'react'
import SVG from 'react-inlinesvg'
import moment from 'moment'
import { toUnicode } from 'punycode'
import { uniqBy, sortBy, groupBy } from 'lodash'
import { HistoryAreaChart } from './AreaChart'
import {
  TTimeout,
  Loadable,
  SmallLoadable,
  ConnectNetworkModal,
  Search,
  Logo,
  Header,
  Commission,
  Tooltip,
  WithTooltip,
  useApiGet,
  useApiPost,
  useOutsideClick,
  useSortableTable,
  useCounter,
  useModal,
  useSnackbar,
  formatCommission,
  formatStat,
  formatCurrencyReversed,
  calculateValues,
  navigateTo,
  navigateReload,
  newTableSort,
  plural,
  map,
  inTimezone,
  isBlank,
  toArray,
  varClass,
} from '../shared'
import { putRequest } from '../../services/api'

const graphColors = [
  '#0C74DC',
  '#33CEC5',
  '#3FAC73',
  '#DA4554',
  '#E06D02',
  '#A2E989',
  '#8552EA',
  '#EBB517',

  '#4F9EEC',
  '#9765FB',
  '#F6DE97',
]

const searchCampaign = (campaign: any, search: string): boolean => {
  return (
    (campaign.campaign.campaignName || '')
      .toLowerCase()
      .includes(search.toLowerCase()) ||
    (campaign.campaign.campaignUrl || '')
      .toLowerCase()
      .includes(search.toLowerCase()) ||
    (campaign.network.networkName || '')
      .toLowerCase()
      .includes(search.toLowerCase())
  )
}

const findEventCampaign = (collection: any, event: any) =>
  collection.find(
    (item) =>
      item.campaignUuid === event.campaign.campaignUuid ||
      item.campaign?.campaignUuid === event.campaign.campaignUuid
  )

const filterLabels = {
  'channelUuids': 'Site',
  'networkUuids': 'Network',
}

const campaignFilter = (collection: any, filters) =>
  collection.filter((item) => {
    for (const filter of filters) {
      if (filter.value) {
        switch (filter.key) {
          case 'channelUuids':
            if (
              !item.channels.find((channel) =>
                filter.value.split(', ').includes(channel.channelUuid)
              )
            )
              return false
            break
          case 'networkUuid':
            if (!filter.value.split(', ').includes(item.network.networkUuid))
              return false
            break
          default:
            if (
              !filter.value
                .split(', ')
                .includes(item[filter.key.replace('Uuids', 'Uuid')])
            )
              return false
            break
        }
      }
    }
    return true
  })

const campaignSort = newTableSort((a: any, b: any, key: string) => {
  if (key === 'networkName') {
    return [a.network.networkName, b.network.networkName]
  }
  if (key === 'campaignCommission') {
    return [
      a.commissions.commissionFlat || a.commissions.commissionPercentage,
      b.commissions.commissionFlat || b.commissions.commissionPercentage,
    ]
  }
})

const trafficSort = newTableSort()

export const Advertiser = (props) => {
  const { advertiserUuid } = props

  const [filters, setFilters] = useState([
    {
      key: 'channelUuid',
      value: '',
    },
    {
      key: 'networkUuid',
      value: '',
    },
  ])
  const filterReset = filters
    .filter((filter) => filter.value)
    .map((filter) => filter.value)
    .join(',')

  const channels = useApiGet('/channels/v1/list')
  const networks = useApiGet('/networks/v1/list')
  const networkDetails = useApiGet('/networks/v1/networks')

  const data = useApiGet(`/advs/v1/advs/${advertiserUuid}`)
  const campaigns = useApiPost<any>(`/advs/v1/advs/${advertiserUuid}/camps`)
  const traffic = useApiPost(
    '/insights/v1/statistics',
    {
      groupBy: 'page',
      fromDate: moment().subtract(30, 'days').toDate(),
      toDate: new Date(),
      filters: {
        advertiserUuids: [advertiserUuid],
        ...filters.reduce((result, filter) => {
          if (filter.value) result[filter.key] = filter.value.split(', ')
          return result
        }, {}),
      },
    },
    [advertiserUuid, filterReset]
  )

  const shownCampaigns = useMemo(() => {
    if (campaigns) {
      const groupCampaigns = groupBy(campaigns, (campaign) =>
        [
          campaign.campaign.campaignId,
          campaign.campaign.campaignName,
          campaign.campaign.campaignIsActive,
          campaign.commissions.commissionFlat,
          campaign.commissions.commissionPercentage,
          campaign.commissions.commissionType,
          campaign.commissions.commissionCurrencyCode,
          campaign.network.networkUuid,
          campaign.isConnected,
        ].join('::')
      )

      return Object.values(groupCampaigns).map((campaigns) => ({
        isConnected: campaigns[0].isConnected,
        campaign: {
          campaignUuids: campaigns
            .map((c) => c.campaign.campaignUuid)
            .filter((uuid) => uuid),
          campaignName: campaigns[0].campaign.campaignName,
          campaignId: campaigns[0].campaign.campaignId,
          campaignUrl: campaigns[0].campaign.campaignUrl,
          campaignIsPriority: Math.min(
            ...campaigns.map((c) => c.campaign.campaignIsPriority)
          ),
          campaignIsExcluded: Math.min(
            ...campaigns.map((c) => c.campaign.campaignIsExcluded)
          ),
          campaignIsActive: Math.min(
            ...campaigns.map((c) => c.campaign.campaignIsActive)
          ),
          campaignIsRevshare: campaigns[0].campaign.campaignIsRevshare,
        },
        network: campaigns[0].network,
        channels: uniqBy(
          campaigns.map((c) => c.channels).flat(),
          (c) => c.channelUuid
        ),
        performance: campaigns.reduce(
          (result, campaign) => {
            result.clicksCount += campaign.performance.clicksCount || 0
            result.conversionsCount +=
              campaign.performance.conversionsCount || 0
            result.revenue += campaign.performance.revenue || 0
            return result
          },
          { clicksCount: 0, conversionsCount: 0, revenue: 0 }
        ),
        commissions: campaigns[0].commissions,
        opportunity: campaigns.some(
          (c: (typeof campaigns)[number]) => c.opportunity
        ),
        alternative: campaigns.some(
          (c: (typeof campaigns)[number]) => c.alternative
        ),
      }))
    }
  }, [campaigns])

  return (
    <>
      <Header backPath="/advs" />
      <Loadable data={data && campaigns && channels && networks}>
        <Content
          data={data}
          campaigns={toArray(shownCampaigns)}
          originalCampaigns={toArray(campaigns)}
          {...{
            channels,
            networks,
            networkDetails,
            traffic,
            filters,
            setFilters,
          }}
        />
      </Loadable>
    </>
  )
}

const Content = (props) => {
  const {
    data,
    campaigns,
    originalCampaigns,
    channels,
    networks,
    networkDetails,
    traffic,
    filters,
    setFilters,
  } = props

  const { advertiser, performance } = data

  const [tab, setTab] = useState('')
  const [search, setSearch] = useState('')

  const [connectedNetworks, activeCampaigns, otherCampaigns] = useMemo(() => {
    if (!campaigns) {
      return [[], [], []]
    }
    const connectedNetworks = uniqBy(
      campaigns
        .filter((campaign) => campaign.isConnected)
        .map((campaign) => campaign.network),
      (network: any) => network.networkUuid
    )
    // const connectedNetworkUuids = connectedNetworks.map(
    //   (network) => network.networkUuid
    // )
    const activeCampaigns = campaigns.filter(
      (campaign) =>
        (search ? searchCampaign(campaign, search) : true) &&
        (campaign.performance.clicksCount ||
          campaign.performance.conversionsCount ||
          campaign.performance.revenue)
    )
    const otherCampaigns = campaigns.filter(
      (campaign) =>
        (search ? searchCampaign(campaign, search) : true) &&
        !activeCampaigns.includes(campaign)
    )
    return [connectedNetworks, activeCampaigns, otherCampaigns]
  }, [campaigns, search])

  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  const histories = useApiPost(
    `/advs/v1/advs/${advertiser.advertiserUuid}/camps/histories`,
    {
      fromDate: new Date(
        advertiser.advertiserCreatedDatetime.replace(' ', 'T')
      ),
      toDate: new Date(),
    }
  )

  return (
    <div className="advertiser-show-content">
      <div className="advertiser-info">
        <div className="card">
          <div className="card-body p-t-10 p-b-1">
            <div className="row row-center row-fill">
              <Logo
                src={advertiser.advertiserFaviconUrl}
                width={32}
                height={32}
                className="m-r-3"
                bordered
              />
              <span className="text-dark text-big text-bolder m-r-2">
                {advertiser.advertiserDomain}
              </span>
              <a
                href={`https://${advertiser.advertiserDomain}`}
                target="_blank"
                rel="noreferrer"
                className="link link-lighter m-t-05"
              >
                <SVG src="/images/icon-outside.svg" />
              </a>
            </div>
            <div className="m-t-5">
              <div className="row row-space-between">
                <div className="text-light text-small text-bold">
                  Date added:
                </div>
                <div className="text-dark">
                  {
                    inTimezone(advertiser.advertiserCreatedDatetime).split(
                      ' '
                    )[0]
                  }
                </div>
              </div>
            </div>
            {advertiser.categoryName && (
              <div className="m-t-2">
                <div className="row row-space-between">
                  <span className="text-light text-small text-bold">
                    Category:
                  </span>
                  {advertiser.categoryName}
                </div>
              </div>
            )}
            {connectedNetworks.length > 0 && (
              <div className="m-t-2">
                <div className="row row-space-between">
                  <span className="text-light text-small text-bold">
                    Found on:
                  </span>
                  <div>
                    {map(connectedNetworks, (network) => (
                      <span
                        key={network.networkUuid}
                        className="badge badge-hoverable text-smaller text-nowrap m-05"
                        onClick={(event) => {
                          navigateTo(event, `/networks/${network.networkUuid}`)
                        }}
                      >
                        {network.networkName}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className="card-divider" />

            <div className="text-dark text-bold m-t-6">Performance 30 days</div>
            <div className="row row-center row-space-between m-t-4">
              <span className="text-light text-small text-bold">Clicks:</span>
              <span className="value-badge value-badge-clickCount">
                {formatStat(performance.clicksCount)}
              </span>
            </div>
            <div className="row row-center row-space-between m-t-4">
              <span className="text-light text-small text-bold">
                Conversions:
              </span>
              <span className="value-badge value-badge-conversionCount">
                {formatStat(performance.conversionsCount)}
              </span>
            </div>
            <div className="row row-center row-space-between m-t-4">
              <span className="text-light text-small text-bold">Revenue:</span>
              <span className="value-badge value-badge-conversionCommission">
                {formatCurrencyReversed(
                  performance.revenue,
                  performance.currencyCode || 'DKK'
                )}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="advertiser-data">
        <div className="card p-b-0 m-b-20">
          {/*<div className="advertiser-tab-select">
            {map(
              {
                '': 'Overview',
                'traffic': 'Traffic',
              },
              (value, label) => (
                <button
                  key={value}
                  className={varClass({
                    'link': true,
                    'active': tab === value,
                  })}
                  onClick={() => setTab(value)}
                >
                  {label}
                </button>
              )
            )}
          </div>*/}

          <div className="card-body p-t-10">
            <div className="vertical-middle m-b-7">
              <CampaignsFilter
                {...{ filters, setFilters, channels, networks }}
              />
            </div>

            {campaigns.length > 1000 && (
              <CampaignsSearch setSearch={setSearch} />
            )}

            {tab === '' && (
              <>
                <Table
                  title="Campaigns with traffic the last 30 days"
                  campaigns={activeCampaigns}
                  networkDetails={networkDetails}
                  filters={filters}
                  channels={channels}
                  showModal={showModal}
                  showSnackbar={showSnackbar}
                />
                <Table
                  title="Other campaigns"
                  campaigns={otherCampaigns}
                  networkDetails={networkDetails}
                  filters={filters}
                  channels={channels}
                  showModal={showModal}
                  showSnackbar={showSnackbar}
                  className="m-t-40"
                />
              </>
            )}

            {tab === 'traffic' && (
              <>
                <SmallLoadable loaded={traffic}>
                  <TrafficTable
                    title="Campaigns with traffic the last 30 days"
                    data={traffic}
                    channels={channels}
                  />
                </SmallLoadable>
              </>
            )}
          </div>
        </div>

        <div className="card p-b-0">
          <div className="card-body p-t-10">
            <SmallLoadable loaded={histories} height={80} className="m-b-60">
              <CampaignHistory
                data={histories}
                campaigns={originalCampaigns}
                {...{ channels, networks }}
              />
            </SmallLoadable>
          </div>
        </div>
      </div>
    </div>
  )
}

const CampaignsFilter = (props) => {
  const { filters, setFilters, channels, networks } = props

  return (
    <div
      className={varClass({
        'subnav-filters': true,
      })}
    >
      {map(filters, (item, index) => (
        <SubnavSelect
          key={item.key}
          index={index}
          {...{
            item,
            channels,
            networks,
          }}
          buttonClassName={`filter-button-${item.key}`}
          label={item.label || item.value}
          selected={item.value}
          onSelect={(value, label) => {
            const newFilters = [...filters]
            const filter = newFilters.find((filter) => filter.key === item.key)
            if (filter.value) {
              const splitValue = filter.value.split(', ')
              const splitLabel = filter.label.split(', ')
              const existValue = splitValue.find((v) => v === value)
              if (existValue) {
                filter.value = splitValue.filter((v) => v !== value).join(', ')
                filter.label = splitLabel.filter((l) => l !== label).join(', ')
              } else {
                filter.value += `, ${value}`
                filter.label += `, ${label}`
              }
            } else {
              filter.value = value
              filter.label = label
            }
            setFilters(newFilters)
          }}
          onRemove={() => {
            const newFilters = [...filters]
            newFilters[index].value = ''
            newFilters[index].label = ''
            setFilters(newFilters)
          }}
        />
      ))}
    </div>
  )
}

const CampaignsSearch = (props) => {
  const { setSearch } = props

  const [shownSearch, setShownSearch] = useState('')
  const searchRef = useRef<TTimeout>()
  useEffect(() => {
    if (searchRef.current) {
      clearTimeout(searchRef.current)
    }
    searchRef.current = setTimeout(() => {
      setSearch(shownSearch)
      searchRef.current = undefined
    }, 650)
  }, [shownSearch])

  return (
    <div className="m-t--2 m-b-5">
      <Search
        value={shownSearch}
        setValue={setShownSearch}
        placeholder="Find campaign"
        className="search flex-1"
      />
    </div>
  )
}

const SubnavSelect = (props) => {
  const {
    wrapperClassName,
    buttonClassName,
    menuClassName,
    label,
    item,
    selected,
    onSelect,
    onRemove,
    channels,
    networks,
  } = props

  const [open, setOpen] = useState(false)
  const ref = useRef()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  const [search, setSearch] = useState('')
  const options = useMemo(() => {
    switch (item.key) {
      case 'channelUuids':
        if (!channels || !Array.isArray(channels)) return {}
        return channels?.reduce((result, channel) => {
          if (channel.channelUuid)
            result[channel.channelUuid] =
              channel.channelName || channel.channelDomain
          return result
        }, {})
      case 'networkUuids':
        if (!networks || !Array.isArray(networks)) return {}
        return networks?.reduce((result, network) => {
          if (network.networkUuid)
            result[network.networkUuid] = network.networkName
          return result
        }, {})
      default:
        return {}
    }
  }, [item.key])
  const showSearch = Object.keys(options).length > 10
  const shownOptions = useMemo(() => {
    if (search) {
      return Object.keys(options).reduce((result, option) => {
        if (options[option].toLowerCase().indexOf(search.toLowerCase()) !== -1)
          result[option] = options[option]
        return result
      }, {})
    } else {
      return options
    }
  }, [options, search])

  return (
    <div
      className={varClass({
        'subnav-filter': true,
        [wrapperClassName || '']: !!wrapperClassName,
      })}
      ref={ref}
    >
      <button
        className={varClass({
          'filter-button text-primary m-r-15': true,
          'active': open,
          [buttonClassName || '']: !!buttonClassName,
        })}
        onClick={() => setOpen(!open)}
      >
        <>
          {filterLabels[item.key]}:{' '}
          <b className="m-l-1">{item.value ? options[item.value] : 'All'}</b>
        </>
        <SVG
          src="/images/chevron-down.svg"
          className={varClass({
            'm-l-1': true,
            'rotate-180': open,
          })}
        />
      </button>
      <div
        className={varClass({
          'submenu': true,
          'shown': open,
          [menuClassName || '']: !!menuClassName,
        })}
      >
        {showSearch && (
          <div className="control control-focusless">
            <SVG src="/images/icon-search.svg" />
            <input
              type="text"
              placeholder={`Find ${filterLabels[item.key].toLowerCase()}`}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
        )}
        <ul>
          {map(shownOptions, (value) => (
            <li
              key={value}
              title={toUnicode(options[value])}
              className={varClass({
                'selected': selected.includes(value),
              })}
              onClick={() => {
                onSelect(value, toUnicode(options[value]))
                setOpen(false)
              }}
            >
              {toUnicode(options[value])}
            </li>
          ))}
          {isBlank(shownOptions) && (
            <li className="text-light text-bold text-center inactive">
              Nothing found
            </li>
          )}
        </ul>
        {selected && (
          <button
            className="remove-btn link"
            onClick={() => {
              onRemove()
              setOpen(false)
            }}
          >
            <SVG src="/images/icon-trash.svg" className="m-r-2" />
            Remove filter
          </button>
        )}
      </div>
    </div>
  )

  return (
    <div
      className={varClass({
        'insights-statistics-dropdown': true,
        [wrapperClassName || '']: !!wrapperClassName,
      })}
      ref={ref}
    >
      <button
        className={varClass({
          'btn btn-md subgroup': true,
          [buttonClassName || '']: !!buttonClassName,
        })}
        onClick={() => setOpen(!open)}
      >
        {label}
      </button>
      <div
        className={varClass({
          'submenu': true,
          'shown': open,
          [menuClassName || '']: !!menuClassName,
        })}
      >
        <ul>
          {map(options, (key) => (
            <li
              key={key}
              onClick={() => {
                onSelect(key)
                setOpen(false)
              }}
            >
              {options[key]}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const checkDate = (newest, oldest, subtractValue, subtractType) => {
  const date = moment().subtract(subtractValue, subtractType)
  return newest > date && oldest < date
}

const CampaignsDateLimitPicker = (props) => {
  const { dateLimit, setDateLimit, newestDate, oldestDate } = props

  const options = useMemo(() => {
    const newest = moment(newestDate)
    const oldest = moment(oldestDate)
    const result = []
    if (checkDate(newest, oldest, 1, 'week')) result.push('1w')
    if (checkDate(newest, oldest, 1, 'month')) result.push('1m')
    if (checkDate(newest, oldest, 3, 'months')) result.push('3m')
    if (checkDate(newest, oldest, 12, 'months')) result.push('12m')
    return result
  }, [oldestDate])

  return (
    <div className="advertiser-campaign-date-limit">
      <ul>
        {map(options, (option) => (
          <li
            key={option}
            className={varClass({
              'selected': dateLimit === option,
            })}
            onClick={() => {
              setDateLimit(option)
            }}
          >
            {option.toUpperCase()}
          </li>
        ))}
        <li
          className={varClass({
            'selected': dateLimit === '',
          })}
          onClick={() => {
            setDateLimit('')
          }}
        >
          All time
        </li>
      </ul>
    </div>
  )
}

/*
const SubnavDropdown = (props) => {
  const {
    wrapperClassName,
    buttonClassName,
    menuClassName,
    label,
    options,
    onSelect,
  } = props

  const [open, setOpen] = useState(false)
  const ref = useRef()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  return (
    <div
      className={varClass({
        'insights-statistics-dropdown': true,
        [wrapperClassName || '']: !!wrapperClassName,
      })}
      ref={ref}
    >
      <button
        className={varClass({
          'btn btn-md subgroup': true,
          [buttonClassName || '']: !!buttonClassName,
        })}
        onClick={() => setOpen(!open)}
      >
        {label}
      </button>
      <div
        className={varClass({
          'submenu': true,
          'shown': open,
          [menuClassName || '']: !!menuClassName,
        })}
      >
        <ul>
          {map(options, (key) => (
            <li
              key={key}
              onClick={() => {
                onSelect(key)
                setOpen(false)
              }}
            >
              {options[key]}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
*/

const Table = (props) => {
  const {
    title,
    campaigns,
    networkDetails,
    filters,
    channels,
    showModal,
    showSnackbar,
    className,
  } = props

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'networkName',
    dir: 'asc',
  })

  const headProps = { sort, dir, toggleSort }

  const filteredCampaigns = campaignFilter(campaigns, filters)
  const shownCampaigns = campaignSort(filteredCampaigns, sort, dir).slice(
    0,
    1000
  )

  return (
    <div className={className || ''}>
      <div className="text-dark text-bold m-b-5">{title}</div>
      <div className="card-body-fill relative">
        <div className="table-container-overflow">
          <table className="table table-bodered table-sortable advertiser-campaigns-table">
            <thead className="table-sticky">
              <tr>
                <TableHead
                  value="networkName"
                  label="Campaign"
                  {...headProps}
                />
                <th className="text-nowrap">Performance 30 days</th>
                <TableHead
                  value="campaignCommission"
                  label="Commission"
                  desc
                  {...headProps}
                />
                <th className="text-nowrap">Sites</th>
                <th className="text-nowrap">Actions</th>
              </tr>
            </thead>
            <tbody>
              {map(shownCampaigns, (campaign, index) => (
                <TableItem
                  key={`${campaign.campaign.campaignUuids}_${index}`}
                  {...{
                    campaign,
                    networkDetails,
                    channels,
                    sort,
                    showModal,
                    showSnackbar,
                  }}
                  isLast={index === shownCampaigns.length - 1}
                />
              ))}
              {filteredCampaigns.length > 1000 && (
                <tr>
                  <td
                    colSpan={10}
                    className="text-center text-light text-bolder"
                  >
                    Search to see more campaigns
                  </td>
                </tr>
              )}
              {isBlank(shownCampaigns) && (
                <tr>
                  <td
                    colSpan={10}
                    className="text-center text-light text-bolder"
                  >
                    Nothing found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Tooltip
        id="advertiser-show-opportunity-tooltip"
        type="dark"
        effect="solid"
        place="bottom"
        rebuild={[shownCampaigns]}
      />
    </div>
  )
}

const TableHead = (props) => {
  const { value, label, desc, sort, dir, toggleSort } = props

  return (
    <th
      className={varClass({
        'sort-highlight': sort === value,
      })}
      onClick={toggleSort(value, desc ? 'desc' : 'asc')}
    >
      <span className="column-label text-nowrap">
        {label}
        {sort === value ? (
          <>
            {dir === 'desc' ? (
              <SVG src="/images/insights/caret-down.svg" className="m-l-1" />
            ) : (
              <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
            )}
          </>
        ) : (
          <SVG src="/images/insights/caret.svg" className="m-l-1" />
        )}
      </span>
    </th>
  )
}

const TableItem = (props) => {
  const {
    campaign,
    networkDetails,
    channels,
    sort,
    showModal,
    showSnackbar,
    isLast,
  } = props

  const [_reloadRef, reload] = useCounter()

  const [submitting, setSubmitting] = useState(false)

  return (
    <tr className="text-nowrap">
      <td
        className={varClass({
          'sort-highlight': sort === 'networkName',
        })}
      >
        <div className="text-bold m-b-1">
          {campaign.campaign.campaignName || (
            <span className="text-italic">Unknown campaign</span>
          )}
          {campaign.opportunity && (
            <WithTooltip
              className="campaign-alternative inline-block m-l-2"
              title="A new campaign can be found for this advertiser."
              text={
                <SVG
                  src="/images/recommendations/icon-campaigns.svg"
                  width={18}
                  height={14}
                />
              }
              id="advertiser-show-opportunity-tooltip"
              forceShow
            />
          )}
          {campaign.alternative && (
            <WithTooltip
              className="campaign-alternative inline-block m-l-2"
              title="A campaign with higher commission can be found for this advertiser."
              text={
                <SVG
                  src="/images/recommendations/icon-alt-campaigns.svg"
                  width={20}
                  height={14}
                />
              }
              id="advertiser-show-opportunity-tooltip"
              forceShow
            />
          )}
        </div>
        {campaign.campaign.campaignId && (
          <div className="text-smaller m-t-05">
            <span className="label-light">ID: </span>
            <span className="text-light">{campaign.campaign.campaignId}</span>
          </div>
        )}
        {campaign.network.networkName && (
          <div className="text-smaller m-t-05">
            <span className="label-light">Network: </span>
            <span className="text-light">{campaign.network.networkName}</span>
          </div>
        )}
        {campaign.campaign.campaignUrl && (
          <div className="text-smaller m-t-05">
            <span className="text-light">{campaign.campaign.campaignUrl}</span>
          </div>
        )}
      </td>
      {campaign.isConnected ? (
        <>
          <td
            className={varClass({
              'p-y-3': true,
            })}
          >
            <div className="m-b-1">
              <span>Clicks: </span>
              <span className="value-badge value-badge-clickCount">
                {formatStat(campaign.performance.clicksCount)}
              </span>
            </div>
            <div className="m-b-1">
              <span>Conversions: </span>
              <span className="value-badge value-badge-conversionCount">
                {formatStat(campaign.performance.conversionsCount)}
              </span>
            </div>
            <div>
              <span>Revenue: </span>
              <span className="value-badge value-badge-conversionCommission">
                {formatCurrencyReversed(
                  campaign.performance.revenue,
                  campaign.commissions.commissionCurrencyCode || 'DKK'
                )}
              </span>
            </div>
          </td>
          <td
            className={varClass({
              'p-y-3': true,
              'sort-highlight': sort === 'campaignCommission',
            })}
          >
            <div className="vertical-middle">
              <Commission
                type={campaign.commissions.commissionType}
                value={formatCommission(
                  campaign.commissions.commissionType,
                  campaign.commissions.commissionFlat,
                  campaign.commissions.commissionPercentage,
                  campaign.commissions.commissionCurrencyCode || 'DKK',
                  true
                )}
              />
            </div>
          </td>
          <td className="p-4 text-wrap">
            {map(campaign.channels, (channel) => (
              <span
                key={channel.channelUuid}
                className="badge badge-hoverable text-smaller text-nowrap m-05"
                onClick={(event) => {
                  navigateTo(event, `/sites/${channel.channelUuid}`)
                }}
              >
                {channel.channelName}
              </span>
            ))}
            {campaign.campaign.campaignIsRevshare && (
              <span className="badge badge-hoverable text-smaller text-nowrap m-05">
                Heylink Boost
              </span>
            )}
          </td>
          <td className="static p-r-4">
            {!campaign.campaign.campaignIsRevshare && (
              <>
                {campaign.campaign.campaignIsActive === 1 ? (
                  <div className="row row-center row-space-between row-nowrap">
                    <div className="row row-center row-narrow row-nowrap">
                      {submitting && (
                        <SmallLoadable
                          height={18}
                          style={{
                            width: 18,
                            height: 18,
                          }}
                          loaded={false}
                        />
                      )}
                      {campaign.campaign.campaignIsPriority ? (
                        <SVG
                          src="/images/advertisers/icon-priority.svg"
                          title="Prioritized"
                        />
                      ) : null}
                      {campaign.campaign.campaignIsExcluded ? (
                        <SVG
                          src="/images/advertisers/icon-excluded.svg"
                          title="Disabled"
                        />
                      ) : null}
                    </div>
                    <TableActionMenu
                      campaign={campaign}
                      reload={reload}
                      showSnackbar={showSnackbar}
                      isLast={isLast}
                      setSubmitting={setSubmitting}
                    />
                  </div>
                ) : (
                  <div className="row row-center row-space-between row-nowrap">
                    <div className="row row-center row-narrow row-nowrap">
                      <SVG
                        src="/images/advertisers/icon-closed.svg"
                        title="Closed"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </td>
        </>
      ) : (
        <>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td className="text-right p-r-4">
            <button
              className="btn btn-dark btn-xs"
              onClick={() => {
                showModal(
                  <ConnectNetworkModal
                    network={networkDetails.find(
                      (network) =>
                        network.networkUuid === campaign.network.networkUuid
                    )}
                    channels={channels}
                    onSkip={navigateReload}
                    onDone={navigateReload}
                  />
                )
              }}
            >
              Connect
            </button>
          </td>
        </>
      )}
    </tr>
  )
}

const TableActionMenu = (props) => {
  const { campaign, reload, showSnackbar, isLast, setSubmitting } = props

  const ref = useRef<HTMLDivElement>()

  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (ref.current) {
      const parent = ref.current.offsetParent as HTMLDivElement
      const top = parent.offsetTop
      const scroll = ref.current.closest('.table-container-overflow').scrollTop
      ref.current.querySelector('ul').style.top = `${top - scroll + 64}px`
    }
  }, [open])

  useOutsideClick(ref, () => {
    setOpen(false)
  })

  const campaignUuids = campaign.campaign.campaignUuids || []

  return (
    <div
      className={varClass({
        'advertiser-campaigns-actions': true,
        'open': open,
        'last': isLast,
      })}
      ref={ref}
    >
      <button className="link" onClick={() => setOpen(!open)}>
        <SVG src="/images/advertisers/icon-dots.svg" />
      </button>
      <ul>
        <li>
          <button
            className="link"
            onClick={(event) => {
              navigateTo(event, '/tools/urls')
            }}
          >
            Create Smart Link
          </button>
        </li>
        {campaignUuids.length && (
          <>
            {campaign.campaign.campaignIsPriority ? (
              <li>
                <button
                  className="link"
                  onClick={async () => {
                    setOpen(false)
                    setSubmitting(true)
                    try {
                      await putRequest(`/advs/v2/camps/tracking`, {
                        campaignUuids,
                        campaignIsPriority: false,
                      })
                    } catch (error) {
                      console.log(error)
                    }
                    campaign.campaign.campaignIsPriority = 0
                    showSnackbar('Tracking priority updated')
                    setSubmitting(false)
                    reload()
                  }}
                >
                  Unprioritize
                </button>
              </li>
            ) : (
              <li>
                <button
                  className="link"
                  onClick={async () => {
                    setOpen(false)
                    setSubmitting(true)
                    try {
                      await putRequest(`/advs/v2/camps/tracking`, {
                        campaignUuids,
                        campaignIsPriority: true,
                      })
                    } catch (error) {
                      console.log(error)
                    }
                    campaign.campaign.campaignIsPriority = 1
                    showSnackbar('Tracking priority updated')
                    setSubmitting(false)
                    reload()
                  }}
                >
                  Prioritize
                </button>
              </li>
            )}
            {campaign.campaign.campaignIsExcluded ? (
              <li>
                <button
                  className="link"
                  onClick={async () => {
                    setOpen(false)
                    setSubmitting(true)
                    try {
                      await putRequest(`/advs/v2/camps/tracking`, {
                        campaignUuids,
                        campaignIsExcluded: false,
                      })
                    } catch (error) {
                      console.log(error)
                    }
                    campaign.campaign.campaignIsExcluded = 0
                    showSnackbar('Tracking enabled')
                    setSubmitting(false)
                    reload()
                  }}
                >
                  Enable
                </button>
              </li>
            ) : (
              <li>
                <button
                  className="link"
                  onClick={async () => {
                    setOpen(false)
                    setSubmitting(true)
                    try {
                      await putRequest(`/advs/v2/camps/tracking`, {
                        campaignUuids,
                        campaignIsExcluded: true,
                      })
                    } catch (error) {
                      console.log(error)
                    }
                    campaign.campaign.campaignIsExcluded = 1
                    showSnackbar('Tracking disabled')
                    setSubmitting(false)
                    reload()
                  }}
                >
                  Disable
                </button>
              </li>
            )}
          </>
        )}
      </ul>
    </div>
  )
}

const TrafficTable = (props) => {
  const { title, data, channels, className } = props

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'clickCount',
    dir: 'desc',
  })

  const headProps = { sort, dir, toggleSort }

  const shownRows = trafficSort(data.rows, sort, dir)

  return (
    <div className={className || ''}>
      <div className="row row-center row-space-between m-b-5">
        <div className="text-dark text-bold">{title}</div>
        <div className="text-light text-small">
          {data?.rows?.length || 0} {plural(data?.rows?.length || 0, 'page')}
        </div>
      </div>
      <div className="card-body-fill">
        <div className="table-container-overflow">
          <table className="table table-bodered table-sortable advertiser-campaigns-table">
            <thead className="table-sticky">
              <tr>
                <TableHead value="grp" label="Page" {...headProps} />
                <TableHead value="clickCount" label="Clicks" {...headProps} />
                <TableHead
                  value="conversionCount"
                  label="Conversions"
                  {...headProps}
                />
                <TableHead
                  value="conversionCommission"
                  label="Revenue"
                  {...headProps}
                />
                {/*<th className="text-nowrap">Actions</th>*/}
              </tr>
            </thead>
            <tbody>
              {map(shownRows, (row, index) => (
                <TrafficTableItem
                  key={row.grp}
                  {...{
                    row,
                    sort,
                    dir,
                    channels,
                  }}
                  isLast={index === shownRows.length - 1}
                />
              ))}
              {isBlank(shownRows) && (
                <tr>
                  <td
                    colSpan={10}
                    className="text-center text-light text-bolder"
                  >
                    Nothing found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const TrafficTableItem = (props) => {
  const { row, sort } = props

  const { grp, name } = row
  const values = calculateValues(row)

  return (
    <tr className="text-nowrap">
      <td
        className={varClass({
          'sort-highlight': sort === 'grp',
        })}
      >
        <div className="text-bold m-b-1">{name || grp || 'Unknown'}</div>
        {/*
        <div>
          <span className="label-light">Channel: </span>
          <span className="text-light">{'?!?!?'}</span>
        </div>
        */}
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'clickCount',
        })}
      >
        <div className="value-badge value-badge-clickCount">
          {values.clickCount}
        </div>
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'conversionCount',
        })}
      >
        <div className="value-badge value-badge-conversionCount">
          {values.conversionCount}
        </div>
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'conversionCommission',
        })}
      >
        <div className="value-badge value-badge-conversionCommission">
          {values.conversionTotalCommission || values.conversionCommission}
        </div>
      </td>
      {/*
      <td className="p-r-4">
        <div className="row row-center row-space-between row-nowrap">
          <div />
          <TableActionMenu isLast={isLast} />
        </div>
      </td>
      */}
    </tr>
  )
}

const CampaignHistory = (props) => {
  const { data, campaigns, channels, networks } = props

  const [filters, setFilters] = useState([
    {
      key: 'channelUuids',
      value: '',
    },
    {
      key: 'networkUuids',
      value: '',
    },
  ])

  const [dateLimit, setDateLimit] = useState('')
  const newestDate = data.events[0]?.event?.eventDatetime
  const oldestDate = data.events[data.events.length - 1]?.event?.eventDatetime
  const selectedDateLimit = useMemo(() => {
    if (dateLimit) {
      const value = parseInt(dateLimit.substring(0, dateLimit.length - 1))
      let type: 'day' | 'week' | 'month' = 'day'
      switch (dateLimit[dateLimit.length - 1]) {
        case 'w':
          type = 'week'
          break
        case 'm':
          type = 'month'
          break
      }

      return moment().subtract(value, type).format('YYYY-MM-DD')
    } else {
      return null
    }
  }, [dateLimit])

  const filterReset =
    filters
      .filter((filter) => filter.value)
      .map((filter) => filter.value)
      .join(',') + dateLimit

  const shownEvents = useMemo(() => {
    const groupEvents = groupBy(data.events, (event) => {
      const campaign = findEventCampaign(campaigns, event)
      return [
        event.event.eventDatetime.substring(0, 10),
        event.event.fromIsActive,
        event.event.toIsActive,
        // event.event.fromIsPriority,
        // event.event.toIsPriority,
        // event.event.fromIsExcluded,
        // event.event.toIsExcluded,
        // event.event.fromIsFallback,
        // event.event.toIsFallback,
        event.event.fromCommissionFlat || event.event.fromCommissionPercentage,
        event.event.toCommissionFlat || event.event.toCommissionPercentage,
        event.network.networkUuid,
        event.campaign.campaignName,
        campaign?.campaign?.campaignId,
      ].join('::')
    })

    return Object.values(groupEvents)
      .map((events) => events[0])
      .filter((event) => {
        event.event.eventDatetime = inTimezone(event.event.eventDatetime)

        if (selectedDateLimit) {
          if (event.event.eventDatetime.substring(0, 10) < selectedDateLimit)
            return false
        }
        const sameActive = event.event.fromIsActive === event.event.toIsActive
        const sameCommission =
          (event.event.fromCommissionFlat ||
            event.event.fromCommissionPercentage) ===
          (event.event.toCommissionFlat || event.event.toCommissionPercentage)
        // TODO: same priority, excluded, fallback
        if (sameActive && sameCommission) {
          return false
        }
        for (const filter of filters) {
          if (filter.value) {
            switch (filter.key) {
              case 'channelUuids':
                if (event.channel[filter.key] !== filter.value) return false
                break
              case 'networkUuids':
                if (event.network[filter.key] !== filter.value) return false
                break
              default:
            }
          }
        }
        return true
      })
  }, [filterReset])
  const shownGraphs = useMemo(() => {
    const result = { ...data.graphs }
    for (const key in result) {
      const shownEvent = shownEvents.find(
        (event) => event.campaign.campaignUuid === key
      )
      if (!shownEvent) {
        delete result[key]
      }
    }
    return result
  }, [filterReset, shownEvents])

  const [graphData, graphCampaigns] = useMemo(() => {
    const events = data.events
    const graphs = shownGraphs
    if (!graphs || isBlank(graphs)) {
      return [[], []]
    } else {
      const rawData = {}
      const campaigns = []
      for (const campaignUuid in graphs) {
        const campaignIndex = campaigns.length
        campaigns.push({
          campaignUuid,
          campaignIndex,
          campaignName: graphs[campaignUuid].campaignName,
          campaignShowFlat: false,
          campaignShowPercentage: false,
        })

        // Frontend hack to add "campaign closed" event to the graph
        const graphRows = [...graphs[campaignUuid].graph]
        for (const event of events) {
          if (event.campaign.campaignUuid === campaignUuid) {
            if (event.event.fromIsActive && !event.event.toIsActive) {
              graphRows.push({
                datetime: event.event.eventDatetime,
                commissionFlat: 0,
                commissionPercentage: 0,
                currencyCode: 'DKK',
              })
            }
          }
        }

        for (const row of sortBy(graphRows, (row) => row.datetime)) {
          const date = row.datetime.split(' ')[0]
          rawData[date] = rawData[date] || {}

          rawData[date][`commissionFlat${campaignIndex}`] = row.commissionFlat
          rawData[date][`commissionPercentage${campaignIndex}`] =
            row.commissionPercentage
          rawData[date][`currencyCode${campaignIndex}`] = row.currencyCode

          if (row.commissionFlat) {
            campaigns[campaignIndex].campaignShowFlat = true
          }

          if (
            !campaigns[campaignIndex].campaignShowFlat &&
            row.commissionPercentage
          ) {
            campaigns[campaignIndex].campaignShowPercentage = true
          }
        }
      }
      const data = Object.keys(rawData)
        .sort()
        .map((date) => ({
          date,
          ...rawData[date],
        }))

      // Remove campaigns that only have zeros
      if (data.length) {
        // Add a blank first day
        const firstDate = moment(data[0].date)
          .subtract(1, 'day')
          .format('YYYY-MM-DD')
        data.unshift({
          date: firstDate,
        })

        // Remove hidden campaigns
        for (const campaign of campaigns) {
          if (!campaign.campaignShowFlat) {
            for (const i in data) {
              delete data[i][`commissionFlat${campaign.campaignIndex}`]
              delete data[i][`currencyCode${campaign.campaignIndex}`]
            }
          }
          if (!campaign.campaignShowPercentage) {
            for (const i in data) {
              delete data[i][`commissionPercentage${campaign.campaignIndex}`]
            }
          }
        }

        let previousData: any
        // Fill in non-blank data from left to right
        previousData = null
        for (let i = 0; i < data.length; i++) {
          if (previousData) {
            for (const j in previousData) {
              if (j === 'date') continue
              if (j.startsWith('currencyCode')) {
                if (
                  previousData[j] &&
                  !Object.getOwnPropertyDescriptor(data[i], j)
                ) {
                  data[i][j] = previousData[j]
                }
                continue
              }
              if (
                previousData[j] &&
                !Object.getOwnPropertyDescriptor(data[i], j)
              ) {
                data[i][j] = previousData[j]
              }
            }
          }
          previousData = data[i]
        }
        // And fill in blank data from right to left
        previousData = null
        for (let i = data.length - 1; i >= 0; i--) {
          if (previousData) {
            for (const j in previousData) {
              if (j === 'date') continue
              if (j.startsWith('currencyCode')) {
                if (
                  previousData[j] &&
                  !Object.getOwnPropertyDescriptor(data[i], j)
                ) {
                  data[i][j] = previousData[j]
                }
                continue
              }
              if (
                Object.getOwnPropertyDescriptor(previousData, j) &&
                !Object.getOwnPropertyDescriptor(data[i], j)
              ) {
                data[i][j] = 0
              }
            }
          }
          previousData = data[i]
        }

        // Extend the last values to include today's date
        const lastDate = moment().format('YYYY-MM-DD')
        const lastData = { ...data[data.length - 1] }
        if (lastData && lastData.date !== lastDate) {
          lastData.date = lastDate
          data.push(lastData)
        }

        // Remove bottom line
        for (let i = 0; i < data.length - 1; i++) {
          for (const j in data[i]) {
            if (j.startsWith('commission')) {
              if (data[i][j] === 0 && data[i + 1][j] === 0) {
                delete data[i][j]
              }
            }
          }
        }
        for (let i = data.length - 1; i > 0; i--) {
          for (const j in data[i]) {
            if (j.startsWith('commission')) {
              if (data[i][j] === 0 && data[i - 1][j] === 0) {
                delete data[i][j]
              }
            }
          }
        }

        // Remove dates with no values
        for (let i = 0; i < data.length; i++) {
          const commissionKeys = Object.keys(data[i]).filter((key) =>
            key.startsWith('commission')
          )
          if (!commissionKeys.length) {
            data.splice(i, 1)
            i--
          }
        }
      }

      return [data, campaigns]
    }
  }, [shownGraphs])

  return (
    <div>
      <div className="row row-center row-space-between">
        <div className="vertical-middle m-b-7">
          <CampaignsFilter {...{ filters, setFilters, channels, networks }} />
        </div>
        <div>
          <CampaignsDateLimitPicker
            {...{ dateLimit, setDateLimit, newestDate, oldestDate }}
          />
        </div>
      </div>

      {isBlank(shownGraphs) ? null : (
        <HistoryAreaChart
          selectedDateLimit={selectedDateLimit}
          data={graphData}
          campaigns={graphCampaigns}
          colors={graphColors}
        />
      )}

      <div className="text-dark text-bold m-t-30 m-b-5">Change log</div>
      <div className="card-body-fill">
        <div className="table-container-overflow">
          <table className="table table-bodered table-sortable advertiser-campaigns-table">
            <thead className="table-sticky">
              <tr>
                <th>Campaign</th>
                <th>Change</th>
                <th>Site</th>
                <th className="text-right">Time</th>
              </tr>
            </thead>
            <tbody>
              {map(shownEvents, (event, index) => (
                <tr key={index}>
                  <td className="p-l-40">
                    <HistoryCampaign
                      event={event}
                      campaign={findEventCampaign(campaigns, event)}
                      graphCampaign={findEventCampaign(graphCampaigns, event)}
                    />
                  </td>
                  <td>
                    <HistoryEvent event={event.event} />
                  </td>
                  <td className="text-wrap">
                    <span
                      className="badge badge-hoverable text-smaller text-nowrap"
                      onClick={(e) => {
                        navigateTo(e, `/sites/${event.channel.channelUuid}`)
                      }}
                    >
                      {event.channel.channelName}
                    </span>
                  </td>
                  <td className="text-right">{event.event.eventDatetime}</td>
                </tr>
              ))}
              {isBlank(shownEvents) && (
                <tr>
                  <td
                    colSpan={10}
                    className="text-center text-light text-bolder"
                  >
                    Nothing found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const HistoryCampaign = (props) => {
  const { event, campaign, graphCampaign } = props

  return (
    <>
      <div
        className="campaign-dot"
        style={{
          background:
            graphColors[
              (graphCampaign?.campaignIndex || 0) % graphColors.length
            ],
        }}
      />
      <div className="text-bold p-l-1 m-b-05">
        {event.campaign.campaignName}
      </div>
      <div className="row" style={{ gap: '2px 16px' }}>
        {campaign?.campaign?.campaignId && (
          <div className="p-l-1 text-smaller">
            <span className="label-light">ID: </span>
            <span className="text-light">{campaign.campaign.campaignId}</span>
          </div>
        )}
        {event.network.networkName && (
          <div className="p-l-1 text-smaller">
            <span className="label-light">Network: </span>
            <span className="text-light">{event.network.networkName}</span>
          </div>
        )}
      </div>
    </>
  )
}

const HistoryEvent = (props) => {
  const { event } = props

  if (event.fromIsActive !== event.toIsActive) {
    if (event.toIsActive) {
      return (
        <span className="badge badge-rounder text-smaller text-bold text-nowrap text-new-green">
          Campaign created
        </span>
      )
    } else {
      return (
        <span className="badge badge-rounder text-smaller text-bold text-nowrap text-light">
          Campaign closed
        </span>
      )
    }
  }

  const fromValue = event.fromCommissionFlat || event.fromCommissionPercentage
  const toValue = event.toCommissionFlat || event.toCommissionPercentage
  const isPositive = toValue > fromValue

  return (
    <span
      className={varClass({
        'campaign-change text-smaller text-bold text-nowrap vertical-middle':
          true,
      })}
    >
      <span
        className="text-primary text-bold"
        style={{ position: 'relative', top: -0.5 }}
      >
        {formatCommission(
          '',
          event.fromCommissionFlat,
          event.fromCommissionPercentage,
          event.currencyCode || 'DKK',
          true
        )}
      </span>
      <SVG
        src="/images/advertisers/chevron-right.svg"
        className="m-x-05"
        style={{ position: 'relative', top: -0.5 }}
      />
      <span
        className={varClass({
          'campaign-change-new-value vertical-middle': true,
          'text-new-green': isPositive,
          'text-new-blood': !isPositive,
        })}
      >
        {formatCommission(
          '',
          event.toCommissionFlat,
          event.toCommissionPercentage,
          event.currencyCode || 'DKK',
          true
        )}
        {isPositive ? (
          <SVG
            src="/images/caret-up.svg"
            width={8}
            height={4}
            className="m-l-1 m-t--05"
          />
        ) : (
          <SVG
            src="/images/caret-down.svg"
            width={8}
            height={4}
            className="m-l-1"
          />
        )}
      </span>
    </span>
  )
}
