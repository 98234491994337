import React, { useState, useEffect, useMemo } from 'react'
import SVG from 'react-inlinesvg'
import { useForm, Controller } from 'react-hook-form'
import moment from 'moment'
import {
  Header,
  NavFilter,
  MiniAreaChart,
  TableInfo,
  TableHead,
  Trend,
  Search,
  Loadable,
  CountrySelectOptions,
  CountryPhoneInput,
  useModal,
  useSortableTable,
  useFormSubmit,
  filterBySearch,
  formatStat,
  formatCurrency,
  formatShortDatetime,
  navigateTo,
  newTableSort,
  varClass,
  map,
  plural,
  capitalize,
  initials,
  responseError,
  DatePicker,
  useDatePickerState,
} from '../shared'
import {
  getCurrentUser,
  postRequest,
  connectGet,
  connectPost,
} from '../../services'

const filterLabels = {
  'status': 'Status',
}

const statusLabels = {
  pending: 'Pending',
  sent: 'Contract sent',
  signed: 'Ready',
  installed: 'Tracking installed',
  running: 'Running',
  ended: 'Ended',
  complete: 'Completed',
  waiting: 'Waiting for tracking',
  none: 'No campaign created',
}

const partnersFilter = (collection: any, filters: any) => {
  return collection.filter((item) => {
    for (const filter of filters) {
      if (filter.value) {
        if (item[filter.key] !== filter.value) {
          return false
        }
      }
    }
    return true
  })
}

const partnersSort = newTableSort()

export const Partners = (props) => {
  const [stats, setStats] = useState(null)
  const [totals, setTotals] = useState(null)
  const [revenues, setRevenues] = useState(null)
  const [data, setData] = useState(null)
  const [noPartners, setNoPartners] = useState(false)
  const { range } = useDatePickerState()

  useEffect(() => {
    const fetchPartners = async () => {
      const currentUser = getCurrentUser()
      const response = await connectGet(
        `/publishers/${currentUser.account?.accountUuid}`
      )
      if (response.code === 200) {
        setData(response?.data?.partners || [])
      } else {
        setNoPartners(true)
      }
    }

    fetchPartners()
  }, [])

  useEffect(() => {
    const fetchStats = async (partners) => {
      setStats(null)
      setTotals(null)
      setRevenues(null)
      const ids = partners.map((partner) => partner.contracts).flat()
      if (!ids?.length) {
        setStats([])
        setTotals({})
        setRevenues([])
        return
      }
      let startDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
      let endDate = moment().format('YYYY-MM-DD')
      if (range) {
        startDate = moment(range.startDate).format('YYYY-MM-DD')
        endDate = moment(range.endDate).format('YYYY-MM-DD')
      }
      const response = await postRequest('/insights/v1/partners', {
        fromDate: startDate,
        toDate: endDate,
        ids,
      })
      if (response.code === 200) {
        setStats(response?.data?.rows || [])
        setTotals(response?.data?.totals || {})
        setRevenues(response?.data?.stats || [])
      } else {
        setStats([])
        setTotals({})
        setRevenues([])
      }
    }

    if (!noPartners && data) {
      fetchStats(data)
    }
  }, [range, noPartners, data])

  const [filters, setFilters] = useState([{ key: 'status', value: '' }])

  if (noPartners) {
    return (
      <>
        <Header label="Partners" />
        <div className="card p-y-7 column column-center column-fill relative m-t-20">
          <SVG
            src="/images/partners/no-partners-bg1.svg"
            style={{
              position: 'absolute',
              top: 46,
              left: 0,
              zIndex: 0,
              pointerEvents: 'none',
            }}
          />
          <SVG
            src="/images/partners/no-partners-bg2.svg"
            style={{
              position: 'absolute',
              top: 57,
              left: 10,
              zIndex: 0,
              pointerEvents: 'none',
            }}
          />
          <div className="row row-space-between">
            <div className="flex-1 column column-space-between column-center">
              <div className="flex-1 column column-center column-middle column-fill text-center w-70">
                <div className="text-dark text-larger text-bolder">
                  Get up to 50% higher commission with direct partnerships!
                </div>
                <div
                  className="text-big text-light m-t-4"
                  style={{ maxWidth: 420 }}
                >
                  Build better partnership by setting up direct partners in
                  Heylink. Work closer with your top Merchants and cut out the
                  middle man.
                </div>
                <a
                  href="mailto:support@heylink.com?subject=Outbound%20direct%20partnership"
                  className="inline-block btn btn-primary m-t-7"
                >
                  Request a demo
                </a>
              </div>
              <div className="text-center w-70">
                See{' '}
                <a
                  href="https://docs.heylink.com/outbound/partner-onboarding"
                  target="_blank"
                  className="link link-underlined"
                >
                  how
                </a>{' '}
                you can easily invite Direct partners to your account!
              </div>
            </div>

            <div className="no-partners-conversion card borderless p-8">
              <div className="partner-conversion-check">Check</div>
              <div className="partner-conversion-part">
                <div className="partner-conversion-header">
                  <SVG src="/images/partners/no-partners-icon1.svg" />
                  Offer Details
                </div>
                <ul>
                  <li>Offer ID</li>
                </ul>
              </div>
              <div className="partner-conversion-part">
                <div className="partner-conversion-header">
                  <SVG src="/images/partners/no-partners-icon2.svg" />
                  Order Details
                </div>
                <ul>
                  <li>
                    Total : <span className="text-new-green">$2600.50</span>
                  </li>
                  <li>
                    Shipping : <span className="text-orange">$200</span>
                  </li>
                  <li>
                    Tax : <span className="text-orange">$400.13</span>
                  </li>
                  <li>
                    Currency : <span className="text-new-light">DKK</span>
                  </li>
                  <li>
                    Coupon code : <span className="text-new-light">25OFF</span>
                  </li>
                  <li>
                    Discount : <span className="text-orange">$10.25</span>
                  </li>
                </ul>
              </div>
              <div className="partner-conversion-part">
                <div className="partner-conversion-header">
                  <SVG src="/images/partners/no-partners-icon3.svg" />
                  Order Items
                </div>
                <ul>
                  <li>
                    Name :{' '}
                    <span className="text-new-blue">Sony Playstation 5</span>
                  </li>
                  <li>
                    Item ID : <span className="text-new-light">123 - abc</span>
                  </li>
                  <li>
                    Quantity : <span className="text-new-light">1</span>
                  </li>
                  <li>
                    Price : <span className="text-new-green">$2000.5</span>
                  </li>
                  <li>
                    Variant :{' '}
                    <span className="text-new-light">Digital Edition</span>
                  </li>
                  <li>
                    Category : <span className="text-new-light">Consoles</span>
                  </li>
                  <li>
                    SKU : <span className="text-new-light">SKUsdsdss</span>
                  </li>
                  <li>
                    Goal ID : <span className="text-new-light">Consoles</span>
                  </li>
                  <li>
                    Url : <span className="text-new-blue">www.product.com</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Header label="Partners" />
      <Loadable data={stats && totals && revenues && data} placeholder={null}>
        <Content {...{ stats, totals, revenues, data, filters, setFilters }} />
        <DatePicker className="in-header" />
      </Loadable>
    </>
  )
}

const Content = (props) => {
  const { stats, totals, revenues, data, filters, setFilters } = props

  return (
    <>
      <TopCharts {...{ stats, totals }} />
      <Nav {...{ filters, setFilters }} />
      <Table {...{ data, revenues, totals, filters }} />
    </>
  )
}

const TopCharts = (props) => {
  const { stats, totals } = props

  const clicks = useMemo(
    () => [
      totals?.clicks?.count || 0,
      totals?.clicks?.change || 0,
      stats?.map((stat) => stat.clickCount) || [],
    ],
    [stats, totals]
  )
  const conversions = useMemo(
    () => [
      totals?.conversions?.count || 0,
      totals?.conversions?.change || 0,
      stats?.map((stat) => stat.conversionCount) || [],
    ],
    [stats, totals]
  )
  const revenue = useMemo(
    () => [
      totals?.commission?.value || 0,
      totals?.commission?.change || 0,
      stats?.map((stat) => stat.conversionTotalCommission) || [],
    ],
    [stats, totals]
  )

  return (
    <div className="row row-nowrap row-md m-b-30">
      <TopChart label="Clicks" data={clicks} color="#ffb000" />
      <TopChart label="Conversions" data={conversions} color="#6a98fd" />
      <TopChart
        label="Commission"
        currency={totals?.commission?.currencyCode || 'DKK'}
        data={revenue}
        color="#0ccfaf"
      />
    </div>
  )
}

const TopChart = (props) => {
  const { label, currency, data, color } = props

  const [value, trend, chartData] = data

  return (
    <div className="card p-20 flex-1">
      <div className="row row-center row-space-between row-nowrap">
        <div>
          {label}
          {currency && <span className="text-light m-l-2">{currency}</span>}
        </div>
        {/*<Trend valuePercentage={trend} />*/}
      </div>
      <div className="text-dark text-larger text-boldest m-t-15 m-b-3">
        {formatStat(value)}
      </div>
      <MiniAreaChart
        title={label}
        data={chartData}
        color={color}
        height={80}
        chartType="monotone"
      />
    </div>
  )
}

const Nav = (props) => {
  const { filters, setFilters } = props

  return (
    <div className="page-nav m-b-20">
      <NavFilter
        {...{
          filters,
          setFilters,
          filterLabels,
          filterOptions: {
            status: {
              'pending': 'Pending',
              'running': 'Running',
              'ended': 'Ended',
            },
          },
        }}
        onRemove={(index, key) => {
          const newFilters = [...filters]
          if (['status'].includes(key)) {
            const filter = newFilters.find((filter) => filter.key === key)
            filter.value = ''
            filter.label = ''
          } else {
            newFilters.splice(index, 1)
          }
          setFilters(newFilters)
        }}
        hideAdd
      />
    </div>
  )
}

const Table = (props) => {
  const { data, revenues, totals, filters } = props

  const { showModal } = useModal()

  const [search, setSearch] = useState('')

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'lastActivityAt',
    dir: 'desc',
  })

  const headProps = { sort, dir, toggleSort }

  const rowStats = useMemo(() => {
    const result = {}
    for (const revenue of revenues) {
      result[revenue.id] = {
        value: revenue.value,
        lastConversionDatetime: revenue.lastConversionDatetime,
      }
    }
    return result
  }, [revenues])

  const shownRows = partnersFilter(
    partnersSort(
      filterBySearch<(typeof data)[number]>(
        data.map((row) => {
          const stats = row.contracts.map(
            (id) => rowStats[id] || { value: 0, lastConversionDatetime: '' }
          )
          const latestTimestamp = Math.max(
            ...stats.map((s) =>
              s?.lastConversionDatetime ? new Date(s.lastConversionDatetime) : 0
            )
          )
          const lastConversionDatetime =
            latestTimestamp > 0 ? new Date(latestTimestamp).toISOString() : ''
          return {
            ...row,
            revenue: stats.reduce((r, v) => r + v.value, 0),
            currency: totals?.commission?.currencyCode || 'DKK',
            lastConversionDatetime,
          }
        }),
        search,
        (row) => [row.name]
      ),
      sort,
      dir
    ),
    filters
  )

  return (
    <div className="card partners-card">
      <div className="card-body card-body-fill">
        <TableInfo>
          <Search
            value={search}
            setValue={setSearch}
            placeholder="Find partner"
            className="search flex-1"
          />
          <div className="vertical-middle">
            <button
              className="btn btn-dark m-l-15"
              onClick={() => {
                showModal(<NewPartnerModal />)
              }}
            >
              <SVG
                src="/images/icon-plus.svg"
                width={18}
                height={18}
                className="o-50 m-r-15"
              />
              Add Partner
            </button>
            <div className="total">
              {data?.length || 0} {plural(data?.length, 'result')}
            </div>
          </div>
        </TableInfo>

        <div className="table-container-overflow">
          <table className="table table-bordered table-sortable table-hoverable">
            <thead className="table-sticky text-nowrap">
              <tr>
                <TableHead value="name" label="Partner" {...headProps} />
                <TableHead
                  value="contactName"
                  label="Partner Contact"
                  {...headProps}
                />
                <TableHead
                  value="revenue"
                  label="Commission last 30 days"
                  {...headProps}
                  desc
                />
                <TableHead value="contactName" label="Owner" {...headProps} />
                <TableHead
                  value="lastConversionDatetime"
                  label="Last conversion"
                  {...headProps}
                  desc
                />
                <TableHead
                  value="contractCount"
                  label="Number Of Campaigns"
                  {...headProps}
                />
                <TableHead
                  value="onboardStatus"
                  label="Onboarding status"
                  {...headProps}
                />
                <TableHead
                  value="createdBy"
                  label="Created By"
                  {...headProps}
                />
                <TableHead
                  value="createdAt"
                  label="Created At"
                  {...headProps}
                />
              </tr>
            </thead>
            <tbody>
              {map(shownRows, (row) => (
                <TableItem key={row.uuid} item={row} sort={sort} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const TableItem = (props) => {
  const { item, sort } = props

  return (
    <tr>
      <td
        className={varClass({
          'text-nowrap': true,
          'sort-highlight': sort === 'name',
        })}
      >
        <button
          className="name"
          onClick={(event) => {
            navigateTo(event, `/partners/${item.uuid}`)
          }}
        >
          <div className="text-dark text-bold">{item.name}</div>
          <div className="m-l-30 vertical-middle">
            <SVG src="/images/icon-details.svg" className="arrow" />
          </div>
        </button>
      </td>
      <td
        className={varClass({
          'text-nowrap': true,
          'sort-highlight': sort === 'contactName',
        })}
      >
        {item.contactName}
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'revenue',
        })}
      >
        {formatCurrency(item.revenue || 0, item.currency)}
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'contactName',
        })}
      >
        {initials(item.contactName) && (
          <div
            className="badge text-light text-smaller text-bolder"
            title={item.contactName}
          >
            {initials(item.contactName)}
          </div>
        )}
      </td>
      <td
        className={varClass({
          'text-nowrap': true,
          'sort-highlight': sort === 'lastConversionDatetime',
        })}
      >
        {item.lastConversionDatetime
          ? formatShortDatetime(
              moment
                .utc(item.lastConversionDatetime || '1234-12-12 00:11:22')
                .local()
                .format('YYYY-MM-DD HH:mm:ss')
            )
          : '-'}
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'status',
        })}
      >
        {item.contractCount ? formatStat(item.contractCount) : '-'}
      </td>
      <td
        className={varClass({
          'text-nowrap': true,
          'sort-highlight': sort === 'status',
        })}
      >
        <Status status={item.onboardStatus} />
      </td>
      <td
        className={varClass({
          'text-nowrap': true,
          'sort-highlight': sort === 'createdBy',
        })}
      >
        {item.createdBy ? item.createdBy : '-'}
      </td>
      <td
        className={varClass({
          'text-nowrap': true,
          'sort-highlight': sort === 'createdAt',
        })}
      >
        {item.createdAt
          ? formatShortDatetime(
              moment
                .utc(item.createdAt || '1234-12-12 00:11:22')
                .local()
                .format('YYYY-MM-DD HH:mm:ss')
            )
          : '-'}
      </td>
    </tr>
  )
}

const Status = (props) => {
  const { status } = props

  return (
    <div
      className={varClass({
        'badge text-smaller text-bolder': true,
        'text-light':
          status === 'pending' || status === 'ended' || status === 'none',
        'text-new-orange': status === 'sent' || status === 'installed',
        'text-new-blue':
          status === 'signed' || status === 'running' || status === 'waiting',
        'text-green': status === 'complete',
      })}
    >
      {statusLabels[status] || capitalize(status)}
    </div>
  )
}

const NewPartnerModal = (props) => {
  const { hideModal } = useModal()

  const [error, setError] = useState('')
  const form = useForm()
  const { register, handleSubmit, formState, control } = form
  const [onSubmit, submitting] = useFormSubmit(async (values) => {
    setError('')

    if (!values.companyAddress.match(/\w/)) {
      setError('Valid address is required')
      return
    }

    const currentUser = getCurrentUser()
    values.createdBy = `${currentUser.user.userFirstname} ${currentUser.user.userLastname}`
    values.createdByEmail = currentUser.user.userEmail || ''
    const response = await connectPost(
      `/publishers/${currentUser.account?.accountUuid}`,
      values
    )

    if (response) {
      if (response.code === 200) {
        hideModal()
        navigateTo(`/partners/${response.data.uuid}#new-campaign`)
      } else {
        setError(responseError(response))
      }
    }
  })

  const placeholderIsRequired = (key: string) => {
    if (formState.errors?.[key]?.type === 'required') {
      return 'This field is required'
    } else {
      return null
    }
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Create new partner</div>
      </div>
      <div className="card-body">
        <form className="new-partner-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row m-b-5">
            <div className="control flex-1-2">
              <label>Company name</label>
              <input
                className="w-100"
                placeholder={placeholderIsRequired('companyName')}
                {...register('companyName', { required: true })}
              />
            </div>
            <div className="control flex-1-2">
              <label>Address</label>
              <input
                className="w-100"
                placeholder={placeholderIsRequired('companyAddress')}
                {...register('companyAddress', {
                  required: true,
                  minLength: 3,
                })}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control flex-1-2">
              <label>Postal code</label>
              <input
                className="w-100"
                placeholder={placeholderIsRequired('companyZip')}
                {...register('companyZip', { required: true, minLength: 3 })}
              />
            </div>
            <div className="control flex-1-2">
              <label>City</label>
              <input
                className="w-100"
                placeholder={placeholderIsRequired('companyCity')}
                {...register('companyCity', { required: true })}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control control-select flex-1-2">
              <label>Country</label>
              <select
                className="w-100"
                {...register('companyCountry', { required: true })}
              >
                <option value="">
                  {formState.errors?.companyCountry?.type === 'required'
                    ? 'This field is required'
                    : 'Choose country'}
                </option>
                <CountrySelectOptions />
              </select>
            </div>
            <div className="control flex-1-2">
              <label>VAT number</label>
              <input className="w-100" {...register('companyVat')} />
            </div>
          </div>
          {/*
            <div className="row m-b-5">
              <div className="control flex-1-2">
                <label>Invoice period</label>
                <input
                  className="w-100"
                  {...register('invoicePeriod', { required: true })}
                />
              </div>
            </div>
            */}
          <div className="text-dark text-bold m-t-30 m-b-4">Contact person</div>
          <div className="row m-b-5">
            <div className="control flex-1-2">
              <label>First name</label>
              <input
                className="w-100"
                placeholder={placeholderIsRequired('contactFirstName')}
                {...register('contactFirstName', { required: true })}
              />
            </div>
            <div className="control flex-1-2">
              <label>Last name</label>
              <input
                className="w-100"
                placeholder={placeholderIsRequired('contactLastName')}
                {...register('contactLastName', { required: true })}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control flex-1-2">
              <label>Title</label>
              <input
                className="w-100"
                placeholder={placeholderIsRequired('contactTitle')}
                {...register('contactTitle', { required: true })}
              />
            </div>
            <div className="control flex-1-2">
              <label>E-mail</label>
              <input
                type="email"
                className="w-100"
                autoComplete="off"
                placeholder={placeholderIsRequired('contactEmail')}
                {...register('contactEmail', { required: true })}
              />
            </div>
          </div>
          <div className="row m-b-5">
            <div className="control control-phone flex-1-2">
              <label>Phone number</label>
              <Controller
                control={control}
                name="contactPhone"
                render={(props) => (
                  <CountryPhoneInput
                    name={props.field.name}
                    initialCode={''}
                    initialPhone={''}
                    onChange={(values) => {
                      props.field.onChange(values.code + values.phone)
                    }}
                    dropdownOnTop
                  />
                )}
              />
            </div>
          </div>
          <div className="row row-center row-space-between m-t-8">
            <span className="text-red m-b-2">{error || ''}</span>
            <button
              className="btn btn-dark"
              type="submit"
              disabled={submitting}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
