import React, { useMemo, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { uniq } from 'lodash'
import { postRequest } from '../../../services'
import { useArray } from '../../shared'
import { Collapse } from '../../shared/elements/Collapse'
import SVG from 'react-inlinesvg'

export const CreatorModal = (props) => {
  const { campaign, hideModal, reloadCampaign, showSnackbar } = props

  const [initialCreatorsOnly, initialCreatorIds] = useMemo(() => {
    return [
      campaign.trackers.every((tracker) => tracker.trackerIsCreatorOnly),
      uniq(
        campaign.trackers
          .map((tracker) => tracker.trackerCreatorIds.split(','))
          .flat()
      )
        .sort()
        .filter((id) => !!id),
    ]
  }, [campaign])

  const [creatorsOnly, setCreatorsOnly] = useState(initialCreatorsOnly)
  const [creatorIdsMethod, setCreatorIdsMethod] = useState(
    initialCreatorIds.length ? 'specific' : 'all'
  )
  const {
    values: creatorIds,
    addAll: addAllCreatorIds,
    remove: removeCreatorId,
  } = useArray(initialCreatorIds)
  const [newCreatorId, setNewCreatorId] = useState('')

  const trackerUuids = useMemo(
    () => campaign.trackers.map((tracker) => tracker.trackerUuid),
    campaign
  )
  const addCreatorIds = () => {
    const newCreatorIds = newCreatorId
      .split(',')
      .map((id) => id.trim())
      .filter((id) => !!id)
    addAllCreatorIds(newCreatorIds)
    setNewCreatorId('')
  }

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const onSubmit = async () => {
    setSubmitting(true)
    setError('')

    const params = {
      trackerUuids,
      trackerIsCreatorOnly: creatorsOnly,
      trackerCreatorIds: [],
    }
    if (creatorIdsMethod === 'specific') {
      params.trackerCreatorIds = creatorIds
    }
    const response = await postRequest(`/advs/v1/camps/creators`, params)

    setSubmitting(false)

    if (response.code === 200) {
      showSnackbar('Creator options updated')
      reloadCampaign()
      hideModal()
    } else {
      setError(response.message)
    }
  }
  const onDisable = async () => {
    setSubmitting(true)
    setError('')

    const response = await postRequest(`/advs/v1/camps/creators`, {
      trackerUuids,
      trackerIsCreatorOnly: false,
      trackerCreatorIds: [],
    })

    setSubmitting(false)

    if (response.code === 200) {
      showSnackbar('Creator options updated')
      reloadCampaign()
      hideModal()
    } else {
      setError(response.message)
    }
    setSubmitting(false)
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title row row-space-between">
          Edit creator options
        </div>
      </div>

      <div className="card-body">
        <div className="flex-1">
          <div className="m-b-4">
            <div className="m-b-1">
              <span className="text-light m-r-1">Network:</span>
              <span className="text-bold text-dark">
                {campaign.network.networkName}
              </span>
            </div>
            <div className="m-b-1">
              <span className="text-light m-r-1">Campaign:</span>
              <span className="text-bold text-dark">
                {campaign.campaign.campaignName}
              </span>
              <span className="m-l-1 text-light">
                ({trackerUuids.length} domain
                {trackerUuids.length === 1 ? '' : 's'})
              </span>
            </div>
          </div>

          <div className="m-b-2">
            <div className="control control-checkbox">
              <input
                type="checkbox"
                checked={creatorsOnly}
                onChange={(event) => setCreatorsOnly(event.target.checked)}
              />
              <label>Creators only</label>
            </div>
            <span
              data-tip="Only clicks from creators will be able to use this campaign"
              data-for="creator-tooltip"
              className="m-l-1"
            >
              <SVG src="/images/recommendations/icon-info.svg" />
            </span>
          </div>

          <div className="m-b-2">
            <div className="control control-checkbox">
              <input
                type="checkbox"
                checked={creatorIdsMethod === 'specific'}
                onChange={(event) =>
                  setCreatorIdsMethod(event.target.checked ? 'specific' : 'all')
                }
              />
              <label>Manual approval</label>
            </div>
            <span
              data-tip="Clicks from these creators will be prioritized for this campaign"
              data-for="creator-tooltip"
              className="m-l-1"
            >
              <SVG src="/images/recommendations/icon-info.svg" />
            </span>
          </div>

          <ReactTooltip
            id="creator-tooltip"
            type="dark"
            effect="solid"
            place="right"
          />

          <Collapse open={creatorIdsMethod === 'specific'} dynamic>
            <div>
              <div className="row">
                <div className="flex-1 control w-100">
                  <input
                    className="w-100"
                    placeholder="Add creator IDs (comma separated)"
                    value={newCreatorId}
                    onChange={(event) => setNewCreatorId(event.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        addCreatorIds()
                      }
                    }}
                  />
                </div>
                <button className="btn btn-primary" onClick={addCreatorIds}>
                  <SVG src="/images/icon-plus2-color.svg" />
                </button>
              </div>
              <div className="m-t-2">
                {creatorIds.map((creatorId: string) => (
                  <span key={creatorId} className="chip m-t-1 m-r-1">
                    <span>{creatorId}</span>
                    <button
                      className="chip-btn"
                      onClick={() => {
                        removeCreatorId(creatorId)
                      }}
                    >
                      <SVG
                        width={14}
                        height={14}
                        className="rotate-45"
                        src="/images/icon-plus2-color.svg"
                      />
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </Collapse>
        </div>
        <div className="row row-space-between row-center row-nowrap m-t-4">
          <div className="text-red max-w-50">{error}</div>
          <div className="row">
            {initialCreatorsOnly || initialCreatorIds?.length ? (
              <button
                className="btn btn-bordered"
                disabled={submitting}
                onClick={onDisable}
              >
                Remove
              </button>
            ) : null}

            <button
              className="btn btn-dark"
              disabled={submitting}
              onClick={onSubmit}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
