import React from 'react'
import SVG from 'react-inlinesvg'
import { uniqBy } from 'lodash'
import { Placeholder } from './Placeholder'
import {
  Header,
  Tooltip,
  WithTooltip,
  Loadable,
  SmallLoadable,
  Logo,
  ConnectNetworkModal,
  NoDataDiv,
  TableHead,
  useSnackbar,
  useApiGet,
  useCounter,
  useModal,
  useDrawer,
  useSortableTable,
  formatStat,
  map,
  newTableSort,
  isBlank,
  plural,
  varClass,
  navigateTo,
  copyToClipboard,
  responseError,
} from '../../shared'
import { postRequest, deleteRequest } from '../../../services/api'

const findNetwork = (networks, networkUuid) =>
  networks.find((network) => network.networkUuid === networkUuid)

const isNetworkConnected = (connections, network): boolean => {
  if (!network || !connections || !Array.isArray(connections)) {
    return false
  }
  try {
    return !!connections?.find(
      (connection) => connection.network?.networkUuid === network.networkUuid
    )
  } catch (error) {
    console.log(error)
  }
  return false
}

const opportunitySort = newTableSort((a: any, b: any, key: string) => {
  if (key === 'channel') {
    if (a.channels) {
      return [a.channels.length, b.channels.length]
    }
    if (a.channel) {
      return [a.channel?.channelDomain || '', b.channel?.channelDomain || '']
    }
  }
})

const channelSort = (a: any, b: any) => {
  const valueA = a.channelName || a.channelDomain
  const valueB = b.channelName || b.channelDomain
  if (valueA > valueB) return 1
  if (valueA < valueB) return -1
  return 0
}

export const Opportunities = (props) => {
  const { showModal } = useModal()
  const { showDrawer } = useDrawer()
  const { showSnackbar } = useSnackbar()

  const channels = useApiGet('/channels/v1/list')
  const networks = useApiGet('/networks/v1/networks')
  const connections = useApiGet('/networks/v1/connections')

  const [reloadRef, reload] = useCounter()

  const data = useApiGet(
    '/discovers/v3/opportunities_alternatives/overview',
    {},
    [reloadRef]
  )

  const ignoreOpportunity = async (opportunity) => {
    opportunity.ignored.isIgnored = true
    const response = await postRequest('/discovers/v3/opportunities/ignores', {
      opportunityIgnoreNetworkUuid: opportunity.network.networkUuid,
      opportunityIgnoreType: opportunity.opportunityType,
    })
    if (response.code === 200) {
      opportunity.ignored.opportunityIgnoreUuid = response.data
      reload()
    } else {
      showSnackbar(responseError(response))
    }
  }

  const unignoreOpportunity = async (opportunity) => {
    opportunity.ignored.isIgnored = false
    const response = await deleteRequest(
      `/discovers/v3/opportunities/ignores/${opportunity.ignored.opportunityIgnoreUuid}`
    )
    if (response.code === 200) {
      opportunity.ignored.opportunityIgnoreUuid = ''
      reload()
    } else {
      showSnackbar(responseError(response))
    }
  }

  const contentProps = {
    networks,
    channels,
    connections,
    ignoreOpportunity,
    unignoreOpportunity,
    showModal,
    showDrawer,
    showSnackbar,
  }

  return (
    <>
      <Header label="Opportunities" />
      {channels && isBlank(channels) ? (
        <div className="card card-transparent">
          <div className="row row-nowrap row-center row-space-between">
            <div className="row row-nowrap row-center">
              <SVG src="/images/empty-opportunities.svg" />
              <div className="column column-fill">
                <div className="text-bigger text-bolder m-b-10">
                  No data available yet
                </div>
                <div className="text-bold">
                  To see your opportunities simply create a Site and add the
                  Tracking script
                </div>
              </div>
            </div>
            <button
              className="btn btn-dark"
              onClick={(event) => {
                navigateTo(event, '/sites')
              }}
            >
              Create site
            </button>
          </div>
        </div>
      ) : (
        <>
          <hr className="m-t-0 m-b-40" />
          <div className="row row-wider row-nowrap row-md-rev">
            <div className="flex-1 flex-1-3">
              <SVG src="/images/discover/icon-opportunities.svg" />
              <div className="text-dark text-big text-bolder m-t-40 m-b-4">
                Connect to networks
              </div>
              <div className="text-bold m-b-40">
                If we find that your websites are sending valuable clicks to a
                merchant that you are not running a campaign with yet, we’ll
                show those opportunities here and you can instantly connect a
                new network to start monetising those clicks and boost your
                revenue.
              </div>
              <button
                className="btn btn-md btn-bordered background-white"
                onClick={(event) => {
                  navigateTo(event, '/networks')
                }}
              >
                Show all networks
              </button>
            </div>
            <div className="flex-2 flex-2-3">
              <Loadable
                data={data && networks && channels && connections}
                placeholder={<Placeholder />}
              >
                <Content data={data} {...contentProps} />

                <Tooltip type="dark" place="bottom" />
              </Loadable>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const Content = (props) => {
  const {
    data,
    networks,
    channels,
    connections,
    ignoreOpportunity,
    unignoreOpportunity,
    showModal,
    showDrawer,
    showSnackbar,
  } = props

  // const [shownData, _setShownData] = useState(() => [
  //   {
  //     'network': {
  //       'networkUuid': networks[12].networkUuid,
  //       'networkName': networks[12].networkName,
  //     },
  //     'advertisers': [
  //       {
  //         'advertiserUuid': '52d0afe1-d3c9-4e61-a1ab-f0c7ab448783',
  //         'advertiserDomain': 'skilte-gruppen.dk',
  //       },
  //     ],
  //     'channels': [
  //       {
  //         'channelUuid': '1738ffa2-c9d7-4724-8f64-c551719c9a52',
  //         'channelName': 'demo.dk',
  //         'channelDomain': 'https://demo.dk',
  //       },
  //     ],
  //     'opportunityClicks': 50,
  //     'opportunityType': 'connect',
  //     'ignored': {
  //       'isIgnored': false,
  //       'opportunityIgnoreUuid': '',
  //     },
  //   },
  //   {
  //     'network': {
  //       'networkUuid': networks[1].networkUuid,
  //       'networkName': networks[1].networkName,
  //     },
  //     'advertisers': [
  //       {
  //         'advertiserUuid': '5ef64653-64d7-4f8c-897f-56207319f444',
  //         'advertiserDomain': 'jydsktagteknik.dk',
  //       },
  //     ],
  //     'channels': [
  //       {
  //         'channelUuid': '1738ffa2-c9d7-4724-8f64-c551719c9a52',
  //         'channelName': 'demo.dk',
  //         'channelDomain': 'https://demo.dk',
  //       },
  //     ],
  //     'opportunityClicks': 50,
  //     'opportunityType': 'connect',
  //     'ignored': {
  //       'isIgnored': true,
  //       'opportunityIgnoreUuid': '51eb41ba-957b-4932-909d-ae4ab2d375ef',
  //     },
  //   },
  // ])

  const shownData = opportunitySort(data, 'opportunityClicks', 'desc')

  return (
    <>
      {map(shownData, (opportunity) => (
        <React.Fragment key={opportunity.network.networkUuid}>
          {!opportunity.ignored.isIgnored ? (
            <Opportunity
              opportunity={opportunity}
              network={findNetwork(networks, opportunity.network.networkUuid)}
              channels={channels}
              connections={connections}
              ignoreOpportunity={ignoreOpportunity}
              showModal={showModal}
              showDrawer={showDrawer}
              showSnackbar={showSnackbar}
            />
          ) : (
            <IgnoredOpportunity
              opportunity={opportunity}
              network={findNetwork(networks, opportunity.network.networkUuid)}
              unignoreOpportunity={unignoreOpportunity}
            />
          )}
        </React.Fragment>
      ))}
      {isBlank(data) && (
        <NoDataDiv
          description="No new opportunities found yet."
          className="p-y-50"
        />
      )}
    </>
  )
}

const Opportunity = (props) => {
  const {
    opportunity,
    network,
    channels,
    connections,
    ignoreOpportunity,
    showModal,
    showDrawer,
    showSnackbar,
  } = props

  if (!network) {
    return null
  }

  const isConnected = isNetworkConnected(connections, network)

  return (
    <div className="card discover-opportunity-card m-b-10">
      <div className="row row-bottom row-space-between row-nowrap">
        <div>
          <div
            className="cursor-pointer"
            onClick={() => {
              showDrawer(
                <AdvertisersDrawer
                  opportunity={opportunity}
                  network={network}
                  showSnackbar={showSnackbar}
                />,
                {
                  cardClassName: 'discover-opportunity-drawer',
                }
              )
            }}
          >
            <Logo
              src={network.networkLogoUrl}
              text={network.networkName}
              width={100}
              height={24}
              className="m-t-15"
            />
            <div className="text-dark text-bolder m-t-6 m-b-30">
              Connect to {network.networkName}
            </div>
          </div>

          <div className="row row-narrow">
            {!isConnected && (
              <button
                className="btn btn-dark vertical-middle"
                onClick={() => {
                  showModal(
                    <ConnectNetworkModal
                      network={network}
                      channels={channels}
                    />
                  )
                }}
              >
                <SVG src="/images/icon-plus.svg" className="o-40 m-r-2" />
                Connect network
              </button>
            )}
            <button
              className="btn btn-bordered text-primary"
              onClick={() => {
                ignoreOpportunity(opportunity)
              }}
            >
              Ignore for now
            </button>
          </div>
        </div>

        <div className="column column-bottom column-right column-fill">
          {map(opportunity.channels?.sort(channelSort), (channel) => (
            <div
              key={channel.channelUuid}
              className="tag-channel m-b-1"
              onClick={(event) => {
                navigateTo(event, `/sites/${channel.channelUuid}`)
              }}
            >
              {channel.channelName}
            </div>
          ))}
          <div
            className="value-badge value-badge-clickCount value-badge-hoverable cursor-pointer m-t-4"
            onClick={() => {
              showDrawer(
                <AdvertisersDrawer
                  opportunity={opportunity}
                  network={network}
                  showSnackbar={showSnackbar}
                />,
                {
                  cardClassName: 'discover-opportunity-drawer',
                }
              )
            }}
          >
            {opportunity.opportunityClicks}{' '}
            {plural(opportunity.opportunityClicks, 'click')}
          </div>
        </div>
      </div>
    </div>
  )
}

const IgnoredOpportunity = (props) => {
  const { opportunity, network, unignoreOpportunity } = props

  if (!network) {
    return null
  }

  return (
    <div className="card discover-opportunity-card m-b-10">
      <div className="row row-top row-space-between">
        <div className="o-50">
          <Logo
            src={network.networkLogoUrl}
            text={network.networkName}
            width={100}
            height={24}
            className="m-t-15"
          />
        </div>

        <div>
          <span className="text-light text-bolder text-italic m-r-30 o-40">
            Ignored
          </span>
          <button
            className="btn btn-light text-bolder vertical-middle"
            onClick={() => {
              unignoreOpportunity(opportunity)
            }}
          >
            Open opportunity
            <SVG src="/images/chevron-right.svg" width={18} className="m-l-1" />
          </button>
        </div>
      </div>
    </div>
  )
}

const AdvertisersDrawer = (props) => {
  const { opportunity, network, showSnackbar } = props

  const { hideDrawer } = useDrawer()

  const data = useApiGet<any>(
    `/discovers/v3/opportunities_alternatives/details/${network.networkUuid}`,
    {},
    [opportunity, network]
  )

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'opportunityClicks',
    dir: 'desc',
  })

  const headProps = { sort, dir, toggleSort }

  const shownData = opportunitySort(
    uniqBy(data, (item: any) => item.opportunityUrlDomain),
    sort,
    dir
  )

  return (
    <div className="card-body">
      <div className="vertical-middle">
        {/*
        <Logo
          src={network.networkLogoUrl}
          text={network.networkName}
          width={32}
          height={32}
          className="m-r-2"
        />
        */}
        <span className="text-dark text-big text-bolder">
          {network.networkUrl}
        </span>
      </div>

      <SmallLoadable loaded={data} height={100}>
        <table className="table table-bordered table-sortable">
          <thead>
            <tr>
              <TableHead
                value="opportunityUrlDomain"
                label="URL"
                {...headProps}
              />
              <TableHead value="opportunityType" label="Type" {...headProps} />
              <TableHead
                value="opportunityClicks"
                label="Clicks"
                desc
                {...headProps}
              />
              <TableHead value="channel" label="Site" {...headProps} />
            </tr>
          </thead>
          <tbody>
            {map(shownData, (item, index) => (
              <TableItem
                key={`${item.opportunityType}_${item.opportunityUrlDomain}_${item.opportunityClicks}_${item.channel?.channelUuid}`}
                item={item}
                hideDrawer={hideDrawer}
                showSnackbar={showSnackbar}
                sort={sort}
              />
            ))}
            {isBlank(data) && (
              <tr>
                <td colSpan={4} className="text-center text-light text-bolder">
                  Nothing found
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <Tooltip
          id="opportunities-drawer-tooltip"
          type="dark"
          place="bottom"
          rebuild={[shownData]}
        />
      </SmallLoadable>
    </div>
  )
}

const TableItem = (props) => {
  const { item, hideDrawer, showSnackbar, sort } = props

  const domain = (item.opportunityUrlDomain || 'Unknown').replace('www.', '')

  return (
    <tr>
      <td
        className={varClass({
          'relative w-30 p-r-40': true,
          'text-dark sort-highlight': sort === 'opportunityUrlDomain',
        })}
      >
        <button
          className="inline-block overflow-hide link ctc"
          onClick={() => {
            copyToClipboard(domain)
            showSnackbar('Copied to clipboard')
          }}
        >
          {domain}
        </button>
      </td>
      <td
        className={varClass({
          'w-0 text-right': true,
          'sort-highlight': sort === 'opportunityType',
        })}
      >
        {item.opportunityType === 'connect' && (
          <WithTooltip
            className="opportunity-type"
            title="You are sending clicks through that could be making you more money. ‘New’ opportunities show non-monetised clicks while ‘Alternative’ opportunities show campaigns with higher commission than the one being used today"
            text={
              <>
                <SVG src="/images/recommendations/icon-campaigns.svg" />
                New
              </>
            }
            forceShow
            tooltipId="opportunities-drawer-tooltip"
          />
        )}
        {item.opportunityType === 'alternative' && (
          <WithTooltip
            className="opportunity-type"
            title="You are sending clicks through that could be making you more money. ‘New’ opportunities show non-monetised clicks while ‘Alternative’ opportunities show campaigns with higher commission than the one being used today"
            text={
              <>
                <SVG src="/images/recommendations/icon-alt-campaigns.svg" />
                Alternative
              </>
            }
            forceShow
            tooltipId="opportunities-drawer-tooltip"
          />
        )}
      </td>
      <td
        className={varClass({
          'text-dark sort-highlight': sort === 'opportunityClicks',
        })}
      >
        {formatStat(item.opportunityClicks || 0)}
      </td>
      <td
        className={varClass({
          'overflow-hide': true,
          'text-dark sort-highlight': sort === 'channel',
        })}
      >
        {map(item.channels, (channel) => (
          <span
            key={channel.channelUuid}
            className="badge badge-hoverable text-smaller p-y-05 m-05"
            onClick={(event) => {
              hideDrawer()
              navigateTo(event, `/sites/${channel.channelUuid}`)
            }}
          >
            {channel.channelName || channel.channelDomain}
          </span>
        ))}
      </td>
    </tr>
  )
}
