import React, { useEffect, useMemo } from 'react'
import moment from 'moment'
import {
  CartesianGrid,
  AreaChart,
  Area,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { colors, graphHeight } from './constants'
import { formatStat, isBlank, formatShortStat, groupSort } from '../../shared'

const formatDate = (value: string, isSingleDate: boolean) => {
  const result = isSingleDate
    ? moment(value).format('HH:00')
    : moment(value).format('MMM. D')
  if (!value || result === 'Invalid date') {
    return ''
  } else {
    return result
  }
}

export const PerformanceAreaChart = (props) => {
  const { rows, graphColumns, setGraphHeight, isSingleDate } = props

  const isGraphColumnShown = (value) => graphColumns.indexOf(value) !== -1
  const isLastGraphColumn = (value) =>
    graphColumns[graphColumns.length - 1] === value

  const data = useMemo(() => {
    if (!rows || !Array.isArray(rows) || isBlank(rows)) {
      return []
    } else {
      return rows.sort(groupSort).map((row, index) => ({
        date: row.grp,
        pageviews: row.pageviewCount,
        clicks: row.clickCount,
        conversions: row.conversionCount,
        commission: row.conversionTotalCommission,
      }))
    }
  }, [rows])

  useEffect(() => {
    setGraphHeight(graphHeight)
  }, [])

  return (
    <ResponsiveContainer
      width="99.9%"
      height={graphHeight}
      className="performance-area-chart"
    >
      <AreaChart
        data={data}
        margin={{ top: 16, left: 0, right: 16, bottom: 16 }}
      >
        <defs>
          <linearGradient id="stroke-pageviews" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor={colors.pageviews} stopOpacity={0.5} />
            <stop offset="100%" stopColor={colors.pageviews} stopOpacity={1} />
          </linearGradient>
          <linearGradient id="fill-pageviews" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={colors.pageviews} stopOpacity={0.1} />
            <stop offset="100%" stopColor="#ffffff" stopOpacity={0.2} />
          </linearGradient>

          <linearGradient id="stroke-clicks" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor={colors.clicks} stopOpacity={0.5} />
            <stop offset="100%" stopColor={colors.clicks} stopOpacity={1} />
          </linearGradient>
          <linearGradient id="fill-clicks" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={colors.clicks} stopOpacity={0.1} />
            <stop offset="100%" stopColor="#ffffff" stopOpacity={0.2} />
          </linearGradient>

          <linearGradient id="stroke-conversions" x1="0" y1="0" x2="1" y2="0">
            <stop
              offset="0%"
              stopColor={colors.conversions}
              stopOpacity={0.5}
            />
            <stop
              offset="100%"
              stopColor={colors.conversions}
              stopOpacity={1}
            />
          </linearGradient>
          <linearGradient id="fill-conversions" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor={colors.conversions}
              stopOpacity={0.1}
            />
            <stop offset="100%" stopColor="#ffffff" stopOpacity={0.2} />
          </linearGradient>

          <linearGradient id="stroke-commission" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor={colors.commission} stopOpacity={0.5} />
            <stop offset="100%" stopColor={colors.commission} stopOpacity={1} />
          </linearGradient>
          <linearGradient id="fill-commission" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={colors.commission} stopOpacity={0.1} />
            <stop offset="100%" stopColor="#ffffff" stopOpacity={0.2} />
          </linearGradient>
        </defs>

        <CartesianGrid
          horizontal={false}
          strokeDasharray="6"
          stroke="#EDEFF2"
        />

        <Tooltip content={<GraphTooltip />} />

        {GraphArea('pageviews', isGraphColumnShown('pageviews'))}
        {GraphArea('clicks', isGraphColumnShown('clicks'))}
        {GraphArea('conversions', isGraphColumnShown('conversions'))}
        {GraphArea('commission', isGraphColumnShown('commission'))}

        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          interval="preserveStartEnd"
          tick={<GraphXTick isSingleDate={isSingleDate} />}
        />
        <YAxis
          yAxisId="pageviews-y"
          axisLine={false}
          tickLine={false}
          allowDecimals={false}
          interval="preserveStartEnd"
          tick={<GraphYTick />}
          hide={!isLastGraphColumn('pageviews')}
        />
        <YAxis
          yAxisId="clicks-y"
          axisLine={false}
          tickLine={false}
          allowDecimals={false}
          interval="preserveStartEnd"
          tick={<GraphYTick />}
          hide={!isLastGraphColumn('clicks')}
        />
        <YAxis
          yAxisId="conversions-y"
          axisLine={false}
          tickLine={false}
          allowDuplicatedCategory={false}
          allowDecimals={false}
          interval="preserveStartEnd"
          tick={<GraphYTick />}
          hide={!isLastGraphColumn('conversions')}
        />
        <YAxis
          yAxisId="commission-y"
          axisLine={false}
          tickLine={false}
          allowDuplicatedCategory={false}
          interval="preserveStartEnd"
          tick={<GraphYTick />}
          hide={!isLastGraphColumn('commission')}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

const GraphXTick = (props) => (
  <text x={props.x} y={props.y + 20} textAnchor="middle" fill="#828B9E">
    {formatDate(props.payload.value, props.isSingleDate)}
  </text>
)

const GraphYTick = (props) => (
  <g transform={`translate(${16},${props.y + 2})`}>
    <text x={0} y={0} textAnchor="start" fill="#828B9E">
      {formatShortStat(props.payload.value)}
    </text>
  </g>
)

const GraphArea = (key, active) => (
  <Area
    type="linear"
    dataKey={key}
    yAxisId={`${key}-y`}
    stroke={`url(#stroke-${key})`}
    strokeWidth={active ? 3 : 1}
    fill={`url(#fill-${key})`}
    fillOpacity={active ? 1 : 0}
    dot={false}
    hide={!active}
    activeDot={
      active
        ? {
            stroke: '#fff',
            strokeWidth: 8,
            r: 10,
            className: 'chart-dot',
          }
        : false
    }
  />
)

const GraphTooltip = (props) => {
  const { active, payload } = props

  if (!active || !payload) {
    return null
  }

  const date = payload[0].payload.date || ''
  const pageviews = payload[0].payload.pageviews || 0
  const clicks = payload[0].payload.clicks || 0
  const conversions = payload[0].payload.conversions || 0
  const commission = payload[0].payload.commission || 0

  return (
    <div className="chart-tooltip">
      <div className="chart-tooltip-header">
        <div className="row row-center row-space-between">
          <span>Performance</span>
          <span className="text-smaller text-bold">{date.substr(5)}</span>
        </div>
      </div>

      <div className="chart-tooltip-body">
        <ul>
          {pageviews !== undefined && (
            <li className="chart-tooltip-pageviews">
              <span>Pageviews</span> <span>{formatStat(pageviews)}</span>
            </li>
          )}
          {clicks !== undefined && (
            <li className="chart-tooltip-clicks">
              <span>Clicks</span> <span>{formatStat(clicks)}</span>
            </li>
          )}
          {conversions !== undefined && (
            <li className="chart-tooltip-conversions">
              <span>Conversions</span> <span>{formatStat(conversions)}</span>
            </li>
          )}
          {commission !== undefined && (
            <li className="chart-tooltip-commission">
              <span>Commission</span> <span>{formatStat(commission)}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
