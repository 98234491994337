import React, { useState, useEffect, useRef } from 'react'
import { varClass } from '..'

const animationLength = 500

export const Collapse = (props) => {
  const { open, dynamic } = props

  const [maxHeight, setMaxHeight] = useState(0)
  const [hideOverflow, setHideOverflow] = useState(!open)

  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setHideOverflow(false)
      }, animationLength)
    } else {
      setHideOverflow(true)
    }
  }, [open])

  useEffect(() => {
    const el = ref.current?.children?.[0]
    if (el) {
      const height = el.clientHeight
      const styles = window.getComputedStyle(el)
      const margins =
        parseFloat(styles.marginTop) + parseFloat(styles.marginBottom)
      setMaxHeight(Math.ceil(height + margins))
    }
  }, [ref.current, dynamic ? open : false])

  return (
    <div
      className={varClass({
        'collapsable': true,
        'open': open,
      })}
      style={{
        maxHeight: open ? maxHeight : 0,
        overflow: hideOverflow ? 'hidden' : 'visible',
      }}
      ref={ref}
    >
      {props.children}
    </div>
  )
}
