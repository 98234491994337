import React, { useEffect, useMemo, useRef, useState } from 'react'
import SVG from 'react-inlinesvg'
import {
  INetworksV1Network,
  INetworksV2Networks,
  capitalize,
  ConnectNetworkModal,
  copyToClipboard,
  Header,
  isBlank,
  Loadable,
  Logo,
  map,
  navigateReload,
  navigateTo,
  RemoveMappingModal,
  Search,
  SmallLoadable,
  toArray,
  useApiGet,
  useApiPost,
  useCounter,
  useModal,
  useOutsideClick,
  useSnackbar,
  varClass,
} from '../shared'
import { deleteRequest, postRequest, putRequest } from '../../services'
import moment from 'moment'

const featureLabels = {
  apiConversions: 'Conversions',
  apiPaidConversions: 'Payment status',
  apiPrograms: 'Programs',
  apiChannels: 'Channels',
  apiRejections: 'Rejections',
  apiCpc: 'CPC',
  callbacks: 'Real-time callbacks',
}

const connectionSort = (a, b) => {
  if (a.connectionChannelName < b.connectionChannelName) {
    return -1
  } else if (a.connectionChannelName > b.connectionChannelName) {
    return 1
  } else {
    return 0
  }
}

const mappingsSort = (a, b) => {
  if (a.channel.channelName < b.channel.channelName) {
    return -1
  } else if (a.channel.channelName > b.channel.channelName) {
    return 1
  } else {
    return 0
  }
}

export const NetworkDetailV2 = (props) => {
  const { networkUuid } = props

  const [reloadRef, reload] = useCounter()

  const channels = useApiGet('/channels/v1/list')
  const data = useApiGet<INetworksV1Network>(
    `/networks/v1/networks/${networkUuid}`,
    {},
    [reloadRef]
  )
  const networks = useApiGet<INetworksV2Networks[]>(
    '/networks/v2/networks',
    {},
    [reloadRef]
  )
  const networkDetail = useMemo(() => {
    if (networks) {
      return networks.find((network) => network.networkUuid === networkUuid)
    } else {
      return undefined
    }
  }, [networks, networkUuid])

  if (data && isBlank(data)) {
    setTimeout(reload, 2000)
    return null
  }

  return (
    <>
      <Header
        backLabel={data?.network?.networkName || 'Single Network'}
        backLabelClass={'text-dark text-bigger text-bolder m-t-30 m-b-6'}
        backPath="/network2"
      />
      <Loadable data={data && networkDetail}>
        <Content {...{ data, networkDetail, channels, reload }} />
      </Loadable>
    </>
  )
}

const Content = (props) => {
  const { data, networkDetail, channels, reload } = props

  const { network, features } = data
  const connections = toArray(data.connections)
  const [listConnection, setListConnection] = useState([])
  const [selectedConnection, setSelectedConnection] = useState(null)
  useEffect(() => {
    const listConnection = connections.map((c, index) => ({
      ...c,
      connectionName:
        c.connectionName || `${data.network.networkName} ${index + 1}`,
    }))
    if (listConnection.length) {
      setListConnection(listConnection)
      setSelectedConnection(listConnection[0])
    }
  }, [connections])

  const changeConnectionHandler = (
    field: string,
    connectionUuid: string,
    value: any
  ) => {
    const updatedList = listConnection.map((c) => {
      if (c.connectionUuid === connectionUuid) {
        return { ...c, [field]: value }
      }
      return { ...c }
    })
    setListConnection(updatedList)
    if (selectedConnection.connectionUuid === connectionUuid) {
      setSelectedConnection({ ...selectedConnection, [field]: value })
    }
  }

  const { showSnackbar } = useSnackbar()

  return (
    <>
      <div className="p-0 network-detail">
        <div className="general-info card">
          <div className="card-body p-t-0">
            <div className="row row-narrow row-space-between row-bottom">
              <Logo
                src={network.networkLogoUrl}
                text={network.networkName}
                className='logo-left'
                width={110}
                height={30}
              />
              <a
                href={`https://${network.networkUrl}`}
                className="text-light text-small link vertical-middle"
                target="_blank"
                rel="noreferrer"
              >
                <span>{network.networkUrl}</span>
                <SVG src="/images/icon-outside.svg" className="m-l-2" />
              </a>
            </div>
            <div className="m-b-5">
              <NetworkAccounts
                {...{
                  connections: listConnection,
                  selectedConnection,
                  setSelectedConnection,
                  networkDetail,
                  channels,
                }}
              />
            </div>
            <div>
              <div className="general-label row row-narrow row-center m-b-5">
                <span>Supported features</span>
                <SVG src="/images/recommendations/icon-info.svg" />
              </div>
              <div className="column list-feature">
                {map(featureLabels, (key, label) => (
                  <div key={key} className="row row-center row-narrow">
                    <SVG
                      src={`/images/networks/featurev2-${
                        features[key] ? 'on' : 'off'
                      }.svg`}
                      className="m-r-10"
                    />
                    <span
                      className={varClass({
                        'feature-label': true,
                        'off': !features[key],
                      })}
                    >
                      {label}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="main-info">
          {selectedConnection && (
            <div className="card m-b-5">
              <div className="card-body network-show-connection p-t-0">
                <NetworkInfo
                  selectedConnection={selectedConnection}
                  changeConnectionNameHandler={(connectionUuid, value) => {
                    changeConnectionHandler(
                      'connectionName',
                      connectionUuid,
                      value
                    )
                  }}
                  networkDetail={networkDetail}
                />
              </div>
            </div>
          )}
          {!!features.callbacks && (
            <div className="card m-b-5">
              <div className="card-body p-t-0">
                <ConnectionInfo
                  selectedConnection={selectedConnection}
                  network={networkDetail}
                  showSnackbar={showSnackbar}
                />
              </div>
            </div>
          )}
          {selectedConnection && (
            <div className="card m-b-5">
              <div className="card-body p-t-0">
                <FallbackBox
                  selectedConnection={selectedConnection}
                  changeFallbackStatusHandler={(connectionUuid, value) => {
                    changeConnectionHandler(
                      'connectionIsFallback',
                      connectionUuid,
                      value
                    )
                  }}
                />
              </div>
            </div>
          )}

          {/*<div className='card'>*/}
          {/*  <div className='card-body p-t-0'>*/}
          {/*    <PropertyInfo*/}
          {/*      selectedConnection={selectedConnection}*/}
          {/*      showModal={showModal}*/}
          {/*      showSnackbar={showSnackbar}*/}
          {/*      reload={reload}*/}
          {/*      channels={channels}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  )
}

const NetworkInfo = ({
  selectedConnection,
  networkDetail,
  changeConnectionNameHandler,
}) => {
  const [editing, setEditing] = useState(false)
  const [newName, setNewName] = useState('')
  const [currentNetworkName, setCurrentNetworkName] = useState('')
  const { showModal, hideModal } = useModal()

  const ref = useRef()
  useOutsideClick(ref, () => {
    setEditing(false)
  })

  const [createdAt, lastUpdatedAt] = useMemo(() => {
    const createdAt = selectedConnection?.connectionCreatedDatetime
      ? moment(selectedConnection.connectionCreatedDatetime).format(
          'DD-MM-YYYY'
        )
      : '-'
    const lastUpdatedAt = selectedConnection?.connectionUpdatedDatetime
      ? moment(selectedConnection.connectionUpdatedDatetime).format(
          'DD-MM-YYYY'
        )
      : '-'
    return [createdAt, lastUpdatedAt]
  }, [selectedConnection])

  useEffect(() => {
    if (selectedConnection) {
      setNewName(selectedConnection.connectionName)
      setCurrentNetworkName(selectedConnection.connectionName)
      setEditing(false)
    }
  }, [selectedConnection])

  const submit = async () => {
    setEditing(false)
    if (currentNetworkName !== newName) {
      changeConnectionNameHandler(selectedConnection.connectionUuid, newName)
      await putRequest(
        `/networks/v1/connections/${selectedConnection.connectionUuid}`,
        {
          connectionName: newName,
        }
      )
    }
  }

  const renderName = () => {
    if (editing) {
      return (
        <div className="edit-container vertical-middle text-nowrap" ref={ref}>
          <input
            value={newName}
            onChange={(event) => setNewName(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                submit()
              }
              if (event.key === 'Escape') {
                setNewName(currentNetworkName)
                setEditing(false)
              }
            }}
          />
          <button className="link m-l-2 m-b-2" onClick={submit}>
            Save
          </button>
        </div>
      )
    } else {
      return (
        <div className="text-dark text-big text-bolder vertical-middle">
          {currentNetworkName}
          <button
            className="edit-btn link m-l-2"
            onClick={() => {
              setEditing(true)
              setTimeout(() => {
                const element = document.querySelector(
                  '.edit-container input'
                ) as HTMLInputElement
                if (element) {
                  element.focus()
                }
              }, 0)
            }}
          >
            <SVG src="/images/networks/icon-edit2.svg" />
          </button>
        </div>
      )
    }
  }

  return (
    <div>
      <div className="row row-space-between">
        {renderName()}
        {networkDetail?.networkIsConnected && (
          <button
            className="network-status-label cursor-pointer text-red m-b-1"
            onClick={() => {
              showModal(<RemoveModal connection={selectedConnection} />)
            }}
          >
            Disconnect
          </button>
        )}
      </div>
      <div className="network-created-time-label">
        Created date: {createdAt}
      </div>
      <div className="network-created-time-label m-t-1">
        Last import date: {lastUpdatedAt}
      </div>
    </div>
  )
}

const ConnectionInfo = ({ selectedConnection, showSnackbar, network }) => {
  return (
    <div className="network-show-connection ">
      <div className="row row-space-between row-center w-100 m-b-5">
        <div className="row row-narrow row-center">
          <span className="general-label">Real-time callback</span>
          {selectedConnection && (
            <span
              className={varClass({
                'tag': true,
                'text-orange':
                  selectedConnection.connectionCallbackStatus === 'PENDING',
                'text-red':
                  selectedConnection.connectionCallbackStatus === 'INACTIVE',
                'text-green':
                  selectedConnection.connectionCallbackStatus === 'ACTIVE',
              })}
            >
              {capitalize(
                selectedConnection.connectionCallbackStatus.toLowerCase()
              )}
            </span>
          )}
        </div>
      </div>
      <div className="text-bold text-light m-t-5 w-90">
        Real-time callbacks enables you to get live data from affiliate networks
        to Heylink Outbound instead of hourly or daily imports. Copy callback
        link and paste it to your Webgains account.
      </div>
      <div className="realtime-status m-t-5">
        <div>
          {network?.networkIsConnected ? (
            <Callback
              {...{
                network,
                selectedConnection,
                showSnackbar,
              }}
            />
          ) : (
            <span>Connect network to view Call back URL</span>
          )}
        </div>
      </div>
    </div>
  )
}

const FallbackBox = ({ selectedConnection, changeFallbackStatusHandler }) => {
  const { showModal, hideModal } = useModal()
  const [loaded, setLoaded] = useState(true)
  const isFallback = useMemo(
    () => !!selectedConnection?.connectionIsFallback,
    [selectedConnection]
  )
  const handleNetworkIsFallback = async (isFallback: boolean) => {
    if (selectedConnection?.connectionUuid) {
      setLoaded(false)
      await putRequest('/advs/v2/camps/tracking', {
        connectionUuids: [selectedConnection.connectionUuid],
        campaignIsFallback: isFallback,
      })
      changeFallbackStatusHandler(selectedConnection.connectionUuid, isFallback)
      setLoaded(true)
    }
  }

  const handleToggle = () => {
    if (!isFallback) {
      showModal(
        <AlertModal
          title="Fallback Only"
          desc={`Are you sure you want to set this network as 'Fallback only'?`}
          hideModal={hideModal}
          handleAgree={() => handleNetworkIsFallback(true)}
        />
      )
    } else {
      handleNetworkIsFallback(false)
    }
  }

  return (
    <div>
      <div className="general-label">Fallback Only</div>
      <div className="row row-center row-space-between row-nowrap gap-20 m-t-5">
        <SmallLoadable loaded={loaded} height={80}>
          <p className="text-bolder text-light w-70 m-y-0">
            When enabling a network as Fallback Only, all other networks will be
            prioritized before this network.
          </p>
          <SVG
            onClick={handleToggle}
            src={`/images/networks/toggle-${isFallback ? 'on' : 'off'}.svg`}
            className="cursor-pointer"
          />
        </SmallLoadable>
      </div>
    </div>
  )
}

const PropertyInfo = ({
  selectedConnection,
  showModal,
  showSnackbar,
  reload,
  channels,
}) => {
  if (!selectedConnection) {
    return null
  }
  const [connectionChannels, setConnectionChannels] = useState(
    selectedConnection.connectionChannels.map((c) => ({
      ...c,
      isToggleOn: true,
    }))
  )
  const toggle = (connectionChannelUuid: string) => {
    const updated = connectionChannels.map((c) => {
      if (c.connectionChannelUuid === connectionChannelUuid) {
        return { ...c, isToggleOn: !c.isToggleOn }
      }
      return { ...c }
    })
    setConnectionChannels(updated)
  }
  const addMenuOffset = useRef({ left: 0, top: 0, bottom: 0 })
  const [selectedConnectionChannel, setSelectedConnectionChannel] = useState()
  const showAddMenu = (event, connectionChannel) => {
    const button =
      event.target.nodeName === 'BUTTON'
        ? event.target
        : event.target.closest('button')
    if (!button) return
    addMenuOffset.current.left = button.offsetParent.offsetLeft + 20
    addMenuOffset.current.top =
      button.offsetParent.offsetTop + button.offsetParent.offsetHeight - 12
    addMenuOffset.current.bottom =
      button.offsetParent.offsetParent.offsetHeight -
      button.offsetParent.offsetTop -
      button.offsetParent.offsetHeight +
      48
    setSelectedConnectionChannel(connectionChannel)
  }

  const renderProperty = () => {
    if (connectionChannels.length) {
      return (
        <div className="w-100 column property-list">
          {map(connectionChannels, (channel) => (
            <div
              key={channel.connectionChannelUuid}
              className="column property-row"
            >
              <div className="row row-space-between row-center w-100">
                <div>{channel.connectionChannelName}</div>
                <div className="row row-narrow row-center">
                  <div className="row row-narrow row-center">
                    <span className="desc">automatic campaign import</span>
                    <SVG src="/images/recommendations/icon-info.svg" />
                  </div>
                  <SVG
                    onClick={() => toggle(channel.connectionChannelUuid)}
                    src={`/images/networks/toggle-${
                      channel.isToggleOn ? 'on' : 'off'
                    }.svg`}
                    className="m-l-10 cursor-pointer"
                  />
                </div>
              </div>
              {!channel.isToggleOn && (
                <div>
                  <MoreInfo
                    channel={channel}
                    channels={connectionChannels}
                    showModal={showModal}
                    showSnackbar={showSnackbar}
                    showAddMenu={showAddMenu}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )
    } else {
      return (
        <div className="column column-middle">
          <div className="no-property-label">No properties found</div>
          <p className="no-property-desc">
            Go to <strong>IMPACT</strong> network to create a property, sed do
            eiusmod tempor Manual import incididunt ut labore et dolore magna
            aliqua.{' '}
          </p>
          <div>
            <button className="btn btn-primary no-property-button">
              Go to network
            </button>
          </div>
        </div>
      )
    }
  }
  return (
    <div className="row row-space-between row-center w-100 m-b-5">
      <div className="row row-narrow row-center">
        <span className="general-label">
          {connectionChannels.length} properties found at Impact.com,
        </span>
        <span className="selected-network-label">
          {selectedConnection.connectionName}
        </span>
        <SVG src="/images/networks/reload.svg" className="m-l-3" />
      </div>
      <div className="w-100">{renderProperty()}</div>
      <div>
        <AddMenu
          show={!!selectedConnectionChannel}
          channel={selectedConnectionChannel}
          hideAddMenu={() => {
            setSelectedConnectionChannel(undefined)
          }}
          {...{
            channels,
            addMenuOffset,
            reload,
          }}
        />
      </div>
    </div>
  )
}

const MoreInfo = (props) => {
  const { channel, channels, showModal, showSnackbar, showAddMenu } = props

  const hasAvailableChannels = useMemo(() => {
    if (!channel || !channels || !Array.isArray(channels)) return false
    return channels.length > channel.connectionsMappings.length
  }, [channels, channel])

  return (
    <div className="more-info row row-narrow row-center network-show-mappings">
      {map(channel.connectionsMappings?.sort(mappingsSort), (mapping) => (
        <span key={mapping.connectionMappingUuid} className="tag">
          {mapping.channel.channelName}
          <button
            className="link link-remove"
            onClick={() => {
              showModal(
                <RemoveMappingModal
                  channel={channel}
                  mapping={mapping}
                  showSnackbar={showSnackbar}
                />
              )
            }}
          >
            <SVG src="/images/networks/icon-x.svg" />
          </button>
        </span>
      ))}
      {hasAvailableChannels && (
        <button
          className="link link-add"
          onClick={(event) => {
            showAddMenu(event, channel)
          }}
        >
          <SVG src="/images/icon-plus-color.svg" />
        </button>
      )}
    </div>
  )
}

const AddMenu = (props) => {
  const { show, channel, channels, addMenuOffset, hideAddMenu, reload } = props

  if (!show) return <div className="network-new-mapping" />

  const parentChannelUuid = channel.connectionChannelUuid

  const [search, setSearch] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const availableChannels = useMemo(() => {
    if (!channel || !channels || !Array.isArray(channels)) return []
    const connectedChannelUuids =
      channel.connectionsMappings?.map(
        (mapping) => mapping.connectionMappingChannelUuid
      ) || []
    return channels.filter(
      (availableChannel) =>
        !connectedChannelUuids.includes(availableChannel.channelUuid)
    )
  }, [channels, channel])

  const shownChannels = useMemo(() => {
    if (search) {
      if (!availableChannels || !Array.isArray(availableChannels)) return []
      return availableChannels.filter(
        (channel) =>
          channel.channelDomain.toLowerCase().indexOf(search.toLowerCase()) !==
          -1
      )
    } else {
      return availableChannels
    }
  }, [availableChannels, search])

  const [positionBottom, setPositionBottom] = useState(null)
  const availableChannelsRef = useRef<HTMLDivElement>()
  useOutsideClick(availableChannelsRef, () => {
    hideAddMenu()
  })

  useEffect(() => {
    if (availableChannelsRef.current && show) {
      const parent = availableChannelsRef.current.offsetParent as HTMLElement
      const bottom =
        addMenuOffset.current.top + availableChannelsRef.current.offsetHeight
      setPositionBottom(bottom > parent.offsetHeight)
    }
  }, [channels, channel, show])

  return (
    <div
      className="network-new-mapping shown"
      style={
        positionBottom
          ? {
              left: addMenuOffset.current.left,
              bottom: addMenuOffset.current.bottom,
            }
          : {
              left: addMenuOffset.current.left,
              top: addMenuOffset.current.top,
            }
      }
      ref={availableChannelsRef}
    >
      <div className="card p-0">
        <Search
          value={search}
          setValue={setSearch}
          placeholder="Type to search"
          className="search"
        />
        {isBlank(shownChannels) ? (
          <div className="text-light text-smallest text-bold text-uppercase p-4">
            Nothing found
          </div>
        ) : (
          <>
            <div className="text-light text-smallest text-bold text-uppercase p-4 p-b-2">
              All channels
            </div>
            <ul>
              {map(shownChannels, (channel) => (
                <li
                  key={channel.channelUuid}
                  onClick={async () => {
                    setSubmitting(true)
                    await postRequest(
                      `/channels/v1/channel/${channel.channelUuid}/connectionchannels`,
                      {
                        connectionChannelUuid: parentChannelUuid,
                      }
                    )
                    reload()
                    hideAddMenu()
                  }}
                >
                  {channel.channelDomain}
                </li>
              ))}
            </ul>
            {submitting && (
              <div className="loader-container">
                <div className="loader" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const Callback = (props) => {
  const { network, selectedConnection, showSnackbar } = props

  if (!selectedConnection) {
    return null
  }

  const link = useApiPost<string>(
    '/networks/v1/connections/callbackurls',
    {
      connectionUuid: selectedConnection.connectionUuid,
      networkUuid: network.networkUuid,
    },
    [network, selectedConnection]
  )

  return (
    <div>
      <div className="text-bolder text-bigger text-black">
        Copy callback url
      </div>
      <div
        className="network-show-callback"
        onClick={() => {
          if (!isBlank(link)) {
            copyToClipboard(link)
            showSnackbar('Copied to clipboard')
          }
        }}
      >
        <SmallLoadable loaded={link} height={80}>
          {isBlank(link) ? (
            <pre className="text-primary text-smallest">
              The link will appear here
            </pre>
          ) : (
            <pre className="text-primary text-smallest p-r-40 relative">
              {link}
              <span className="copy-button absolute">
                <SVG src="/images/icon-copy.svg" className="m-t--2" />
              </span>
            </pre>
          )}
        </SmallLoadable>
      </div>
    </div>
  )
}

const RemoveModal = (props) => {
  const { connection } = props

  const { hideModal } = useModal()

  const [confirm, setConfirm] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const submit = async () => {
    setSubmitting(true)
    await deleteRequest(`/networks/v1/connections/${connection.connectionUuid}`)
    hideModal()
    navigateTo('/networks')
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Delete network</div>
      </div>
      <div className="card-body">
        <div className="notice-info m-b-4">
          <SVG src="/images/notice-info.svg" className="m-r-2" />
          You are about to delete a Network connection. This will cause all
          imported Campaigns from this Network to no longer be imported and used
          by Heylink Intelligent Tracking.
        </div>
        <div className="control control-checkbox m-b-8">
          <input
            type="checkbox"
            checked={confirm}
            onChange={(event) => setConfirm(event.target.checked)}
          />
          <label>
            Yes — I'm sure i want to delete this network connection.
          </label>
        </div>

        <div className="text-right">
          <button
            className="btn btn-primary"
            disabled={!confirm || submitting}
            onClick={submit}
          >
            Delete
          </button>
        </div>
      </div>
    </>
  )
}

export const NetworkAccounts = ({
  connections,
  selectedConnection,
  setSelectedConnection,
  networkDetail,
  channels,
}) => {
  const [open, setOpen] = useState(false)

  const ref = useRef()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  return (
    <div className="table-columns network-selection w-100" ref={ref}>
      <div className="row row-narrow m-t-30 m-b-5">
        <span className="label network-selection-label">
          You have {connections.length} accounts
        </span>
      </div>
      {connections && selectedConnection && (
        <>
          <button
            className={varClass({
              'table-columns-select link action w-100 network-selected': true,
            })}
            onClick={() => {
              setOpen(!open)
            }}
          >
            <div className="row row-space-between row-nowrap row-center w-100">
              <span>{selectedConnection.connectionName}</span>
              {selectedConnection.connectionIsActive ? (
                <span className="status active">Active</span>
              ) : (
                <span className="status inactive">Inactive</span>
              )}
            </div>
            <SVG src="/images/chevron-down.svg" className="m-l-4 m-r--15" />
          </button>

          <div
            className={varClass({
              'table-columns-menu network-selection-menu w-100': true,
              'shown': open,
            })}
          >
            <ul>
              {map(connections, (connection, index) => {
                return (
                  <li
                    className={varClass({
                      'selected':
                        selectedConnection.connectionUuid ===
                        connection.connectionUuid,
                    })}
                    key={connection.connectionUuid}
                    onClick={() => {
                      setSelectedConnection(connection)
                      setOpen(false)
                    }}
                  >
                    <div className="row row-space-between row-nowrap row-center w-100">
                      <span>{connection.connectionName}</span>
                      {connection.connectionIsActive ? (
                        <span className="status active">Active</span>
                      ) : (
                        <span className="status inactive">Inactive</span>
                      )}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </>
      )}
      <div className=" m-t-30">
        <button
          className="btn btn-primary w-100"
          onClick={(event) => {
            showModal(
              <ConnectNetworkModal
                network={networkDetail}
                channels={channels}
                onDone={navigateReload}
              />
            )
          }}
        >
          <SVG
            src="/images/icon-plus.svg"
            width={18}
            height={18}
            className="m-r-15"
          />
          {connections.length ? 'Connect another account' : 'Connect account'}
        </button>
      </div>
    </div>
  )
}

export const AlertModal = (props) => {
  const { title, desc, hideModal, handleAgree } = props

  return (
    <>
      <div className="card-header">
        <div className="card-title">{title}</div>
      </div>
      <div className="card-body">
        {desc}
        <div className="m-t-5">
          <button
            className="btn btn-dark m-r-3"
            onClick={() => {
              handleAgree()
              hideModal()
            }}
          >
            Yes
          </button>
          <button
            className="btn btn-link text-dark"
            onClick={() => {
              hideModal()
            }}
          >
            No
          </button>
        </div>
      </div>
    </>
  )
}
