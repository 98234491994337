import React, { useMemo, useState } from "react"
import { postRequest } from "../../../services"
import { useModal, useApiGet, IProfileV1Users, map, useArray, Loadable } from "../../shared"
import { MultiSelectDropdown } from "./shared"

export const AssigneesModal = (props) => {
  const { advertiser, assignees, onDone } = props

  const { hideModal } = useModal()

  const users = useApiGet<IProfileV1Users[]>('/profile/v1/users')

  const initialSelectedUsers = useMemo(
    () => map(assignees, (assignee) => assignee.assigneeUserUuid),
    [assignees]
  )
  const {
    values: selectedUsers,
    toggle: toggleSelectedUser,
    clear: clearSelectedUsers,
  } = useArray(initialSelectedUsers)

  const [submitting, setSubmitting] = useState(false)
  const submit = async () => {
    setSubmitting(true)
    try {
      await postRequest('/advs/v1/assignees', {
        advertiserUuid: advertiser.advertiserUuid,
        userUuids: selectedUsers,
      })
    } catch (err) {
      console.error(err)
    }
    setSubmitting(false)
    hideModal()
    if (onDone) {
      onDone()
    }
  }

  if (!users?.length) {
    return (
      <div className="card-body">
        <Loadable loaded={false} />
      </div>
    )
  }

  return (
    <>
      <div className="card-body">
        <div className="subnav-filters w-100 m-t-2">
          <MultiSelectDropdown
            label="Users"
            emptyLabel="None"
            values={users}
            sortKey="userCreatedDatetime"
            uuidGetter={(user: IProfileV1Users) => user.userUuid}
            labelGetter={(user: IProfileV1Users) =>
              `${user.userFirstname} ${user.userLastname}`
            }
            selectedValues={selectedUsers}
            toggleSelectedValue={toggleSelectedUser}
            clearSelectedValues={clearSelectedUsers}
          />
        </div>
        <div className="text-right m-t-5">
          <button className="btn btn-bordered m-r-2" onClick={hideModal}>
            Cancel
          </button>
          <button
            className="btn btn-dark"
            onClick={submit}
            disabled={submitting}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  )
}
