import React, { useState, useEffect, useRef, useMemo } from 'react'
import SVG from 'react-inlinesvg'
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd'
import { HTML5Backend, getEmptyImage } from 'react-dnd-html5-backend'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { Placeholder } from './Placeholder'
import {
  Header,
  DatePicker,
  NavFilter,
  TableInfo,
  TableColumns,
  TableEmpty,
  useDatePickerState,
  MiniAreaChart,
  Loadable,
  SmallLoadable,
  ConversionDrawer,
  ConversionStatusTag,
  useApiGet,
  useApiPost,
  useApiMore,
  useDrawer,
  useSnackbar,
  useSortableTable,
  useInterval,
  formatFilterRecords,
  formatStat,
  calculateConversionValues,
  copyToClipboard,
  navigateTo,
  isBlank,
  map,
  varClass,
  plural,
  newTableSort,
  groupSort,
  useSearch,
  filterBySearch,
  filterTableColumns,
  sleep,
  downloadCsv,
} from '../../shared'
import { postRequest } from '../../../services/api'

const perPage = 20

const tableColumnsKey = 'insights-conversions-columns-1130'
const defaultTableColumns = [
  'advertiserDomain',
  'conversionDatetime',
  'channelDomain',
  'conversionSale',
  'conversionCommission',
  'conversionUuid',
  'conversionSaleOrderId',
  'conversionStatusKey',
]

const availableTableColumns = [
  'advertiserDomain',
  'conversionDatetime',
  'channelDomain',
  'conversionSale',
  'conversionCommission',
  'conversionUuid',
  'conversionSaleOrderId',
  'conversionStatusKey',
  'conversionSubId',
  'conversionSubId1',
  'conversionSubId2',
  'conversionSubId3',
  'conversionSubId4',
  'conversionSubId5',
]

const tableLabels = {
  'advertiserDomain': 'Merchant',
  'conversionDatetime': 'Time',
  'channelDomain': 'Site',
  'conversionSale': 'Sale Amount',
  'conversionCommission': 'Revenue',
  'conversionName': 'Conversion Name',
  'conversionUuid': 'Conversion ID',
  'conversionSaleOrderId': 'Order ID',
  'conversionStatusKey': 'Status',
  'conversionSubId': 'Sub ID',
  'conversionSubId1': 'Sub ID 1',
  'conversionSubId2': 'Sub ID 2',
  'conversionSubId3': 'Sub ID 3',
  'conversionSubId4': 'Sub ID 4',
  'conversionSubId5': 'Sub ID 5',
}

const filterLabels = {
  'conversionStatusKeys': 'Status',
  'conversionAdvertiserUuids': 'Merchant',
  'conversionChannelUuids': 'Site',
  'conversionNetworkUuids': 'Network',
}

const filterOptions = {
  'conversionStatusKeys': {
    'approved': 'Approved',
    'pending': 'Pending',
    'rejected': 'Rejected',
    'paid': 'Paid',
  },
}

const normalizeFilters = (filters) => {
  const result = {}

  for (const filter of filters) {
    if (filter.value) {
      result[filter.key] = filter.value.split(', ')
    }
  }

  return result
}

const conversionSort = newTableSort((a: any, b: any, key: string) => {
  if (key === 'advertiserDomain') {
    return [
      a.conversionName || a.advertiser[key],
      b.conversionName || b.advertiser[key],
    ]
  }
  if (key === 'channelDomain') {
    return [a.channel[key], b.channel[key]]
  }
})

export const InsightConversions = (props) => {
  const datePickerState = useDatePickerState()

  const [filters, setFilters] = useState([
    { key: 'conversionStatusKeys', value: '' },
    { key: 'conversionChannelUuids', value: '' },
  ])
  const filterReset = filters
    .filter((filter) => filter.value)
    .map((filter) => filter.value)
    .join(',')

  const [search, setSearch] = useSearch()

  const advertisers = useApiGet('/advs/v1/minlist')
  const channels = useApiGet('/channels/v1/list')
  const networks = useApiGet('/networks/v1/list')

  const totalData = useApiPost(
    '/insights/v1/conversions/insights',
    {
      fromDate: datePickerState.range.startDate,
      toDate: datePickerState.range.endDate,
      filters: normalizeFilters(filters),
    },
    [
      datePickerState.range.startDate,
      datePickerState.range.endDate,
      filterReset,
    ]
  )

  const { data, loading, hasMore, loadMore } = useApiMore(
    '/insights/v1/conversions',
    (offset: number) => ({
      fromDate: datePickerState.range.startDate,
      toDate: datePickerState.range.endDate,
      offset: offset,
      limit: perPage,
      filters: normalizeFilters(filters),
    }),
    {
      perPage,
      resetDeps: [
        datePickerState.range.startDate,
        datePickerState.range.endDate,
        filterReset,
      ],
    }
  )

  return (
    <>
      <Header label="Conversions" />
      <Loadable
        data={totalData && data && advertisers && channels && networks}
        placeholder={<Placeholder />}
      >
        <DndProvider backend={HTML5Backend}>
          <Stats
            {...{
              datePickerState,
              search,
              setSearch,
              data,
              totalData,
              loading,
              hasMore,
              loadMore,
              filters,
              setFilters,
              advertisers,
              channels,
              networks,
            }}
          />
        </DndProvider>
      </Loadable>
    </>
  )
}

const Nav = (props) => {
  const { filters, setFilters, advertisers, channels, networks } = props

  return (
    <>
      <div className="page-nav m-b-4">
        <NavFilter
          {...{
            filters,
            setFilters,
            filterLabels,
            filterOptions: {
              ...filterOptions,
              conversionAdvertiserUuids: formatFilterRecords(
                advertisers,
                'advertiserUuid',
                'advertiserDomain'
              ),
              conversionChannelUuids: formatFilterRecords(
                channels,
                'channelUuid',
                'channelName',
                'channelDomain'
              ),
              conversionNetworkUuids: formatFilterRecords(
                networks,
                'networkUuid',
                'networkName'
              ),
            },
          }}
          filtersToKeep={['conversionChannelUuids', 'conversionStatusKeys']}
          isMultipleChoice
        />
      </div>
      <DatePicker className="in-header" />
    </>
  )
}

const Stats = (props) => {
  const {
    datePickerState,
    search,
    setSearch,
    data,
    totalData,
    loading,
    hasMore,
    loadMore,
    filters,
    setFilters,
    advertisers,
    channels,
    networks,
  } = props

  return (
    <>
      <div className="row row-nowrap row-lg m-b-30">
        <TopStat
          title="Approved"
          icon="/images/insights/sq-conversions-paid.svg"
          data={totalData.approvedInsights}
          chartColor="#0CCFAF"
        />
        <TopStat
          title="Pending"
          icon="/images/insights/sq-conversions-pending.svg"
          data={totalData.pendingInsights}
          chartColor="#FFB000"
        />
        <TopStat
          title="Rejected"
          icon="/images/insights/sq-conversions-rejected.svg"
          data={totalData.rejectedInsights}
          chartColor="#EB0038"
        />
      </div>

      <Nav {...{ filters, setFilters, advertisers, channels, networks }} />

      <Table
        {...{
          datePickerState,
          search,
          setSearch,
          filters,
          data,
          networks,
          loading,
          hasMore,
          loadMore,
        }}
      />

      {loading && (
        <div className="m-t-15 m-b-2">
          <SmallLoadable loaded={false} />
        </div>
      )}
    </>
  )
}

const TopStat = (props) => {
  const { title, icon, data, chartColor } = props

  if (!data) {
    return null
  }

  const chartData = useMemo(() => {
    if (isBlank(data)) {
      return [0, 0, 0, 0, 0, 0, 0]
    } else {
      return data.groups.sort(groupSort).map((row) => row.sums.commission || 0)
    }
  }, [data])

  return (
    <div className="insight-conversion-filter card flex-1 flex-1-3 row row-nowrap row-space-between row-bottom p-x-20">
      <div>
        <div>
          <SVG src={icon} />
        </div>
        <div className="m-t-2">{title}</div>
        <div className="text-dark text-big text-boldest text-nowrap m-t-2">
          {formatStat(data.commission, data.currencyCode || 'DKK')}
        </div>
      </div>

      <div className="flex-1-2" style={{ maxWidth: 180, minWidth: 80 }}>
        <MiniAreaChart
          title={title}
          data={chartData}
          color={chartColor}
          height={80}
          chartType="monotone"
        />
      </div>
    </div>
  )
}

const Table = (props) => {
  const {
    datePickerState,
    search,
    filters,
    data,
    networks,
    loading,
    hasMore,
    loadMore,
  } = props

  const { showDrawer } = useDrawer()
  const { showSnackbar } = useSnackbar()

  const [columns, setColumns] = useState(() =>
    filterTableColumns(
      localStorage.getItem(tableColumnsKey),
      availableTableColumns,
      defaultTableColumns
    )
  )

  const [draggedColumn, setDraggedColumn] = useState('')

  const swapColumns = (value1: string, value2: string) => {
    const index1 = columns.indexOf(value1)
    const index2 = columns.indexOf(value2) || 1
    if (index1 === index2) return
    if (index1 === 0 || index2 === 0) return
    const newColumns = [...columns]
    const item = newColumns[index1]
    newColumns.splice(index1, 1)
    newColumns.splice(index2, 0, item)
    setColumns(newColumns)
    localStorage.setItem(tableColumnsKey, newColumns.join(','))
  }

  const { sort, dir, toggleSort } = useSortableTable(
    columns.includes('conversionDatetime')
      ? {
          sort: 'conversionDatetime',
          dir: 'desc',
        }
      : {
          sort: 'advertiserDomain',
          dir: 'asc',
        }
  )

  const shownRows = conversionSort(
    filterBySearch(data, search, (row: any) => [
      row.conversionName || row.advertiser.advertiserDomain || 'Unknown',
    ]),
    sort,
    dir
  )

  const [csvDownloading, setCsvDownloading] = useState(false)

  const isEmpty = !!data && isBlank(data)

  const scrollCheckInterval = useInterval(1000)
  useEffect(() => {
    const table = document.querySelector('.insights-conversion-table')
    if (hasMore && !loading && table) {
      const element = table.parentElement
      const bottom = element.scrollHeight - element.offsetHeight - 30
      const isScrolledToBottom = element.scrollTop >= bottom
      if (isScrolledToBottom) {
        loadMore()
      }
    }
  }, [scrollCheckInterval])

  return (
    <>
      <div className="card insights-conversions-card">
        <div className="card-body card-body-fill">
          <TableInfo>
            {/*
            <Search
              value={search}
              setValue={setSearch}
              placeholder="Find merchant"
              className="search flex-1"
            />
            */}
            <div />
            <div className="vertical-middle">
              <TableColumns
                storageKey={tableColumnsKey}
                columns={columns}
                setColumns={setColumns}
                availableColumns={availableTableColumns}
                columnLabels={tableLabels}
              />
              <button
                className={varClass({
                  'link action m-l-15': true,
                  'loader-btn': csvDownloading,
                })}
                disabled={csvDownloading}
                onClick={async () => {
                  setCsvDownloading(true)

                  try {
                    const outputRows = []

                    const limit = 1000
                    let offset = 0
                    do {
                      const moreRows = await postRequest(
                        '/insights/v1/conversions',
                        {
                          fromDate: moment(
                            datePickerState.range.startDate
                          ).format('YYYY-MM-DD'),
                          toDate: moment(datePickerState.range.endDate).format(
                            'YYYY-MM-DD'
                          ),
                          offset: offset,
                          limit: limit,
                          filters: normalizeFilters(filters),
                        }
                      )
                      if (!moreRows || !moreRows.data || !moreRows.data.length)
                        break
                      outputRows.push(...moreRows.data)
                      offset += limit
                      await sleep(500)
                    } while (true) // eslint-disable-line no-constant-condition

                    const rows = conversionSort(
                      filterBySearch(outputRows, search, (row) => [
                        row.conversionName ||
                          row.advertiser.advertiserDomain ||
                          'Unknown',
                      ]),
                      sort,
                      dir
                    )

                    downloadCsv({
                      filename: `conversions_${moment().format(
                        'YYYY-MM-DD'
                      )}.csv`,
                      columns: columns as any,
                      rows,
                      columnFormatter: (column) => tableLabels[column] || '',
                      rowFormatter: (row) => calculateConversionValues(row),
                    })
                  } catch (error) {
                    console.error(error)
                  } finally {
                    setCsvDownloading(false)
                  }
                }}
              >
                <SVG src="/images/insights/icon-csv.svg" className="m-r-10" />
                Download CSV
              </button>
              <div className="total">
                {data?.length || 0} {plural(data?.length, 'result')}
              </div>
            </div>
          </TableInfo>

          <div className="table-container-overflow">
            <table className="table table-bordered table-sortable table-hoverable insights-conversion-table">
              <thead className="table-sticky text-nowrap">
                <tr>
                  {columns.map((column, index) => (
                    <TableHead
                      key={column}
                      index={index}
                      value={column}
                      label={tableLabels[column]}
                      desc={
                        column === 'conversionDatetime' ||
                        column === 'conversionSale' ||
                        column === 'conversionCommission'
                      }
                      {...{
                        toggleSort,
                        sort,
                        dir,
                        columns,
                        draggedColumn,
                        setDraggedColumn,
                        swapColumns,
                      }}
                    />
                  ))}
                </tr>
              </thead>
              <tbody className="text-nowrap">
                {map(shownRows, (conversion) => (
                  <TableItem
                    key={conversion.conversionUuid}
                    {...{
                      conversion,
                      sort,
                      columns,
                      draggedColumn,
                      networks,
                      showSnackbar,
                      showDrawer,
                    }}
                  />
                ))}
                {!isEmpty && (isBlank(data) || isBlank(shownRows)) && (
                  <tr>
                    <td
                      colSpan={10}
                      className="text-center text-light text-bolder"
                    >
                      Nothing found
                    </td>
                  </tr>
                )}
                <tr className="blank-space">
                  <td colSpan={10} />
                </tr>
              </tbody>
            </table>
            <DragTable
              {...{
                shownRows,
                draggedColumn,
                columns,
              }}
            />
          </div>

          {isEmpty && (
            <TableEmpty
              icon="conversions"
              title="No data available yet"
              subtitle="To start seeing Conversions simply connect a network"
              buttons={{
                '/networks': 'Connect network',
              }}
              dataTitle="No data available yet"
              dataSubtitle="No conversion has gone through yet.<br />Come back later and see your conversions here."
            />
          )}
        </div>
      </div>

      <ReactTooltip type="dark" place="left" />
    </>
  )
}

const TableHead = (props) => {
  const {
    value,
    label,
    desc,
    sort,
    dir,
    toggleSort,
    index,
    columns,
    draggedColumn,
    setDraggedColumn,
    swapColumns,
  } = props

  const ref = useRef(null)

  const [{ isDragging }, dragHandle, drag] = useDrag(() => ({
    type: 'column',
    item: () => {
      setDraggedColumn(value)
      return { value }
    },
    end: (item) => {
      setDraggedColumn('')
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const [_opts, drop] = useDrop({
    accept: 'column',
    hover: (item, monitor) => {
      if (!ref.current) {
        return
      }
      const value1 = item.value
      const value2 = value
      if (value1 === value2) {
        return
      }
      const index1 = columns.indexOf(value1)
      const index2 = columns.indexOf(value2)

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const hoverQuarterX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 4

      const clientOffset = monitor.getClientOffset()
      const hoverClientX = clientOffset.x - hoverBoundingRect.left

      if (index1 < index2 && hoverClientX < hoverMiddleX - hoverQuarterX) {
        return
      }
      if (index1 > index2 && hoverClientX > hoverMiddleX + hoverQuarterX) {
        return
      }

      swapColumns(value1, value2)
    },
    drop: (item: any) => {
      swapColumns(item.value, value)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  })

  drag(getEmptyImage())
  drop(ref)

  if (index === 0) {
    return (
      <th
        onClick={toggleSort(value, desc ? 'desc' : 'asc')}
        ref={ref}
        className={varClass({
          'vertical-middle p-r-4': true,
          'sort-highlight': sort === value,
        })}
      >
        {draggedColumn && (
          <div
            className={varClass({
              'drop-space': true,
              'opaque': draggedColumn === columns[index],
            })}
          />
        )}
        <div className="row row-fill row-nowrap">
          <span className="column-label">
            {label}
            {sort === value ? (
              <>
                {dir === 'desc' ? (
                  <SVG
                    src="/images/insights/caret-down.svg"
                    className="m-l-1"
                  />
                ) : (
                  <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
                )}
              </>
            ) : (
              <SVG src="/images/insights/caret.svg" className="m-l-1" />
            )}
          </span>
        </div>
      </th>
    )
  } else {
    return (
      <th
        onClick={toggleSort(value, desc ? 'desc' : 'asc')}
        ref={ref}
        className={varClass({
          'relative vertical-middle p-l-2 p-r-4': true,
          'sort-highlight': sort === value,
          'drag-highlight': draggedColumn === value,
        })}
        style={{
          color: isDragging ? 'transparent' : '',
        }}
      >
        {draggedColumn && <div className="drop-space" />}
        <div className="row row-fill row-nowrap">
          <span ref={dragHandle} className="cursor-grab m-r-10">
            <SVG src="/images/insights/icon-drag.svg" className="handle" />
          </span>
          <span className="column-label">
            {label}
            {sort === value ? (
              <>
                {dir === 'desc' ? (
                  <SVG
                    src="/images/insights/caret-down.svg"
                    className="m-l-1"
                  />
                ) : (
                  <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
                )}
              </>
            ) : (
              <SVG src="/images/insights/caret.svg" className="m-l-1" />
            )}
          </span>
        </div>
      </th>
    )
  }
}

const TableItem = (props) => {
  const {
    conversion,
    sort,
    columns,
    draggedColumn,
    networks,
    showDrawer,
    showSnackbar,
  } = props

  const values = calculateConversionValues(conversion)

  return (
    <tr>
      {columns.map((column) => (
        <td
          key={column}
          className={varClass({
            'text-nowrap': true,
            'text-right':
              column === 'conversionDatetime' ||
              column === 'conversionSale' ||
              column === 'conversionCommission',
            'sort-highlight': sort === column,
            'drag-highlight': draggedColumn === column,
          })}
        >
          {(() => {
            switch (column) {
              case 'advertiserDomain':
                return (
                  <button
                    className="name"
                    onClick={() => {
                      showDrawer(
                        <ConversionDrawer
                          conversionUuid={conversion.conversionUuid}
                          networks={networks}
                          showSnackbar={showSnackbar}
                        />,
                        {
                          cardClassName: 'insight-conversion-drawer',
                        }
                      )
                    }}
                  >
                    <div
                      className={varClass({
                        'text-bold': true,
                        'text-dark': values[column] !== 'Unknown',
                        'text-light text-italic': values[column] === 'Unknown',
                      })}
                    >
                      {values[column]}
                    </div>
                    <div className="m-l-30 vertical-middle">
                      {conversion.conversionErrorCode && (
                        <div
                          className="link p-1 p-b-0"
                          data-tip={conversion.conversionErrorCode}
                        >
                          <SVG src="/images/insights/icon-danger.svg" />
                        </div>
                      )}
                      <SVG src="/images/icon-details.svg" className="arrow" />
                    </div>
                  </button>
                )
              case 'channelDomain':
                return (
                  <span
                    className={varClass({
                      'badge text-smaller': true,
                      'badge-hoverable text-bold': values[column] !== 'Unknown',
                      'text-light text-italic': values[column] === 'Unknown',
                    })}
                    onClick={(event) => {
                      if (conversion.channel.channelUuid) {
                        navigateTo(
                          event,
                          `/sites/${conversion.channel.channelUuid}`
                        )
                      }
                    }}
                  >
                    {values[column]}
                  </span>
                )
              case 'conversionUuid':
                return (
                  <button
                    className="link ctc"
                    onClick={() => {
                      copyToClipboard(values[column])
                      showSnackbar('Copied to clipboard')
                    }}
                  >
                    {values[column]}
                  </button>
                )
              case 'conversionStatusKey':
                return <ConversionStatusTag status={values[column]} />
              default:
                return <TableValue values={values} column={column} />
            }
          })()}
        </td>
      ))}
    </tr>
  )
}

const TableValue = (props) => {
  const { values, column } = props

  if (
    column === 'conversionCount' ||
    column === 'pageviewCount' ||
    column === 'clickCount' ||
    column === 'conversionSale' ||
    column === 'conversionCommission'
  ) {
    return (
      <div className={`value-badge value-badge-${column}`}>
        {values[column]}
      </div>
    )
  } else {
    return values[column]
  }
}

const DragTable = (props) => {
  const { shownRows } = props

  const { item, currentOffset, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging || !currentOffset) {
    return null
  }

  const columns = [item?.value]

  return (
    <div
      className="insights-conversions-card drag-card"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        transform: `translate(${currentOffset?.x}px, ${currentOffset?.y}px)`,
        pointerEvents: 'none',
        zIndex: 100,
      }}
    >
      <div className="table-container-overflow">
        <table
          className="table table-bordered table-sortable insights-conversion-table drag-table relative"
          style={{
            position: 'relative',
            width: 100,
            height: 100,
          }}
        >
          <thead className="text-nowrap">
            <tr>
              <th className="relative vertical-middle p-l-2 p-r-4 drag-highlight">
                <div className="row row-fill row-nowrap">
                  <span className="cursor-grab text-blue m-r-10">
                    <SVG
                      src="/images/insights/icon-drag.svg"
                      className="handle"
                    />
                  </span>
                  <span className="column-label">
                    {tableLabels[item?.value]}
                    <SVG src="/images/insights/caret.svg" className="m-l-1" />
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-nowrap">
            {map(shownRows, (conversion) => (
              <DragTableItem
                key={conversion.conversionUuid}
                {...{
                  conversion,
                  columns,
                }}
              />
            ))}
            {isBlank(shownRows) && (
              <tr>
                <td colSpan={10} className="text-center text-light text-bolder">
                  Nothing found
                </td>
              </tr>
            )}
            <tr className="blank-space">
              <td colSpan={10} />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

const DragTableItem = (props) => {
  const { conversion, columns } = props

  const values = calculateConversionValues(conversion)

  const column = columns[0]

  return (
    <>
      <tr>
        <td
          className={varClass({
            'text-nowrap': true,
            'text-right':
              column === 'conversionDatetime' ||
              column === 'conversionSale' ||
              column === 'conversionCommission',
          })}
        >
          {(() => {
            switch (column) {
              case 'advertiserDomain':
                return (
                  <div className="row row-center row-space-between row-nowrap">
                    <div
                      className={varClass({
                        'text-bold': true,
                        'text-light text-italic': values[column] === 'Unknown',
                      })}
                    >
                      {values[column]}
                    </div>
                    {conversion.conversionErrorCode && (
                      <div
                        className="link p-1"
                        data-tip={conversion.conversionErrorCode}
                      >
                        <SVG
                          src="/images/insights/icon-danger.svg"
                          className="m-l-2"
                        />
                      </div>
                    )}
                  </div>
                )
              case 'channelDomain':
                return (
                  <span
                    className={varClass({
                      'tag text-smaller': true,
                      'text-bold': values[column] !== 'Unknown',
                      'text-light text-italic': values[column] === 'Unknown',
                    })}
                  >
                    {values[column]}
                  </span>
                )
              case 'conversionUuid':
                return <button className="link ctc">{values[column]}</button>
              case 'conversionStatusKey':
                return <ConversionStatusTag status={values[column]} />
              default:
                return <TableValue values={values} column={column} />
            }
          })()}
        </td>
      </tr>
    </>
  )
}
