import React, { useState, useEffect, useMemo } from 'react'
import { uniqBy } from 'lodash'
import SVG from 'react-inlinesvg'
import ReactTooltip from 'react-tooltip'
import { Placeholder } from './Placeholder'
import {
  Header,
  Search,
  Logo,
  Loadable,
  // SmallLoadable,
  ConnectNetworkModal,
  // EditConnectionModal,
  // RemoveConnectionModal,
  useApiGet,
  // useApiPost,
  useModal,
  useSnackbar,
  useCounter,
  map,
  isBlank,
  varClass,
  // copyToClipboard,
  parseQuery,
  navigateTo,
  responseError,
} from '../shared'
import { daisyconOauthTokens } from '../../services'
import { postRequest } from '../../services/api'

const connectionSort = (a, b) => {
  if (a.network.networkName < b.network.networkName) {
    return -1
  } else if (a.network.networkName > b.network.networkName) {
    return 1
  } else {
    return 0
  }
}

const networkSort = (a, b) => {
  if (a.networkName < b.networkName) {
    return -1
  } else if (a.networkName > b.networkName) {
    return 1
  } else {
    return 0
  }
}

export const Networks = (props) => {
  const [reloadRef, reload] = useCounter()

  const { showSnackbar } = useSnackbar()

  const channels = useApiGet('/channels/v1/list')
  const networks = useApiGet('/networks/v1/networks')
  const connections = useApiGet('/networks/v1/connections', {}, [reloadRef])

  return (
    <>
      <Header label="Networks" />
      <Loadable
        data={channels && networks && connections}
        placeholder={<Placeholder />}
      >
        <Connections
          channels={channels}
          networks={networks}
          connections={connections}
          showSnackbar={showSnackbar}
        />
        <AvailableNetworks
          channels={channels}
          networks={networks}
          reload={reload}
          showSnackbar={showSnackbar}
        />

        <ReactTooltip type="light" effect="solid" place="bottom" />
      </Loadable>
    </>
  )
}

const Connections = (props) => {
  const { connections, channels, networks, showSnackbar } = props

  const { showModal } = useModal()
  const itemProps = { channels, showModal, showSnackbar }

  const shownConnections = useMemo(() => {
    if (!connections || !Array.isArray(connections)) return []
    return uniqBy(
      connections.sort(connectionSort),
      (connection) => connection.network.networkUuid
    )
  }, [connections])

  return (
    <div
      className={varClass({
        'card m-b-20': true,
        'card-transparent': isBlank(connections),
      })}
    >
      <div className="card-body card-body-fill p-6 networks-active-networks-container">
        {map(shownConnections, (connection) => (
          <Connection
            key={connection.connectionUuid}
            connection={connection}
            network={
              networks?.find(
                (network) =>
                  network.networkUuid === connection.network.networkUuid
              ) || connection.network
            }
            {...itemProps}
          />
        ))}
        {isBlank(connections) && (
          <div className="row row-nowrap row-center">
            <SVG src="/images/empty-networks.svg" />
            <div className="column column-fill">
              <div className="text-bigger text-bolder m-b-10">
                No connections found
              </div>
              <div className="text-bold">
                To connect a network simply choose the network below and follow
                the instructions
              </div>
            </div>
            <SVG src="/images/empty-action2.svg" className="empty-action" />
          </div>
        )}
      </div>
    </div>
  )
}

const Connection = (props) => {
  const { network } = props

  // const { connection, network, showModal, showSnackbar } = props
  // const { connectionPartnerId: id, connectionAdvertisersCount: advertisers } =
  //   connection
  // const connectionStatus = connection.connectionIsActive ? 'active' : 'inactive'
  // const callbackStatus =
  //   connection.connectionCallbackStatus?.toLowerCase() || 'inactive'

  return (
    <div className="network">
      <div className="m-r-1">
        <div className="text-dark text-bold m-b-2">{network.networkName}</div>
        <div className="m-b-6">
          <a
            href={`https://${network.networkUrl}`}
            className="text-light text-small text-nowrap link vertical-middle"
            target="_blank"
            rel="noreferrer"
          >
            <span>{network.networkUrl}</span>
            <SVG src="/images/icon-outside.svg" className="m-l-2" />
          </a>
        </div>
        <a
          href={`/networks/${network.networkUuid}`}
          className="btn btn-bordered btn-xs text-dark text-smaller text-bolder"
        >
          View
        </a>
      </div>

      <div>
        <Logo
          src={network.networkLogoUrl}
          text={network.networkName}
          width={100}
          height={24}
          className="logo-right"
        />
      </div>

      {/*
      <div className="text-right">
        <div className="row row-end row-fill m-b-4">
          {false && (
            <button
              className="btn btn-xs btn-link inline-block text-smaller text-bolder m-l-3"
              onClick={() =>
                showModal(
                  <EditConnectionModal
                    connection={connection}
                    network={network}
                  />
                )
              }
            >
              Edit
            </button>
          )}
          <button
            className="btn btn-xs btn-link inline-block text-smaller text-bolder m-l-3"
            onClick={() =>
              showModal(<RemoveConnectionModal connection={connection} />)
            }
          >
            Delete
          </button>
        </div>

        <div className="row row-center row-end row-fill">
          {connectionStatus === 'active' && (
            <ConnectionStatus label="Connection" status="Active" icon="ok" />
          )}
          {connectionStatus === 'inactive' && (
            <ConnectionStatus label="Connection" status="Lost" icon="bad" />
          )}

          <SVG className="m-x-3" src="/images/get-started/dot.svg" />

          {callbackStatus === 'pending' && (
            <ConnectionStatus
              label="Real-time callback"
              status="Pending first lead"
              icon="pending"
              tip
              statusTip
              connection={connection}
              network={network}
            />
          )}
          {callbackStatus === 'active' && (
            <ConnectionStatus
              label="Real-time callback"
              status="Active"
              icon="ok"
              tip
              statusTip
              connection={connection}
              network={network}
            />
          )}
          {callbackStatus === 'inactive' && (
            <>
              <ConnectionStatus
                label="Real-time callback"
                status="Inactive"
                icon="bad"
                tip
                btnLabel="Setup"
                btnClick={() =>
                  showModal(
                    <SetupCallbackModal
                      connection={connection}
                      network={network}
                      showSnackbar={showSnackbar}
                    />
                  )
                }
              />
            </>
          )}
        </div>
      </div>
      */}
    </div>
  )
}

// const ConnectionStatus = (props) => {
//   const {
//     icon,
//     label,
//     status,
//     btnLabel,
//     btnClick,
//     tip,
//     statusTip,
//     connection,
//     network,
//   } = props
//
//   const [hovered, setHovered] = useState(false)
//
//   return (
//     <div className="row row-center row-space-between row-fill row-nowrap network-connection-status">
//       <div className="vertical-middle">
//         <SVG src={`/images/networks/status-${icon}.svg`} className="m-r-2" />
//         <span className="relative">
//           {statusTip ? (
//             <>
//               {label}: <b onMouseEnter={(e) => setHovered(true)}>{status}</b>{' '}
//               <ConnectionStatusTip
//                 load={hovered}
//                 connection={connection}
//                 network={network}
//               />
//             </>
//           ) : (
//             <>
//               {label}: <b>{status}</b>
//             </>
//           )}
//         </span>
//       </div>
//
//       {btnLabel && (
//         <button
//           className="link link-underlined text-smaller p-0 m-l-2"
//           onClick={btnClick}
//         >
//           {btnLabel}
//         </button>
//       )}
//
//       {tip && (
//         <div
//           className="no-text m-l-2"
//           data-tip="Real-time callbacks enables you to get live data from affiliate networks to Heylink Outbound instead of hourly or daily imports."
//         >
//           <SVG
//             src="/images/networks/status-info.svg"
//             className="cursor-pointer"
//           />
//         </div>
//       )}
//     </div>
//   )
// }

// const ConnectionStatusTip = (props) => {
//   const { load, connection, network } = props
//
//   const [loading, setLoading] = useState(false)
//   const [content, setContent] = useState('')
//
//   useEffect(async () => {
//     if (load && !content && !loading) {
//       setLoading(true)
//       const response = await postRequest(
//         `/networks/v1/connections/callbackurls`,
//         {
//           connectionUuid: connection.connectionUuid,
//           networkUuid: network.networkUuid,
//         }
//       )
//       setContent(response.data)
//     }
//   }, [load])
//
//   return (
//     <div className="network-connection-status-tip">
//       {content ? <pre>{content}</pre> : <SmallLoadable />}
//     </div>
//   )
// }

const AvailableNetworks = (props) => {
  const { networks, channels, reload, showSnackbar } = props

  const [search, setSearch] = useState('')

  const { showModal } = useModal()
  const itemProps = { channels, showModal, reload }

  const shownNetworks = useMemo(() => {
    if (!networks || !Array.isArray(networks)) return []
    return networks
      .sort(networkSort)
      .filter((network) => {
        if (
          search &&
          !network.networkName.toLowerCase().includes(search.toLowerCase())
        ) {
          return false
        }
        return true
      })
      .reduce((result, network) => {
        const letter = network.networkName[0].toUpperCase()
        result[letter] = result[letter] || []
        result[letter].push(network)
        return result
      }, {})
  }, [networks, search])

  useEffect(() => {
    const callback = async () => {
      // Daisycon special case
      const hash = location.hash
      if (!hash) return
      if (!hash.startsWith('#daisycon?code=')) return
      const query = parseQuery(hash.replace('#daisycon', ''))
      if (!query?.code) return
      const network = networks.find(
        (network) => network.networkIdentifier === 'daisycon'
      )
      if (!network) return
      const tokens = await daisyconOauthTokens(query.code)
      if (!tokens?.access_token || !tokens?.refresh_token) return

      const reconnectConnectionUuid = localStorage.getItem('daisycon-reconnect')
      if (reconnectConnectionUuid) {
        // Reconnect immediate submit
        localStorage.removeItem('daisycon-reconnect')
        const response = await postRequest(
          `/networks/v1/connections/reconnect/${reconnectConnectionUuid}`,
          {
            connectionApiKey: tokens.access_token,
            connectionApiPassword: tokens.refresh_token,
          }
        )
        if (response.code === 200) {
          navigateTo(`/networks/${network.networkUuid}`)
        } else {
          showSnackbar(responseError(response))
        }
      } else {
        // New connection with immediate submit
        showModal(
          <ConnectNetworkModal
            network={network}
            channels={channels}
            onSkip={reload}
            onDone={reload}
            submitConnection={{
              connectionApiKey: tokens.access_token,
              connectionApiPassword: tokens.refresh_token,
            }}
          />
        )
      }
    }
    callback()
  }, [])

  return (
    <div className="card">
      <div className="card-header">
        <Search
          value={search}
          setValue={setSearch}
          placeholder="Find network"
          className="search w-100 m-t-1 m-b--2"
          inputClassName="w-100"
        />
      </div>
      <div className="card-body">
        {map(Object.keys(shownNetworks).sort(), (letter) => (
          <React.Fragment key={letter}>
            <div className="text-lighter text-large text-bolder m-t-6 m-b-2">
              {letter}
            </div>
            <div className="networks-available-networks-container">
              {map(shownNetworks[letter], (network) => (
                <AvailableNetwork
                  key={network.networkUuid}
                  network={network}
                  {...itemProps}
                />
              ))}
            </div>
          </React.Fragment>
        ))}
        {isBlank(shownNetworks) && (
          <div className="flex-1 text-center text-light text-bolder m-y-2">
            Nothing found
          </div>
        )}
      </div>
    </div>
  )
}

const AvailableNetwork = (props) => {
  const { network, channels, reload, showModal } = props

  return (
    <div className="network">
      <div className="m-r-1">
        <div className="text-dark text-bold m-b-2">{network.networkName}</div>
        <div className="m-b-6">
          <a
            href={`https://${network.networkUrl}`}
            className="text-light text-small text-nowrap link vertical-middle"
            target="_blank"
            rel="noreferrer"
          >
            <span>{network.networkUrl}</span>
            <SVG src="/images/icon-outside.svg" className="m-l-2" />
          </a>
        </div>
        <button
          className="btn btn-dark btn-xs text-smaller text-bolder"
          onClick={() => {
            showModal(
              <ConnectNetworkModal
                network={network}
                channels={channels}
                onSkip={reload}
                onDone={reload}
              />
            )
          }}
        >
          Connect
        </button>
      </div>

      <div>
        <Logo
          src={network.networkLogoUrl}
          text={network.networkName}
          width={100}
          height={24}
          className="logo-right"
        />
      </div>
    </div>
  )
}

// const SetupCallbackModal = (props) => {
//   const { connection, network, showSnackbar } = props
//
//   const { hideModal } = useModal()
//
//   const callback = useApiPost(
//     `/networks/v1/connections/callbackurls`,
//     {
//       connectionUuid: connection.connectionUuid,
//       networkUuid: network.networkUuid,
//     },
//     [connection.connectionUuid, network.networkUuid]
//   )
//
//   return (
//     <>
//       <div className="card-header">
//         <div className="card-title">
//           Setup real-time callback for {network.networkName}
//         </div>
//       </div>
//       <div className="card-body">
//         <div className="m-b-5">
//           Real-time callbacks enables you to get live data from affiliate
//           networks to Heylink Outbound instead of hourly or daily imports.
//         </div>
//
//         <SmallLoadable loaded={callback} height={134}>
//           <div className="notice-alert text-breakword p-4 m-b-3">
//             {callback}
//           </div>
//         </SmallLoadable>
//
//         <div className="m-b-5">
//           <button
//             className="btn text-bolder"
//             onClick={() => {
//               copyToClipboard(callback || '')
//               showSnackbar('Copied to clipboard')
//             }}
//           >
//             <SVG src="/images/icon-copy.svg" className="m-r-2" />
//             Copy
//           </button>
//         </div>
//
//         <div className="m-b-5">
//           Please refer to your affiliate network documentation on how to setup
//           callbacks.
//         </div>
//
//         <div className="text-right">
//           <button className="btn btn-primary" onClick={() => hideModal()}>
//             I did it
//           </button>
//         </div>
//       </div>
//     </>
//   )
// }
