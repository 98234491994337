import React, { useEffect, useMemo, useState } from 'react'
import SVG from 'react-inlinesvg'
import ReactTooltip from 'react-tooltip'
import { Placeholder } from './Placeholder'
import { navigate } from 'gatsby'
import {
  INetworksV2Networks,
  filterBySearch,
  Header,
  Loadable,
  Logo,
  map,
  Search,
  useApiGet,
  useCounter,
  varClass,
} from '../shared'

export const Network2 = (props) => {
  const [reloadRef, reload] = useCounter()
  const [search, setSearch] = useState('')
  const [networks, setNetworks] = useState([])

  const networksV2 = useApiGet<INetworksV2Networks[]>('/networks/v2/networks')

  useEffect(() => {
    if (search && search.trim()) {
      const searched = networksV2?.filter((n) =>
        n.networkName.toLowerCase().includes(search.trim().toLowerCase())
      )
      setNetworks(searched)
    } else {
      setNetworks(networksV2)
    }
  }, [networksV2, search])

  return (
    <>
      <Header label="Networks" />
      <Loadable data={networks} placeholder={<Placeholder />}>
        <Content {...{ networks, search, setSearch }} />
      </Loadable>
    </>
  )
}

const Content = (props) => {
  const { search, setSearch, networks } = props

  return (
    <div className="card network2-card">
      <div className="text-primary text-bold m-b-4">
        Discover networks among{' '}
        <span className="text-new-blue">
          {networks?.length?.toLocaleString()}
        </span>{' '}
        networks
      </div>

      <Nav
        {...{
          search,
          setSearch,
        }}
      />

      <NetworkList {...{ networks, search }} />
    </div>
  )
}

const Nav = (props) => {
  const { search, setSearch } = props
  const [shownSearch, setShownSearch] = useState(search)

  return (
    <div className="page-nav m-b-4">
      <div className="row m-b-4">
        <div className="flex-1 vertical-middle">
          <Search
            value={shownSearch}
            setValue={setShownSearch}
            onSubmit={() => {
              setSearch(shownSearch)
            }}
            onBlur={() => {
              setSearch(shownSearch)
            }}
            placeholder="Find networks"
            className="search w-60"
            inputClassName="w-100 p-r-8 grey-input"
            highlight={!!shownSearch}
          />
          <button
            className={varClass({
              'link link-new-lighter': true,
              'o-0 unclickable': !shownSearch,
              'o-100': shownSearch,
            })}
            style={{
              position: 'relative',
              top: 2,
              left: -32,
              paddingTop: 4,
              marginTop: -4,
            }}
            onClick={() => {
              setShownSearch('')
              setSearch('')
            }}
          >
            <SVG src="/images/icon-times.svg" />
          </button>
          <button
            className="btn btn-white text-small text-bold m-l--6"
            onClick={() => {
              setSearch(shownSearch)
            }}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  )
}

const NetworkList = ({ networks, search }) => {
  const [tab, setTab] = useState('all')
  const [showConnectedNetworks, setShowConnectedNetworks] = useState(true)
  const [showRecommendations, setShowRecommendations] = useState(true)
  const [showCustomNetworks, setShowCustomNetworks] = useState(true)

  const showNetworks = filterBySearch(networks, search, (network: any) => [
    network.networkUrl,
    network.networkName,
  ])

  const tabs = useMemo(() => {
    if (networks && networks.length) {
      const connectedNetworks = networks.filter(
        (network) => network.networkIsConnected
      )
      const recommendations = networks.filter(
        (network) =>
          !network.networkIsConnected && network.networkHasOpportunities
      )
      const customNetworks = networks.filter(
        (network) => network.networkIsCustom
      )
      return {
        all: {
          label: 'All networks',
          count: networks?.length,
        },
        connectedNetworks: {
          label: 'Connected networks',
          items: connectedNetworks,
          count: connectedNetworks?.length,
        },
        recommendations: {
          label: 'Recommendations',
          items: recommendations,
          count: recommendations?.length,
        },
        customNetworks: {
          value: 'customNetworks',
          label: 'Custom Networks',
          items: customNetworks,
          count: customNetworks?.length,
        },
      }
    }
  }, [networks])

  useEffect(() => {
    if (tab === 'all') {
      setShowConnectedNetworks(!!tabs?.connectedNetworks?.count)
      setShowRecommendations(!!tabs?.recommendations?.count)
      setShowCustomNetworks(!!tabs?.customNetworks?.count)
    }
    if (tab === 'connectedNetworks') {
      setShowConnectedNetworks(!!tabs?.connectedNetworks?.count)
      setShowRecommendations(false)
      setShowCustomNetworks(false)
    }

    if (tab === 'recommendations') {
      setShowConnectedNetworks(false)
      setShowRecommendations(!!tabs?.recommendations?.count)
      setShowCustomNetworks(false)
    }

    if (tab === 'customNetworks') {
      setShowConnectedNetworks(false)
      setShowRecommendations(false)
      setShowCustomNetworks(!!tabs?.customNetworks?.count)
    }

    if (tabs && !tabs[tab].count) {
      setTab('all')
    }
  }, [tabs, tab])

  return (
    <div>
      {tabs ? (
        <>
          <div className="page-subnav">
            <div className="nav p-y-2 m-x--5 m-t-0 m-b-1">
              <ul>
                {map(Object.entries(tabs), ([key, item]) => {
                  if (item.count) {
                    return (
                      <li key={key} className={tab === key ? 'active' : ''}>
                        <button className="link" onClick={() => setTab(key)}>
                          {item.label}
                          <div className="tab-count">
                            {Math.min(item.count, 99)}
                          </div>
                        </button>
                      </li>
                    )
                  }
                  return null
                })}
              </ul>
            </div>
          </div>

          {showConnectedNetworks && (
            <div className="m-t-25">
              <div className="row row-center row-space-between p-x-15">
                <div className="text-bigger text-bold text-primary">
                  Connected networks
                </div>
                {tab !== 'connectedNetworks' && (
                  <button
                    className="link link-light text-bold"
                    onClick={() => {
                      setTab('connectedNetworks')
                    }}
                  >
                    View all ({tabs['connectedNetworks'].count.toLocaleString()}
                    )
                  </button>
                )}
              </div>
              <div className="row m-t-20">
                <div className="network-grid-items">
                  {map(tabs['connectedNetworks'].items, (item, index) => (
                    <NetworkItem key={item.networkUuid} network={item} />
                  ))}
                </div>
              </div>
            </div>
          )}

          {showRecommendations && (
            <div className="m-t-50">
              <div className="row row-center row-space-between p-x-15">
                <div className="text-bigger text-bold text-primary">
                  Recommendations
                </div>
                {tab !== 'recommendations' && (
                  <button
                    className="link link-light text-bold"
                    onClick={() => {
                      setTab('recommendations')
                    }}
                  >
                    View all ({tabs['recommendations'].count.toLocaleString()})
                  </button>
                )}
              </div>
              <div className="row m-t-20">
                <div className="network-grid-items">
                  {map(tabs['recommendations'].items, (item, index) => (
                    <NetworkItem key={item.networkUuid} network={item} />
                  ))}
                </div>
              </div>
            </div>
          )}

          {showCustomNetworks && (
            <div className="m-t-50">
              <div className="row row-center row-space-between p-x-15">
                <div className="text-bigger text-bold text-primary">
                  Custom Networks
                </div>
                {tab !== 'customNetworks' && (
                  <button
                    className="link link-light text-bold"
                    onClick={() => {
                      setTab('customNetworks')
                    }}
                  >
                    View all ({tabs['customNetworks'].count.toLocaleString()})
                  </button>
                )}
              </div>
              <div className="row m-t-20">
                <div className="network-grid-items">
                  {map(tabs['customNetworks'].items, (item, index) => (
                    <NetworkItem key={item.networkUuid} network={item} />
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <NotFoundNetwork />
      )}
    </div>
  )
}

const NotFoundNetwork = () => {
  return (
    <div className="column column-center column-fill text-center m-t-60 m-b-40 w-100">
      <SVG src="/images/merchants/no-search-illustration.svg" />
      <div className="text-larger text-bolder text-dark m-t-30">
        No networks found
      </div>
      <div className="m-t-4" style={{ maxWidth: '60%' }}>
        We couldn't find any results. Change selected filters or search.
      </div>
    </div>
  )
}

const NetworkItem = (props) => {
  const { network } = props
  return (
    <div
      className="network-item column column-fill column-space-between card-border card-border-rounded cursor-pointer relative"
      onClick={() => navigate(`/network2/${network.networkUuid}`)}
    >
      <div className="flex-2 flex-center w-100 h-100 ">
        <Logo
          src={network.networkLogoUrl}
          text={network.networkName}
          width={100}
          height={24}
        />
      </div>
      <div className="divide" />
      <div className="network-status text-new-light text-regular text-normal row row-middle row-center">
        {network.networkHasOpportunities ? (
          <div className="flex-center">
            <SVG
              src="/images/recommendations/icon-campaigns.svg"
              className="network-icon"
            />{' '}
            <span className="m-l-2">Non-monetised clicks</span>
          </div>
        ) : network.networkHasAlternatives ? (
          <div className="flex-center">
            <SVG
              src="/images/recommendations/icon-alt-campaigns.svg"
              className="network-icon"
            />{' '}
            <span className="m-l-2">Commission boost found</span>
          </div>
        ) : network.networkIsConnected ? (
          <div>Connected</div>
        ) : (
          <div>Not connected</div>
        )}
      </div>
    </div>
  )
}
