import React from 'react'
import { Load } from '../../shared'

export const Placeholder = (props) => {
  return (
    <>
      <div className="card" style={{ height: 135 }}>
        <div className="m-t-15">
          <Load width={160} height={24} />
        </div>
      </div>
      <div className="card" style={{ height: 89, marginTop: 84 }}>
        <div className="m-t-15">
          <Load width={100} height={24} />
        </div>
      </div>
      <div className="card m-t-10" style={{ height: 89 }}>
        <div className="m-t-15">
          <Load width={100} height={24} />
        </div>
      </div>
    </>
  )
}
