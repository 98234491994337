import React from 'react'
import { Load } from '../../shared'

export const Placeholder = (props) => {
  return (
    <>
      <div className="card" style={{ height: 'calc(100vh - 186px)' }}>
        <div className="card-body" style={{ paddingTop: 52 }}>
          <Load width={200} height={30} />
          <div className='card-body-fill m-t-3'>
            <Load height={49} />
          </div>
        </div>
      </div>
    </>
  )
}
