import React, { useState, useCallback } from 'react'
import SVG from 'react-inlinesvg'
import { uploadFileRequest } from '../../../services'
import { useModal, varClass } from '../../shared'

export const UploadBolModal = (props) => {
  const { selectedConnection, reload } = props

  const { hideModal } = useModal()

  const [error, setError] = useState('')
  const [confirm, setConfirm] = useState(false)
  const [checking, setChecking] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [preview, setPreview] = useState(null)
  const [file, setFile] = useState(null)
  const [filename, setFilename] = useState('')
  const [editingName, setEditingName] = useState(false)

  const checkFile = useCallback((file) => {
    setChecking(true)

    if (!file.name.startsWith('order_rapportage')) {
      setError('Incorrect file - expected Bol.com Orders report')
      setChecking(false)
      return
    }

    const datesStr = file.name
      .replace('order_rapportage_', '')
      .replace('.xlsx', '')
    const dateSplitIndex = 10
    const fromDate = datesStr.substring(0, dateSplitIndex)
    const toDate = datesStr.substring(dateSplitIndex + 1)

    const newPreview = [
      `<span>Conversion dates:</span><span>${fromDate} – ${toDate}</span>`,
    ]
    setPreview(`<div>${newPreview.join('</div><div>')}</div>`)
    setChecking(false)
  }, [])

  const uploadFile = useCallback(async () => {
    setSubmitting(true)
    const response = await uploadFileRequest(
      `/conversion_uploads/v1/${selectedConnection.connectionUuid}`,
      file,
      {
        name: filename,
      }
    )
    setSubmitting(false)

    if (response.code === 200) {
      hideModal()
      reload()
    } else {
      setError(response.message)
    }
  }, [file, filename, selectedConnection])

  if (submitting) {
    return (
      <>
        <div className="card-header">
          <div className="card-title">Upload conversions</div>
        </div>
        <div className="card-body">
          <div className="m-t-8">
            <p className="m-b-9">
              Your file is being uploaded — please be aware that it can take up
              to 10 minutes before you see the conversions in the reporting
            </p>

            <div className="row row-top row-space-between row-nowrap">
              <div className="text-red text-big text-bold m-t-2" />

              <div className="row row-nowrap">
                <div
                  className="loader-sm"
                  style={{ width: 16, height: 16, margin: 0, marginTop: 10 }}
                />

                <button className="btn btn-primary" disabled={true}>
                  Uploading...
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title">Upload conversions</div>
      </div>
      <div className="card-body">
        <div className="network-conversion-upload m-t-4 m-b-5">
          <div
            className="display-inline-block"
            style={{ width: 102, height: 36 }}
          >
            <input
              type="file"
              accept=".xlsx"
              onChange={(event) => {
                if (event.target.files[0]) {
                  setError('')
                  setFile(event.target.files[0])
                  setFilename(event.target.files[0].name)
                  checkFile(event.target.files[0])
                } else {
                  setFile(null)
                  setFilename('')
                  setPreview(null)
                }
              }}
            />
          </div>
        </div>
        {preview ? (
          <div className="m-t-8">
            <div className="notice-info m-b-4">
              <SVG src="/images/notice-info.svg" className="m-r-2" />
              <div>
                <div>Please verify the content below</div>
              </div>
            </div>

            <div className="network-conversion-upload-preview m-b-2">
              {editingName ? (
                <div>
                  <span>File name:</span>
                  <span className="control">
                    <input
                      className="input"
                      value={filename}
                      onChange={(event) => setFilename(event.target.value)}
                    />
                    <button
                      className="btn btn-link btn-borderless btn-xs filename-edit-toggle"
                      onClick={() => setEditingName(false)}
                    >
                      <SVG src="/images/icon-check.svg" />
                    </button>
                  </span>
                </div>
              ) : (
                <div>
                  <span>File name:</span>
                  <span>
                    {filename}

                    <button
                      className="btn btn-link btn-borderless btn-xs filename-edit-toggle"
                      onClick={() => setEditingName(true)}
                    >
                      <SVG src="/images/icon-edit.svg" />
                    </button>
                    {filename !== file?.name && (
                      <button
                        className="btn btn-link btn-borderless btn-xs filename-reset-toggle"
                        onClick={() => setFilename(file.name)}
                      >
                        <SVG src="/images/icon-times.svg" />
                      </button>
                    )}
                  </span>
                </div>
              )}
            </div>

            <div
              className="network-conversion-upload-preview"
              dangerouslySetInnerHTML={{ __html: preview }}
            />

            <div className="control control-checkbox m-t-6 m-b-4">
              <input
                type="checkbox"
                checked={confirm}
                onChange={(event) => setConfirm(event.target.checked)}
              />
              <label>Yes — everything looks correct</label>
            </div>

            <div className="row row-top row-space-between row-nowrap">
              <div className="text-red text-big text-bold m-t-2">
                {error || null}
              </div>

              <div className="row row-nowrap">
                <div
                  className={varClass({
                    'loader-sm': true,
                    'o-0': !submitting,
                  })}
                  style={{ width: 16, height: 16, margin: 0, marginTop: 10 }}
                />

                <button
                  className="btn btn-primary"
                  disabled={!confirm || checking || submitting}
                  onClick={uploadFile}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        ) : error ? (
          <div className="text-red text-big text-bold text-center m-t-4">
            {error || null}
          </div>
        ) : null}
      </div>
    </>
  )
}
